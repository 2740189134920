import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'

import { setModal, setPlaceholder } from '../../../../store/actions/emailTemplateActions'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import Flex from '../../../../components/layouts/Flex'
import RichTextbox from '../../../../components/dashboard/controls/RichTextBox'

const SetPlaceholder = () => {
  const { addToast } = useToasts()
  const { selectedEmailTemplate } = useSelector((state) => state.emailTemplates)

  const dispatch = useDispatch()

  const [value, setValue] = useState('')

  function handleClose() {
    if (selectedEmailTemplate.id) {
      dispatch(setModal('updateEmailTemplate'))
    } else {
      dispatch(setModal('createEmailTemplate'))
    }
  }

  function handleSetPlaceholder() {
    if (value.trim() === '')
      return addToast('Placeholder is required.', {
        appearance: 'error',
        autoDismiss: true,
      })
    dispatch(setPlaceholder(value))
    handleClose();
  }

  return (
    <MainModal
      title="Add Placeholder"
      onClick={handleSetPlaceholder}
      onClose={handleClose}
      buttonText="Add"
    >
      <Flex gap="0.4rem">
        <RichTextbox
          label="Placeholder"
          content={value}
          setContent={(val) => setValue(val)}
          additionalConfig={
            {
              cleanHTML: {
                fillEmptyParagraph: false
              },
              enter: 'br'
            }
          }
          customButtons={['hr', 'source', 'align']}
        />
      </Flex>
    </MainModal>
  )
}

export default SetPlaceholder
