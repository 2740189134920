import React, { memo, useEffect, useState, useMemo } from 'react'

import useMutation from '../../../hooks/useMutation'

import { useSelector } from 'react-redux'

import CreatableSelect from 'react-select/creatable'

import { useToasts } from 'react-toast-notifications'

import styled from 'styled-components'

import { P } from '../../../style/Typography.style'
import Flex from '../../layouts/Flex'
import DashboardButton from '../DashboardButton'
import { BorderlessButton } from './AddNote'

import RangeSlider from './RangeSlider'

import axios from 'axios'

import { baseUrl } from '../../../constant/constant'

const Skills = (data) => {
  const talent = data.talent

  const { selectedStage } = useSelector((state) => state.requisitions)

  const { addToast } = useToasts()
  const { activities } = useSelector((state) => state.modal)

  const [options, setOptions] = useState([])
  const [selectedSkills, setSelectedSkills] = useState([])
  const [loadingOpt, setLoadingOpt] = useState(false)

  const cachedData = useMemo(() => localStorage.getItem('cachedData'))

  const { error, handleRequest, loading, success } = useMutation()

  const handleSubmit = () => {
    const payload = {
      applicant_id: talent.id,
      skills_meta: JSON.stringify(selectedSkills),
      user_id: parseInt(localStorage.getItem('id')),
    }

    handleRequest('/recruiter-skill-input', payload)
  }

  const handleChangeOptions = (selectedOptions) => {
    if (selectedOptions.length > 5) {
      addToast('Please enter 5 skills only.', { appearance: 'error' })
      return
    }
    const newTechSkills = selectedOptions
      .map((skill) => ({
        value: skill.skill,
        label: skill.skill,
        skill: skill.skill,
        experience: skill?.experience,
        id: skill.id || skill.skill,
      }))
      .filter((skill) => skill.label)

    setSelectedSkills(newTechSkills)
    cacheSkill(newTechSkills)
  }

  const handleReset = () => {
    setSelectedSkills([])
    cacheSkill([])
  }

  const getTechnicalSkills = (ids) => {
    setLoadingOpt(true)
    axios
      .get(`${baseUrl}/technical-skills?all=true`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('recruiterToken')}`,
        },
      })
      .then((res) => {
        const techSkills = res.data.resp
        const talentTechSkills = talent?.recruiter_input_skills
          ? JSON.parse(talent?.recruiter_input_skills)
          : []

        const parsedCacheData = JSON.parse(cachedData) || []
        const skillCache = parsedCacheData.skill || []
        const talentData = skillCache
          ? skillCache.filter((e) => e.applicant_id === talent?.id)
          : []

        if (talentData.length > 0) {
          setSelectedSkills(talentData[0].skill)
        } else if (talentTechSkills.length > 0) {
          setSelectedSkills(talentTechSkills)
        } else {
          setSelectedSkills([])
        }

        const newTechSkills = techSkills.map((skill) => ({
          value: skill.skills,
          label: skill.skills,
          skill: skill.skills,
          experience: skill?.experience || 1,
        }))

        setOptions(newTechSkills)
        setLoadingOpt(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const cacheSkill = (skills) => {
    const parsedCacheData = JSON.parse(cachedData) || []
    let skillCache = parsedCacheData.skill || []

    const talentData = skillCache.filter((e) => e.applicant_id === talent?.id)

    if (talentData.length === 0) {
      skillCache.push({
        applicant_id: talent?.id,
        skill: skills,
      })
    } else {
      skillCache = skillCache.map((e) => {
        if (e.applicant_id === talent?.id) {
          e.applicant_id = talent?.id
          e.skill = skills
        }
        return e
      })
    }

    if (skills.length === 0) {
      const newCache = skillCache.filter((e) => e.applicant_id !== talent?.id)
      localStorage.setItem(
        'cachedData',
        JSON.stringify({
          ...parsedCacheData,
          skill: newCache,
        })
      )
    } else {
      localStorage.setItem(
        'cachedData',
        JSON.stringify({
          ...parsedCacheData,
          skill: skillCache,
        })
      )
    }
  }

  useEffect(() => {
    const professionalHeadlineId = []

    if (talent) {
      if (
        talent.professional_headline &&
        talent.professional_headline.length > 0
      ) {
        const professionalHeadlines = JSON.parse(talent.professional_headline)
        professionalHeadlines.forEach((headline) => {
          if (!professionalHeadlineId.includes(headline.id)) {
            professionalHeadlineId.push(headline.id)
          }
        })
      }
      getTechnicalSkills(JSON.stringify(professionalHeadlineId))
    }
  }, [])

  useEffect(() => {
    if (success) {
      cacheSkill([])
      addToast('Skills saved successfully.', { appearance: 'success' })
    }

    if (error) {
      addToast(error?.response?.data, { appearance: 'error' })
    }
  }, [success, error])

  if (loadingOpt) return <P>Loading...</P>

  return (
    options && (
      <StyledSkills activities={activities}>
        <CreatableSelect
          isMulti
          options={options}
          value={selectedSkills}
          onChange={handleChangeOptions}
          placeholder="Select skills..."
          isSearchable
          isDisabled={selectedStage === 'closed'}
          isValidNewOption={() => false}
        />

        {selectedSkills
          ?.sort(function (a, b) {
            if (a.skill < b.skill) {
              return -1
            }
            if (a.skill > b.skill) {
              return 1
            }
            return 0
          })
          .map(({ skill, experience, id }) => (
            <RangeSlider
              key={id}
              skill={skill}
              experience={experience}
              id={id}
              setSkills={handleChangeOptions}
              skills={selectedSkills}
              disabled={selectedStage === 'closed'}
            />
          ))}
        {selectedSkills.length > 0 && selectedStage !== 'closed' && (
          <StyledButtons>
            <Flex items="center" justify="flex-end" gap="1.5rem">
              <BorderlessButton type="reset" onClick={handleReset}>
                Clear
              </BorderlessButton>
              <DashboardButton
                type="button"
                text={loading ? 'Loading...' : 'Save'}
                notIcon
                onClick={handleSubmit}
              />
            </Flex>
          </StyledButtons>
        )}
      </StyledSkills>
    )
  )
}

export default memo(Skills)

const StyledSkills = styled.div`
  min-height: 40vh;
  width: 100%;
  padding: 0 1rem;
  padding-bottom: 2rem;
  position: relative;

  &::after {
    content: '';
    position: absolute;
  }

  &::before {
    content: '';
    position: absolute;
  }
`
const StyledButtons = styled.div`
  margin-bottom: 2rem;
  margin-top: 2rem;
`
