import React from 'react'
import { useSelector } from 'react-redux'
import Flex from '../../../components/layouts/Flex'
import CreateJobSidebar from './components/CreateJobSidebar'
import Qualification from './components/Qualification'
import ReviewPost from './components/ReviewPost'
import JobDetails from './components/JobDetails'
import Salaries from './components/Salaries'

const CreateJobPost = () => {
  const { step } = useSelector((state) => state.jobPosting)

  return (
    <Flex margin="1.5rem 2rem" marginMobile="1.5rem 0" mobileDirection="column">
      <CreateJobSidebar />
      {step === 1 && <JobDetails />}
      {step === 2 && <Salaries />}
      {step === 3 && <Qualification />}
      {step === 4 && <ReviewPost />}
    </Flex>
  )
}

export default CreateJobPost
