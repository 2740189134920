const os = ['Windows', 'MacOs', 'Linux', 'Others']
const processor = [
  'i3',
  'i5',
  'i7',
  'i9',
  '8-core CPU with 4 performance cores and 4 efficiency cores',
  '8-core GPU',
  '16-core Neural Engine',
  'Others',
]
const ram = ['2', '4', '8', '16', '32', 'Others']

export const specifications = {
  os: os.map((e, i) => ({ value: i + 1, label: e })),
  processor: processor.map((e, i) => ({ value: i + 1, label: e })),
  ram: ram.map((e, i) => ({ value: i + 1, label: e })),
}
