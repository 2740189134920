import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'

import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { BsThreeDots } from 'react-icons/bs'

import JobDetail from './components/JobDetail'
import Qualification from './components/Qualification'
import SalaryAndBenefits from './components/SalaryAndBenefits'
import { UpdateJob } from './components/updateJob'
import ConfirmCloseJob from './components/ConfirmCloseJob'
import Loader from '../../../components/Loader'
import ShareJobPostModal from '../job-posting/components/ShareJobPostModal'

import { setModal, selectJob } from '../../../store/actions/jobListingActions'
import { setShareJobPostModal } from '../../../store/actions/modalActions'
import { setActiveJobId } from '../../../store/actions/jobPostingActions'

import axiosInstance from '../../../services/config/AxiosIntance'

import { capitalize } from '../../../utils/capitalize'

const JobListingDetails = () => {
  const dispatch = useDispatch()

  const { modal, selectedJob, jobChanged } = useSelector(
    (state) => state.jobListing
  )

  const [tab, setTab] = useState('Job Details')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [showMenu, setShowMenu] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const { id } = useParams()

  useEffect(() => {
    if (id) {
      setLoading(true)
      axiosInstance
        .get('/jobs', {
          params: {
            id: id,
          },
        })
        .then((data) => {
          setData(data?.data?.data?.jobs[0])
          setLoading(false)
        })
    }
  }, [id, selectedJob, jobChanged])

  const handleCloseJobModal = () => {
    dispatch(setModal('closeJob'))
  }

  const handleShareJobPostModal = () => {
    dispatch(setActiveJobId(id))
    dispatch(setShareJobPostModal(true))
  }

  const handleListingDropdown = () => {
    setOpenModal(true)
    dispatch(selectJob(data && JSON.stringify(data)))
  }

  return (
    <>
      <StyledJobListingDetails>
        {showMenu && (
          <div className="menu-wrapper">
            <div className="menu-item" onClick={handleListingDropdown}>
              {data?.status === 'closed' ? 'View Listing' : 'Edit Listing'}
            </div>
            {data?.status === 'active' && (
              <div className="menu-item" onClick={handleCloseJobModal}>
                Close Vacancy
              </div>
            )}
            {data?.status !== 'closed' && (
              <div className="menu-item" onClick={handleShareJobPostModal}>
                Share Listing
              </div>
            )}
          </div>
        )}

        {openModal && (
          <UpdateJob id={id} data={data} setOpenModal={setOpenModal} />
        )}

        <ShareJobPostModal />

        {modal === 'closeJob' && <ConfirmCloseJob />}

        <div className="heading_wrapper">
          <p
            className={tab === 'Job Details' ? 'active' : ''}
            onClick={() => setTab('Job Details')}
          >
            Job Details
          </p>
          <p
            className={tab === 'Salary and Benefits' ? 'active' : ''}
            onClick={() => setTab('Salary and Benefits')}
          >
            Payment and Benefits
          </p>
          <p
            className={tab === 'Qualifications' ? 'active' : ''}
            onClick={() => setTab('Qualifications')}
          >
            Qualifications
          </p>

          <div
            onClick={() => setShowMenu(!showMenu)}
            className={showMenu ? 'icon-wrapper active' : 'icon-wrapper'}
          >
            <BsThreeDots className="dotted-icons" />
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="content_wrapper">
            {tab === 'Job Details' && (
              <JobDetail
                title={data?.title}
                jobType={capitalize(data?.job_type)}
                description={data?.description}
                timeShift={data?.time_shift}
                status={data?.status}
                activeVacancy={data?.active_vacancy}
                vacancyPooling={data?.vacancy_for_pooling}
                client={data?.client}
                requiresCoaching={data?.requires_coaching}
                priority={data?.priority}
                postedBy={data?.recruiter?.name}
                contact={
                  data?.pointOfContacts?.first_name +
                  ' ' +
                  data?.pointOfContacts?.middle_name +
                  ' ' +
                  data?.pointOfContacts?.last_name
                }
                countries={data?.countries}
              />
            )}

            {tab === 'Salary and Benefits' && (
              <SalaryAndBenefits
                minSalary={data?.min_salary}
                maxSalary={data?.max_salary}
                benefits={data?.benefits}
                basis={data?.salary_basis}
                currencies={data?.currencies}
              />
            )}

            {tab === 'Qualifications' && (
              <Qualification
                experience={data?.experience}
                software={data?.software}
                technicalSkills={data?.technical_skills}
                education={data?.education}
                language={data?.language}
                availability_to_start={data?.availability_to_start}
                certification={data?.certification}
                hardware_requirements={data?.hardware_requirement}
                personal_skills={data?.personal_skills}
                shift_availability={data?.shift_availability}
              />
            )}
          </div>
        )}
      </StyledJobListingDetails>
    </>
  )
}

export default JobListingDetails

const StyledJobListingDetails = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #eeeef7;
  padding: 1.5rem;
  margin-top: 0.5rem;
  overflow-y: auto;

  .icon-wrapper {
    position: absolute;
    top: 3rem;
    right: 1.5rem;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &.active {
      background: #eeeef6;
    }

    .dotted-icons {
      font-size: 1rem;
    }
  }

  .menu-wrapper {
    position: absolute;
    top: 5rem;
    right: 1.5rem;
    width: 172px;
    min-height: 3rem;
    border: 1px solid #eeeef6;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 300;
    gap: 0.5rem;

    .menu-item {
      cursor: pointer;
      width: 100%;
      height: 100%;

      &:hover {
        background: #eeeef6;
      }
    }
  }

  .heading_wrapper {
    display: flex;
    align-items: center;
    gap: 4rem;

    p {
      font-size: 1rem;
      font-weight: 300;
      color: #0e1324;
      cursor: pointer;
      position: relative;

      &.active {
        &:after {
          position: absolute;
          content: '';
          bottom: 0;
          width: 100%;
          left: 0;
          height: 1px;
          background: #1877f2;
        }
      }
    }
  }

  .content_wrapper {
    width: 100%;
    height: 100%;
  }
`
