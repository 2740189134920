import React from 'react'
import styled from 'styled-components'

import { ColorRing } from 'react-loader-spinner'

const Loader = () => {
  return (
    <StyledLoader>
      <ColorRing
        visible={true}
        height="40"
        width="40"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={['#000']}
      />
    </StyledLoader>
  )
}

export default Loader

const StyledLoader = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`
