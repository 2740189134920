/* eslint-disable camelcase */
import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'

import TagInput from '../../../../components/dashboard/controls/TagInput'
import DashboardInput from '../../../../components/dashboard/DashboardInput'
import Modal from '../../../../components/dashboard/Modal'
import {
  SingleDropdownSelect,
  SingleDropdownSlimSelect,
} from '../../../../components/dashboard/TalentPool/DropdownSelect'
import Flex from '../../../../components/layouts/Flex'
import { currencyList } from '../../../../../src/constant/currencies'

import {
  SET_BENEFITS,
  SET_MAX_SALARY,
  SET_MIN_SALARY,
  SET_SALARY_BASIS,
  SET_CURRENCY,
} from '../../../../store/types/jobPosting'
import {
  SET_REVIEW_POST_MODAL,
  SHOW_MODAL,
  HIDE_MODAL,
} from '../../../../store/types/modal'

const SalaryModal = () => {
  const dispatch = useDispatch()
  const [allCurrencies, setCurrencies] = useState([])

  const { min_salary, max_salary, benefits, salary_basis, currencies } =
    useSelector((state) => state.jobPosting)

  const [initialState, setIntialState] = useState({
    salary_basis: '',
    min_salary: '',
    max_salary: '',
    benefits: [],
    currencies: '',
  })

  useMemo(() => {
    Object.keys(currencyList[0])?.forEach((curr) => {
      setCurrencies((allCurrencies) => [
        ...allCurrencies,
        { value: curr, label: curr },
      ])
    })
    return allCurrencies
  }, [currencyList])

  useEffect(() => {
    setIntialState(() => ({
      salary_basis,
      min_salary,
      max_salary,
      benefits,
      currencies,
    }))
  }, [])

  const handleTags = (tags) => {
    dispatch({ type: SET_BENEFITS, payload: tags })
  }

  const handleSalaryUpdate = () => {
    if (min_salary === '')
      return toast.error('Please enter minimum payment', { type: 'error' })
    if (max_salary === '')
      return toast.error('Please enter maximum payment', { type: 'error' })
    if (Number(min_salary) > Number(max_salary))
      return toast.error('Minimum salary should be less than Maximum Salary', {
        type: 'error',
      })
    if (salary_basis === '')
      return toast.error('Please select basic payment ', { type: 'error' })
    if (benefits.length === 0)
      return toast.error('Please add at least one benefit', { type: 'error' })

    dispatch({ type: SHOW_MODAL })
    dispatch({ type: SET_REVIEW_POST_MODAL, payload: '' })
  }

  const onClose = () => {
    dispatch({ type: SET_BENEFITS, payload: initialState.benefits })
    dispatch({ type: SET_SALARY_BASIS, payload: initialState.salary_basis })
    dispatch({ type: SET_MIN_SALARY, payload: initialState.min_salary })
    dispatch({ type: SET_MAX_SALARY, payload: initialState.max_salary })
    dispatch({ type: SET_CURRENCY, payload: initialState.currencies })
    dispatch({ type: HIDE_MODAL })
  }

  return (
    <Modal
      height="auto"
      title="Payment and Benefits"
      btnFunc={handleSalaryUpdate}
      btnTitle="Update"
      onClose={onClose}
    >
      {/* creating three section input for web the stack them over each other on mobile */}
      <Flex width="100%" mobileDirection="column" gap="1rem">
        {/* Salary types */}
        <SingleDropdownSelect
          value={salary_basis}
          onChange={(e) =>
            dispatch({ type: SET_SALARY_BASIS, payload: e.target.value })
          }
          maxw="8rem"
          title="Basis"
        >
          <option>Choose an option</option>
          <option value="Per Month">Per Month</option>
          <option value="Per Year">Per Year</option>
        </SingleDropdownSelect>

        {/* creating double type input box */}
        <Flex width="100%" mobileDirection="column">
          <SingleDropdownSlimSelect
            value={currencies}
            onChange={(e) =>
              dispatch({ type: SET_CURRENCY, payload: e.target.value })
            }
            title="Currency"
          >
            {Object.keys(allCurrencies).map((key) => (
              <option key={key} value={allCurrencies[key].value}>
                {allCurrencies[key].value}
              </option>
            ))}
          </SingleDropdownSlimSelect>

          <DashboardInput
            label="Min Payment"
            placeholder=""
            name="minSalary"
            type="number"
            height="2.5rem"
            width="45%"
            inputInnerTag=""
            value={min_salary}
            handleChange={(e) =>
              dispatch({ type: SET_MIN_SALARY, payload: e.target.value })
            }
          />

          <DashboardInput
            label="Max Payment"
            placeholder=""
            name="maxSalary"
            type="number"
            height="2.5rem"
            width="45%"
            inputInnerTag=""
            value={max_salary}
            handleChange={(e) =>
              dispatch({ type: SET_MAX_SALARY, payload: e.target.value })
            }
          />
        </Flex>
      </Flex>

      {/* Multi tag input box */}
      <TagInput label="Benefits" tags={benefits} setTags={handleTags} />

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Modal>
  )
}

export default SalaryModal
