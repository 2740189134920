import React, { memo, useState, useEffect, useMemo } from 'react'

import { useSelector } from 'react-redux'

import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
  Tooltip,
  Title,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { useToasts } from 'react-toast-notifications'

import Loader from '../../../../components/Loader'
import NoData from './NoData'
import DashboardItemTitle from './DashboardItemTitle'

import axiosInstance from '../../../../services/config/AxiosIntance'

import { chartThreeMonthColors } from '../../../../constant/constant'

ChartJS.register(CategoryScale, LinearScale, BarElement, Legend, Tooltip, Title)

const RecruitmentFunnelTrendChart = () => {
  const { addToast } = useToasts()
  const filters = useSelector((state) => state.dashboard.filters)

  const [loading, setLoading] = useState(false)
  const [highestNumber, setHighestNumber] = useState(0)
  const [chartData, setChartData] = useState({
    labels: [''],
    datasets: [],
  })

  useEffect(() => {
    setLoading(true)
    const queryParams = new URLSearchParams(filters).toString()
    axiosInstance
      .get(`/dashboard/recruitmentFunnelTrend?${queryParams}`)
      .then((res) => {
        if (res?.data?.result?.labels) {
          setChartData((prev) => ({
            ...prev,
            labels: res.data.result.labels,
            datasets: res?.data?.result?.datasets.map((item, index) => ({
              label: item.name,
              data: item.data,
              backgroundColor: chartThreeMonthColors[index],
              borderColor: 'black',
              borderWidth: 1,
              barPercentage: 1,
            })),
          }))
          const mergedArray = res?.data?.result?.datasets
            .map((dataset) => {
              return dataset.data
            })
            .reduce((acc, val) => acc.concat(val), [])
          setHighestNumber(Math.max(...mergedArray))
        }
      })
      .catch((err) => {
        addToast(
          err?.response?.message ||
          err?.response?.data?.message ||
          'Internal Server Error',
          {
            appearance: 'error',
            autoDismiss: true,
          }
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }, [filters])

  const options = useMemo(
    () => ({
      type: 'bar',
      data: chartData,
      maintainAspectRatio: false,
      plugins: {
        title: {
          color: 'black',
          display: true,
          align: 'start',
          position: 'top',
          text: 'Recruitment Funnel Trend',
          font: {
            size: 24,
            family: "'Lato', 'sans-serif'",
            weight: 300,
          },
        },
        // legend: {
        //   position: 'right',
        //   display: true,
        // },
        datalabels: {
          display: true,
          color: (ctx) => ctx.dataset.backgroundColor,
          anchor: 'end',
          align: 'end',
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          // barThickness: 5
        },
        y: {
          max: (ctx) => {
            if (highestNumber > 0) {
              return highestNumber + 10
            } else {
              return ctx.chart.scales.y.max + 10
            }
          },
          grid: {
            display: false,
          },
          ticks: {
            padding: 0.5,
          },
        },
      },
      responsive: true,
    }),
    [highestNumber]
  )

  const component = loading ? (
    <Loader />
  ) : chartData.labels.length ? (
    <Bar
      data={chartData}
      options={options}
      plugins={[ChartDataLabels]}
      width={50}
      height={100}
    />
  ) : (
    <>
      <DashboardItemTitle title="Recruitment Funnel Trend" />
      <NoData />
    </>
  )

  return component
}

export default memo(RecruitmentFunnelTrendChart)
