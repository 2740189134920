import axiosInstance from '../../services/config/AxiosIntance'
import requisitionType from '../types/requisition'

export const setModal = (modalType) => {
  return { type: requisitionType.SETMODAL, payload: modalType }
}

export const setInterviews = (interviews) => {
  return { type: requisitionType.SETINTERVIEWS, payload: interviews }
}

export const getApplicantIds = (url) => (dispatch) => {
  axiosInstance
    .get(url)
    .then((res) => {
      dispatch({
        type: requisitionType.SETAPPLICANTS,
        payload: res?.data?.resp?.interviews,
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const setApplicants = (applicants) => {
  return { type: requisitionType.SETAPPLICANTS, payload: applicants }
}

export const setSearch = (search) => {
  return { type: requisitionType.SETSEARCH, payload: search }
}

export const setSearchRole = (searchRole) => {
  return { type: requisitionType.SETSEARCHROLE, payload: searchRole }
}

export const setUpdatedStage = (updatedStage) => {
  return { type: requisitionType.SETUPDATEDSTAGE, payload: updatedStage }
}

export const setSelectedInterview = (interview) => {
  return { type: requisitionType.SETSELECTEDINTERVIEW, payload: interview }
}

export const setRecruiters = (recruiters) => {
  return { type: requisitionType.SETRECRUITERS, payload: recruiters }
}

export const setInterviewStage = (stage) => {
  return { type: requisitionType.SETSTAGE, payload: stage }
}

export const setModalFooter = (footer) => {
  return { type: requisitionType.SETMODALFOOTER, payload: footer }
}

export const setFilters = (filter) => {
  return { type: requisitionType.SETFILTERS, payload: filter }
}

export const clearFilters = () => ({
  type: requisitionType.CLEARFILTERS,
})

export const setApplicantListUrl = (baseUrl) => {
  const pageNumberIndicator = '&pageNumber'
  const condition = '&id_list=1&pageNumber=1&pageSize=99999'
  const url =
    (baseUrl.includes(pageNumberIndicator)
      ? baseUrl.slice(0, baseUrl.indexOf(pageNumberIndicator))
      : baseUrl) + condition

  return { type: requisitionType.SETAPPLICANTLISTURL, payload: url }
}

export const setRefreshList = (refresh) => {
  return { type: requisitionType.REFRESHLIST, payload: refresh }
}

export const setUpdatedNote = (note) => {
  return { type: requisitionType.SETUPDATEDNOTE, payload: note }
}
