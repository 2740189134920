import React, { useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import { useSelector, useDispatch } from 'react-redux'

import DashboardButton from '../../../../components/dashboard/DashboardButton'
import FilterInput from '../../../../components/dashboard/FilterInput'
import Flex from '../../../../components/layouts/Flex'

import {
  setQueryParams,
  setModal,
} from '../../../../store/actions/userTypeActions'

const HeaderFilter = () => {
  const { loading } = useSelector((state) => state.userTypes)
  const dispatch = useDispatch()

  const [filter, setFilter] = useState('')

  const handleFilter = (e) => {
    e.preventDefault()
    dispatch(setQueryParams({ searchTerm: filter }))
  }

  const handleCreateUserType = () => {
    dispatch(setModal('createUserType'))
  }

  return (
    <Flex
      width="100%"
      margin="1.5rem 0 0 0"
      items="center"
      justify="space-between"
    >
      <Flex
        width="100%"
        as="form"
        items="center"
        justify="flex-start"
        gap="1rem"
        onSubmit={handleFilter}
      >
        <FilterInput
          maxW="300px"
          whiteSpace="nowrap"
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
          placeholder="Search..."
          handleClear={() => setFilter('')}
        />
        <DashboardButton
          text={loading ? 'Loading...' : 'Search'}
          type="submit"
        />
      </Flex>

      <Flex width="180px" items="center" gap="0.5rem">
        <DashboardButton
          text="Add User Type"
          type="button"
          notIcon
          externalIcon={<FiPlus className="icon" />}
          maxWidth="180px"
          onClick={handleCreateUserType}
        />
      </Flex>
    </Flex>
  )
}

export default HeaderFilter
