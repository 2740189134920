import React from 'react'
import { H6, P } from '../../../../style/Typography.style'
import Flex from '../../../layouts/Flex'
import TagInput from '../../controls/TagInput'
import SwitchToggle from '../../SwitchToggle'

const StepTwo = () => {
  const [showSalary, setShowSalary] = React.useState(true)

  return (
    <>
      <Flex items="center" justify="space-between" width="100%">
        <P>Generated Caption</P>

        <SwitchToggle
          size="small"
          maxw="166px"
          firstText="Display Salary"
          secondText="Hide Salary"
          active={showSalary}
          setActive={() => setShowSalary(!showSalary)}
        />
      </Flex>

      <Flex
        padding="0.5rem"
        margin="0.5rem 0 0 0"
        direction="column"
        width="100%"
        border="1px solid #AEB3C9"
      >
        <H6 weight="700">Marketing Assistant</H6>

        {showSalary && <P weight="500">$1200 - $1500 a month</P>}

        <P padding="1rem 0 0.75rem 0">
          Full-Time | Permanent Position | Australian Business Hours
        </P>

        <P padding="1rem 0" lineHeight="1.75">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.{' '}
        </P>

        {/* Others */}
        <Flex direction="column" gap="1.5rem" wrap="wrap" maxw="400px">
          <Flex direction="column" gap="0.5rem">
            <P size="0.875rem" weight="500">
              Experience
            </P>
            <P>Market Research: 2 Years</P>
            <P>Content Management: 3 Years</P>
          </Flex>
          <Flex direction="column" gap="0.5rem">
            <P size="0.875rem" weight="500">
              Education
            </P>
            <P>Bachelor’s Degree in Human Resources</P>
          </Flex>
          <Flex direction="column" gap="0.5rem">
            <P size="0.875rem" weight="500">
              Benefits
            </P>
            <P>Remote Work</P>
          </Flex>
        </Flex>
      </Flex>

      {/* Multi tag input box */}
      <TagInput margin="1rem 0 0 0" label="Social Media Tags" />
    </>
  )
}

export default StepTwo
