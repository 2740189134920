import React from 'react'
import styled from 'styled-components'
import EmailTemplateList from './EmailTemplateList'

const EmailTemplateListWrapper = () => {
  return (
    <StyledEmailTemplateListWrapper>
      <EmailTemplateList />
    </StyledEmailTemplateListWrapper>
  )
}

export default EmailTemplateListWrapper

const StyledEmailTemplateListWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 16rem);
  background: #fff;
  border: 0.5px solid #aeb3c9;
  border-bottom: none;
  margin-top: 1rem;
  overflow-y: hidden;
`
