import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MultiDropDownSelect } from '../../DropdownSelect'

import {
  setOS,
  setProcessor,
  setRAM,
} from '../../../../../store/actions/talentActions'

import { specifications } from '../../../../../constant/computerSpecs'

const HardwareFilter = () => {
  const dispatch = useDispatch()

  const { os, processor, ram } = useSelector((state) => state.talents)

  const handleOS = (value) => {
    dispatch(setOS(value))
  }

  const handleProcessor = (value) => {
    dispatch(setProcessor(value))
  }
  const handleRAM = (value) => {
    dispatch(setRAM(value))
  }

  return (
    <>
      <MultiDropDownSelect
        setSelectedOption={handleOS}
        selectedOption={os}
        options={specifications.os}
        title="Operating System"
        placeholder="Select Operating System"
      />

      <MultiDropDownSelect
        setSelectedOption={handleProcessor}
        selectedOption={processor}
        options={specifications.processor}
        title="Processor"
        placeholder="Select Processor"
      />

      <MultiDropDownSelect
        setSelectedOption={handleRAM}
        selectedOption={ram}
        options={specifications.ram}
        title="RAM"
        placeholder="Select RAM"
      />
    </>
  )
}

export default HardwareFilter
