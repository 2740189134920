import React from 'react'

import styled from 'styled-components'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const DashboardPhoneInput = ({
  name,
  label,
  placeholder,
  handleChange,
  value,
  height,
  inputInnerTag,
  maxWidth,
  width,
  margin,
  marginBottom,
  errorMessage,
}) => {
  return (
    <StyledDashboardPhoneInput
      maxWidth={maxWidth}
      width={width}
      height={height}
      inputInnerTag={inputInnerTag}
      margin={margin}
      marginBottom={marginBottom}
    >
      {label && (
        <label htmlFor={name} className={`label ${errorMessage && 'error'}`}>
          {label}
        </label>
      )}
      <PhoneInput
        country={'ph'}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        id={name}
        countryCodeEditable={false}
        inputClass={`phone-input ${errorMessage && 'error'}`}
        dropdownClass="dropdown"
      />
      {errorMessage && <div className="error__message">{errorMessage}</div>}
    </StyledDashboardPhoneInput>
  )
}

export default DashboardPhoneInput

const StyledDashboardPhoneInput = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: ${({ width }) => width || '100%'};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
  margin: ${({ margin }) => margin || '0'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '1rem'};

  .label {
    font-size: 0.75rem;
    font-weight: 300;
    color: #0e1324;
    margin-bottom: 4px;
    text-align: left;

    &.error {
      color: red;
    }
  }

  .phone-input {
    width: 100%;
    height: ${({ height }) => height || '100%'};
    background: #fff;
    outline: none;
    border: 1px solid #aeb3c9;
    font-size: 0.75rem;
    font-weight: 300;
    color: #0e1324;

    &.error {
      border: 1px solid red;
      color: red;
    }
  }

  .dropdown {
    position: relative;
    bottom: 250px;
  }

  .error__message {
    font-size: 0.75rem;
    font-weight: 300;
    position: absolute;
    bottom: 0;
    color: red;
  }
`
