import React from 'react'
import Flex from '../../layouts/Flex'
import DashboardInput from '../DashboardInput'
import { SingleDropdownSelect } from '../TalentPool/DropdownSelect'

const ExperienceInput = ({
  onChangeExperience,
  onChangeSpecification,
  years,
  specification,
  disabled,
}) => {
  return (
    <Flex width="100%" gap="1rem" mobileDirection="column">
      {/* Years of experience */}
      <SingleDropdownSelect
        disabled={disabled}
        onChange={onChangeExperience}
        value={years}
        maxw="8rem"
        title="Years of Experience"
      >
        <option>Select year of experience</option>
        <option value="1 Year">1 Year</option>
        <option value="2 Years">2 Years</option>
        <option value="3 Years">3 Years</option>
        <option value="4 Years">4 Years</option>
        <option value="5 Years">5 Years</option>
        <option value="More than 5 Years">More than 5 Years</option>
      </SingleDropdownSelect>

      {/* experience specialization */}
      <DashboardInput
        readOnly={disabled}
        label="Specialization"
        placeholder="e.g. Graphic Design, App Development"
        name="specialization"
        type="text"
        height="2.5rem"
        handleChange={onChangeSpecification}
        value={specification}
      />
    </Flex>
  )
}

export default ExperienceInput
