import React, { useState, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import Flex from '../../../../components/layouts/Flex'
import DashboardInput from '../../../../components/dashboard/DashboardInput'

import { setModal } from '../../../../store/actions/listsActions'
import { setInterviews } from '../../../../store/actions/recruitersActions'

import useMutation from '../../../../hooks/useMutation'

const AddAskingSalary = () => {
  const [askingSalary, setAskingSalary] = useState(0)

  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const { selectedInterview, interviews } = useSelector(
    (state) => state.requisitions
  )
  const { data, loading, handleRequest, success, error } = useMutation()

  const handleSubmit = () => {
    if (!askingSalary) {
      return addToast('Asking salary field is required.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    handleRequest(
      `/interviews/${selectedInterview.id}/update`,
      {
        applicant_id: selectedInterview.applicant_id.toString(),
        asking_salary: askingSalary,
      },
      'PUT'
    )
  }

  const handleAskingSalaryChanged = (e) => setAskingSalary(e.target.value)

  useEffect(() => {
    setAskingSalary(selectedInterview?.askingSalary)
  }, [])

  // check if request was successful.
  useEffect(() => {
    if (data) {
      const updatedInterviews = interviews.map((item) => {
        if (item.id === selectedInterview.id) {
          item.askingSalary = data.resp.asking_salary
        }
        return item
      })
      dispatch(setInterviews(updatedInterviews))

      addToast('Asking salary added successfully.', {
        appearance: 'success',
        autoDismiss: true,
      })

      dispatch(setModal(''))
    }
  }, [success])

  // check if request was unsuccessful.
  useEffect(() => {
    if (error) {
      addToast(
        error.message ||
          error.data.message ||
          error.response.data.message ||
          'something went wrong.',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    }
  }, [error])

  return (
    <>
      <MainModal
        title={`Add Asking Salary`}
        buttonText="Submit"
        loading={loading}
        onClick={handleSubmit}
      >
        <Flex direction="column" gap="0.4rem">
          <DashboardInput
            type="number"
            label="Asking Salary (PHP)"
            value={askingSalary}
            handleChange={handleAskingSalaryChanged}
          ></DashboardInput>
        </Flex>
      </MainModal>
    </>
  )
}

export default AddAskingSalary
