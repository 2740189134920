/* eslint-disable camelcase */
import React, { useEffect } from 'react'
import styled from 'styled-components'
import ClipLoader from 'react-spinners/ClipLoader'
import { useToasts } from 'react-toast-notifications'

import List from './List'
import Flex from '../../../../components/layouts/Flex'
import useFetch from '../../../../hooks/useFetch'
import { useDispatch, useSelector } from 'react-redux'
import { addList, addLists } from '../../../../store/actions/listsActions'

const Lists = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { data, loading, error } = useFetch('/list-names')

  const { lists, selectedList } = useSelector((state) => state.lists)

  useEffect(() => {
    if (data) {
      dispatch(addLists(data.resp))
      dispatch(addList(data.resp[0]))
    }
  }, [data])

  // if request is not successful
  useEffect(() => {
    if (error) {
      addToast('Something went wrong while fetching lists', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }, [error])

  return (
    <ListContainer>
      {loading ? (
        <Flex height="6rem" bg="#fff" justify="center" items="center">
          <ClipLoader color="#1a1a1a" size="2rem" />
        </Flex>
      ) : (
        <>
          {lists?.map((list) => (
            <List
              key={list.id}
              active={list.id === selectedList?.id}
              list={list}
              title={list.list_name}
              number={list.numbersOfApplicantInList}
            />
          ))}
        </>
      )}
    </ListContainer>
  )
}

export default Lists

const ListContainer = styled.div`
  height: 100%;
  max-width: 270px;
  overflow-x: hidden;
  overflow-y: auto;
`
