import React from 'react'

import styled from 'styled-components'

const Tag = ({ key, children }) => {
  return <StyledTag key={key}>{children}</StyledTag>
}

const StyledTag = styled.div`
  display: inline-block;
  padding: 0.5rem 0.75rem;
  border-radius: 100px;
  border: 0.5px solid #31374f;
  font-size: 1rem;
  color: #0e1324;
  font-weight: 400;
`

export default Tag;