import React, { useState, useEffect, useMemo, useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { FiPlus } from 'react-icons/fi'
import Joi from 'joi'
import styled from 'styled-components'

import ContactListing from './Listings/ContactListing'
import Flex from '../../../../components/layouts/Flex'
import EmptyWrapper from '../../../../components/EmptyWrapper'
import SelectDropdown from '../../../../components/SelectDropdown'
import DashboardInput from '../../../../components/dashboard/DashboardInput'
import DashboardButton from '../../../../components/dashboard/DashboardButton'
import { JobToggleButton } from '../../../../components/dashboard/ToggleButton'
import DashboardPhoneInput from '../../../../components/dashboard/DashboardPhoneInput'
import MainModal from '../../../../components/dashboard/modal/MainModal'
import FormProgress from '../../../../components/dashboard/controls/FormProgress'

import axiosInstance from '../../../../services/config/AxiosIntance'

import countries from 'all-countries-and-cities-json'

import { equals } from '../../../../utils/utils'

import {
  setModal,
  setLoading,
  setFormData,
  setListChanged,
  setList,
  setNotification,
  setDefaultFormData,
} from '../../../../store/actions/clientActions'

const ContactFormModal = () => {
  const dispatch = useDispatch()

  const modal = useSelector((state) => state.clients.modal)

  const loadingContactForm = useSelector(
    (state) => state.clients.loading.contactForm
  )

  const selectedClient = useSelector(
    (state) => state.clients.selectedRecord.client
  )
  const selectedContact = useSelector(
    (state) => state.clients.selectedRecord.contact
  )

  const contactLists = useSelector((state) => state.clients.lists.contact)

  const clientFields = useSelector((state) => state.clients.formData.client)
  const contactFields = useSelector((state) => state.clients.formData.contact)

  const [errors, setErrors] = useState({
    name: '',
    email: '',
  })

  const [existingPOC, setExistingPOC] = useState(false)
  const [pocUsers, setPOCUsers] = useState([])
  const [mappedPOCUsers, setMappedPOCUsers] = useState([])
  const [mappedCountries, setMappedCountries] = useState([])
  const [mappedCities, setMappedCities] = useState([])

  const validationSchema = useMemo(() => {
    return Joi.object({
      id: Joi.number().required().allow(null),
      company_id: Joi.number().optional().allow(null),
      user_id: Joi.number().optional().allow(null),
      first_name: Joi.string().label('First Name').required(),
      middle_name: Joi.string().label('Middle Name').required(),
      last_name: Joi.string().label('Last Name').required(),
      email: Joi.string()
        .label('Contact Email')
        .email({ tlds: { allow: false } })
        .required(),
      phone_number: Joi.string().allow(''),
      city: Joi.string().allow(''),
      country: Joi.string().allow(''),
      status: Joi.string().allow(''),
      monitor_all_jobs: Joi.alternatives()
        .try(Joi.number().valid(0, 1), Joi.boolean())
        .required(),
    })
  }, [])

  const memoizedCountries = useMemo(() => countries, [])

  useEffect(() => {
    const result = validationSchema.validate(contactFields, {
      abortEarly: false,
    })
    const { error } = result
    const fieldNames = Object.keys(contactFields)
    const updatedErrors = fieldNames.reduce((acc, fieldName) => {
      acc[fieldName] =
        error?.details
          .find((detail) => detail.context.key === fieldName)
          ?.message.replace(/"/g, '') || ''
      return acc
    }, {})

    setErrors(updatedErrors)
  }, [contactFields])

  useEffect(() => {
    const allCountries = Object.keys(memoizedCountries).map((country) => ({
      value: country,
      label: country,
    }))
    setMappedCountries(allCountries)
    handleFieldChange('country', allCountries[0].value)
  }, [])

  useEffect(() => {
    const selectedCountry =
      contactFields.country || Object.keys(memoizedCountries)[0]
    const allCities = memoizedCountries[selectedCountry].map((city) => ({
      value: city,
      label: city,
    }))
    setMappedCities(allCities)
    handleFieldChange('city', allCities[0].value)
  }, [contactFields.country])

  const handlePrevious = () => {
    const removed = modal.filter((m) => m !== 'contactFormModal')
    dispatch(setModal(removed))
  }

  const handleFieldChange = (name, value) =>
    dispatch(setFormData('contact', { [name]: value }))

  const handleCloseForm = () => {
    let openedModals = [...modal]
    if (modal.includes('clientForm')) {
      openedModals = []
    } else {
      openedModals = modal.filter((m) => m !== 'contactFormModal')
    }
    dispatch(setModal(openedModals))
  }

  const handleSaveContact = () => {
    const result = validationSchema.validate(contactFields, {
      abortEarly: false,
    })

    let errorMessage = ''
    let updatedContactLists = []

    if (result.error) {
      errorMessage =
        'Please correct the validation errors first before proceeding.'
    } else {
      const contactIndex = contactLists.findIndex((contact) =>
        equals(contact, selectedContact)
      )

      if (contactIndex === -1) {
        const newContact = {
          ...contactFields,
          status: 'Active',
          company_id: selectedClient.id,
          user_id: existingPOC ? contactFields.user_id : null,
          id: null,
        }

        const existingContacts = contactLists.filter(
          (e) =>
            e.first_name === contactFields.first_name &&
            e.middle_name === contactFields.middle_name &&
            e.last_name === contactFields.last_name &&
            e.email === contactFields.email &&
            e.phone_number === contactFields.phone_number &&
            e.city === contactFields.city &&
            e.country === contactFields.country
        )

        const existingContactInfo = contactLists.filter(
          (e) =>
            e.email === contactFields.email ||
            e.phone_number === contactFields.phone_number
        )

        if (existingContactInfo.length) {
          errorMessage =
            'The following contact information (email, phone number) already exist in this company.'
        } else if (existingContacts.length) {
          errorMessage = 'The following records already exist in this company.'
        } else {
          updatedContactLists = [...contactLists, newContact]
        }
      } else {
        const updatedContacts = contactLists.map((current, index) => {
          if (index === contactIndex) {
            return { ...current, ...contactFields }
          }
          return current
        })

        updatedContactLists = updatedContacts
      }
    }

    if (errorMessage) {
      dispatch(setNotification('error', errorMessage))
    } else {
      dispatch(setList({ listName: 'contact', data: updatedContactLists }))
      setExistingPOC(false)
      dispatch(setDefaultFormData('contact'))
    }
  }

  const handleSubmit = async () => {
    try {
      let response = ''
      if (modal.includes('clientForm')) {
        if (clientFields.id) {
          response = await axiosInstance.put(
            `/company/${clientFields.id}/update`,
            {
              client: clientFields,
              contacts: contactLists,
            }
          )
        } else {
          response = await axiosInstance.post(`/company/create`, {
            client: clientFields,
            contacts: contactLists,
          })
        }
        dispatch(setDefaultFormData('client'))
        handleCloseForm()
      } else {
        dispatch(setLoading({ name: 'contactForm', value: true }))

        response = await axiosInstance.post('/poc/save', contactLists)

        const removed = modal.filter((m) => m !== 'contactFormModal')
        dispatch(setLoading({ name: 'contactForm', value: false }))
        dispatch(setModal(removed))
      }
      dispatch(setListChanged('client'))
      dispatch(setNotification('success', response?.data?.message))
    } catch (error) {
      dispatch(
        setNotification('error', error?.response?.data || error?.message)
      )
    } finally {
      dispatch(setListChanged('contactListing'))
      dispatch(setLoading({ name: 'contactForm', value: false }))
    }
  }

  const handleCountryChanged = (e) => {
    handleFieldChange('country', e.value)
    handleFieldChange('city', mappedCities[0].value)
  }

  const handleCityChanged = (e) => {
    handleFieldChange('city', e.value)
  }

  const handlePOCUserChanged = (e) => {
    const selectedUser = pocUsers.find((user) => user.id === e.value)
    dispatch(setFormData('contact', selectedUser))
  }

  const getSelectedCountry = useCallback(
    () =>
      mappedCountries.filter(
        (country) => country.value === contactFields.country
      )[0],
    [contactFields.country]
  )

  const getSelectedCity = useCallback(
    () => mappedCities.filter((city) => city.value === contactFields.city)[0],
    [contactFields.city]
  )

  const getSelectedPOC = useCallback(
    () => mappedPOCUsers.filter((user) => user.value === contactFields.id)[0],
    [contactFields.first_name]
  )

  const handlePOCChecked = () => {
    setExistingPOC(!existingPOC)

    if (!existingPOC) {
      axiosInstance
        .get('/poc/users?distinctRecords=true')
        .then((response) => {
          const result = response?.data?.data
          const mappedResult = result.map((poc) => ({
            value: poc.id,
            label: `${poc.first_name} ${poc.middle_name} ${poc.last_name}`,
          }))
          setPOCUsers(result)
          setMappedPOCUsers(mappedResult)
          dispatch(setFormData('contact', result[0]))
        })
        .catch((err) => {
          dispatch(setNotification('error', err?.response?.data?.message))
        })
    } else {
      dispatch(setDefaultFormData('contact'))
    }
  }

  const getContactListComponent = useCallback(() => {
    let component

    if (contactLists.length) {
      component = contactLists?.map((contact) => (
        <ContactListing
          key={contact?.id}
          data={{
            id: contact?.id,
            company_id: contact?.company_id,
            user_id: contact?.user_id,
            first_name: contact?.first_name,
            middle_name: contact?.middle_name,
            last_name: contact?.last_name,
            email: contact?.email,
            phone_number: contact?.phone_number,
            city: contact?.city,
            country: contact?.country,
            status: contact?.status,
            monitor_all_jobs: contact?.monitor_all_jobs,
          }}
          renderedFrom="contactForm"
        />
      ))
    } else {
      component = <EmptyWrapper message="You have no point of contacts." />
    }

    return component
  }, [contactLists])

  return (
    <MainModal
      title="Contact Form"
      buttonText={loadingContactForm ? 'Submitting...' : 'Submit'}
      {...(!modal.includes('clientDetailsModal') && {
        previousBtn: handlePrevious,
      })}
      onClick={handleSubmit}
      onClose={handleCloseForm}
      size={contactLists.length >= 0 ? 'large' : 'small'}
    >
      {!modal.includes('clientDetailsModal') && (
        <Flex gap="0.4rem">
          <FormProgress totalSteps={2} activeStep={2} />
        </Flex>
      )}
      <Flex width="100%" gap="0.4rem">
        <Flex width="50%">
          <JobToggleButton
            value={existingPOC}
            onClick={handlePOCChecked}
            label="Existing POC?"
            mt="1.5rem"
          />
        </Flex>
        {existingPOC && (
          <Flex width="50%" direction="column">
            <label htmlFor="select__poc">POC Users</label>
            <SelectDropdown
              id="select__poc"
              options={mappedPOCUsers}
              selected={getSelectedPOC()}
              onChange={handlePOCUserChanged}
            />
          </Flex>
        )}
      </Flex>
      <Flex gap="0.4rem" margin="0.25rem 0 0 0">
        <DashboardInput
          errorMessage={errors.first_name}
          label="First Name *"
          value={contactFields.first_name}
          handleChange={(e) => handleFieldChange('first_name', e.target.value)}
          placeholder=""
          type="text"
        />
        <DashboardInput
          errorMessage={errors.middle_name}
          label="Middle Name *"
          value={contactFields.middle_name}
          handleChange={(e) => handleFieldChange('middle_name', e.target.value)}
          placeholder=""
          type="text"
        />
        <DashboardInput
          errorMessage={errors.last_name}
          label="Last Name *"
          value={contactFields.last_name}
          handleChange={(e) => handleFieldChange('last_name', e.target.value)}
          placeholder=""
          type="text"
        />
      </Flex>
      <Flex gap="0.4rem">
        <DashboardInput
          errorMessage={errors.email}
          label="Email Address *"
          value={contactFields.email}
          handleChange={(e) => handleFieldChange('email', e.target.value)}
          placeholder=""
          height="2.5rem"
          type="text"
        />
        <DashboardPhoneInput
          name="phoneNumber"
          label="Contact Number"
          placeholder=""
          type="text"
          height="2.5rem"
          value={contactFields.phone_number}
          handleChange={(e) => handleFieldChange('phone_number', e)}
        />
      </Flex>
      <Flex gap="0.4rem" margin="0 0 0.5rem 0">
        <Flex width="100%" direction="column">
          <label htmlFor="select__countries">Country</label>
          <SelectDropdown
            id="select__countries"
            options={mappedCountries}
            selected={getSelectedCountry()}
            onChange={handleCountryChanged}
          />
        </Flex>
        <Flex width="100%" direction="column">
          <label htmlFor="select__cities">City</label>
          <SelectDropdown
            id="select__cities"
            options={mappedCities}
            selected={getSelectedCity()}
            onChange={handleCityChanged}
          />
        </Flex>
      </Flex>
      <Flex width="100%" justify="space-between" margin="0 0 2rem 0">
        <JobToggleButton
          value={contactFields.monitor_all_jobs}
          onClick={() =>
            handleFieldChange(
              'monitor_all_jobs',
              !contactFields.monitor_all_jobs
            )
          }
          label="Monitor all jobs?"
          mt="1.5rem"
        />
      </Flex>
      <Flex gap="1rem" width="100%" margin="5px">
        <DashboardButton
          maxWidth={'200px'}
          height={'48px'}
          color="#F0F7FF"
          textColor="#1877F2"
          border="1px solid"
          icon={<FiPlus style={{ color: '#1877F2' }} />}
          rounded
          text="Save and Add more"
          onClick={handleSaveContact}
        />
      </Flex>

      <StyledContactFormModal>
        {getContactListComponent()}
      </StyledContactFormModal>
    </MainModal>
  )
}

export default ContactFormModal

const StyledContactFormModal = styled.div`
  height: 250px;
  overflow-y: auto;
`
