import React from 'react'
import styled from 'styled-components'
import { P } from '../../../style/Typography.style'

const CvProgress = ({ progress }) => {
  return (
    <>
      <P weight="500" size=".75rem" marginTop=".75rem" marginBottom=".3rem">
        {progress}/3 Profile Setup Complete
      </P>
      <StyledProgress>
        <div
          className={progress >= 1 ? 'progress-bar active' : 'progress-bar'}
        />
        <div
          className={progress >= 2 ? 'progress-bar active' : 'progress-bar'}
        />
        <div
          className={progress >= 3 ? 'progress-bar active' : 'progress-bar'}
        />
      </StyledProgress>
    </>
  )
}

export default CvProgress

const StyledProgress = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2px;

  .progress-bar {
    width: 100%;
    height: 6px;
    border-radius: 100px;
    background: #dfdfe9;

    &.active {
      background: #1877f2;
    }
  }
`
