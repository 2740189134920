/* eslint-disable camelcase */
import React, { useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ToastContainer, toast } from 'react-toastify'

import { currencyList } from '../../../../../src/constant/currencies'

import {
  SET_BENEFITS,
  SET_MAX_SALARY,
  SET_MIN_SALARY,
  SET_REACH_STEP,
  SET_SALARY_BASIS,
  SWITCH_STEP,
  SET_CURRENCY,
} from '../../../../store/types/jobPosting'

import TagInput from '../../../../components/dashboard/controls/TagInput'
import DashboardButton from '../../../../components/dashboard/DashboardButton'
import DashboardInputSlim from '../../../../components/dashboard/DashboardInput'
import {
  SingleDropdownSelect,
  SingleDropdownSlimSelect,
} from '../../../../components/dashboard/TalentPool/DropdownSelect'
import Flex from '../../../../components/layouts/Flex'
import { H2 } from '../../../../style/Typography.style'

const Salaries = () => {
  const dispatch = useDispatch()
  const [allCurrencies, setCurrencies] = useState([])

  const { min_salary, max_salary, benefits, salary_basis, currencies } =
    useSelector((state) => state.jobPosting)

  const handleTags = (tags) => {
    dispatch({ type: SET_BENEFITS, payload: tags })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (min_salary === '')
      return toast.error('Please enter minimum salary', { type: 'error' })
    if (max_salary === '')
      return toast.error('Please enter maximum salary', { type: 'error' })
    if (Number(min_salary) > Number(max_salary))
      return toast.error('Minimum salary should be less than Maximum Salary', {
        type: 'error',
      })
    if (salary_basis === '')
      return toast.error('Please select basic salary', { type: 'error' })
    if (benefits.length === 0)
      return toast.error('Please add at least one benefit', { type: 'error' })

    dispatch({ type: SWITCH_STEP, payload: 3 })
    dispatch({ type: SET_REACH_STEP, payload: 3 })
    localStorage.setItem('reachedStep', 3)
    localStorage.setItem('step', 3)
    localStorage.setItem(
      'salaries',
      JSON.stringify({
        min_salary,
        max_salary,
        benefits,
        salary_basis,
        currencies,
      })
    )
  }

  useMemo(() => {
    Object.keys(currencyList[0])?.forEach((curr) => {
      setCurrencies((allCurrencies) => [
        ...allCurrencies,
        { value: curr, label: curr },
      ])
    })
    return allCurrencies
  }, [currencyList])

  return (
    <Flex
      margin="2.5rem 1rem 9rem 1rem"
      direction="column"
      width="100%"
      maxw="500px"
      maxwMobile="300px"
    >
      <H2>Payment and Benefits</H2>

      {/* creating three section input for web the stack them over each other on mobile */}
      <Flex width="100%" mobileDirection="column" gap="1rem">
        {/* Salary types */}
        <SingleDropdownSelect
          value={salary_basis}
          onChange={(e) =>
            dispatch({ type: SET_SALARY_BASIS, payload: e.target.value })
          }
          maxw="8rem"
          title="Basis"
        >
          <option>Choose an option</option>
          <option value="Per Month">Per Month</option>
          <option value="Per Year">Per Year</option>
        </SingleDropdownSelect>

        {/* creating double type input box */}
        <Flex width="100%" mobileDirection="column">
          <SingleDropdownSlimSelect
            value={currencies}
            onChange={(e) =>
              dispatch({ type: SET_CURRENCY, payload: e.target.value })
            }
            title="Currency"
          >
            {Object.keys(allCurrencies).map((key) => (
              <option key={key} value={allCurrencies[key].value}>
                {allCurrencies[key].value}
              </option>
            ))}
          </SingleDropdownSlimSelect>

          <DashboardInputSlim
            label="Min Payment"
            placeholder=""
            name="minSalary"
            type="number"
            height="2.5rem"
            width="45%"
            inputInnerTag=""
            value={min_salary}
            handleChange={(e) =>
              dispatch({ type: SET_MIN_SALARY, payload: e.target.value })
            }
          />

          <DashboardInputSlim
            label="Max Payment"
            placeholder=""
            name="maxSalary"
            type="number"
            height="2.5rem"
            width="45%"
            inputInnerTag=""
            value={max_salary}
            handleChange={(e) =>
              dispatch({ type: SET_MAX_SALARY, payload: e.target.value })
            }
          />
        </Flex>
      </Flex>

      {/* Multi tag input box */}
      <TagInput label="Benefits" tags={benefits} setTags={handleTags} />

      {/*  submit button */}
      <Flex margin="1rem 0 0 0" width="100%" justify="flex-end">
        <DashboardButton
          text="Next Step"
          type="button"
          onClick={handleSubmit}
          rounded
          notIcon
        />
      </Flex>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Flex>
  )
}

export default Salaries
