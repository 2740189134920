import clientTypes from '../types/clients'

export const setLoading = (value) => {
  return { type: clientTypes.SET_LOADING, payload: value }
}
export const setModal = (modalType) => {
  return { type: clientTypes.SET_MODAL, payload: modalType }
}
export const setNotification = (type, message) => (dispatch) => {
  dispatch({
    type: clientTypes.SET_NOTIFICATION,
    payload: { type: type, message: message },
  })
}
export const setErrorMessage = (message) => (dispatch) => {
  dispatch(setNotification('error', message))
}
export const setSuccessMessage = (message) => (dispatch) => {
  dispatch(setNotification('success', message))
}
export const setFormData = (form, fields) => {
  return { type: clientTypes.SET_FORM_DATA, payload: { form: form, fields } }
}
export const setPagination = (options) => {
  return { type: clientTypes.SET_PAGINATION_OPTIONS, payload: options }
}
export const setFilters = (filter) => {
  return { type: clientTypes.SET_FILTERS, payload: filter }
}

export const setList = (value) => {
  return { type: clientTypes.SET_LIST, payload: value }
}

export const setSelectedRecord = (name, value) => {
  return {
    type: clientTypes.SET_SELECTED_RECORD,
    payload: { name, value },
  }
}
export const setListChanged = (name) => {
  return {
    type: clientTypes.SET_LIST_CHANGED,
    payload: { name },
  }
}
export const setDefaultFormData = (name) => {
  return {
    type: clientTypes.SET_DEFAULT_FORM_DATA,
    payload: { name },
  }
}
