import React from 'react'
import ReactDOM from 'react-dom'
import { useNavigate } from 'react-router'

import { connect, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { FiUser, FiSettings, FiLogOut } from 'react-icons/fi'

import { logout } from '../store/actions/authActions'
import { clearErrors } from '../store/actions/errorActions'
import { clearJobPosting } from '../store/actions/jobPostingActions'

import SocketIOClient from '../lib/SocketIOClient'

const HeaderDropdown = ({ logout, clearErrors }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  return ReactDOM.createPortal(
    <StyledHeaderDropdown>
      <div className="menuIt">
        <FiUser className="ico" />
        My Profile
      </div>
      <div className="menuIt">
        <FiSettings className="ico" />
        Settings
      </div>
      <div
        onClick={() => {
          const socket = new SocketIOClient()

          socket.emit('logout')

          setTimeout(() => {
            socket.disconnect()
          }, 1000)

          localStorage.removeItem('recruiterToken')
          localStorage.removeItem('user')
          localStorage.removeItem('id')
          localStorage.removeItem('qualifications')
          localStorage.removeItem('skills')
          localStorage.removeItem('salaries')
          localStorage.removeItem('jobDetails')
          localStorage.removeItem('reachedStep')
          localStorage.removeItem('step')
          localStorage.removeItem('jobTitle')
          dispatch(clearJobPosting())
          logout()
          clearErrors()
          navigate('/login')
        }}
        className="menuIt"
      >
        <FiLogOut className="ico" />
        Logout
      </div>
    </StyledHeaderDropdown>,
    document.getElementById('header_popout')
  )
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, { logout, clearErrors })(HeaderDropdown)

const StyledHeaderDropdown = styled.div`
  width: 133px;
  position: absolute;
  top: 55px;
  right: 31px;
  z-index: 102;
  min-height: 100px;
  background: white;
  border: 1px solid rgb(238, 238, 246);
  box-sizing: border-box;
  box-shadow: rgba(178, 178, 203, 0.2) 3px 3px 7px;
  border-radius: 1px;
  padding: 1rem;

  .menuIt {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 300;
    letter-spacing: 0.03rem;
    color: #31374f;
    padding: 0.5rem 0;
    cursor: pointer;

    .ico {
      font-size: 0.75rem;
      color: #31374f;
      margin-right: 0.5rem;
    }

    &:hover {
      color: #ff7b9b;

      .ico {
        color: #ff7b9b;
      }
    }
  }
`
