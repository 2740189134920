import React, { useEffect } from 'react'
import moment from 'moment'
import { useToasts } from 'react-toast-notifications'
import useFetch from '../../../hooks/useFetch'
import { P } from '../../../style/Typography.style'

function getPhase(stage) {
  switch(stage) {
    case 'first_interview':
      return '1st Interview Phase';
    case 'second_interview':
      return '2nd Interview Phase';
    case 'skill_assessment':
      return 'Skill Assessment Phase';
    default:
      return '1st Interview Phase';
  }
}

const ReasonForRejection = ({ talent }) => {
  const { addToast } = useToasts()
  const { data, error } = useFetch(`${talent.id}/rejected`)

  useEffect(() => {
    if (error) {
      addToast(error?.response?.data?.message || 'Something went wrong.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }, [error])
  return (
    <>
      <P color="#DC2929" weight="500" padding="0 0 0.25rem 0">
        REASON FOR REJECTION:
      </P>

      <P padding="0 0 0.25rem 0">{data?.resp?.message}</P>
      <P size="0.75rem">
        By {data?.resp?.rejectedBy || "Hopla_Recruiter"} on{' '}
        {data &&
          data?.resp?.created_at &&
          moment(data?.resp?.created_at).format('DD/MM/YYYY')}{' '}
        • {data?.resp?.stages && getPhase(data?.resp?.stages)}{' '}
      </P>
    </>
  )
}

export default ReasonForRejection
