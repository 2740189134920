import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import useMutation from '../../../../hooks/useMutation'
import { addLists, setModal } from '../../../../store/actions/listsActions'
import { P } from '../../../../style/Typography.style'

const DeleteList = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { selectedList, lists } = useSelector((state) => state.lists)
  const { data, error, handleRequest, loading, success } = useMutation()

  function handleDelete() {
    handleRequest(`/${selectedList.id}/delete-list`, {}, 'DELETE')
  }

  // check if the request is success
  useEffect(() => {
    if (data) {
      const newLists = lists.filter((item) => item.id !== selectedList.id)
      addToast(`${selectedList.list_name} is deleted`, {
        appearance: 'info',
        autoDismiss: true,
      })

      dispatch(addLists(newLists))
      dispatch(setModal(''))
    }
  }, [success])

  // check if the request is error
  useEffect(() => {
    if (error) {
      addToast(
        error?.response?.data?.message ||
          error?.response?.message ||
          'Something went wrong',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    }
  }, [error])

  return (
    <MainModal
      error
      title={`Delete ${selectedList.list_name || ''}`}
      buttonText="Delete List"
      onClick={handleDelete}
      loading={loading}
    >
      <P>
        Are you sure you want to delete this list? This list will be deleted
        from the system and listed applicants will be unlisted.
      </P>
    </MainModal>
  )
}

export default DeleteList
