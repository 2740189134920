const interviewStatus = [
  {
    value: 'Accepted',
    label: 'Accepted',
  },
  {
    value: 'Rejected',
    label: 'Rejected',
  },
]

export default interviewStatus
