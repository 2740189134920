import clientTypes from '../types/clients'

const initialState = {
  modal: [],
  formData: {
    client: {
      id: null,
      name: '',
      client_website: '',
      client_email: '',
      sector: '',
      company_details: '',
    },
    contact: {
      id: null,
      user_id: null,
      company_id: null,
      first_name: '',
      middle_name: '',
      last_name: '',
      email: '',
      status: 'Active',
      phone_number: '63',
      city: '',
      country: '',
      monitor_all_jobs: true,
    },
    contactListing: {
      id: null,
      job_id: null,
      poc_id: null,
      recommendations: '',
      general_structure: '',
      client_questions: '',
      notes: '',
    },
  },
  lists: {
    client: [],
    contact: [],
    contactListing: [],
    jobListings: [],
  },
  selectedRecord: {
    client: {},
    contact: {},
    activeListing: {},
    contactListing: {},
    jobListing: {},
  },
  pagination: {
    pageSize: 10,
    page: 1,
    pageCount: 1,
    rowCount: 0,
  },
  loading: {
    clientList: false,
    contactForm: false,
    contactList: false,
    changeClientStatus: false,
    activeListings: false,
    contactListings: false,
    jobListings: false,
    note: false,
  },
  notification: {},
  filters: { searchTerm: '' },
  listChanged: {
    client: false,
    contactListing: false,
    jobListing: false,
  },
}

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case clientTypes.SET_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.name]: action.payload.value,
        },
      }
    case clientTypes.SET_MODAL:
      return {
        ...state,
        modal: action.payload,
      }
    case clientTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      }
    case clientTypes.SET_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.form]: {
            ...state.formData[action.payload.form],
            ...action.payload.fields,
          },
        },
      }
    case clientTypes.SET_PAGINATION_OPTIONS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      }
    case clientTypes.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      }
    case clientTypes.SET_LIST:
      return {
        ...state,
        lists: {
          ...state.lists,
          [action.payload.listName]: action.payload.data,
        },
      }
    case clientTypes.SET_SELECTED_RECORD:
      return {
        ...state,
        selectedRecord: {
          ...state.selectedRecord,
          [action.payload.name]: action.payload.value,
        },
      }
    case clientTypes.SET_LIST_CHANGED:
      return {
        ...state,
        listChanged: {
          ...state.listChanged,
          [action.payload.name]: !state.listChanged[action.payload.name],
        },
      }

    case clientTypes.SET_DEFAULT_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.name]: initialState.formData[action.payload.name],
        },
      }

    default:
      return state
  }
}

export default clientReducer
