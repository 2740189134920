import React, { useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch, useSelector } from 'react-redux'
import { ClipLoader } from 'react-spinners'

import Flex from '../../layouts/Flex'

import TalentCardPopList from './TalentCardPopList'
import TalentCardPopout from './TalentCardPopout'

import useFetch from '../../../hooks/useFetch'

import {
  addLists,
  moveList,
  setModal,
} from '../../../store/actions/listsActions'

const ShowLists = ({ talent, setShowLists }) => {
  const dispatch = useDispatch()
  const { data, loading } = useFetch(
    `/jobs/dropdown?pooling_applicant=${talent?.id.toString()}`
  )

  const { lists } = useSelector((state) => state.lists)

  useEffect(() => {
    if (data) {
      const sortedData = data?.data?.sort((a, b) => {
        if (a.pooling_id === null) return 1
        if (b.pooling_id === null) return -1
        return a.pooling_id - b.pooling_id
      })
      dispatch(addLists(sortedData))
    }
  }, [data])

  const handleMoveApplicant = (list) => {
    if (talent?.stage_id && talent?.stage_id !== 8) {
      dispatch(setModal('tagApplicant'))
    } else {
      if (talent.listName) {
        dispatch(setModal('moveToList'))
      } else {
        dispatch(setModal('addToList'))
      }
    }

    dispatch(moveList(list))
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowLists(false)
      }}
    >
      <TalentCardPopout right="-85%">
        {loading ? (
          <Flex height="3rem" justify="center" items="center">
            <ClipLoader color="#1a1a1a" size="1rem" />
          </Flex>
        ) : (
          <>
            {lists.map((list) =>
              list.status === 'active' ? (
                <TalentCardPopList
                  onClick={() => handleMoveApplicant(list)}
                  text={`${list.title.substring(0, 20)}${
                    list.title.length > 20 ? '...' : ''
                  }`}
                  key={list.id}
                  customClass={list?.pooling_id ? 'tagged' : ''}
                />
              ) : null
            )}
          </>
        )}
      </TalentCardPopout>
    </OutsideClickHandler>
  )
}

export default ShowLists
