import React, { useState } from 'react'

import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'
import { P } from '../../../style/Typography.style'
import axiosInstance from '../../../services/config/AxiosIntance'

import CVImage from '../TalentPool/CVImage'

import { FiPaperclip } from 'react-icons/fi'

const UploadInput = ({
  cvDetails: cvData,
  setCvDetails,
  deletePreviousCv,
  fileUploadRef,
}) => {
  const { addToast } = useToasts()

  const [loading, setLoading] = useState(false)

  async function handleCvUpload(e) {
    // check if uploaded file is of accepted type.
    const filePath = e.target.value

    const allowedExtension = /(\.pdf)$/i
    if (!allowedExtension.exec(filePath)) {
      addToast('Invalid file extension, only PDF files are accepted', {
        appearance: 'error',
        autoDismiss: true,
      })
      e.target.value = ''
      return false
    }

    const file = e.target.files[0]

    // if the file pass the check
    // push the file inside a form data
    const formData = new FormData()
    formData.append('cv', file)
    setLoading(true)

    axiosInstance
      .post('/upload-cv', formData)
      .then((res) => {
        // if the file is uploaded successfully check if there is an existing cv then delete.
        deletePreviousCv()
        const data = res.data.resp

        const slicedUrlNoExt = data.url.slice(0, -3)
        const slicedUrl =
          slicedUrlNoExt.charAt(slicedUrlNoExt.length - 1) === '.'
            ? slicedUrlNoExt + 'jpg'
            : slicedUrlNoExt + '.jpg'

        // if cv is successfully uploaded to cloudinary, temporary save the details to local storage
        const cvDetails = {
          url: data.url,
          slicedUrl: slicedUrl,
          id: data.public_id,
          totalPageNumber: data.pages,
        }

        localStorage.setItem('talentCvDetails', JSON.stringify(cvDetails))
        setCvDetails(cvDetails)
        setLoading(false)
        e.target.value = ''
      })
      .catch((err) => {
        addToast(
          err?.response?.message ||
            err?.response?.data?.message ||
            'Internal Server Error',
          {
            appearance: 'error',
            autoDismiss: true,
          }
        )
        setLoading(false)
      })
  }

  return (
    <StyledInput>
      <P>Upload Cv</P>
      <input
        onChange={handleCvUpload}
        type="file"
        ref={fileUploadRef}
        hidden
        name="upload"
        id="cv-upload"
      />
      {cvData.id ? (
        <>
          {[...Array(cvData?.totalPageNumber).keys()].map((page) => (
            <CVImage
              key={page}
              url={cvData.slicedUrl}
              pageNumber={page + 1}
            />
          ))}
        </>
      ) : (
        <>
          {loading ? (
            <P>loading...</P>
          ) : (
            <label htmlFor="cv-upload">
              <FiPaperclip className="clip-icon" />
              <P size="0.75rem">Choose File</P>
            </label>
          )}
        </>
      )}
    </StyledInput>
  )
}

export default UploadInput

const StyledInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;

  label {
    width: 100%;
    height: 2.5rem;
    background: #fff;
    border: 1px solid #aeb3c9;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.65rem;
    color: #858aa0;
    cursor: pointer;

    .clip-icon {
      color: #858aa0;
      font-size: 1rem;
    }
  }
`
