import { jobs } from '../types/jobListing'

const intialState = {
  jobListings: [],
  pagination: {
    page: 1,
    pageSize: 10,
    rowCount: 10,
  },
  loading: false,
  modal: '',
  notification: {},
  selectedJob: {},
  jobChanged: false,
  jobUpdated: false,
}

const jobListingReducer = (state = intialState, action) => {
  switch (action.type) {
    case jobs.GET_JOB_LISTINGS:
      return {
        ...state,
        jobListings: action.payload.jobs,
        pagination: action.payload.pagination,
      }
    case jobs.IS_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case jobs.SET_MODAL:
      return {
        ...state,
        modal: action.payload,
      }
    case jobs.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      }
    case jobs.SELECT_JOB:
      return {
        ...state,
        selectedJob: action.payload,
      }
    case jobs.SET_JOB_CHANGED:
      return {
        ...state,
        jobChanged: !state.jobChanged,
      }
    case jobs.SET_JOB_UPDATED:
      return {
        ...state,
        jobUpdated: action.payload,
      }
    default:
      return state
  }
}

export default jobListingReducer
