import React from 'react'
import { connect } from 'react-redux'

import styled from 'styled-components'
import { P } from '../../style/Typography.style'

import {
  setFilterByPhoto,
  filterTalents,
} from '../../store/actions/talentActions'

const ToggleButton = ({ label, mt, mb, hasPhoto, setFilterByPhoto }) => {
  return (
    <StyledToggleButton isActive={hasPhoto} mt={mt} mb={mb}>
      <div onClick={setFilterByPhoto} className="toggle-button">
        <div className="toggle" />
      </div>
      <P>{label}</P>
    </StyledToggleButton>
  )
}

const mapStateToProps = (state) => {
  return {
    hasPhoto: state.talents.hasPhoto,
  }
}

export default connect(mapStateToProps, { setFilterByPhoto, filterTalents })(
  ToggleButton
)

export const JobToggleButton = ({ label, mt, mb, value, onClick }) => {
  return (
    <StyledToggleButton isActive={value} mt={mt} mb={mb}>
      <div onClick={onClick} className="toggle-button">
        <div className="toggle" />
      </div>
      <P>{label}</P>
    </StyledToggleButton>
  )
}

export const TextToggler = ({ firstLabel, lastLabel, mt, mb }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <StyledTextToggler isActive={isOpen} onClick={() => setIsOpen(!isOpen)}>
      <div className="item">{firstLabel}</div>
      <div className="item">{lastLabel}</div>
    </StyledTextToggler>
  )
}

const StyledTextToggler = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 1.75rem;
  border-radius: 100px;
  background: #efeff6;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    ${(props) => (props.isActive ? 'right: 0' : 'left: 0')};
    width: 50%;
    height: 100%;
    border-radius: 100px;
    background: #ffffff;
    border: 1px solid #aeb3c9;
    z-index: 1;
  }

  .item {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.875rem 1.3rem;
    border-radius: 100px;
    color: #0e1324;
    font-size: 0.75rem;
    font-weight: 300;
  }
`

const StyledToggleButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0')};
  margin-top: ${(props) => (props.mt ? props.mt : '0')};

  .toggle-button {
    width: 2.625rem;
    height: 1.5rem;
    background: ${(props) => (props.isActive ? '#0E1324' : '#AEB3C9')};
    border-radius: 100px;
    padding: 3px;
    position: relative;
    margin-right: 0.5rem;
    cursor: pointer;

    .toggle {
      position: absolute;
      width: 1.125rem;
      height: 1.125rem;
      background: #fff;
      border-radius: 50%;
      top: 3px;
      ${(props) => (props.isActive ? 'right: 3px' : 'left: 3px')};
      transition: all 0.3s ease-in-out;
    }
  }
`
