import React from 'react'
import styled from 'styled-components'

import ModuleList from './ModuleList'

const ModuleListWrapper = ({ fetchModuleList }) => {
  return (
    <StyledModuleListWrapper>
      <ModuleList fetchModuleList={fetchModuleList} />
    </StyledModuleListWrapper>
  )
}

export default ModuleListWrapper

const StyledModuleListWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 16rem);
  background: #fff;
  border: 0.5px solid #aeb3c9;
  border-bottom: none;
  margin-top: 1rem;
  overflow-y: hidden;
`
