import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import { setUpdateStatusTab } from '../../../../../store/actions/jobPostingActions'

const ModalSidebar = () => {
  const { updateStatusTab } = useSelector((state) => state.jobPosting)
  const dispatch = useDispatch()
  return (
    <StyledModalSidebar>
      <div
        onClick={() => dispatch(setUpdateStatusTab('Job Details'))}
        className={
          updateStatusTab === 'Job Details' ? 'menu_item active' : 'menu_item'
        }
      >
        Job Details
      </div>
      <div
        onClick={() => dispatch(setUpdateStatusTab('Payment and Benefits'))}
        className={
          updateStatusTab === 'Payment and Benefits'
            ? 'menu_item active'
            : 'menu_item'
        }
      >
        Payment and Benefits
      </div>
      <div
        onClick={() => dispatch(setUpdateStatusTab('Qualifications'))}
        className={
          updateStatusTab === 'Qualifications'
            ? 'menu_item active'
            : 'menu_item'
        }
      >
        Qualifications
      </div>
    </StyledModalSidebar>
  )
}

export default ModalSidebar

const StyledModalSidebar = styled.div`
  width: 100%;
  max-width: 200px;
  height: 100%;

  .menu_item {
    width: 100%;
    padding: 0.75rem 1rem;
    font-weight: 300;
    font-size: 1rem;
    color: #31374f;
    cursor: pointer;
    border-left: 4px solid transparent;
    transition: all 0.2s ease-in-out;

    &:hover,
    &.active {
      background: #f3f6ff;
      border-left: 4px solid #1877f2;
      color: #004eb2;
    }
  }
`
