import React from 'react'
import { useExpanded, useGroupBy, useSortBy, useTable } from 'react-table'

import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa'

import styled from 'styled-components'

export default function RecruitmentStageFunnelTable({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      { columns, data, initialState: { groupBy: ['month'] } },
      useGroupBy,
      useSortBy,
      useExpanded
    )

  return (
    <StyledTable {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, headerGroupIndex) => (
          <tr key={headerGroupIndex} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, columnIndex) => (
              <th
                key={columnIndex}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {/* Add group by column functionality */}
                {/* {column.canGroupBy && column.id === 'month' ? (
                  <span
                    className="grouped__col"
                    {...column.getGroupByToggleProps()}
                  >
                    {' '}
                    {column.isGrouped ? <FaPlusCircle /> : <FaMinusCircle />}
                  </span>
                ) : null} */}

                {column.render('Header')}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row)
          return (
            <tr key={rowIndex} {...row.getRowProps()}>
              {row.cells.map((cell, cellIndex) => {
                return (
                  <td key={cellIndex} {...cell.getCellProps()}>
                    {cell.isGrouped ? (
                      // If it's a grouped cell, add an expander and row count
                      <div className="group">
                        <span {...row.getToggleRowExpandedProps()}>
                          {row.isExpanded ? (
                            <FaMinusCircle />
                          ) : (
                            <FaPlusCircle />
                          )}
                        </span>
                        {cell.render('Cell')}
                      </div>
                    ) : cell.isAggregated ? (
                      // If the cell is aggregated, use the Aggregated
                      // renderer for cell
                      cell.render('Aggregated')
                    ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                      // Otherwise, just render the regular cell
                      cell.render('Cell')
                    )}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </StyledTable>
  )
}

const StyledTable = styled.table`
  width: 100%;
  background: #fff;
  padding: 0.5rem 0;
  padding-top: 0;
  border-collapse: separate;
  border-spacing: 0;
  text-align: center;

  th,
  tr,
  td {
    border: 1px solid #eeeef6;
  }

  /* tr:nth-child(even) {
    background: #f3f6ff;
  } */

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;

    th {
      border-bottom: 1px solid #eeeef6;
      max-width: 100px;
      font-size: 1rem;
      padding: 0.75rem 0;
      padding-top: 0.75rem;
      font-weight: 500;
    }
  }

  tbody {
    .group {
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 5px;
      width: 100%;

      span {
        margin-right: 10px;
      }
    }

    tr {
      word-break: break-all;

      &:hover {
        background: #e8e8e8;
        transition: all 0.3s ease;
        cursor: pointer;
      }
    }

    td {
      max-width: 210px;
      padding: 0.5rem;
      font-size: 1rem;
      font-weight: 300;
    }

    tr:last-child td {
      border-bottom: 0;
    }

    tr:first-child td {
      padding: 1.25rem 0.5rem;
    }
  }
`
