import styled from 'styled-components'
import Footer from '../components/Footer'
import Header from '../components/Header'

const AuthLayout = ({ children, width }) => {
  return (
    <>
      <Header />
      <ContentWrapper width={width}>{children}</ContentWrapper>
      <Footer />
    </>
  )
}

export default AuthLayout

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: ${(p) => (p.width ? p.width : '376px')};
  margin: 0 auto;
  margin-top: 4rem;
  min-height: calc(100% - 142px);

  @media (max-width: 768px) {
    padding: 0 6%;
  }
`
