import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'
import styled from 'styled-components'
import { BsList } from 'react-icons/bs'

import ReactivateApplicant from './components/ReactivateApplicant'
import MoveApplicant from '../lists/components/MoveApplicant'
import AddApplicantToList from '../lists/components/AddApplicantToList'
import TagApplicant from '../lists/components/TagApplicant'
import Heading from '../../../components/dashboard/Heading'
import TopFilter from '../../../components/dashboard/TopFilter'
import TalentPoolWrapper from '../../../components/dashboard/TalentPool/TalentPoolWrapper'
import BulkAddToVacancy from './components/BulkAddToVacancy'

import Flex from '../../../components/layouts/Flex'

import { H6 } from '../../../style/Typography.style'

import { setModalFooter } from '../../../store/actions/recruitersActions'
import { toggleSideFilters } from '../../../store/actions/talentActions'

const Home = () => {
  const dispatch = useDispatch()
  const { modal } = useSelector((state) => state.lists)
  const { activeFilters } = useSelector((state) => state.talents)

  const [filterCount, setFilterCount] = useState(0)

  useEffect(() => {
    dispatch(setModalFooter(false))
    const sum = Object.keys(activeFilters).reduce((acc, key) => {
      return acc + activeFilters[key]
    }, 0)
    setFilterCount(sum)
  }, [activeFilters])

  return (
    // Do not replace the <div> with <> as this will ruin the styles
    <div>
      {/* talents pool heading */}
      <Heading
        title="Talent Pool"
        subTitle={moment().format('MMMM Do, YYYY')}
      />

      {/* talent pool filter contains all the top section filters */}
      <FieldWrappers>
        <TopFilter />

        <Flex
          margin="0 0 1rem 0"
          items="center"
          gap="0.5rem"
          justify="flex-start"
        >
          <StyledFilterIcon
            size={24}
            onClick={() => dispatch(toggleSideFilters())}
          />
          <H6 mb="0">Filters</H6>
          <ActiveFilterCount>{filterCount}</ActiveFilterCount>
        </Flex>
      </FieldWrappers>
      {/* talent pool wrapper contains components for the sidebar filter, talent lists and talent details */}
      <TalentPoolWrapper />

      {modal === 'moveToList' && <MoveApplicant />}
      {modal === 'addToList' && <AddApplicantToList />}
      {modal === 'tagApplicant' && <TagApplicant />}
      {modal === 'reactivateApplicant' && <ReactivateApplicant />}
      {modal === 'bulkActionAddToVacancy' && <BulkAddToVacancy />}
    </div>
  )
}

export default Home

const StyledFilterIcon = styled(BsList)`
  &:hover {
    cursor: pointer;
  }
`
const ActiveFilterCount = styled.span`
  display: flex;
  border-radius: 50%;
  padding: 5px;
  width: 24px;
  height: 24px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background-color: rgb(24, 119, 242);
  justify-content: center;
  align-items: center;
`
const FieldWrappers = styled.div`
  position: sticky;
  width: 100%;
  top: 5rem;
  background: white;
  z-index: 101;
`
