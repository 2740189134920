const requisitionType = {
  SETMODAL: 'SETMODAL',
  SETINTERVIEWS: 'SETINTERVIEWS',
  SETAPPLICANTS: 'SETAPPLICANTS',
  SETFILTERS: 'SETFILTERS',
  CLEARFILTERS: 'CLEARFILTERS',
  SETUPDATEDSTAGE: 'SETUPDATEDSTAGE',
  SETSELECTEDINTERVIEW: 'SETSELECTEDINTERVIEW',
  SETRECRUITERS: 'SETRECRUITERS',
  SETSTAGE: 'SETSTAGE',
  SETMODALFOOTER: 'SETMODALFOOTER',
  SETAPPLICANTLISTURL: 'SETAPPLICANTLISTURL',
  REFRESHLIST: 'REFRESHLIST',
  SETUPDATEDNOTE: 'SETUPDATEDNOTE',
}

export default requisitionType
