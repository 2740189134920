import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { components } from 'react-select'

import { SET_SOFTWARE } from '../../../store/types/jobPosting'
import styled from 'styled-components'
import CreatableSelect from 'react-select/creatable'
import useFetch from '../../../hooks/useFetch'

import useMutation from '../../../hooks/useMutation'
import { toast } from 'react-toastify'
import Loader from '../controls/Loader'

const Menu = (props) => {
  return <components.Menu {...props}>{props.children}</components.Menu>
}

const Software = ({ disabled }) => {
  const dispatch = useDispatch()

  const { data } = useFetch('/software')

  const { handleRequest, success, loading } = useMutation()
  const [newSoftware, setNewSoftware] = useState('')

  const softwareOptions = useMemo(() => {
    if (!data?.resp?.length) return []

    return data?.resp?.map((software) => ({
      value: software.software,
      label: software.software,
    }))
  }, [data])

  const { software } = useSelector((state) => state.jobPosting)

  function checkDuplicates(values) {
    const uniqueIds = []

    const unique = values.filter((element) => {
      const isDuplicate = uniqueIds.includes(element.label)

      if (!isDuplicate) {
        uniqueIds.push(element.label)

        return true
      }

      return false
    })

    dispatch({ type: SET_SOFTWARE, payload: unique })
  }

  async function createSoftware(inputWord) {
    const body = {
      software: [inputWord],
    }

    setNewSoftware(inputWord)

    handleRequest('/software', body, 'POST')
  }

  useEffect(() => {
    if (success) {
      dispatch({
        type: SET_SOFTWARE,
        payload: [...software, { label: newSoftware, value: newSoftware }],
      })
      toast.success('Software Created Successfully', { type: 'success' })
    }
  }, [success])

  return (
    <SoftwareWrapper>
      <label>Software</label>
      <CreatableSelect
        isDisabled={disabled}
        components={{ Menu }}
        className="react-select"
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        isMulti
        // isValidNewOption={isValidNewOption}
        value={software}
        options={softwareOptions || []}
        onChange={(value) => {
          checkDuplicates(value)
        }}
        onCreateOption={(inputValue) => {
          createSoftware(inputValue)
        }}
        placeholder="Select Software"
      />

      {loading && <Loader />}
    </SoftwareWrapper>
  )
}

export default Software

const SoftwareWrapper = styled.div`
  width: 100%;
  position: relative;

  label {
    font-size: 0.75rem;
    font-weight: 300;
    color: #31374f;
    margin-bottom: 0.5rem;
  }

  .react-select {
    width: 100%;

    .css-1s2u09g-control,
    .css-1pahdxg-control,
    .css-1s2u09g-control:hover,
    .css-1s2u09g-control:focus {
      border: 1px solid #aeb3c9;
      border-radius: 0;
      min-height: 2.5rem;
      font-size: 0.75rem;
      font-weight: 300;
    }

    .css-1rhbuit-multiValue {
      background: #f2f3f8;
    }
  }
`
