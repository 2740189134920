import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import ModalHead from '../../../../components/dashboard/modal/ModalHead'

import axiosInstance from '../../../../services/config/AxiosIntance'

import { setModal } from '../../../../store/actions/listsActions'
import { setInterviews, setUpdatedStage } from '../../../../store/actions/recruitersActions'

import { P } from '../../../../style/Typography.style'

const AcceptApplicant = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const [reason, setReason] = useState('')
  const [loading, setLoading] = useState(false)

  const { selectedInterview, interviews } = useSelector(
    (state) => state.requisitions
  )

  async function handleRejectApplicant(e) {
    e.preventDefault()

    if (reason === '') {
      return addToast('Please input a reason', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    setLoading(true)

    const reqBody = {
      message: reason,
      applicant_id: selectedInterview.applicant_id.toString(),
      recruiter_id: selectedInterview.recruiter_id,
      stage_id: selectedInterview.stage_id,
      role: selectedInterview.role,
      applicant_email: selectedInterview.applicantEmail,
      job_id: selectedInterview?.job_id?.toString(),
      applicant_name: selectedInterview.applicantName
    }

    try {
      await axiosInstance.put(
        `/interviews/${selectedInterview.id}/accept`,
        reqBody
      )


      const filteredInterviews = interviews.filter(
        (interview) => interview.id !== selectedInterview.id
      )

      dispatch(setInterviews(filteredInterviews))

      addToast(
        `${selectedInterview.applicantName} is accepted and moved to 'Closed' stage`,
        {
          appearance: 'info',
          autoDismiss: true,
        }
      )
      dispatch(setUpdatedStage('offer'))
      setReason('')
      dispatch(setModal(''))
      setLoading(false)
    } catch (error) {
      setLoading(false)
      addToast(
        error?.response?.data?.message ||
        error?.response?.data ||
        'Something went wrong',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    }

  }
  return (
    <MainModal
      buttonText="Accept Applicant"
      loading={loading}
      onClick={handleRejectApplicant}
      info
      header={
        <ModalHead
          name={selectedInterview.applicantName}
          role={selectedInterview.role}
        />
      }
    >
      <Grid>
        <div>
          <P size="1.25rem" as="label" htmlFor="accept">
            Accept Applicant
          </P>
          <P as="label" htmlFor="accept">
            This will move the applicant to the &apos;Closed&apos; stage and marked with the &apos;Accepted&apos; tag
          </P>
        </div>
      </Grid>

      {/*  */}

      <TextAreaContainer>
        <P>
          Reason for Acceptance <span style={{ color: '#DC2929' }}>*</span>
        </P>
        <textarea
          onChange={(e) => setReason(e.target.value)}
          value={reason}
        ></textarea>
      </TextAreaContainer>

    </MainModal>
  )
}

export default AcceptApplicant

const Grid = styled.div`
  gap: 1rem;
  margin-bottom: 1rem;
`
const TextAreaContainer = styled.div`
  margin-top: 2rem;

  textarea {
    width: 100%;
    height: 222px;
    border: 1px solid #aeb3c9;
    margin-top: 0.875rem;
    outline: none;
    border-radius: none;
    padding: 0.75rem;
    font-size: 0.875rem;
    font-weight: 300;

    &:focus,
    &:hover {
      border: 1px solid #1a1a1a;
    }
  }
`
