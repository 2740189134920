import React from 'react'

import { Routes, Route } from 'react-router-dom'

// pages for job posting
import JobPostingHome from './JobPostingHome'
import CreateJobPost from './CreateJobPost'
import PublishJobPosting from './PublishJobPosting'

const JobPosting = () => {
  return (
    <Routes>
      <Route path="/" element={<JobPostingHome />} />
      <Route path="/create" element={<CreateJobPost />} />
      <Route path="/publish" element={<PublishJobPosting />} />
    </Routes>
  )
}

export default JobPosting
