import React from 'react'
import styled from 'styled-components'

const FormProgress = ({ totalSteps, activeStep }) => {
    return <StyledFormLinearProgress>
        <div className='progress_bar'>
            {[...Array(totalSteps)].map((_, index) => (
                <div
                    className={`step ${index + 1 <= activeStep ? "active" : ""}`}
                    key={index}
                />
            ))}
        </div>
        <h5 className='progress_number'>{`${activeStep}/${totalSteps}`}</h5>
    </StyledFormLinearProgress>
}

const StyledFormLinearProgress = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 0.5rem;

    .progress_bar {
        display: flex;
        width: 100%;
        height: 4px;
        background: #DFDFE9;
        border-radius: 100px;
        margin: 15px 0;

        .step {
            width: 100%;
            height: 4px;

            &.active {
                background: #1877f2;
              }

            &:not(:last-child) {
                margin-right: 4px;
            }
        }
    }
    .progress_number {
        font-weight: 500;
        font-size: 14px;
        color: #858AA0;
        margin: 0;
    }
`

export default FormProgress;


