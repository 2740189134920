import React from 'react'

import { IoCloseOutline } from 'react-icons/io5';
import { FiCheck } from 'react-icons/fi';

import styled from 'styled-components'

const Mark = ({ type, isActive, onClick, disabled }) => {
  return (
    <StyledMark disabled={disabled} onClick={() => !disabled ? onClick() : null} type={type} className={isActive ? 'active' : ''}>
        {type === 'correct' ? <FiCheck color="white" size="1rem" /> : <IoCloseOutline size="1rem" color="#fff" />}
    </StyledMark>
  )
}

export default Mark

const StyledMark = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #DFDFE9;
    cursor: ${({ disabled }) => disabled ? "not-allowed" : "pointer"};

    &.active, &:hover {
        background: ${props => props.type === 'correct' ? '#41BA80' : '#DC2929'};
    }
`