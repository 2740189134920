import React from 'react'

import styled from 'styled-components'

import { IoIosClose } from 'react-icons/io'

const TagInput = ({ name, label, margin, tags = [], setTags, disabled }) => {
  const addTags = (event) => {
    if (!disabled) {
      const inputValue = event.target.value
      if (inputValue !== '') {
        if (
          tags.filter((t) => t.toLowerCase() === inputValue.toLowerCase())
            .length === 0
        ) {
          setTags([...tags, inputValue])
        }

        event.target.value = ''
      }
    }
  }

  const removeTags = (indexToRemove) => {
    if (!disabled) {
      const newTags = tags.filter((tag, index) => index !== indexToRemove)
      setTags(newTags)
    }
  }

  return (
    <StyledTagInput margin={margin}>
      <label htmlFor={name} className="label">
        {label}
      </label>

      {/* input wrapper */}
      <div className="input-wrapper">
        {tags.map((tag, index) => (
          <div key={index} className="innerTag">
            {tag}
            <IoIosClose onClick={() => removeTags(index)} className="icon" />
          </div>
        ))}

        {/* input */}
        <input
          placeholder="Press enter to add more..."
          onKeyUp={(e) => {
            if (!disabled) {
              if (e.key === 'Enter') {
                addTags(e)
              }
              return null
            }
          }}
          disabled={disabled}
        />
      </div>
    </StyledTagInput>
  )
}

export default TagInput

const StyledTagInput = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin: ${({ margin }) => margin || '0'};
  margin-bottom: 1rem;

  .label {
    font-size: 0.75rem;
    font-weight: 300;
    color: #0e1324;
    margin-bottom: 4px;
    text-align: left;
  }

  .input-wrapper {
    width: 100%;
    padding: 1rem;
    border: 1px solid #aeb3c9;
    background: #fff;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;

    .innerTag {
      padding: 0.5rem;
      font-size: 0.75rem;
      font-weight: 400;
      background: #f2f3f8;
      border-radius: 3px;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .icon {
        cursor: pointer;
      }
    }

    input {
      width: auto;
      min-width: 200px;
      height: 100%;
      font-size: 0.75rem;
      font-weight: 300;
      border: none;
      outline: none;
      background: none;
    }
  }
`
