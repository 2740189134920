import moment from 'moment'

import axiosInstance from '../../services/config/AxiosIntance'

import userTypes from '../types/users'

export const setModal = (modalType) => {
  return { type: userTypes.SET_MODAL, payload: modalType }
}
export const setErrorMessage = (message) => {
  return { type: userTypes.SET_ERROR, payload: message }
}
export const insertRecord = (formData) => async (dispatch) => {
  dispatch({ type: userTypes.SAVE_DATA_LOADING })
  try {
    const result = await axiosInstance.post('/user/registration', formData)
    const data = result.data.data
    if (data.user_type_id === 1) {
      data.recruiterName = data.name
    }
    if (data.user_type_id === 3) {
      data.adminName = data.name
    }
    if (data.user_type_id === 5) {
      data.interviewerName = data.name
    }
    if (data.user_type_id === 7) {
      data.cdmName = data.name
    }

    data.userType = formData.user_type
    const successMessage = result.data.message
    dispatch({
      type: userTypes.ADD_DATA_SUCCESS,
      payload: { data: data, message: successMessage },
    })
  } catch (error) {
    dispatch({ type: userTypes.SAVE_DATA_ERROR, payload: error })
  }
}
export const updateRecord = (id, formData) => async (dispatch, getState) => {
  const selectedRecord = getState().users.selectedRecord
  dispatch({ type: userTypes.SAVE_DATA_LOADING })
  try {
    const result = await axiosInstance.put(`/user/${id}/update`, formData)
    const data = formData
    data.id = id
    data.status = selectedRecord.status
    data.userType = formData.user_type
    data.created_at = selectedRecord.created_at
    data.updated_at = moment()
    if (formData.user_type === 'recruiter') {
      data.recruiterName = `${formData.first_name} ${formData.last_name}`
    }
    if (formData.user_type === 'admin') {
      data.adminName = `${formData.first_name} ${formData.last_name}`
    }
    const successMessage = result.data.message
    dispatch({
      type: userTypes.UPDATE_DATA_SUCCESS,
      payload: { data: data, id: id, message: successMessage },
    })
  } catch (error) {
    dispatch({ type: userTypes.SAVE_DATA_ERROR, payload: error })
  }
}
export const updateUserStatus = (id) => async (dispatch, getState) => {
  const selectedRecord = getState().users.selectedRecord
  const list = getState().users.users
  dispatch({ type: userTypes.SAVE_DATA_LOADING })
  try {
    const result = await axiosInstance.put(`/user/${id}/update-status`, {})
    const data = list.filter((e) => e.id === id)[0]
    if (selectedRecord.status === 1) {
      data.status = 0
    } else {
      data.status = 1
    }
    const successMessage = result.data.message
    dispatch({
      type: userTypes.UPDATE_DATA_SUCCESS,
      payload: { data: data, id: id, message: successMessage },
    })
  } catch (error) {
    dispatch({ type: userTypes.SAVE_DATA_ERROR, payload: error })
  }
}

export const passwordReset = (id) => async (dispatch, getState) => {
  const selectedRecord = getState().users.selectedRecord
  dispatch({ type: userTypes.SAVE_DATA_LOADING })
  try {
    const result = await axiosInstance.put(`/user/${id}/password-reset`, {})
    const successMessage = result.data.message
    dispatch({
      type: userTypes.UPDATE_DATA_SUCCESS,
      payload: { data: selectedRecord, id: id, message: successMessage },
    })
  } catch (error) {
    dispatch({ type: userTypes.SAVE_DATA_ERROR, payload: error })
  }
}

export const fetchList =
  (url = '/users') =>
  async (dispatch) => {
    dispatch({ type: userTypes.FETCH_LIST_LOADING })
    try {
      const { data } = await axiosInstance.get(url)
      let result
      if (data?.resp?.users) {
        result = data.resp
      } else {
        result = []
      }
      dispatch({ type: userTypes.FETCH_LIST_SUCCESS, payload: result })
    } catch (error) {
      dispatch({ type: userTypes.FETCH_LIST_ERROR, payload: error })
    }
  }
export const fetchRecord = (value) => async (dispatch) => {
  dispatch({ type: userTypes.FETCH_RECORD_LOADING })
  try {
    let result
    if (value.id) {
      await axiosInstance.get(`/users?id=${value.id}`)
      result = value
    } else {
      result = {}
    }
    dispatch({ type: userTypes.FETCH_RECORD_SUCCESS, payload: result })
  } catch (error) {
    dispatch({ type: userTypes.FETCH_RECORD_ERROR, payload: error })
  }
}
export const setPagination = (options) => {
  return { type: userTypes.SET_PAGINATION_OPTIONS, payload: options }
}
export const setQueryParams = (params) => {
  return { type: userTypes.SET_QUERY_PARAMS, payload: params }
}
