/* eslint-disable camelcase */
import React from 'react'

import { MdOutlineSupervisorAccount } from 'react-icons/md'
import { HiOutlineCalendar } from 'react-icons/hi'
import { IoMdTime } from 'react-icons/io'
import { useSelector } from 'react-redux'

import { H6, P } from '../../../../style/Typography.style'

import Flex from '../../../../components/layouts/Flex'
import IconWrapper from '../../../../components/IconWrapper'
import Tag from '../../../../components/Tag'

const PreviewPost = () => {
  const {
    title,
    active_vacancy,
    time_shift,
    description,
    min_salary,
    max_salary,
    salary_basis,
    experience,
    education,
    technical_skills,
    language,
    benefits,
    job_type,
    currencies,
  } = useSelector((state) => state.jobPosting)

  return (
    <Flex
      direction="column"
      style={{ borderBottom: '1px solid #DFDFE9', paddingBottom: '0.75rem' }}
    >
      <H6 mt="2rem">{title}</H6>

      <P>
        {currencies} {min_salary} - {currencies} {max_salary} {salary_basis}
      </P>

      <Flex
        margin="1rem 0"
        items="center"
        gap="2rem"
        gapMobile="1rem"
        wrap="wrap"
      >
        <Flex items="center" gap="8px">
          <IconWrapper>
            <MdOutlineSupervisorAccount className="icon" />
          </IconWrapper>
          <P size="0.75rem">{active_vacancy} Vacancies</P>
        </Flex>
        <Flex items="center" gap="8px">
          <IconWrapper>
            <HiOutlineCalendar className="icon" />
          </IconWrapper>
          <P size="0.75rem">{job_type}</P>
        </Flex>
        <Flex items="center" gap="8px">
          <IconWrapper>
            <IoMdTime className="icon" />
          </IconWrapper>
          <P size="0.75rem">{time_shift}</P>
        </Flex>
      </Flex>

      {/* Benefits */}
      <Flex direction="column" gap="1rem">
        <P size="0.875rem" weight="500">
          Benefits
        </P>

        <Flex wrap="wrap" gap="1rem">
          {benefits.map((benefit) => (
            <Tag key={benefit}>{benefit}</Tag>
          ))}
        </Flex>
      </Flex>

      {/* Required Technical Skills */}
      <Flex margin="1.5rem 0" direction="column" gap="1rem">
        <P size="0.875rem" weight="500">
          Required Technical Skills
        </P>

        <Flex wrap="wrap" gap="1rem">
          {technical_skills.map((skill) => (
            <Tag key={skill.value}>{skill.value}</Tag>
          ))}
        </Flex>
        <P size="0.875rem" weight="500">
          Job Summary
        </P>
        <P dangerouslySetInnerHTML={{ __html: description }} />
      </Flex>

      {/* Others */}
      <Flex gap="2rem" wrap="wrap" maxw="400px">
        <Flex direction="column" gap="0.5rem">
          <P size="0.875rem" weight="500">
            Experience
          </P>
          <Flex direction="column" gap="0.5rem">
            <P>
              {experience[0].specification} : {experience[0].years}
            </P>
            {experience[1].specification !== '' && (
              <P>
                {experience[1].specification} : {experience[1].years}
              </P>
            )}
          </Flex>
        </Flex>
        <Flex direction="column" gap="0.5rem">
          <P size="0.875rem" weight="500">
            Education
          </P>
          <P>{education}</P>
        </Flex>
        <Flex direction="column" gap="0.5rem">
          <P size="0.875rem" weight="500">
            Language
          </P>
          <P>
            {language.map((item) => {
              if (item === language[language.length - 1]) {
                return item.value
              }
              return `${item.value}, `
            })}
          </P>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PreviewPost
