import { GET_SKILLS } from '../types/skills'

const initialState = {
  personalSkills: [],
  technicalSkills: [],
}

const skillsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SKILLS:
      return {
        ...state,
        personalSkills: action.payload.personalSkills,
        technicalSkills: action.payload.technicalSkills,
      }
    default:
      return state
  }
}

export default skillsReducer
