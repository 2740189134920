import React from 'react'

import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch } from 'react-redux'

import TalentCardPopList from '../../../../components/dashboard/TalentPool/TalentCardPopList'
import TalentCardPopout from '../../../../components/dashboard/TalentPool/TalentCardPopout'

import { setModal } from '../../../../store/actions/sidebarModuleActions'

const ActionsMenu = ({ setShowMenu }) => {
  const dispatch = useDispatch()

  const handleOutsideClick = () => setShowMenu(false)

  const handleUpdateModuleModal = (e) => {
    e.stopPropagation()
    dispatch(setModal('updateModule'))
  }

  const handleDeleteModule = (e) => {
    e.stopPropagation()
    dispatch(setModal('deleteModule'))
  }

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <TalentCardPopout maxW="199px" width="199px" top="0" left="55%">
        <TalentCardPopList
          onClick={handleUpdateModuleModal}
          text="Edit Module"
        />
        <TalentCardPopList onClick={handleDeleteModule} text="Delete Module" />
      </TalentCardPopout>
    </OutsideClickHandler>
  )
}

export default ActionsMenu
