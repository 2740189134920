import React, { useEffect, useState, useCallback } from 'react'
import ReactDOM from 'react-dom'

import { useSelector, useDispatch } from 'react-redux'

import styled from 'styled-components'
import { IoCloseOutline } from 'react-icons/io5'
import moment from 'moment'
import { FiPlus } from 'react-icons/fi'
import { ClipLoader } from 'react-spinners'

import { H6, P } from '../../../../../style/Typography.style'

import Flex from '../../../../../components/layouts/Flex'
import DashboardButton from '../../../../../components/dashboard/DashboardButton'
import EmptyWrapper from '../../../../../components/EmptyWrapper'
import ActiveListing from '../Listings/ActiveListing'
import ContactListing from '../Listings/ContactListing'
import JobListing from '../Listings/JobListing'
import ContactListingForm from './ContactListingForm'

import axiosInstance from '../../../../../services/config/AxiosIntance'

import {
  setLoading,
  setNotification,
  setSelectedRecord,
  setModal,
  setList,
  setFormData,
} from '../../../../../store/actions/clientActions'

const ClientDetailsModal = () => {
  const listChanged = useSelector((state) => state.clients.listChanged)
  const modal = useSelector((state) => state.clients.modal)

  const selectedClient = useSelector(
    (state) => state.clients.selectedRecord.client
  )
  const selectedContact = useSelector(
    (state) => state.clients.selectedRecord.contact
  )
  const selectedActiveListing = useSelector(
    (state) => state.clients.selectedRecord.activeListing
  )

  const loadingClient = useSelector((state) => state.clients.loading.client)
  const loadingContactList = useSelector(
    (state) => state.clients.loading.contactList
  )
  const loadingActiveListings = useSelector(
    (state) => state.clients.loading.activeListings
  )
  const loadingContactListings = useSelector(
    (state) => state.clients.loading.contactListings
  )
  const loadingJobListings = useSelector(
    (state) => state.clients.loading.jobListings
  )

  const contactListingChanged = useSelector(
    (state) => state.clients.listChanged.contactListing
  )

  const contactLists = useSelector((state) => state.clients.lists.contact)
  const jobListings = useSelector((state) => state.clients.lists.jobListings)

  const dispatch = useDispatch()

  const [activeListings, setActiveListings] = useState([])
  const [activeTab, setActiveTab] = useState('activeListings')

  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type

  useEffect(() => {
    if (activeTab === 'activeListings') {
      let requestUrl = `/company/jobs?company_id=${selectedClient?.id}&status=active`

      if (role === 'poc') {
        const companyDetails = JSON.parse(currentUser)?.companyDetails
        requestUrl += `&companyDetails=${JSON.stringify(companyDetails)}`
      }

      dispatch(setLoading({ name: 'activeListings', value: true }))
      axiosInstance
        .get(requestUrl)
        .then((response) => {
          dispatch(setLoading({ name: 'activeListings', value: true }))
          setActiveListings(response.data.data)
          dispatch(setSelectedRecord('activeListing', response.data.data[0]))
        })
        .catch((err) => {
          dispatch(setNotification('error', err?.response?.data?.message))
        })
        .finally(() => {
          dispatch(setLoading({ name: 'activeListings', value: false }))
        })
    }
    if (activeTab === 'pointOfContacts') {
      dispatch(setLoading({ name: 'contactList', value: true }))

      let requestUrl = `/poc/users?company_id=${selectedClient.id}`

      if (role === 'poc') {
        const companyDetails = JSON.parse(currentUser)?.companyDetails
        requestUrl += `&companyDetails=${JSON.stringify(companyDetails)}`
      }

      axiosInstance
        .get(requestUrl)
        .then((response) => {
          const result = response.data.data
          const fetchedContacts = result.map((contact) => {
            return {
              id: contact.id,
              user_id: contact.user_id,
              company_id: contact.company_id,
              first_name: contact.first_name,
              middle_name: contact.middle_name,
              last_name: contact.last_name,
              email: contact.email,
              phone_number: contact.phone_number,
              city: contact.city,
              country: contact.country,
              status: contact.status,
              monitor_all_jobs: contact.monitor_all_jobs,
            }
          })
          dispatch(setList({ listName: 'contact', data: fetchedContacts }))
          dispatch(setLoading({ name: 'contactList', value: false }))
          dispatch(setSelectedRecord('contact', fetchedContacts[0]))
        })
        .catch((err) => {
          dispatch(setNotification('error', err?.response?.data?.message))
        })
    }
  }, [activeTab, contactListingChanged])

  useEffect(() => {
    const activeListing = selectedActiveListing ?? activeListings[0]

    if (activeListing?.job_id && activeListing?.poc_id) {
      dispatch(setLoading({ name: 'contactListing', value: true }))
      axiosInstance
        .get(
          `/contact-listing?job_id=${activeListing?.job_id}&poc_id=${activeListing?.poc_id}`
        )
        .then((response) => {
          const result = response?.data?.data[0]
          let contactListingFields = {}

          if (result) {
            delete result.created_at
            delete result.updated_at
            contactListingFields = result
          } else {
            contactListingFields = {
              id: null,
              job_id: activeListing?.job_id,
              poc_id: activeListing?.poc_id,
              recommendations: '',
              general_structure: '',
              client_questions: '',
              notes: '',
            }
          }
          dispatch(setFormData('contactListing', contactListingFields))
        })
        .catch((err) => {
          dispatch(setNotification('error', err?.response?.data?.message))
        })
        .finally(() =>
          dispatch(setLoading({ name: 'contactListing', value: false }))
        )
    }
  }, [selectedActiveListing?.id, activeListings])

  useEffect(() => {
    dispatch(setLoading({ name: 'jobListings', value: true }))
    axiosInstance
      .get(`/job?poc_id=${selectedContact?.id}`)
      .then((response) => {
        const updatedJobs = response.data.data.map((e) => ({
          ...e,
          expanded: false,
        }))
        dispatch(setList({ listName: 'jobListings', data: updatedJobs }))
      })
      .catch((err) => {
        dispatch(setNotification('error', err?.response?.data?.message))
      })
      .finally(() => {
        dispatch(setLoading({ name: 'jobListings', value: false }))
      })
  }, [selectedContact?.id, listChanged.jobListing])

  const handleClose = () => dispatch(setModal([]))
  const handleContactForm = () => {
    dispatch(setModal([...modal, 'contactFormModal']))
    dispatch(setSelectedRecord('contact', { id: null }))
  }

  const getActiveListingComponent = useCallback(() => {
    let component

    if (loadingActiveListings) {
      component = (
        <Flex padding="1rem" bg="#fff" justify="center" items="center">
          <ClipLoader color="#1a1a1a" size="2rem" />
        </Flex>
      )
    } else {
      if (activeListings.length) {
        component = activeListings.map((listing, index) => (
          <ActiveListing
            key={index}
            data={{
              ...listing,
              id: index,
              date: moment(listing?.created_at).format('MMM Do YYYY'),
            }}
          />
        ))
      } else {
        component = <EmptyWrapper message="You have no active listings." />
      }
    }

    return component
  }, [loadingActiveListings])

  const getContactListComponent = useCallback(() => {
    let component

    if (loadingContactList) {
      component = (
        <Flex padding="1rem" bg="#fff" justify="center" items="center">
          <ClipLoader color="#1a1a1a" size="2rem" />
        </Flex>
      )
    } else {
      if (contactLists.length) {
        component = contactLists?.map((contact) => (
          <ContactListing
            key={contact?.id}
            data={contact}
            renderedFrom="clientDetailsModal"
          />
        ))
      } else {
        component = <EmptyWrapper message="You have no point of contacts." />
      }
    }

    return component
  }, [loadingContactList])

  const getJobListingComponent = useCallback(() => {
    let component

    if (loadingJobListings) {
      component = (
        <Flex padding="1rem" bg="#fff" justify="center" items="center">
          <ClipLoader color="#1a1a1a" size="2rem" />
        </Flex>
      )
    } else {
      if (jobListings.length) {
        component = jobListings.map((job, index) => (
          <JobListing
            key={job.id}
            jobId={job.id}
            expanded={job.expanded}
            index={index}
            title={job.title}
            date={moment(job?.created_at).format('MMM Do YYYY')}
            status={job.status}
          />
        ))
      } else {
        component = <EmptyWrapper message="You have no job listings" />
      }
    }

    return component
  }, [loadingJobListings, jobListings])

  return ReactDOM.createPortal(
    <StyledModalWrapper>
      <div className="modal-wrapper">
        {loadingClient ? (
          <Flex bg="#fff" justify="center" items="center">
            <ClipLoader color="#1a1a1a" size="2rem" />
          </Flex>
        ) : (
          <div className="client_info">
            <ModalHeader>
              <div className="details">
                <H6 weight="500" size="1.25rem" align="left">
                  {selectedClient?.name}
                </H6>
                <H6 weight="500" size="1rem" align="left">
                  Website:{' '}
                  <a
                    href={`${selectedClient?.client_website}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {selectedClient?.client_website}
                  </a>
                </H6>
                <H6 weight="500" size="1rem" align="left">
                  Email: {selectedClient?.client_email}
                </H6>
                <H6 weight="500" size="1rem" align="left">
                  Sector: {selectedClient?.sector}
                </H6>
              </div>
            </ModalHeader>
            <ModalBody>
              <Flex margin="1rem 0 0.5rem 0" items="center">
                <P weight="500" size="16px">
                  What the company does
                </P>
              </Flex>
              <Flex items="center">
                <P
                  weight="300"
                  size="14px"
                  textAlign="justified"
                  dangerouslySetInnerHTML={{
                    __html:
                      selectedClient?.company_details ||
                      'No description at the moment',
                  }}
                ></P>
              </Flex>
              <Flex margin="1rem 0 0 0" items="center" gap="1rem">
                <P
                  weight="500"
                  size="16px"
                  onClick={() => setActiveTab('activeListings')}
                  borderBottom={`${
                    activeTab === 'activeListings' && '2px solid blue'
                  }`}
                  cursor="pointer"
                >
                  Active Listings
                </P>
                <P
                  weight="500"
                  size="16px"
                  onClick={() => setActiveTab('pointOfContacts')}
                  borderBottom={`${
                    activeTab === 'pointOfContacts' && '2px solid blue'
                  }`}
                  cursor="pointer"
                >
                  Point of Contacts
                </P>
              </Flex>
              {activeTab === 'activeListings' && getActiveListingComponent()}

              {activeTab === 'pointOfContacts' && role === 'admin' && (
                <Flex justify="flex-end">
                  <DashboardButton
                    text="Add POC"
                    onClick={handleContactForm}
                    icon={<FiPlus className="icon" />}
                  />
                </Flex>
              )}

              {activeTab === 'pointOfContacts' && getContactListComponent()}
            </ModalBody>
          </div>
        )}

        {loadingContactListings ? (
          <Flex bg="#fff" justify="center" items="center">
            <ClipLoader color="#1a1a1a" size="2rem" />
          </Flex>
        ) : (
          <div className="client_details">
            <div className="contact_header">
              <div className="contact_title">
                <H6 weight="500" size="1.25rem" align="left" mb="1.5rem">
                  {activeTab === 'activeListings'
                    ? 'Contact Listings'
                    : 'Vacancies Per Contact'}
                </H6>
              </div>
              <IoCloseOutline onClick={handleClose} className="icon" />
            </div>
            <div className="contact_lists">
              {activeTab === 'activeListings' && <ContactListingForm />}

              {activeTab === 'pointOfContacts' && getJobListingComponent()}
            </div>
          </div>
        )}
      </div>
    </StyledModalWrapper>,
    document.getElementById('modal')
  )
}

export default ClientDetailsModal

const StyledModalWrapper = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(71, 86, 116, 0.4);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 101;

  .modal-wrapper {
    width: 100%;
    max-width: 1336px;
    height: 94vh;
    background: #fff;
    display: grid;
    grid-template-columns: 579px 1fr;
    position: relative;

    .client_info {
      border-right: 1px solid #dfdfe9;
    }

    .client_details {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 650px;
      overflow-y: auto;
      padding: 1rem 1.5rem;

      .contact_title {
        display: flex;
        gap: 0.5rem;
      }

      .contact_header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        position: fixed;
        width: 100%;
        max-width: 701px;
        top: 48px;
        padding-top: 0.5rem;
        background: #ffff;
        font-size: 1.5rem;
        z-index: 100;

        .icon {
          cursor: pointer;

          &.poc {
            display: none;
          }
        }
      }

      .contact_lists {
        position: relative;
        top: 50px;
        bottom: 0;
      }
    }
  }
`

const ModalHeader = styled.div`
  width: 100%;
  padding: 1rem 1rem 0 1.5rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  border-bottom: 1px solid #dfdfe9;

  a {
    text-decoration: none;
  }
`

const ModalBody = styled.div`
  width: 100%;
  padding: 0 1.5rem 0 1.5rem;
  height: calc(100vh - 13rem);
  overflow-y: auto;
`
