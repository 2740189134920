import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const NavLinks = ({ children, show, to = '/' }) => {
  return (
    <NavLinkStyle show={show} to={to}>
      {children}
    </NavLinkStyle>
  )
}

export default NavLinks

const NavLinkStyle = styled(NavLink)`
  width: 100%;
  display: flex;
  align-items: center;
  color: #31374f;
  font-size: 0.875rem;
  font-weight: 300;
  text-decoration: none;
  padding: 0.85rem 0;
  transition: all ease-in-out 0.3s;

  .icon {
    font-size: 1.5rem;
    color: #858AA0;
    margin-right: ${(p) => (p.show ? '1rem' : '0')};
    width: 1.5rem;
    height: 1.5rem;

    path {
      fill: #858AA0;
    }
  }

  &.active,
  &:hover {
    color: #000;
    font-weight: 500;

    .icon {
      color: #000;

      path {
        fill: #000;
      }
    }
  }
`
