import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Flex from '../../../../components/layouts/Flex'
import MainModal from '../../../../components/dashboard/modal/MainModal'

import DashboardInput from '../../../../components/dashboard/DashboardInput'

import {
  insertRecord,
  updateRecord,
} from '../../../../store/actions/userTypeActions'

const SaveUserType = () => {
  const dispatch = useDispatch()

  const { loading, modal, selectedRecord } = useSelector(
    (state) => state.userTypes
  )

  const [name, setName] = useState('')
  const [code, setCode] = useState('')

  useEffect(() => {
    if (modal === 'updateUserType') {
      setName(selectedRecord.name)
      setCode(selectedRecord.user_type)
    }
  }, [modal])

  const handleSaveUserType = () => {
    const request = {
      name,
      user_type: code,
    }
    if (modal === 'updateUserType') {
      dispatch(updateRecord(selectedRecord.id, request))
    } else {
      dispatch(insertRecord(request))
    }
  }

  return (
    <MainModal
      title={
        modal === 'updateUserType' ? 'Update User Type' : 'Create User Type'
      }
      onClick={handleSaveUserType}
      buttonText="Submit"
      loading={loading}
      size="small"
    >
      <Flex gap="0.4rem">
        <DashboardInput
          name="userTypeName"
          label="User Type Name *"
          placeholder="Enter User Type Name"
          type="text"
          height="2.5rem"
          value={name}
          handleChange={(e) => setName(e.target.value)}
        />
      </Flex>
      <Flex gap="0.4rem">
        <DashboardInput
          name="userTypeCode"
          label="User Type Code *"
          placeholder="eg. recruiter"
          type="text"
          height="2.5rem"
          value={code}
          handleChange={(e) => setCode(e.target.value)}
        />
      </Flex>
    </MainModal>
  )
}

export default SaveUserType
