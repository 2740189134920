import React from 'react'

import { useSelector, useDispatch } from 'react-redux'

import styled from 'styled-components'
import { SlArrowDown, SlArrowUp } from 'react-icons/sl'

import JobListingDetails from './JobListingDetails'

import { capitalize } from '../../../../../utils/capitalize'

import {
  setList,
  setSelectedRecord,
  setLoading,
} from '../../../../../store/actions/clientActions'

const JobListing = ({ title, date, status, expanded, jobId, index }) => {
  const dispatch = useDispatch()

  const jobListings = useSelector((state) => state.clients.lists.jobListings)

  const handleExpand = () => {
    dispatch(setLoading({ name: 'jobListings', value: true }))
    const updatedJobListing = [...jobListings]
    updatedJobListing[index] = {
      ...updatedJobListing[index],
      expanded: !updatedJobListing[index].expanded,
    }
    dispatch(setList({ listName: 'jobListings', data: updatedJobListing }))
    dispatch(setSelectedRecord('jobListing', updatedJobListing[index]))
    dispatch(setLoading({ name: 'jobListings', value: false }))
  }

  return (
    <>
      <StyledJobListing onClick={handleExpand}>
        <div className="job_section">
          <div className="job_header">
            <h5 className="job_header_text">{title}</h5>
            <div className="icons">
              {expanded ? (
                <SlArrowUp onClick={handleExpand} />
              ) : (
                <SlArrowDown onClick={handleExpand} />
              )}
            </div>
          </div>
          <div className="job_body">
            <h5 className="job_body_text">{date + ' '}</h5>
            <h5 className={`job_body_text status ${status}`}>
              • {capitalize(status)}
            </h5>
          </div>
        </div>
      </StyledJobListing>
      {expanded && <JobListingDetails jobId={jobId} />}
    </>
  )
}

const StyledJobListing = styled.div`
  .job_section {
    background: #f2f5f9;
    border: 1px solid #dfe2ec;
    border-radius: 3px;
    padding-left: 17px;
    margin: 12px 0px 8px 0px;
    gap: 4px;
    cursor: pointer;

    .job_header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: baseline;
      padding-top: 11px;
      margin: 0 !important;

      .job_header_text {
        font-weight: 500;
        font-size: 16px;
      }

      .icons {
        display: flex;
        gap: 14px;
        font-size: 18px;
        margin-right: 14px;
        cursor: pointer;
      }
    }
    .job_body {
      padding-bottom: 0.5rem;

      h5 {
        display: inline;
        font-size: 14px;
      }
      .job_body_text {
        font-weight: 300;
      }

      .status {
        font-weight: 600;

        &.active {
          color: #488e6d;
        }

        &.inactive,
        &.closed {
          color: #9a9a9a;
        }
      }
    }
  }
`

export default JobListing
