import React, { useEffect, useState, useMemo } from 'react'

import styled from 'styled-components'
import useMutation from '../../../hooks/useMutation'

import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'

import Flex from '../../layouts/Flex'
import DashboardButton from '../DashboardButton'
import RichTextbox from '../controls/RichTextBox'

import { OPEN_ACTIVITIES_MODAL } from '../../../store/actions/types'

const AddNote = ({ refetch }) => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const [note, setNote] = useState('')
  const { user } = useSelector((store) => store.auth)
  const { talent } = useSelector((store) => store.talents)
  const { error, handleRequest, loading, success } = useMutation()

  const noteAuthor = user?.recruiterName || user?.adminName || 'Main Recruiter'

  const cachedData = useMemo(() => localStorage.getItem('cachedData'))

  const formData = {
    applicant_id: talent?.id?.toString(),
    note_author_id: user ? user.id.toString() : '',
    note_author: noteAuthor,
    notes: [note],
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (note === '')
      return addToast('Please add a note', { appearance: 'error' })
    if (typeof note !== 'string')
      return addToast('Note must be a string', { appearance: 'error' })

    // send the request if everything works as planned
    handleRequest('/notes', formData)
    cacheNotes('')
  }

  const handleNoteChanged = (e) => {
    setNote(e)
  }

  const handleReset = () => {
    setNote('')
    cacheNotes('')
  }

  const cacheNotes = (noteData) => {
    const parsedCacheData = JSON.parse(cachedData) || []
    let notesCache = parsedCacheData?.note || []

    const notesArr = notesCache.map((e) => e.applicant_id)

    if (!notesArr.includes(talent?.id)) {
      notesCache.push({
        applicant_id: talent?.id,
        note: noteData,
      })
    } else {
      notesCache = notesCache.map((e) => {
        if (e.applicant_id === talent?.id) {
          e.applicant_id = talent?.id
          e.note = noteData
        }
        return e
      })
    }

    const newCache = {
      ...parsedCacheData,
      note: notesCache,
    }

    localStorage.setItem('cachedData', JSON.stringify(newCache))
  }

  useEffect(() => {
    if (success) {
      setNote('')
      refetch()
      dispatch({ type: OPEN_ACTIVITIES_MODAL, payload: 'notes' })
      addToast('Note added successfully.', { appearance: 'success' })
    }

    if (error) {
      addToast(error?.response?.data, { appearance: 'error' })
    }
  }, [success, error])

  useEffect(() => {
    if (note) {
      cacheNotes(note)
    }
  }, [note])

  useEffect(() => {
    if (cachedData) {
      const cachedDataObj = JSON.parse(cachedData)
      const selectedNote = cachedDataObj?.note?.filter(
        (e) => e.applicant_id === talent?.id
      )
      if (selectedNote) setNote(selectedNote[0]?.note || '')
    }
  }, [talent?.id])

  return (
    <StyledTextareaWrapper onSubmit={handleSubmit}>
      <label>Notes</label>

      <RichTextbox
        content={note}
        setContent={handleNoteChanged}
        isReadOnly={false}
      />

      <Flex items="center" justify="flex-end" gap="1.5rem">
        <BorderlessButton type="reset" onClick={handleReset}>
          Clear
        </BorderlessButton>
        <DashboardButton
          type="submit"
          text={loading ? 'Loading...' : 'Add Note'}
          notIcon
        />
      </Flex>
    </StyledTextareaWrapper>
  )
}

export default AddNote

export const BorderlessButton = styled.button`
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  color: #858aa0;
  background: none;
  border: none;
  outline: none;

  &:hover {
    color: #1a1a1a;
  }
`

const StyledTextareaWrapper = styled.form`
  width: 100%;
  position: relative;

  label {
    font-size: 0.75rem;
    font-weight: 300;
    color: #1a1a1a;
    margin-bottom: 0.25rem;
    display: block;
  }

  textarea {
    width: 100%;
    height: 4.375rem;
    padding: 0.375rem;
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1.5;
    border: 1px solid #dfdfe9;
    outline: none;
    transition: border 0.2s ease-in;

    &:hover {
      border: 1px solid #1a1a1a;
    }
  }

  .loader-wrapper {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
`
