import React from 'react'

import styled from 'styled-components'

import { P } from '../../../../style/Typography.style'

import preview from '../../../../assets/image/preview.jpg'
import Flex from '../../../layouts/Flex'
import DashboardInput from '../../DashboardInput'
import { SingleDropdownSelect } from '../../TalentPool/DropdownSelect'

const StepOne = () => {
  return (
    <>
      <P>Image Preview</P>
      <StyledImg src={preview} alt="preview" />

      <Flex
        margin="1rem 0 0 0"
        gap="1rem"
        width="100%"
        mobileDirection="column"
      >
        <DashboardInput
          label="Job Title"
          placeholder=""
          value="HR Manager"
          name="jobTitle"
          type="text"
          height="2.5rem"
        />

        <SingleDropdownSelect value="Full Time" title="Job Type">
          <option>Full Time</option>
        </SingleDropdownSelect>
      </Flex>

      <DashboardInput
        label="List Item 1"
        placeholder=""
        value="Bachelor’s degree in Human Resources Management or equivalent."
        name="listOne"
        type="text"
        height="2.5rem"
        topRightContent={<P size="0.65rem">60/100</P>}
      />

      <DashboardInput
        label="List Item 2"
        placeholder=""
        value="3 years experience in Human Resources or related fields for Australian clients and has an"
        name="listTwo"
        type="text"
        height="2.5rem"
        topRightContent={<P size="0.65rem">40/100</P>}
      />

      <DashboardInput
        label="List Item 2"
        placeholder=""
        value="3 years experience in Human Resources or related fields for Australian clients and has an"
        name="listTwo"
        type="text"
        height="2.5rem"
        topRightContent={<P size="0.65rem">40/100</P>}
      />
    </>
  )
}

export default StepOne

const StyledImg = styled.img`
  width: 100%;
  max-width: 450px;
  height: auto;
  margin: 0.75rem 0;
`
