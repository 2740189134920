import React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

import TalentCardPopList from '../../../../components/dashboard/TalentPool/TalentCardPopList'
import TalentCardPopout from '../../../../components/dashboard/TalentPool/TalentCardPopout'

const ActionsMenu = ({ value, setShowMenu, onEditClient, onChangeClientStatus }) => {
  const statusName = value?.status_name
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowMenu(false)
      }}
    >
      <TalentCardPopout maxW="199px" width="199px" top="0" left="76%">
        <TalentCardPopList
          onClick={(e) => {
            e.stopPropagation()
            onEditClient()
          }}
          text="Edit Client Profile"
        />
        <TalentCardPopList
          onClick={(e) => {
            e.stopPropagation()
            onChangeClientStatus()
          }}
          text={`${statusName === 'Active' ? 'Deactivate' : 'Activate'} Client`}
        />
      </TalentCardPopout>
    </OutsideClickHandler>
  )
}

export default ActionsMenu
