import React, { useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import {
  useTable,
  usePagination,
  useResizeColumns,
  useSortBy,
} from 'react-table'

import styled from 'styled-components'
import { P } from '../style/Typography.style'
import Flex from './layouts/Flex'
import PageButtons from './PageButtons'

const Table = ({
  columns,
  data,
  loading,
  totalPage,
  currentPage,
  setCurrentPage,
  rowCount,
  pageSize: mypageSize,
  setNewPageSize,
  fetchData,
  noData,
  height,
  padding,
  onClick,
  url,
  initialState = {},
}) => {
  const initState = {
    pageSize: mypageSize,
    ...initialState,
  }
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        ...initState,
      },
      pageIndex: currentPage,
      pageSize: mypageSize,
      manualPagination: true,
      disableSortRemove: true,
      defaultCanSort: true,
    },
    useSortBy,
    useResizeColumns,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageSize },
  } = tableInstance

  const [update, setUpdate] = useState(false)
  const startIndex = (currentPage - 1) * pageSize + 1
  const endIndex = Math.min(currentPage * pageSize, rowCount)

  useEffect(() => {
    fetchData(url)
  }, [update])

  const handleChangePageSize = (newPageSize) => {
    setNewPageSize(newPageSize)
    setPageSize(newPageSize)
    setUpdate(!update)
  }

  const handleByIndex = (index) => {
    gotoPage(index)
    setCurrentPage(index)
    setUpdate(!update)
  }

  return (
    // apply the table props
    <TableWrapper height={height}>
      {loading ? (
        <Flex
          height="calc(100vh - 15rem);"
          bg="#fff"
          justify="center"
          items="center"
        >
          <ClipLoader color="#1a1a1a" size="2rem" />
        </Flex>
      ) : (
        <>
          {data.length > 0 ? (
            <>
              <StyledTable padding={padding} {...getTableProps()}>
                <thead>
                  {
                    // Loop over the header rows
                    headerGroups.map((headerGroup, index) => (
                      // Apply the header row props
                      <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                        {
                          // Loop over the headers in each row
                          headerGroup.headers.map((column) => (
                            // Apply the header cell props
                            // eslint-disable-next-line react/jsx-key
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {
                                // Render the header
                                column.render('Header')
                              }

                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? ' ▼'
                                    : ' ▲'
                                  : column.canSort
                                  ? ' ▲'
                                  : ''}
                              </span>
                            </th>
                          ))
                        }
                      </tr>
                    ))
                  }
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                  {
                    // Loop over the table rows
                    page.map((row, index) => {
                      // Prepare the row for display
                      prepareRow(row)
                      return (
                        // Apply the row props
                        <tr
                          key={index}
                          onClick={() =>
                            onClick(row?.original?.actions || row?.original)
                          }
                          {...row.getRowProps()}
                        >
                          {
                            // Loop over the rows cells
                            row.cells.map((cell, index) => {
                              // Apply the cell props
                              return (
                                <td key={index} {...cell.getCellProps()}>
                                  {
                                    // Render the cell contents
                                    cell.render('Cell')
                                  }
                                </td>
                              )
                            })
                          }
                        </tr>
                      )
                    })
                  }
                </tbody>
              </StyledTable>
              <Pagination>
                <PageButtons
                  totalPages={totalPage}
                  currentPage={currentPage}
                  onPageClick={handleByIndex}
                  maxWidth={300}
                />
                <select
                  value={pageSize}
                  onChange={(e) => {
                    handleChangePageSize(Number(e.target.value))
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      View {pageSize}
                    </option>
                  ))}
                </select>
                <span>{`${startIndex} - ${endIndex} of ${rowCount}`}</span>
              </Pagination>
            </>
          ) : (
            <Flex
              height="calc(100vh - 15rem)"
              bg="#fff"
              justify="center"
              items="center"
            >
              <P>{noData || 'No Data to Display'}</P>
            </Flex>
          )}
        </>
      )}
    </TableWrapper>
  )
}

export default Table

const StyledTable = styled.table`
  width: 100%;
  background: #fff;
  padding: 0.5rem 0;
  padding-top: 0;
  border-collapse: separate;
  border-spacing: 0;
  text-align: center;
  table-layout: fixed;

  th,
  tr,
  td {
    border: 1px solid #eeeef6;
  }

  tr:nth-child(even) {
    background: #f3f6ff;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;

    th {
      border-bottom: 1px solid #eeeef6;
      max-width: 100px;
      font-size: 0.75rem;
      padding: 0.75rem 0;
      padding-top: 0.75rem;
      font-weight: 500;
    }
  }

  tbody {
    tr {
      word-break: break-all;

      &:hover {
        background: #e8e8e8;
        transition: all 0.3s ease;
        cursor: pointer;
      }
    }

    td {
      max-width: 210px;
      padding: 0.5rem;
      font-size: 0.75rem;
      font-weight: 300;
    }

    tr:last-child td {
      border-bottom: 0;
    }

    tr:first-child td {
      padding: 1.25rem 0.5rem;
    }
  }
`
const TableWrapper = styled.div`
  height: ${({ height }) => height || 'calc(100vh - 15rem)'};
  overflow-y: auto;
  position: relative;
`
const Pagination = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;

  background: #f9f9fc;
  display: flex;
  align-items: center;

  select {
    height: 2rem;
    background: #ffffff;
    border: 0.5px solid #aeb3c9;
    margin: 0 0.75rem;
    font-size: 0.75rem;
    padding: 0 0.5rem;
    outline: none;
  }

  span {
    font-size: 0.75rem;
    font-weight: 300;
    color: #0e1324;
  }
`
