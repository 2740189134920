import React from 'react'
import styled from 'styled-components'

const TalentCardPopout = ({
  top,
  right,
  left,
  children,
  maxW,
  width,
  overflowY,
  maxH,
}) => {
  return (
    <StyledPopout
      maxW={maxW}
      maxH={maxH}
      width={width}
      top={top}
      right={right}
      left={left}
      overflowY={overflowY}
    >
      {children}
    </StyledPopout>
  )
}

export default TalentCardPopout

const StyledPopout = styled.div`
  width: ${({ width }) => width || '100%'};
  max-width: ${({ maxW }) => maxW || '199px'};
  max-height: 400px;
  overflow-y: scroll;
  max-height: ${({ maxH }) => maxH || 'auto'};
  padding: 0.3125rem;
  background: #fff;
  border: 1px solid #eeeef6;
  box-shadow: 3px 3px 7px rgba(178, 178, 203, 0.2);
  position: absolute;
  top: ${({ top }) => top || 0};
  ${({ right }) => right && `right: ${right}`};
  ${({ left }) => left && `left: ${left}`};
  z-index: 100;

  ${({ overflowY }) =>
    overflowY && `overflow-y: ${overflowY}; overflow-x: hidden;`};
`