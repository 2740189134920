import React from 'react'
import styled from 'styled-components'

const EmptyDetails = () => {
  return <StyledEmptyDetails>Cv is currently empty</StyledEmptyDetails>
}

export default EmptyDetails

const StyledEmptyDetails = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 400;
  color: #1a1a1a;
`
