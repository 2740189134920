import React from 'react'

function EmptySearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="201"
      height="223"
      fill="none"
      viewBox="0 0 201 223"
    >
      <circle
        cx="82.545"
        cy="125.222"
        r="68.983"
        fill="#F9F9FC"
        stroke="#0E1324"
      ></circle>
      <circle
        cx="83.103"
        cy="124.807"
        r="58.779"
        fill="#F9F9FC"
        stroke="#0E1324"
        transform="rotate(-1.017 83.103 124.807)"
      ></circle>
      <rect
        width="12.169"
        height="64.485"
        x="171.924"
        y="213.654"
        fill="#F9F9FC"
        stroke="#0E1324"
        rx="1.39"
        transform="rotate(135 171.924 213.654)"
      ></rect>
      <path
        fill="#F9F9FC"
        stroke="#0E1324"
        d="M110.699 97.447l-.155.361.316.236 49.679 37.12-56.986-24.464-.362-.155-.236.315-37.12 49.679L90.3 103.553l.155-.362-.315-.235-49.68-37.12L97.447 90.3l.362.155.235-.315 37.12-49.68-24.464 56.986z"
      ></path>
    </svg>
  )
}

export default EmptySearchIcon
