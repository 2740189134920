import React, { useEffect, useState, useRef } from 'react'
import ReactDOM from 'react-dom'

import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'
import CreatableSelect from 'react-select/creatable'
import { connect, useSelector } from 'react-redux'
import { IoCloseOutline } from 'react-icons/io5'

import UploadInput from '../controls/UploadInput'
import Menu from '../controls/Menu'
import DashboardInput from '../DashboardInput'
import { ModalWrapper } from './IconModal'

import { H6, P } from '../../../style/Typography.style'
import Flex from '../../layouts/Flex'
import RangeSlider from './RangeSlider'

import { filterTalents } from '../../../store/actions/talentActions'

import axiosInstance from '../../../services/config/AxiosIntance'
import axios from 'axios'

import { baseUrl } from '../../../constant/constant'

import { meta } from '../../../constant/meta'

const SaveApplicant = ({ onClose, filterTalents, isEdit = false }) => {
  const { addToast } = useToasts()
  const [state, setState] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
  })

  const [loading, setLoading] = useState(false)
  const [professionalHeadlineOptions, setProfessionalHeadlineOptions] =
    useState([])
  const [professionalHeadline, setProfessionalHeadline] = useState([])
  const [skillOptions, setSkillOptions] = useState([])
  const [selectedSkills, setSelectedSkills] = useState([])

  const { talent } = useSelector((state) => state.talents)

  const fileUploadRef = useRef(null)

  useEffect(() => {
    const fetchProffesionalHeadlines = async () => {
      await axiosInstance
        .get('/professional-headlines?withSkills=true')
        .then((res) => {
          const data = res.data.resp
          data.map((item) =>
            setProfessionalHeadlineOptions((prev) => [
              ...prev,
              {
                value: item.id,
                label: item.professional_headline,
                id: item.id,
              },
            ])
          )
        })
    }
    fetchProffesionalHeadlines()
  }, [])

  useEffect(() => {
    if (isEdit) {
      setState({
        user_id: talent.user_id.toString(),
        first_name: talent.first_name,
        middle_name: talent.middle_name,
        last_name: talent.last_name,
        email: talent.email,
      })

      const parsedHeadlines = JSON.parse(talent.professional_headline)

      setProfessionalHeadline(parsedHeadlines)

      setCvDetails({
        url: talent?.cv_url,
        slicedUrl: talent?.cv_url?.slice(0, -4) + '.jpg',
        id: talent?.cv_public_id,
        totalPageNumber: '',
      })
    }
  }, [])

  const [cvDetails, setCvDetails] = useState({
    url: '',
    slicedUrl: '',
    id: '',
    totalPageNumber: '',
  })

  function deletePreviousCv() {
    const cvDetails = JSON.parse(localStorage.getItem('talentCvDetails'))
    if (cvDetails) {
      axiosInstance
        .post('/delete-cv', {
          cv_public_id: cvDetails.id,
        })
        .then((res) => {})
        .catch((err) => {
          addToast.error(
            err?.response?.message ||
              err?.response?.data?.message ||
              'Internal Server Error',
            {
              appearance: 'error',
              autoDismiss: true,
            }
          )
        })
    } else {
      return null
    }
  }

  function handleOnclose() {
    deletePreviousCv()
    onClose()
  }

  function handleOnChangeFile() {
    fileUploadRef.current.click()
  }

  function checkDuplicates(values) {
    const uniqueIds = []

    const unique = values.filter((element) => {
      const isDuplicate = uniqueIds.includes(element.label)

      if (!isDuplicate) {
        uniqueIds.push(element.label)

        return true
      }

      return false
    })

    setProfessionalHeadline(unique)
  }

  async function handleOnSubmit(e) {
    e.preventDefault()

    const updatedProfessionalHeadline = professionalHeadline.map(
      (headline) => ({
        ...headline,
        item: headline.label,
      })
    )

    if (state.first_name === '')
      return addToast('First Name is required', {
        appearance: 'error',
        autoDismiss: true,
      })
    if (state.last_name === '')
      return addToast('Last Name is required', {
        appearance: 'error',
        autoDismiss: true,
      })

    if (state.email === '')
      return addToast('Email is required', {
        appearance: 'error',
        autoDismiss: true,
      })

    if (professionalHeadline.length === 0)
      return addToast('Professional Headline is required', {
        appearance: 'error',
        autoDismiss: true,
      })

    if (selectedSkills.length === 0)
      return addToast('Technical Skill is required', {
        appearance: 'error',
        autoDismiss: true,
      })

    if (cvDetails.id === '')
      return addToast('Please upload your cv.', {
        appearance: 'error',
        autoDismiss: true,
      })

    setLoading(true)

    await axiosInstance
      .post('/save-applicant', {
        ...state,
        cv_url: cvDetails.url,
        cv_public_id: cvDetails.id,
        professional_headline: JSON.stringify(updatedProfessionalHeadline),
        meta: JSON.stringify({
          ...meta,
          fullSkills: [selectedSkills, []],
        }),
        is_edit: isEdit,
      })
      .then((res) => {
        addToast(res.data.message, {
          appearance: 'success',
          autoDismiss: true,
        })
        setLoading(false)
        onClose()
        localStorage.removeItem('talentCvDetails')
        filterTalents()
      })
      .catch((err) => {
        setLoading(false)
        addToast(
          err.response.message ||
            err.response.data.message ||
            'Internal Server Error',
          {
            appearance: 'error',
            autoDismiss: true,
          }
        )
      })
  }

  const handleSkillChangeOptions = (selectedOptions) => {
    const newTechSkills = selectedOptions
      .map((skill) => ({
        value: skill.skill,
        label: skill.skill,
        skill: skill.skill,
        professional_headline_id: skill.professional_headline_id,
        experience: skill?.experience,
        id: skill.id,
      }))
      .filter((skill) => skill.label)
    setSelectedSkills(newTechSkills)
  }

  const getTechnicalSkills = (ids) => {
    axios
      .get(`${baseUrl}/technical-skills?professionalHeadline_id=[${ids}]`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('recruiterToken')}`,
        },
      })
      .then((res) => {
        const techSkills = res.data.resp

        let newTechSkills = techSkills.map((skill) => ({
          value: skill.skills,
          label: skill.skills,
          skill: skill.skills,
          professional_headline_id: skill.professional_headline_id,
          experience: skill?.experience || 1,
          id: skill.id,
        }))

        const preSelectedSkills = selectedSkills.filter((x) =>
          newTechSkills.some((y) => y.id === x.id)
        )
        setSelectedSkills(preSelectedSkills)

        newTechSkills = newTechSkills.filter(
          (x) => !selectedSkills.some((y) => y.id === x.id)
        )
        setSkillOptions(newTechSkills)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (professionalHeadline.length > 0) {
      const ids = professionalHeadline.map((x) => x.id)
      getTechnicalSkills(ids)
    } else {
      setSelectedSkills([])
    }
  }, [professionalHeadline])

  return ReactDOM.createPortal(
    <ModalWrapper>
      <div className="content">
        <div className="header">
          <IoCloseOutline className="close" onClick={handleOnclose} />
          <H6 size="1.5rem" mb="0">
            Add Applicant
          </H6>
        </div>

        <div className="content-body">
          <Flex
            gap={cvDetails.id ? '1rem' : '0'}
            direction={cvDetails.id ? 'row' : 'column'}
          >
            <Flex
              margin="2rem 0 0 0"
              width="100%"
              direction="column"
              gap="0.5rem"
            >
              <P>First Name</P>
              <DashboardInput
                value={state.first_name}
                handleChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    first_name: e.target.value,
                  }))
                }
                height="2.5rem"
              />
            </Flex>
            <Flex
              margin={cvDetails.id ? '2rem 0 0 0' : '0'}
              width="100%"
              direction="column"
              gap="0.5rem"
            >
              <P>Middle Name</P>
              <DashboardInput
                value={state.middle_name}
                handleChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    middle_name: e.target.value,
                  }))
                }
                height="2.5rem"
              />
            </Flex>
            <Flex
              margin={cvDetails.id ? '2rem 0 0 0' : '0'}
              width="100%"
              direction="column"
              gap="0.5rem"
            >
              <P>Last Name</P>
              <DashboardInput
                value={state.last_name}
                handleChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    last_name: e.target.value,
                  }))
                }
                height="2.5rem"
              />
            </Flex>
          </Flex>
          <Flex width="100%" direction="column" gap="0.5rem">
            <P>Email</P>
            <DashboardInput
              value={state.email}
              handleChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }))
              }
              height="2.5rem"
            />
          </Flex>
          {/* Professional headlines */}
          <Flex width="100%" direction="column" gap="0.5rem">
            <P>Professional Headline</P>
            <CreatableSelect
              menuPlacement="top"
              components={{ Menu }}
              className="react-select"
              isMulti
              isValidNewOption={() => false}
              value={professionalHeadline}
              options={professionalHeadlineOptions || []}
              onChange={(value) => {
                if (value.length <= 5) {
                  checkDuplicates(value)
                }
              }}
              onCreateOption={(inputValue) => {
                setProfessionalHeadline((prevState) => {
                  if (
                    prevState.filter(
                      (t) => t.label.toLowerCase() === inputValue.toLowerCase()
                    ).length === 0
                  ) {
                    return [
                      ...prevState,
                      { label: inputValue, value: inputValue },
                    ]
                  }

                  if (prevState.includes(inputValue)) {
                    return [...prevState]
                  }

                  return prevState
                })
              }}
              placeholder="Select Professional Headlines"
            />
          </Flex>
          <Flex width="100%" direction="column" gap="0.5rem">
            <P>Technical Skills</P>
            <CreatableSelect
              isValidNewOption={() => false}
              menuPlacement="top"
              components={{ Menu }}
              className="react-select"
              isMulti
              options={skillOptions}
              value={selectedSkills}
              onChange={handleSkillChangeOptions}
              placeholder="Select skills..."
              isSearchable
            />

            {selectedSkills
              ?.sort(function (a, b) {
                if (a.skill < b.skill) {
                  return -1
                }
                if (a.skill > b.skill) {
                  return 1
                }
                return 0
              })
              .map(({ skill, experience, id }) => (
                <RangeSlider
                  key={id}
                  skill={skill}
                  experience={experience}
                  id={id}
                  setSkills={handleSkillChangeOptions}
                  skills={selectedSkills}
                />
              ))}
          </Flex>
          <Container>
            {/* upload cv */}
            <UploadInput
              cvDetails={cvDetails}
              setCvDetails={setCvDetails}
              deletePreviousCv={deletePreviousCv}
              fileUploadRef={fileUploadRef}
              state={state}
            />
          </Container>
        </div>

        <div className="footer">
          <Flex
            margin="1rem 0 0 0"
            items="center"
            justify={cvDetails.id ? 'space-between' : 'flex-end'}
            gap="0.6rem"
          >
            <Button onClick={handleOnclose}>Cancel</Button>
            <Flex gap="1rem">
              {cvDetails.id && (
                <Button
                  onClick={handleOnChangeFile}
                  className="outline"
                  as="label"
                  htmlFor="upload"
                >
                  Change File
                </Button>
              )}
              <Button onClick={handleOnSubmit} className="filled">
                {loading ? 'Loading...' : 'Save Applicant'}
              </Button>
            </Flex>
          </Flex>
        </div>
      </div>
    </ModalWrapper>,
    document.getElementById('modal')
  )
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, { filterTalents })(SaveApplicant)

const Button = styled.button`
  padding: 0.875rem 1.25rem;
  border-radius: 100px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: #858aa0;
  font-size: 0.75rem;

  &.filled {
    background: #1a1a1a;
    color: #fff;
  }

  &.outline {
    border: 1px solid #1a1a1a;
    color: #1a1a1a;

    &:hover {
      border: 1px solid #1a1a1a;
      background-color: #1a1a1a;
      color: #fff;
    }
  }
`

const Container = styled.div`
  margin-top: 1rem;
  width: 100%;
`
