import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { BsThreeDots } from 'react-icons/bs'
import ActionMenu from './ActionMenu'
import { selectApplicant } from '../../../../store/actions/listsActions'
import { getTalent } from '../../../../store/actions/talentActions'

const TableAction = ({ value }) => {
  const dispatch = useDispatch()
  const [showMenu, setShowMenu] = useState(false)

  const handleClick = (e) => {
    e.stopPropagation()
    setShowMenu((prevState) => !prevState)
    dispatch(selectApplicant(value))
    dispatch(getTalent(value.applicantId))
  }

  return (
    <StyledTableAction>
      <div
        onClick={handleClick}
        className={showMenu ? 'icon-container active' : 'icon-container'}
      >
        <BsThreeDots className="icon" />
      </div>
      {showMenu && <ActionMenu setShowMenu={setShowMenu} value={value} />}
    </StyledTableAction>
  )
}

export default React.memo(TableAction)

const StyledTableAction = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .icon-container {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover,
    &.active {
      background: #eeeef6;
    }

    .icon {
      font-size: 1rem;
      color: #1a1a1a;
    }
  }
`
