import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import styled from 'styled-components'
import Sidebar from '../components/dashboard/Sidebar'
import Header from '../components/Header'
import useFetch from '../hooks/useFetch'
import { USER_LOADED } from '../store/actions/types'

const DashboardLayout = ({ children }) => {
  const dispatch = useDispatch()
  const id = localStorage.getItem('id')
  const { data } = useFetch(`/user/${id}`)
  useEffect(() => {
    if (data) {
      dispatch({ type: USER_LOADED, payload: data.data[0] })
    }
  }, [data])

  return (
    <StyledDashboard>
      <Header />
      <div className="contentWrapper">
        <Sidebar />
        <div className="content">{children}</div>
      </div>
    </StyledDashboard>
  )
}

export default DashboardLayout

const StyledDashboard = styled.div`
  width: 100%;
  max-width: 105vw;
  height: 100vh;
  background: #f9f9fc;
  overflow-y: hidden;

  .contentWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: auto;

    .content {
      width: 100%;
      margin-right: 2rem;

      @media (max-width: 768px) {
        margin-left: 4rem;
      }
    }
  }
`
