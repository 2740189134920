export const jobs = {
  GET_JOB_LISTINGS: 'GET_JOB_LISTINGS',
  IS_LOADING: 'IS_LOADING',
  SET_MODAL: 'SET_MODAL',
  CLOSE_JOB: 'CLOSE_JOB',
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  SELECT_JOB: 'SELECT_JOB',
  SET_JOB_CHANGED: 'SET_JOB_CHANGED',
  SET_JOB_UPDATED: 'SET_JOB_UPDATED',
}
