import React from 'react'

import styled from 'styled-components'

import { FiPlus } from 'react-icons/fi'

const AddSectionButton = ({ text, active, onClick }) => {
  return (
    <AddSectionButtonStyle
      disabled={active}
      onClick={onClick}
      className={active ? 'active' : ''}
    >
      <div className="iconWrapper">
        <FiPlus className="icon" />
      </div>
      {text}
    </AddSectionButtonStyle>
  )
}

export default AddSectionButton

const AddSectionButtonStyle = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  font-size: 0.7rem;
  font-weight: 300;
  color: #0e1324;
  border: none;
  outline: none;
  background: none;
  text-align: left;

  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.15rem;
    height: 1.15rem;
    border-radius: 100%;
    border: 1px solid #0e1324;

    .icon {
      font-size: 0.75rem;
      color: #0e1324;
    }
  }

  &:hover,
  &:focus,
  &.active {
    .iconWrapper {
      border-color: #0e1324;
      background: #0e1324;

      .icon {
        color: #fff;
      }
    }
  }
`
