import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleDropdownSelect } from '../TalentPool/DropdownSelect'

import { SET_EDUCATION } from '../../../store/types/jobPosting'

const EducationalLevel = ({ disabled }) => {
  const { education } = useSelector((state) => state.jobPosting)
  const dispatch = useDispatch()
  return (
    <SingleDropdownSelect
      disabled={disabled}
      onChange={(e) =>
        dispatch({ type: SET_EDUCATION, payload: e.target.value })
      }
      title="Level of Education"
      selectedOption={education}
    >
      <option>Choose Option</option>
      <option>No Preference</option>
      <option>Undergraduate</option>
      <option>Bachelors Degree</option>
      <option>Masters Degree</option>
    </SingleDropdownSelect>
  )
}

export default EducationalLevel
