import { useDispatch, useSelector } from 'react-redux'
import DashboardInput from '../../../DashboardInput'

import {
  setJobTitle,
  setFilterByYears,
  setCompanyName,
} from '../../../../../store/actions/talentActions'
import { SingleDropdownSelect } from '../../DropdownSelect'

const WorkExperienceFilter = () => {
  const dispatch = useDispatch()

  const { jobTitle, filterByYears, professionalHeadlines, companyName } =
    useSelector((state) => state.talents)

  const options = [
    {
      label: 'Less than 1 year',
      name: '[0, 1]',
    },
    {
      label: '1-2 years',
      name: '[1, 2]',
    },
    {
      label: '3-5 years',
      name: '[3, 5]',
    },
    {
      label: '6-10 years',
      name: '[6, 10]',
    },
    {
      label: 'More than 10 years',
      name: '[11, 99]',
    },
  ]

  return (
    <>
      <SingleDropdownSelect
        name="jobTitle"
        title="Industry Experience"
        value={jobTitle}
        onChange={(e) => dispatch(setJobTitle(e.target.value))}
      >
        <option>Choose Option</option>
        {professionalHeadlines.map((item) => (
          <option key={item.id} value={item.id.toString()}>
            {item.professional_headline}
          </option>
        ))}
      </SingleDropdownSelect>

      <DashboardInput
        name="companyName"
        label="Company Name"
        value={companyName}
        handleChange={(e) => dispatch(setCompanyName(e.target.value))}
        type="text"
      />

      <SingleDropdownSelect
        name="levelOfEducation"
        title="Level of Experience"
        selectedOption={filterByYears}
        onChange={(e) => dispatch(setFilterByYears(e.target.value))}
      >
        <option>Choose Option</option>
        {options.map(({ label, name }, index) => (
          <option key={index} value={name}>
            {label}
          </option>
        ))}
      </SingleDropdownSelect>
    </>
  )
}

export default WorkExperienceFilter
