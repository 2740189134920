import React, { useState, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import Flex from '../../../../components/layouts/Flex'
import { SingleDropdownSelect } from '../../../../components/dashboard/TalentPool/DropdownSelect'

import { setModal } from '../../../../store/actions/listsActions'
import { setInterviews } from '../../../../store/actions/recruitersActions'

import useMutation from '../../../../hooks/useMutation'
import useFetch from '../../../../hooks/useFetch'

const ChangeVacancy = () => {
  const [jobId, setJobId] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { selectedInterview, interviews } = useSelector(
    (state) => state.requisitions
  )

  const { data: jobsData } = useFetch(
    `/jobs/dropdown?pooling_applicant=${selectedInterview.applicant_id.toString()}`
  )

  const sortedData = jobsData?.data.sort((a, b) => {
    if (a.pooling_id === null) return 1
    if (b.pooling_id === null) return -1
    return a.pooling_id - b.pooling_id
  })

  const { data, loading, handleRequest, success, error } = useMutation()

  const handleSelectJob = (e) => {
    const selectedIndex = e.target.selectedIndex
    const selectedOption = e.target.options[selectedIndex]
    const label = selectedOption.textContent
    setJobTitle(label)
    setJobId(e.target.value)
  }

  const handleSubmit = () => {
    if (!jobId) {
      return addToast('Job field is required.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    handleRequest(
      `/v2/interviews/${selectedInterview.id}/change-vacancy`,
      {
        job_id: jobId.toString(),
        role: jobTitle.toString(),
        applicant_id: selectedInterview.applicant_id.toString(),
      },
      'PUT'
    )
  }

  // check if request was successful.
  useEffect(() => {
    if (data) {
      const filteredInterview = interviews.filter(
        (interview) => interview.id !== selectedInterview.id
      )

      const updatedInterview = selectedInterview

      updatedInterview.job_id = jobId
      updatedInterview.role = jobTitle

      dispatch(setInterviews([updatedInterview, ...filteredInterview]))

      addToast('Job vacancy updated successfully!', {
        appearance: 'success',
        autoDismiss: true,
      })

      dispatch(setModal(''))
    }
  }, [success])

  useEffect(() => {
    if (error) {
      addToast(
        error?.response?.data?.message ||
          error?.response?.message ||
          'Something went wrong',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    }
  }, [error])

  return (
    <>
      <MainModal
        title={`Change Vacancy`}
        buttonText="Submit"
        loading={loading}
        onClick={handleSubmit}
      >
        <Flex direction="column" gap="0.4rem" margin="1rem 0 0 0">
          <SingleDropdownSelect
            className="with__pooling"
            onChange={handleSelectJob}
            title="Select Vacancy"
          >
            <option>Choose an option</option>
            {sortedData?.map((job) => {
              if (job.status === 'active') {
                return (
                  <option
                    key={job?.id}
                    value={job?.id}
                    data-pooling={job?.pooling_id}
                  >
                    {job?.title}
                  </option>
                )
              } else {
                return null
              }
            })}
          </SingleDropdownSelect>
        </Flex>
      </MainModal>
    </>
  )
}

export default ChangeVacancy
