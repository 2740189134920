/* eslint-disable camelcase */
import React, { useEffect, useState, useMemo  } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'

import TagInput from '../../../../../components/dashboard/controls/TagInput'
import DashboardInputSlim from '../../../../../components/dashboard/DashboardInput'
import { SingleDropdownSelect, SingleDropdownSlimSelect } from '../../../../../components/dashboard/TalentPool/DropdownSelect'
import Flex from '../../../../../components/layouts/Flex'
import ModalFooter from './ModalFooter'

import { currencyList } from '../../../../../constant/currencies' 
import { setJobUpdated } from '../../../../../store/actions/jobListingActions'
import { jobs } from '../../../../../store/types/jobListing'

import axiosInstance from '../../../../../services/config/AxiosIntance'
import {
  SET_BENEFITS,
  SET_CURRENCY,
  SET_MAX_SALARY,
  SET_MIN_SALARY,
  SET_SALARY_BASIS,
} from '../../../../../store/types/jobPosting'

const PaymentUpdate = ({
  id,
  min_salary: minSalary,
  max_salary: maxSalary,
  salary_basis: salaryBasis,
  benefits: initial_benefits,
  setOpenModal,
  status,
  currencies: initial_currencies
}) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [allCurrencies, setCurrencies] = useState([])

  const { min_salary, max_salary, benefits, salary_basis, currencies } = useSelector(
    (state) => state.jobPosting
  )

  const [initialState, setIntialState] = useState({
    salary_basis: '',
    min_salary: '',
    max_salary: '',
    benefits: [],
    currencies: ''
  })

  useEffect(() => {
    setIntialState(() => ({
      salary_basis: salaryBasis,
      min_salary: minSalary,
      max_salary: maxSalary,
      benefits: initial_benefits,
      currencies: initial_currencies
    }))

    dispatch({
      type: SET_BENEFITS,
      payload: initial_benefits ? JSON.parse(initial_benefits) : [],
    })
    dispatch({ type: SET_SALARY_BASIS, payload: salaryBasis })
    dispatch({ type: SET_MIN_SALARY, payload: minSalary })
    dispatch({ type: SET_MAX_SALARY, payload: maxSalary })
    dispatch({ type: SET_CURRENCY, payload: initial_currencies })
  }, [initial_benefits, salaryBasis, minSalary, maxSalary, initial_currencies])

  const handleTags = (tags) => {
    dispatch({ type: SET_BENEFITS, payload: tags })
  }

  const handleSalaryUpdate = async () => {
    if (min_salary === '')
      return toast.error('Please enter minimum salary', { type: 'error' })
    if (max_salary === '')
      return toast.error('Please enter maximum salary', { type: 'error' })
    if (Number(min_salary) > Number(max_salary))
      return toast.error('Minimum salary should be less than Maximum Salary', {
        type: 'error',
      })
    if (salary_basis === '')
      return toast.error('Please select salary basis', { type: 'error' })
    if (benefits.length === 0)
      return toast.error('Please add at least one benefit', { type: 'error' })

    setLoading(true)
    await axiosInstance
      .put(`/${id}/job`, {
        min_salary,
        max_salary,
        salary_basis,
        benefits: JSON.stringify(benefits),
        currencies
      })
      .then((res) => {
        setLoading(false)
        toast(res?.data?.message || 'Job Updated Successfully', {
          type: 'success',
        })
        dispatch({
          type: jobs.SET_JOB_CHANGED,
        })
        dispatch(setJobUpdated(true))
      })
      .catch((err) => {
        setLoading(false)
        toast(err.response.data.message || 'something went wrong.', {
          type: 'error',
        })
      })
  }

  useMemo(() => {
    Object.keys(currencyList[0])?.forEach((curr) => {
      setCurrencies((allCurrencies) => [
        ...allCurrencies,
        { value: curr, label: curr },
      ])
    })
    return allCurrencies
  }, [currencyList])

  function onClose() {
    dispatch({ type: SET_BENEFITS, payload: initialState.benefits })
    dispatch({ type: SET_SALARY_BASIS, payload: initialState.salary_basis })
    dispatch({ type: SET_MIN_SALARY, payload: initialState.min_salary })
    dispatch({ type: SET_MAX_SALARY, payload: initialState.max_salary })
    dispatch({ type: SET_CURRENCY, payload: initialState.currencies })
    setOpenModal(false)
  }
  return (
    <div>
      {/* creating three section input for web the stack them over each other on mobile */}
      <Flex width="100%" mobileDirection="column" gap="1rem">
        {/* Salary types */}
        <SingleDropdownSelect
          disabled={status === 'closed'}
          value={salary_basis}
          onChange={(e) =>
            dispatch({ type: SET_SALARY_BASIS, payload: e.target.value })
          }
          maxw="8rem"
          title="Basis"
        >
          <option>Choose an option</option>
          <option value="Per Month">Per Month</option>
          <option value="Per Year">Per Year</option>
        </SingleDropdownSelect>

        {/* creating double type input box */}
        <Flex width="100%" mobileDirection="column">
        <SingleDropdownSlimSelect
            value={currencies}
            onChange={(e) =>
              dispatch({ type: SET_CURRENCY, payload: e.target.value })
            }
            title="Currency"
          >
            {Object.keys(allCurrencies).map((key) => (
              <option key={key} value={allCurrencies[key].value}>
                {allCurrencies[key].value}
              </option>
            ))}
          </SingleDropdownSlimSelect>

          <DashboardInputSlim
            readOnly={status === 'closed'}
            label="Min Salary"
            placeholder=""
            name="minSalary"
            type="number"
            height="2.5rem"
            width="45%"
            inputInnerTag=""
            value={min_salary}
            handleChange={(e) =>
              dispatch({ type: SET_MIN_SALARY, payload: e.target.value })
            }
          />

          <DashboardInputSlim
            readOnly={status === 'closed'}
            label="Max Salary"
            placeholder=""
            name="maxSalary"
            type="number"
            height="2.5rem"
            width="45%"
            inputInnerTag=""
            value={max_salary}
            handleChange={(e) =>
              dispatch({ type: SET_MAX_SALARY, payload: e.target.value })
            }
          />
        </Flex>
      </Flex>

      {/* Multi tag input box */}
      <TagInput
        label="Benefits"
        tags={benefits}
        setTags={handleTags}
        disabled={status === 'closed'}
      />

      <ModalFooter
        readOnly={status === 'closed'}
        handleUpdate={handleSalaryUpdate}
        onClose={onClose}
        loading={loading}
      />

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default PaymentUpdate
