import React from 'react'

import styled from 'styled-components'

import Flex from './layouts/Flex'

const EmptyWrapper = ({ message }) => {
  return (
    <Flex padding="1rem" bg="#fff" justify="center" items="center">
      <StyledMessage>{message}</StyledMessage>
    </Flex>
  )
}

const StyledMessage = styled.h1`
  font-weight: 300;
  font-size: 1.5rem;
`

export default EmptyWrapper
