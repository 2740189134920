import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { useToasts } from 'react-toast-notifications'

import Heading from '../../../components/dashboard/Heading'
import HeaderFilter from './components/HeaderFilter'
import ModuleListWrapper from './components/ModuleListWrapper'
import SaveModule from './components/SaveModule'
import ConfirmDeleteModule from './components/ConfirmDeleteModule'

import {
  fetchUserType,
  fetchList,
  resetNotification,
} from '../../../store/actions/sidebarModuleActions'

import { convertObjectToUrlParams } from '../../../utils/stateHelpers'

const SidebarModules = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { modal, notification, queryParams, userType } = useSelector(
    (state) => state.sidebarModules
  )

  useEffect(() => {
    if (notification.value === 'success') {
      addToast(notification?.message, {
        appearance: 'success',
        autoDismiss: true,
      })
    }
    if (notification.value === 'error') {
      addToast(notification?.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    dispatch(resetNotification())
  }, [notification])

  useEffect(() => {
    dispatch(fetchUserType(id))
  }, [])

  useEffect(() => {
    const currentRole = JSON.parse(localStorage.getItem('user')).user_type
    const allowedAccess = ['admin']
    if (!allowedAccess.includes(currentRole)) {
      window.location.replace('/')
    }
  }, [])

  useEffect(() => {
    dispatch(fetchList(id, convertObjectToUrlParams(queryParams)))
  }, [queryParams])

  const handleFetchList = (queryParams) => {
    dispatch(fetchList(id, queryParams))
  }

  return (
    <>
      {/* Sidebar Modules heading */}
      <Heading
        title={`Sidebar Modules for ${userType.name}`}
        subTitle={moment().format('MMMM Do, YYYY')}
      />

      {/* Module list filter, it contains the search filter and add module button */}
      <HeaderFilter fetchList={handleFetchList} />

      {/* Contains list of sidebar modules */}
      <ModuleListWrapper fetchModuleList={handleFetchList} />

      {/* Modal section */}
      {(modal === 'createModule' || modal === 'updateModule') && <SaveModule />}
      {modal === 'deleteModule' && <ConfirmDeleteModule />}
    </>
  )
}

export default SidebarModules
