import React from 'react'
import styled from 'styled-components'

const TableTag = ({ status = '', content  }) => {
  const className = status.toLowerCase()
  return <TabStyled className={className}>{content}</TabStyled>
}

export default TableTag

const TabStyled = styled.span`
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.55rem 1rem;
  border-radius: 100px;
  word-break: break-all;

  &.pending {
    background-color: rgba(255, 195, 39, 0.2);
    color: #aa7e0b;
  }

  &.rejected {
    background-color: #fbe4e4;
    color: #e35959;
  }

  &.approved,
  &.reviewed {
    background-color: rgba(92, 202, 149, 0.2);
    color: #176a42;
  }
`
