import axios from 'axios'
import { baseUrl } from '../../constant/constant'

import { returnErrors } from './errorActions'

import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  UPDATE_VERIFICATION_CODE,
  VERIFY_USER,
} from './types'

//  Get User data
export const getUser =
  ({ id }) =>
  (dispatch, getState) => {
    const token = getState().auth.token

    // Headers
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    axios.get(`${baseUrl}/user/${id}`, config).then((res) => {
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      })
    })
  }

// Login User
export const login =
  ({ email, password }) =>
  (dispatch) => {
    // set Loading to true
    dispatch({
      type: USER_LOADING,
    })

    // Headers
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    // Request body
    const body = {
      email,
      password,
      user_type: 'recruiter',
    }

    axios
      .post(`${baseUrl}/user/login/dashboard`, body, config)
      .then((res) => {
        localStorage.setItem('id', res.data.data.user.id)
        localStorage.setItem('user', JSON.stringify(res.data.data.user))
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data.data,
        })
      })
      .catch((err) => {
        dispatch(
          returnErrors(
            err.response.data.message || err.response.data,
            err.response.status,
            'LOGIN_FAIL'
          )
        )

        if (err.response.data.message === 'Update your password') {
          localStorage.setItem('userId', err.response.data.userId)
          localStorage.setItem(
            'verificationCode',
            JSON.stringify(err.response.data.code)
          )
          dispatch({
            type: UPDATE_VERIFICATION_CODE,
            payload: {
              verificationCode: err.response.data.code,
              userId: err.response.data.userId,
            },
          })
        } else {
          dispatch({
            type: LOGIN_FAIL,
          })
        }
      })
  }

export const updatePassword = (newPassword, userId) => (dispatch) => {
  // set Loading to true
  dispatch({
    type: USER_LOADING,
  })

  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  // Request body
  const body = {
    newPassword,
    userId: userId?.toString(),
  }

  axios
    .post(`${baseUrl}/update-password`, body, config)
    .then((res) => {
      dispatch({
        type: VERIFY_USER,
        payload: res.data.message.token,
      })
    })
    .catch((err) => {
      dispatch(
        returnErrors(
          err.response.data.message || err.response.data,
          err.response.status,
          'VERIFICATION_FAILED'
        )
      )

      dispatch({
        type: AUTH_ERROR,
      })
    })
}

// Logout User
export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  })
}
