import React, { useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import TalentCardPopList from '../../../../components/dashboard/TalentPool/TalentCardPopList'
import TalentCardPopout from '../../../../components/dashboard/TalentPool/TalentCardPopout'
import useMutation from '../../../../hooks/useMutation'
import { setModal } from '../../../../store/actions/emailTemplateActions'


const ActionsMenu = ({ setShowMenu }) => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const { data, error, success } = useMutation()

  // check if request was successful.
  useEffect(() => {
    if (data) {

      addToast(data.message, {
        appearance: 'success',
        autoDismiss: true,
      })

      setShowMenu(false)
    }
  }, [success])

  // check if request was unsuccessful.
  useEffect(() => {
    if (error) {
      addToast(
        error.message ||
        error.data.message ||
        error.response.data.message ||
        'something went wrong.',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    }
  }, [error])

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowMenu(false)
      }}
    >
      <TalentCardPopout maxW="199px" width="199px" top="0" left="76%">
        <TalentCardPopList
          onClick={(e) => {
            e.stopPropagation()
            dispatch(setModal('updateEmailTemplate'))
          }}
          text="Edit Email Template"
        />
        <TalentCardPopList
          onClick={(e) => {
            e.stopPropagation()
            dispatch(setModal('sendEmail'))
          }}
          text="Send Email"
        />
      </TalentCardPopout>
    </OutsideClickHandler>
  )
}

export default ActionsMenu
