import React from 'react'

import styled from 'styled-components'
import { P } from '../../../style/Typography.style'

import moment from 'moment'

const WorkExperienceCard = ({
  from,
  to,
  company,
  title,
  location,
  description,
  experienceCurrentStatus,
}) => {
  return (
    <StyledWorkExperienceCard>
      <div className="dateContainer">
        <P
          size=".75rem"
          marginBottom="6px"
          weight="500"
          textTransform="uppercase"
        >
          {moment(from).format('MMM YYYY')} -{' '}
          {experienceCurrentStatus === 'Currently employed'
            ? 'Present'
            : moment(to).format('MMM YYYY')}
        </P>
        <P size=".75rem" marginBotton="6px">
          {company}
        </P>
        <P size=".75rem">{location?.label ? location?.label : location}</P>
      </div>

      <div className="otherContent">
        <P
          size=".75rem"
          marginBottom="6px"
          weight="500"
          textTransform="uppercase"
        >
          {title}
        </P>
        <P size=".75rem" dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </StyledWorkExperienceCard>
  )
}

export default WorkExperienceCard

const StyledWorkExperienceCard = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 1.5rem;

  .dateContainer {
    width: 100%;
    max-width: 8.25rem;
    margin-right: 0.75rem;
  }

  .otherContent {
    width: 100%;
  }
`
