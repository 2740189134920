import React, { useState } from 'react'

import styled from 'styled-components'
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi'

import NavLinks from './NavLinks'
import SidebarProfileCard from './SidebarProfileCard'

// SVG Icons: https://www.svgrepo.com/collection/zest-interface-icons/

const Sidebar = () => {
  const [isActive, setIsActive] = useState(false)
  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type

  return (
    <StyledSidebar isActive={isActive}>
      <div onClick={() => setIsActive(!isActive)} className="navigator">
        {isActive ? (
          <FiChevronLeft className="icon" />
        ) : (
          <FiChevronRight className="icon" />
        )}
      </div>
      <SidebarProfileCard
        show={isActive}
        currentUser={JSON.parse(currentUser)}
      />

      {/* Dashboard  */}
      <Scroller isActive={isActive}>
        <NavLinks to="/dashboard" show={isActive}>
          <svg
            className="icon"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M13 5C13 4.44772 13.4477 4 14 4H19C19.5523 4 20 4.44772 20 5V18H22C22.5523 18 23 18.4477 23 19C23 19.5523 22.5523 20 22 20H19H14H10H5H2C1.44772 20 1 19.5523 1 19C1 18.4477 1.44772 18 2 18H4V12C4 11.4477 4.44772 11 5 11H10C10.5523 11 11 11.4477 11 12V18H13V5ZM15 18H18V6H15V18ZM9 18V13H6V18H9Z" />
          </svg>
          {isActive && 'Dashboard'}
        </NavLinks>

        {/* User Lists  */}
        {role === 'admin' && (
          <NavLinks to="/users" show={isActive}>
            <svg
              viewBox="0 0 24 24"
              fill="none"
              className="icon"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7 8C7 6.34315 8.34315 5 10 5C11.6569 5 13 6.34315 13 8C13 9.65685 11.6569 11 10 11C8.34315 11 7 9.65685 7 8ZM13.506 11.5648C14.4282 10.6577 15 9.39565 15 8C15 5.23858 12.7614 3 10 3C7.23858 3 5 5.23858 5 8C5 9.39827 5.57396 10.6625 6.49914 11.5699C3.74942 12.5366 2 14.6259 2 17C2 17.5523 2.44772 18 3 18C3.55228 18 4 17.5523 4 17C4 15.2701 5.93073 13 10 13C11.9542 13 13.4554 13.5451 14.4579 14.2992C14.8992 14.6311 15.5262 14.5425 15.8581 14.1011C16.1901 13.6598 16.1015 13.0328 15.6601 12.7008C15.0431 12.2368 14.3206 11.851 13.506 11.5648ZM19 14.5C19.5523 14.5 20 14.9477 20 15.5V18H22.5C23.0523 18 23.5 18.4477 23.5 19C23.5 19.5523 23.0523 20 22.5 20H20V22.5C20 23.0523 19.5523 23.5 19 23.5C18.4477 23.5 18 23.0523 18 22.5V20H15.5C14.9477 20 14.5 19.5523 14.5 19C14.5 18.4477 14.9477 18 15.5 18H18V15.5C18 14.9477 18.4477 14.5 19 14.5Z"></path>
            </svg>
            {isActive && 'Users'}
          </NavLinks>
        )}

        {/* User Types */}
        <NavLinks to="/user-types" show={isActive}>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            className="icon"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {' '}
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 5C1 3.34315 2.34315 2 4 2H8.43845C9.81505 2 11.015 2.93689 11.3489 4.27239L11.7808 6H13.5H20C21.6569 6 23 7.34315 23 9V10C23 10.5523 22.5523 11 22 11C21.4477 11 21 10.5523 21 10V9C21 8.44772 20.5523 8 20 8H13.5H11.7808H4C3.44772 8 3 8.44772 3 9V10V19C3 19.5523 3.44772 20 4 20H8C8.55228 20 9 20.4477 9 21C9 21.5523 8.55228 22 8 22H4C2.34315 22 1 20.6569 1 19V10V9V5ZM3 6.17071C3.31278 6.06015 3.64936 6 4 6H9.71922L9.40859 4.75746C9.2973 4.3123 8.89732 4 8.43845 4H4C3.44772 4 3 4.44772 3 5V6.17071ZM17 19C14.2951 19 13 20.6758 13 22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22C11 20.1742 12.1429 18.5122 13.9952 17.6404C13.3757 16.936 13 16.0119 13 15C13 12.7909 14.7909 11 17 11C19.2091 11 21 12.7909 21 15C21 16.0119 20.6243 16.936 20.0048 17.6404C21.8571 18.5122 23 20.1742 23 22C23 22.5523 22.5523 23 22 23C21.4477 23 21 22.5523 21 22C21 20.6758 19.7049 19 17 19ZM17 17C18.1046 17 19 16.1046 19 15C19 13.8954 18.1046 13 17 13C15.8954 13 15 13.8954 15 15C15 16.1046 15.8954 17 17 17Z"
                fill="#000000"
              ></path>{' '}
            </g>
          </svg>
          {isActive && 'User Types'}
        </NavLinks>

        {/* Email  */}
        {role === 'admin' && (
          <NavLinks to="/email-templates" show={isActive}>
            <svg
              viewBox="0 0 24 24"
              className="icon"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2.8 4C1.86451 4 1.0468 4.46923 0.544325 5.16792C0.20074 5.64567 0 6.23499 0 6.86667V17.1333C0 18.682 1.21964 20 2.8 20H12.2C12.7523 20 13.2 19.5523 13.2 19C13.2 18.4477 12.7523 18 12.2 18H2.8C2.39214 18 2 17.6466 2 17.1333V7.94766L7.77948 14.3096C8.96986 15.6199 11.0301 15.6199 12.2205 14.3096L18 7.94766V12.1333C18 12.6856 18.4477 13.1333 19 13.1333C19.5523 13.1333 20 12.6856 20 12.1333V6.86667C20 6.235 19.7993 5.64567 19.4557 5.16792C18.9532 4.46923 18.1355 4 17.2 4H2.8ZM9.25983 12.9647L2.9327 6H17.0673L10.7402 12.9647C10.3434 13.4015 9.65662 13.4015 9.25983 12.9647ZM20 15.5C20 14.9477 19.5523 14.5 19 14.5C18.4477 14.5 18 14.9477 18 15.5V18H15.5C14.9477 18 14.5 18.4477 14.5 19C14.5 19.5523 14.9477 20 15.5 20H18V22.5C18 23.0523 18.4477 23.5 19 23.5C19.5523 23.5 20 23.0523 20 22.5V20H22.5C23.0523 20 23.5 19.5523 23.5 19C23.5 18.4477 23.0523 18 22.5 18H20V15.5Z"></path>
            </svg>
            {isActive && 'Email Templates'}
          </NavLinks>
        )}
        {/* Client Lists  */}
        {['admin'].includes(role) && (
          <NavLinks to="/clients" show={isActive}>
            <svg
              viewBox="0 0 24 24"
              className="icon"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.29289 1.29289C9.48043 1.10536 9.73478 1 10 1H18C19.6569 1 21 2.34315 21 4V8C21 8.55228 20.5523 9 20 9C19.4477 9 19 8.55228 19 8V4C19 3.44772 18.5523 3 18 3H11V8C11 8.55228 10.5523 9 10 9H5V20C5 20.5523 5.44772 21 6 21H9C9.55228 21 10 21.4477 10 22C10 22.5523 9.55228 23 9 23H6C4.34315 23 3 21.6569 3 20V8C3 7.73478 3.10536 7.48043 3.29289 7.29289L9.29289 1.29289ZM6.41421 7H9V4.41421L6.41421 7ZM18 19C15.2951 19 14 20.6758 14 22C14 22.5523 13.5523 23 13 23C12.4477 23 12 22.5523 12 22C12 20.1742 13.1429 18.5122 14.9952 17.6404C14.3757 16.936 14 16.0119 14 15C14 12.7909 15.7909 11 18 11C20.2091 11 22 12.7909 22 15C22 16.0119 21.6243 16.936 21.0048 17.6404C22.8571 18.5122 24 20.1742 24 22C24 22.5523 23.5523 23 23 23C22.4477 23 22 22.5523 22 22C22 20.6758 20.7049 19 18 19ZM18 17C19.1046 17 20 16.1046 20 15C20 13.8954 19.1046 13 18 13C16.8954 13 16 13.8954 16 15C16 16.1046 16.8954 17 18 17Z"></path>
            </svg>
            {isActive && 'Clients'}
          </NavLinks>
        )}
        {/* List  */}
        <NavLinks to="/lists" show={isActive}>
          <svg
            className="icon"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M19 3.875H14.82C14.4 2.715 13.3 1.875 12 1.875C10.7 1.875 9.6 2.715 9.18 3.875H5C3.9 3.875 3 4.775 3 5.875V19.875C3 20.975 3.9 21.875 5 21.875H19C20.1 21.875 21 20.975 21 19.875V5.875C21 4.775 20.1 3.875 19 3.875ZM12 3.875C12.55 3.875 13 4.325 13 4.875C13 5.425 12.55 5.875 12 5.875C11.45 5.875 11 5.425 11 4.875C11 4.325 11.45 3.875 12 3.875ZM7 7.875H17V5.875H19V19.875H5V5.875H7V7.875ZM12 17.875V15.875H17V17.875H12ZM12 11.875V9.875H17V11.875H12ZM8 12.875V9.875H7V8.875H9V12.875H8ZM9.25 14.875C9.66 14.875 10 15.215 10 15.625C10 15.825 9.92 16.015 9.79 16.145L8.12 17.875H10V18.875H7V17.955L9 15.875H7V14.875H9.25Z" />
          </svg>
          {isActive && 'Lists'}
        </NavLinks>

        {/* Job Posting */}
        {role !== 'poc' && (
          <NavLinks to="/job-posting" show={isActive}>
            <svg
              className="icon"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 15H10V13H12V15ZM18 15H14V13H18V15ZM8 11H6V9H8V11ZM18 11H10V9H18V11ZM20 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V6C2 5.46957 2.21071 4.96086 2.58579 4.58579C2.96086 4.21071 3.46957 4 4 4H20C20.5304 4 21.0391 4.21071 21.4142 4.58579C21.7893 4.96086 22 5.46957 22 6V18C22 18.5304 21.7893 19.0391 21.4142 19.4142C21.0391 19.7893 20.5304 20 20 20ZM4 6V18H20V6H4Z" />
            </svg>
            {isActive && 'Job Posting'}
          </NavLinks>
        )}

        {/* job listing */}
        {role !== 'poc' && (
          <NavLinks to="/job-listing" show={isActive}>
            <svg
              className="icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17 21L14.25 18L15.41 16.84L17 18.43L20.59 14.84L21.75 16.25L17 21ZM12.8 21H5C3.89 21 3 20.11 3 19V5C3 3.89 3.89 3 5 3H19C20.11 3 21 3.89 21 5V12.8C20.39 12.45 19.72 12.2 19 12.08V5H5V19H12.08C12.2 19.72 12.45 20.39 12.8 21ZM12 17H7V15H12V17ZM14.68 13H7V11H17V12.08C16.15 12.22 15.37 12.54 14.68 13ZM17 9H7V7H17" />
            </svg>
            {isActive && 'Job Listing'}
          </NavLinks>
        )}

        {/* Requisition */}
        {role !== 'poc' && (
          <NavLinks to="/requisitions" show={isActive}>
            <svg
              className="icon"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M22 3H2C0.91 3.04 0.04 3.91 0 5V19C0.04 20.09 0.91 20.96 2 21H22C23.09 20.96 23.96 20.09 24 19V5C23.96 3.91 23.09 3.04 22 3ZM22 19H2V5H22V19ZM14 17V15.75C14 14.09 10.66 13.25 9 13.25C7.34 13.25 4 14.09 4 15.75V17H14ZM9 7C8.33696 7 7.70107 7.26339 7.23223 7.73223C6.76339 8.20107 6.5 8.83696 6.5 9.5C6.5 9.8283 6.56466 10.1534 6.6903 10.4567C6.81594 10.76 7.00009 11.0356 7.23223 11.2678C7.70107 11.7366 8.33696 12 9 12C9.3283 12 9.65339 11.9353 9.95671 11.8097C10.26 11.6841 10.5356 11.4999 10.7678 11.2678C10.9999 11.0356 11.1841 10.76 11.3097 10.4567C11.4353 10.1534 11.5 9.8283 11.5 9.5C11.5 9.1717 11.4353 8.84661 11.3097 8.54329C11.1841 8.23998 10.9999 7.96438 10.7678 7.73223C10.5356 7.50009 10.26 7.31594 9.95671 7.1903C9.65339 7.06466 9.3283 7 9 7ZM14 7V8H20V7H14ZM14 9V10H20V9H14ZM14 11V12H18V11H14Z" />
            </svg>
            {isActive && 'Requisitions'}
          </NavLinks>
        )}

        {/* Talent Pool */}
        {role !== 'poc' && (
          <NavLinks to="/talent-pool" show={isActive}>
            <svg
              className="icon"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 5C11.0717 5 10.1815 5.36875 9.52513 6.02513C8.86875 6.6815 8.5 7.57174 8.5 8.5C8.5 9.42826 8.86875 10.3185 9.52513 10.9749C10.1815 11.6313 11.0717 12 12 12C12.9283 12 13.8185 11.6313 14.4749 10.9749C15.1313 10.3185 15.5 9.42826 15.5 8.5C15.5 7.57174 15.1313 6.6815 14.4749 6.02513C13.8185 5.36875 12.9283 5 12 5ZM12 7C12.3978 7 12.7794 7.15804 13.0607 7.43934C13.342 7.72064 13.5 8.10218 13.5 8.5C13.5 8.89782 13.342 9.27936 13.0607 9.56066C12.7794 9.84196 12.3978 10 12 10C11.6022 10 11.2206 9.84196 10.9393 9.56066C10.658 9.27936 10.5 8.89782 10.5 8.5C10.5 8.10218 10.658 7.72064 10.9393 7.43934C11.2206 7.15804 11.6022 7 12 7ZM5.5 8C4.83696 8 4.20107 8.26339 3.73223 8.73223C3.26339 9.20107 3 9.83696 3 10.5C3 11.44 3.53 12.25 4.29 12.68C4.65 12.88 5.06 13 5.5 13C5.94 13 6.35 12.88 6.71 12.68C7.08 12.47 7.39 12.17 7.62 11.81C6.89 10.86 6.5 9.7 6.5 8.5C6.5 8.41 6.5 8.31 6.5 8.22C6.2 8.08 5.86 8 5.5 8ZM18.5 8C18.14 8 17.8 8.08 17.5 8.22C17.5 8.31 17.5 8.41 17.5 8.5C17.5 9.7 17.11 10.86 16.38 11.81C16.5 12 16.63 12.15 16.78 12.3C16.94 12.45 17.1 12.58 17.29 12.68C17.65 12.88 18.06 13 18.5 13C18.94 13 19.35 12.88 19.71 12.68C20.47 12.25 21 11.44 21 10.5C21 9.83696 20.7366 9.20107 20.2678 8.73223C19.7989 8.26339 19.163 8 18.5 8ZM12 14C9.66 14 5 15.17 5 17.5V19H19V17.5C19 15.17 14.34 14 12 14ZM4.71 14.55C2.78 14.78 0 15.76 0 17.5V19H3V17.07C3 16.06 3.69 15.22 4.71 14.55ZM19.29 14.55C20.31 15.22 21 16.06 21 17.07V19H24V17.5C24 15.76 21.22 14.78 19.29 14.55ZM12 16C13.53 16 15.24 16.5 16.23 17H7.77C8.76 16.5 10.47 16 12 16Z" />
            </svg>
            {isActive && 'Talent Pool'}
          </NavLinks>
        )}
      </Scroller>
    </StyledSidebar>
  )
}

export default Sidebar

const StyledSidebar = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  max-width: ${(p) => (p.isActive ? '13rem' : '5.5rem')};
  height: calc(100vh - 4.3rem);
  background: #fff;
  padding: 1.25rem 2rem;
  margin-right: 2rem;
  border-right: 1px solid #dfdfe9;

  @media (max-width: 768px) {
    position: fixed;
    max-width: ${(p) => (p.isActive ? '13rem' : '3.5rem')};
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1rem;
    margin-right: 1rem;
    z-index: 6;
  }

  .navigator {
    position: absolute;
    top: 1.5rem;
    right: -0.75rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #dfdfe9;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      font-size: 1rem;
      color: #858aa0;
      cursor: pointer;
    }
  }
`

const Scroller = styled.div`
  height: calc(80.5vh - 4.5rem);
  overflow-y: auto;
  width: ${(p) => (p.isActive ? '175px' : '55px')};
`
