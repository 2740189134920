import React, { useCallback, useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'

import Table from '../../../../components/Table'
import TableAction from './TableAction'
import axiosInstance from '../../../../services/config/AxiosIntance'
import {
  addEmailTemplates,
  setSelectedEmailTemplate,
  setPaginationFooter,
  setModal
} from '../../../../store/actions/emailTemplateActions'
import Flex from '../../../../components/layouts/Flex'
import { P } from '../../../../style/Typography.style'


const EmailTemplateList = () => {
  const { addToast } = useToasts()
  const { emailTemplates, dataChanged } = useSelector((state) => state.emailTemplates)

  const dispatch = useDispatch()
  const [totalPage, setTotalPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(true)
  const [rowCount, setRowCount] = useState(0)

  const url = '/email-template/table'

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const result = await axiosInstance.get(url)

      const { data } = result

      setTotalPage(data?.resp?.pagination?.pageCount || 1)
      setCurrentPage(data?.resp?.pagination?.page || 1)
      setPageSize(data?.resp?.pagination?.pageSize || 10)
      setRowCount(data?.resp?.pagination?.rowCount || 0)

      dispatch(addEmailTemplates(data?.emailTemplates || []))

      setLoading(false)
    } catch (err) {
      addToast(err?.response?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
      })
      setLoading(false)
    }
  }, [])


  useEffect(() => {
    fetchData(url)
  }, [dataChanged])


  const columns = React.useMemo(
    () => [
      {
        Header: 'Action',
        accessor: 'actions',
        Cell: ({ value }) => <TableAction value={value} />,
      },
      {
        Header: 'Template Name',
        accessor: 'name',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Email Subject',
        accessor: 'subject',
      },
      {
        Header: 'Date Created',
        accessor: 'dateCreated',
      },
      {
        Header: 'Last Updated',
        accessor: 'lastUpdated',
      },
    ],
    []
  )

  const tableData = emailTemplates.map(
    (item) =>
    ({
      actions: item,
      name: item.name,
      description: item.description,
      subject: item.subject,
      dateCreated: moment(item.created_at).format('DD/MM/YYYY') || '',
      lastUpdated: moment(item.created_at).format('DD/MM/YYYY') || ' || ""',
    } || '')
  )

  return (
    <StyledUserList>
      {emailTemplates?.length > 0 ? (
        <Table
          data={tableData}
          columns={columns}
          loading={loading}
          totalPage={totalPage}
          currentPage={currentPage}
          pageSize={pageSize}
          rowCount={rowCount}
          fetchData={fetchData}
          setNewPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          url={`${url}?pageNumber=${currentPage}&pageSize=${pageSize}`}
          noData="User List is empty"
          height="calc(105vh - 16.3rem)"
          onClick={(row) => {
            dispatch(setPaginationFooter(true))
            dispatch(setSelectedEmailTemplate(row))
            dispatch(setModal('userDetails'))
          }}
        />
      ) : (
        <Flex
          height="calc(100vh - 15rem)"
          bg="#fff"
          justify="center"
          items="center"
        >
          <P>Email Template List is empty</P>
        </Flex> 
      )}
    </StyledUserList>
  )
}

export default EmailTemplateList

const StyledUserList = styled.div`
  width: 100%;
  height: 100%;
`
