import React, { useEffect, useState, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import Joi from 'joi'
import { useToasts } from 'react-toast-notifications'

import Flex from '../../../../../components/layouts/Flex'
import MainModal from '../../../../../components/dashboard/modal/MainModal'
import FormProgress from '../../../../../components/dashboard/controls/FormProgress'
import DashboardInput from '../../../../../components/dashboard/DashboardInput'
import RichTextbox from '../../../../../components/dashboard/controls/RichTextBox'

import {
  setModal,
  setFormData,
} from '../../../../../store/actions/clientActions'

const ClientForm = () => {
  const dispatch = useDispatch()

  const { addToast } = useToasts()

  const modal = useSelector((state) => state.clients.modal)

  const fields = useSelector((state) => state.clients.formData.client)

  const [errors, setErrors] = useState({
    name: '',
    client_website: '',
    client_email: '',
    sector: '',
    company_details: '',
  })

  const validationSchema = useMemo(() => {
    return Joi.object({
      id: Joi.number().required().allow(null),
      name: Joi.string().label('Client Name').required(),
      client_website: Joi.string()
        .label('Client Website')
        .uri({ scheme: ['http', 'https'] })
        .required(),
      client_email: Joi.string()
        .label('Client Email')
        .email({ tlds: { allow: false } })
        .required(),
      sector: Joi.string().label('Sector').required(),
      company_details: Joi.string().allow(''),
    })
  }, [])

  useEffect(() => {
    const result = validationSchema.validate(fields, {
      abortEarly: false,
    })
    const { error } = result
    const fieldNames = Object.keys(fields)
    const updatedErrors = fieldNames.reduce((acc, fieldName) => {
      acc[fieldName] =
        error?.details
          .find((detail) => detail.context.key === fieldName)
          ?.message.replace(/"/g, '') || ''
      return acc
    }, {})

    setErrors(updatedErrors)
  }, [fields])
  const handleFieldChange = (name, value) =>
    dispatch(setFormData('client', { [name]: value }))

  const handleCloseForm = () => {
    dispatch(setModal([]))
  }

  const handleNext = () => {
    const result = validationSchema.validate(fields, {
      abortEarly: false,
    })

    if (result.error) {
      addToast(
        'Please correct the validation errors first before proceeding.',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    } else {
      dispatch(setModal([...modal, 'contactFormModal']))
    }
  }
  return (
    <MainModal
      title={`${modal === 'editClientForm' ? 'Edit' : 'Add'} Client`}
      buttonText="Next"
      onClick={handleNext}
      onClose={handleCloseForm}
      size="large"
    >
      <Flex gap="0.4rem">
        <FormProgress totalSteps={2} activeStep={1} />
      </Flex>
      <Flex gap="0.4rem">
        <DashboardInput
          errorMessage={errors.name}
          label="Client Name *"
          value={fields.name}
          handleChange={(e) => handleFieldChange('name', e.target.value)}
          placeholder=""
          type="text"
          height="2.5rem"
        />
      </Flex>
      <Flex gap="0.4rem">
        <DashboardInput
          errorMessage={errors.client_website}
          label="Client Website *"
          value={fields.client_website}
          handleChange={(e) =>
            handleFieldChange('client_website', e.target.value)
          }
          placeholder=""
          type="text"
          height="2.5rem"
        />
      </Flex>
      <Flex gap="0.4rem">
        <DashboardInput
          errorMessage={errors.client_email}
          label="Client Email *"
          value={fields.client_email}
          handleChange={(e) =>
            handleFieldChange('client_email', e.target.value)
          }
          placeholder=""
          type="text"
          height="2.5rem"
        />
      </Flex>
      <Flex gap="0.4rem">
        <DashboardInput
          errorMessage={errors.sector}
          label="Sector *"
          value={fields.sector}
          handleChange={(e) => handleFieldChange('sector', e.target.value)}
          placeholder=""
          type="text"
          height="2.5rem"
        />
      </Flex>
      <Flex gap="0.4rem">
        <RichTextbox
          label="Client Description"
          content={fields.company_details}
          setContent={(e) => handleFieldChange('company_details', e)}
        />
      </Flex>
    </MainModal>
  )
}

export default ClientForm
