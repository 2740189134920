import React, { useState } from 'react'

import AuthLayout from '../layouts/AuthLayout'
import { H2, P } from '../style/Typography.style'
import { Input } from '../components/Input'
import { Button } from '../components/Button'
import { LoginContainer } from './Login'
import axios from 'axios'
import { useNavigate } from 'react-router'
import { baseUrl } from '../constant/constant'
import Error from '../components/Error'

const ForgetPassword = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const handleEmail = (value) => {
    setEmail(value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (email !== '') {
      setIsLoading(true)

      axios
        .post(`${baseUrl}/user/reset_password`, {
          email,
          user_type: 'recruiter',
        })
        .then((res) => {
          localStorage.setItem('email', email)
          navigate('/verify-email')
          setIsLoading(false)
        })
        .catch((error) => {
          if (error.response) {
            setError(error.response.data.message || error.response.data)
          }
          setIsLoading(false)
        })
    } else {
      setError('Please fill all fields')
    }
  }

  return (
    <AuthLayout>
      <LoginContainer onSubmit={handleSubmit}>
        <H2>Forgot your password?</H2>

        <P style={{ marginBottom: '1.5rem' }}>
          Enter the email address associated with your account and we’ll send
          you a link to reset your password
        </P>

        {error !== '' && <Error error={error} />}

        <Input
          value={email}
          error={error !== ''}
          onChange={handleEmail}
          title="Email"
        />

        <Button type="submit">{isLoading ? 'Loading' : 'Send'}</Button>
      </LoginContainer>
    </AuthLayout>
  )
}

export default ForgetPassword
