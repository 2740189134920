const featureFlags = {
  requisitions: {
    DISABLE_FIRST_INTERVIEW_COLUMN: true,
  },
  lists: {
    DISABLE_LIST_FUNCTIONALITIES: true,
  },
}

export default featureFlags
