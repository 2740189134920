import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import styled from 'styled-components'
import { BsThreeDots } from 'react-icons/bs'

import ActionsMenu from './ActionsMenu'

import {
  setModalFooter,
  setSelectedInterview,
} from '../../../../store/actions/recruitersActions'

import { getTalent } from '../../../../store/actions/talentActions'

const TableAction = ({ value }) => {
  const dispatch = useDispatch()

  const [showMenu, setShowMenu] = useState(false)

  return (
    <StyledTableAction>
      <div
        onClick={(e) => {
          e.stopPropagation()
          setShowMenu((prevState) => !prevState)
          dispatch(setModalFooter(true))
          dispatch(setSelectedInterview(value))
          dispatch(getTalent(value.applicant_id))
        }}
        className={showMenu ? 'icon-container active' : 'icon-container'}
      >
        <BsThreeDots className="icon" />
      </div>
      {showMenu && <ActionsMenu setShowMenu={setShowMenu} />}
    </StyledTableAction>
  )
}

export default TableAction

const StyledTableAction = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 0.4rem;

  .icon-container {
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover,
    &.active {
      background: #eeeef6;
    }

    .icon {
      font-size: 1rem;
      color: #1a1a1a;
    }
  }
`
