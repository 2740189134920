import React from 'react'
import ReactDOM from 'react-dom'

import styled from 'styled-components'
import { H6, P } from '../../../style/Typography.style'
import Flex from '../../layouts/Flex'

import { RiAlertFill } from 'react-icons/ri'
import { IoCloseOutline } from 'react-icons/io5'
import DashboardButton from '../DashboardButton'
import { useDispatch, useSelector } from 'react-redux'
import axiosInstance from '../../../services/config/AxiosIntance'
import { updateTalents } from '../../../store/actions/talentActions'
import { useToasts } from 'react-toast-notifications'

const IconModal = ({ onClose, name }) => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { talent, talents } = useSelector((state) => state.talents)

  async function handleApplicantArchive() {
    try {
      const { data } = await axiosInstance.put(`/${talent.id}/archive`)

      const filteredTalents = talents.filter(
        (applicant) => applicant.id !== talent.id
      )

      dispatch(updateTalents(filteredTalents))

      addToast(`${talent.name} has been archived` || data?.message, {
        appearance: 'info',
        autoDismiss: true,
      })
    } catch (error) {}
  }

  return ReactDOM.createPortal(
    <ModalWrapper>
      <div className="content">
        <IoCloseOutline className="close" onClick={onClose} />
        <Flex items="center" gap="1rem">
          <RiAlertFill className="icon error" />
          <H6 size="1.5rem" mb="0">
            Archive {name}
          </H6>
        </Flex>

        {/* modal content */}
        <Flex margin="1.5rem 0 4rem 0">
          <P>
            Are you sure you want to archive this applicant? Archived applicants
            will be deleted from the system within x hours.
          </P>
        </Flex>

        {/* footer */}
        <Flex items="center" justify="flex-end" gap="2rem">
          <DashboardButton
            text="Cancel"
            notIcon
            color="#fff"
            textColor="#858AA0"
            maxWidth="100px"
            onClick={onClose}
          />
          <DashboardButton
            text="Archive Applicant"
            notIcon
            maxWidth="200px"
            color="#dc2929"
            hoverColor="#E45050"
            onClick={handleApplicantArchive}
          />
        </Flex>
      </div>
    </ModalWrapper>,
    document.getElementById('modal')
  )
}

export default IconModal

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(71, 86, 116, 0.04);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;

  .content {
    position: relative;
    width: 100%;
    height: auto;
    max-height: 90vh;
    max-width: 620px;
    background: #fff;
    padding: 1.55rem 2rem;
    overflow-y: auto;

    .header {
      height: 2.5rem;
    }

    .content-body {
      height: calc(100% - 6.5rem);
      overflow-y: auto;

      .react-select {
        width: 100%;
        margin-bottom: 0.75rem;

        .css-1rhbuit-multiValue {
          background: #f5f5f5;
        }
      }
    }

    .footer {
      position: sticky;
      bottom: -1.25rem;
      left: 0;
      width: 100%;
      background: #fff;
      /* height: 4rem; */
    }

    .close {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      cursor: pointer;
      z-index: 3;
      font-size: 1.5rem;
    }

    .icon {
      font-size: 2rem;

      &.error {
        color: #dc2929;
      }
    }
  }
`
