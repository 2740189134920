import React from 'react'

import styled from "styled-components";
import EmptySearchIcon from '../../../components/icon/EmptySearchIcon';

const JobListingEmptyDetails = () => {
  return (
    <StyledEmptyDetails>
        <EmptySearchIcon />

        <p>Select a job post listing to view details</p>
    </StyledEmptyDetails>
  )
}

export default JobListingEmptyDetails

const StyledEmptyDetails = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
        color: #31374F;
        font-weight: 300;
        font-size: 1rem;
        margin-top: 2rem;
    }
`