import moment from 'moment'

import axiosInstance from '../../services/config/AxiosIntance'

import sidebarModules from '../types/sidebarModules'

const baseUrl = '/sidebar-module'

export const setModal = (modalType) => {
  return { type: sidebarModules.SET_MODAL, payload: modalType }
}
export const setErrorMessage = (message) => {
  return { type: sidebarModules.SET_ERROR, payload: message }
}
export const insertRecord = (formData) => async (dispatch) => {
  dispatch({ type: sidebarModules.SAVE_DATA_LOADING })
  try {
    const result = await axiosInstance.post(`${baseUrl}/create`, formData)
    const data = result.data.data

    const successMessage = result.data.message
    dispatch({
      type: sidebarModules.ADD_SIDEBAR_MODULE_DATA_SUCCESS,
      payload: { data: data, message: successMessage },
    })
  } catch (error) {
    dispatch({ type: sidebarModules.SAVE_DATA_ERROR, payload: error })
  }
}
export const updateRecord = (id, formData) => async (dispatch, getState) => {
  const selectedRecord = getState().sidebarModules.selectedRecord
  dispatch({ type: sidebarModules.SAVE_DATA_LOADING })
  try {
    const result = await axiosInstance.put(`${baseUrl}/${id}/update`, formData)
    const data = formData
    data.id = id
    data.created_at = selectedRecord.created_at
    data.updated_at = moment()
    const successMessage = result.data.message
    dispatch({
      type: sidebarModules.UPDATE_DATA_SUCCESS,
      payload: { data: data, id: id, message: successMessage },
    })
  } catch (error) {
    dispatch({ type: sidebarModules.SAVE_DATA_ERROR, payload: error })
  }
}

export const fetchList = (userTypeId, urlParams) => async (dispatch) => {
  const url = `/user-types/${userTypeId}/modules/paged?${urlParams}`
  dispatch({ type: sidebarModules.FETCH_LIST_LOADING })
  try {
    const { data } = await axiosInstance.get(url)
    let result
    if (data?.resp) {
      result = data.resp
    } else {
      result = []
    }
    dispatch({ type: sidebarModules.FETCH_LIST_SUCCESS, payload: result })
  } catch (error) {
    dispatch({ type: sidebarModules.FETCH_LIST_ERROR, payload: error })
  }
}
export const fetchRecord = (value) => async (dispatch) => {
  dispatch({ type: sidebarModules.FETCH_RECORD_LOADING })
  try {
    let result
    if (value.id) {
      const response = await axiosInstance.get(`${baseUrl}?id=${value.id}`)
      result = response?.data?.resp[0]
    } else {
      result = {}
    }

    dispatch({ type: sidebarModules.FETCH_RECORD_SUCCESS, payload: result })
  } catch (error) {
    dispatch({ type: sidebarModules.FETCH_RECORD_ERROR, payload: error })
  }
}
export const setPagination = (options) => {
  return { type: sidebarModules.SET_PAGINATION_OPTIONS, payload: options }
}
export const setQueryParams = (params) => {
  return { type: sidebarModules.SET_QUERY_PARAMS, payload: params }
}
export const fetchUserType = (id) => async (dispatch) => {
  dispatch({ type: sidebarModules.FETCH_RECORD_LOADING })
  try {
    const result = await axiosInstance.get(`/user-type?id=${id}`)
    dispatch({
      type: sidebarModules.FETCH_USER_TYPE,
      payload: result?.data?.resp[0],
    })
  } catch (error) {
    dispatch({ type: sidebarModules.FETCH_RECORD_ERROR, payload: error })
  }
}
export const deleteRecord = (id) => async (dispatch) => {
  dispatch({ type: sidebarModules.SAVE_DATA_LOADING })
  try {
    const result = await axiosInstance.delete(`${baseUrl}/${id}/delete`)
    const successMessage = result.data.message
    dispatch({
      type: sidebarModules.DELETE_SIDEBAR_DATA_SUCCESS,
      payload: { id: id, message: successMessage },
    })
  } catch (error) {
    dispatch({ type: sidebarModules.SAVE_DATA_ERROR, payload: error })
  }
}
export const resetNotification = () => {
  return { type: sidebarModules.RESET_NOTIFICATION }
}
