import React, { memo, useState, useEffect, useRef } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { Pie, getElementAtEvent } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js'
import { useToasts } from 'react-toast-notifications'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import Loader from '../../../../components/Loader'
import NoData from './NoData'
import DashboardItemTitle from './DashboardItemTitle'

import axiosInstance from '../../../../services/config/AxiosIntance'

import {
  setFilters,
  setTableFilters
} from '../../../../store/actions/dashboardActions'

ChartJS.register(ArcElement, Tooltip, Legend, Title)

const ChannelsChart = () => {
  const { addToast } = useToasts()

  const dispatch = useDispatch()
  const { filters } = useSelector((state) => state.dashboard)

  const [loading, setLoading] = useState(false)
  const [chartData, setChartData] = useState({
    labels: [''],
    datasets: [],
  })

  const chartRef = useRef()

  useEffect(() => {
    setLoading(true)
    const queryParams = new URLSearchParams(filters).toString()

    axiosInstance
      .get(`/dashboard/channelChart?${queryParams}`)
      .then((res) => {
        const borderColors = [
          ...Array(parseInt(res.data.result.labels.length)).keys(),
        ].map(() => 'black')
        setChartData((prev) => ({
          ...prev,
          labels: res.data.result.labels,
          channel_ids: res.data.result.channel_ids,
          datasets: res?.data?.result?.datasets.map((item) => ({
            data: item.data,
            backgroundColor: res.data.result.color_codes,
            borderWidth: 1,
            borderColor: borderColors,
          })),
        }))
      })
      .catch((err) => {
        addToast(
          err?.response?.message ||
          err?.response?.data?.message ||
          'Internal Server Error',
          {
            appearance: 'error',
            autoDismiss: true,
          }
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }, [filters.dates, filters.job_id, filters.company_id])

  useEffect(() => {
    if (chartData.datasets.length) {
      const selectedIndex = chartData.channel_ids.findIndex(
        (item) => item === parseInt(filters.channel_id)
      )

      const selected = chartData.datasets[0].borderColor.map((color, index) =>
        index === selectedIndex ? 'red' : 'black'
      )

      const updatedDataset = [
        {
          ...chartData.datasets[0],
          borderColor: selected,
        },
      ]

      setChartData((prev) => ({
        ...prev,
        datasets: updatedDataset,
      }))
    }
  }, [filters.channel_id])

  const handlePieElementClicked = (e) => {
    const event = getElementAtEvent(chartRef.current, e)
    if (event.length) {
      const channelId = chartData.channel_ids[event[0].index]
      const channelName = chartData.labels[event[0].index]
      dispatch(setFilters({ channel_id: channelId }))
      dispatch(setTableFilters({ channel: channelName }))
    }
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        color: 'black',
        display: true,
        align: 'start',
        position: 'top',
        text: 'Channels',
        font: {
          size: 24,
          family: "'Lato', 'sans-serif'",
          weight: 300,
        },
      },
      pieceLabel: { mode: 'percentage', precision: 2, fontSize: '100px' },
      legend: {
        position: 'right',
        display: true,
      },
      datalabels: {
        color: 'black',
        align: 'end',
        display: 'auto',
        offset: '4',
        anchor: (value) => {
          const sum = value.dataset.data.reduce((acc, curr) => acc + curr, 0)
          const percentValue = (value.dataset.data[value.dataIndex] / sum) * 100
          return percentValue > 5 ? 'center' : 'end'
        },
        // offset: (value) => {
        //   const sum = value.dataset.data.reduce((acc, curr) => acc + curr, 0)
        //   const percentValue = (value.dataset.data[value.dataIndex] / sum) * 100
        //   return percentValue > 2 ? 1 : 4
        // },
        position: 'outside',
        formatter: (value, ctx) => {
          const total = ctx.chart.getDatasetMeta(0).total
          return ((value * 100) / total).toFixed(2) + '%'
        },
      },
    },
    responsive: true,
  }

  const component = loading ? (
    <Loader />
  ) : chartData.labels.length ? (
    <Pie
      data={chartData}
      options={options}
      width={50}
      height={100}
      ref={chartRef}
      plugins={[ChartDataLabels]}
      onClick={handlePieElementClicked}
    />
  ) : (
    <>
      <DashboardItemTitle title="Channels" />
      <NoData />
    </>
  )

  return component
}

export default memo(ChannelsChart)
