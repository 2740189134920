import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import ModalHead from '../../../../components/dashboard/modal/ModalHead'
import CustomNumberInput from '../../../../components/CustomNumberInput'

import { P } from '../../../../style/Typography.style'

import {
  showApplicants,
  setModal,
} from '../../../../store/actions/listsActions'

import useMutation from '../../../../hooks/useMutation'

const UpdateScore = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const [score, setScore] = useState(0)

  const { applicant, applicants, selectedApplicantList } = useSelector(
    (state) => state.lists
  )
  const { data, handleRequest, loading, success, error } = useMutation()

  function handleUpdateScore() {
    handleRequest(
      `/applicant-list/${selectedApplicantList.applicant_list_id}/update`,
      { score },
      'PUT'
    )
  }

  useEffect(() => {
    setScore(selectedApplicantList.score)
  }, [])

  useEffect(() => {
    if (data) {
      const updatedApplicants = applicants.map((item) => {
        if (item.applicant_list_id === applicant.applicant_list_id) {
          item.score = data.resp.score
        }
        return item
      })

      dispatch(showApplicants(updatedApplicants))

      dispatch(setModal(''))

      addToast(`${applicant?.applicantName} score has been updated.`, {
        appearance: 'success',
        autoDismiss: true,
      })
    }
  }, [success])

  useEffect(() => {
    if (error) {
      addToast(
        error?.response?.data?.message ||
          error?.response?.message ||
          'Something went wrong',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    }
  }, [error])

  return (
    <MainModal
      buttonText="Update Score"
      loading={loading}
      onClick={handleUpdateScore}
      info
      header={
        <ModalHead name={applicant.applicant_name} role={applicant.listName} />
      }
    >
      <TextAreaContainer>
        <P>Update Applicant Score</P>
        <CustomNumberInput value={score} onChange={setScore} />
      </TextAreaContainer>
    </MainModal>
  )
}

export default UpdateScore

const TextAreaContainer = styled.div`
  margin-top: 2rem;

  textarea {
    width: 100%;
    height: 222px;
    border: 1px solid #aeb3c9;
    margin-top: 0.875rem;
    outline: none;
    border-radius: none;
    padding: 0.75rem;
    font-size: 0.875rem;
    font-weight: 300;

    &:focus,
    &:hover {
      border: 1px solid #1a1a1a;
    }
  }
`
