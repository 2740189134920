import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

class PDFDownloader {
  /**
   * Print the component into a PDF file and downloads it
   * @componentRef React Component
   */

  static async downloadPDF(componentRef, fileName) {
    const element = componentRef.current
    const height = element.scrollHeight / 2.8

    return html2canvas(element, {
      scrollY: -window.scrollY,
      allowTaint: true,
      useCORS: true,
      windowHeight: height,
    }).then((canvas) => {
      const imgWidth = 510
      const imgData = canvas.toDataURL('image/jpeg', 1.0)
      const pdf = jsPDF('p', 'px', [imgWidth, height])
      pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, height)
      pdf.save(fileName)
    })
  }
}

export default PDFDownloader
