import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MainModal from '../../../../components/dashboard/modal/MainModal'
import ErrorModalHead from '../../../../components/dashboard/modal/ErrorModalHead'
import { passwordReset } from '../../../../store/actions/userActions'
import { capitalize } from '../../../../utils/capitalize'
import { P } from '../../../../style/Typography.style'

const PasswordReset = () => {
  const dispatch = useDispatch()

  const [currentStatus, setCurrentStatus] = useState('deactivate')
  const [message, setMessage] = useState('')

  const { selectedRecord, loading } = useSelector(
    (state) => state.users
  )

  function handleUpdateUserStatus() {
    dispatch(passwordReset(selectedRecord.id))
  }

  useEffect(() => {
    setCurrentStatus('password reset')
    setMessage('')
  }, [])

  return (
    <MainModal
      buttonText={`${capitalize(currentStatus)} User`}
      loading={loading}
      onClick={handleUpdateUserStatus}
      error={true}
      header=
      {<ErrorModalHead
        title={`${capitalize(currentStatus)} ${selectedRecord.applicantName || selectedRecord.adminName || selectedRecord.recruiterName}`}
      />}

    >
      {/*  */}
      <P>
        Are you sure you want to {currentStatus} <strong>{selectedRecord.applicantName || selectedRecord.adminName || selectedRecord.recruiterName}</strong>? {message}
      </P>

    </MainModal>
  )
}

export default PasswordReset
