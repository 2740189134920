import React from 'react'

import styled from 'styled-components'
import { P } from '../style/Typography.style'

const Textarea = ({ value, onChange }) => {
  return (
    <StyledTextarea>
      <P>
        Reason for Removal <span className="error">*</span>
      </P>

      <textarea value={value} onChange={onChange} />
    </StyledTextarea>
  )
}

export default Textarea

const StyledTextarea = styled.div`
  width: 100%;
  height: auto;
  margin-top: 1.5rem;

  .error {
    color: #dc2929;
  }

  textarea {
    width: 100%;
    height: 100%;
    min-height: ${({ minH }) => minH || '200px'};
    padding: 0.75rem;
    margin-top: 0.5rem;
    font-size: 0.875rem;
    font-weight: 300;
    color: #31374f;
    border: 1px solid #aeb3c9;
    outline: none;
    line-height: 1.5;
  }
`
