import React, { useEffect } from 'react'

import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import moment from 'moment'
import { useToasts } from 'react-toast-notifications'

import Heading from '../../../components/dashboard/Heading'
import ClientList from './components/ClientList'
import HeaderFilter from './components/HeaderFilter'
import ClientForm from './components/SaveClient/ClientForm'
import ConfirmChangeClientStatus from './components/ConfirmChangeClientStatus'
import ClientDetailsModal from './components/ClientDetails/ClientDetailsModal'
import ContactListingModal from './components/ContactListingModal'
import ContactFormModal from './components/ContactFormModal'

import { setNotification } from '../../../store/actions/clientActions'

const Clients = () => {
  const dispatch = useDispatch()

  const modal = useSelector((state) => state.clients.modal, shallowEqual)
  const notification = useSelector(
    (state) => state.clients.notification,
    shallowEqual
  )
  const { addToast } = useToasts()

  useEffect(() => {
    if (notification.type === 'success') {
      if (notification?.message) {
        addToast(notification?.message, {
          appearance: 'success',
          autoDismiss: true,
        })
      }
      dispatch(setNotification('', ''))
    }
    if (notification.type === 'error') {
      if (notification?.message) {
        addToast(notification?.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      dispatch(setNotification('', ''))
    }
  }, [notification])

  return (
    <>
      <Heading
        title="Client Lists"
        subTitle={moment().format('MMMM Do, YYYY')}
      />
      <HeaderFilter />
      <ClientList />

      {modal.includes('clientForm') && <ClientForm />}
      {/* {modal.includes('contactFormStep') && <ContactFormModal />} */}
      {modal.includes('confirmChangeClientStatus') && (
        <ConfirmChangeClientStatus />
      )}
      {modal.includes('clientDetailsModal') && <ClientDetailsModal />}
      {modal.includes('contactListingModal') && <ContactListingModal />}
      {modal.includes('contactFormModal') && <ContactFormModal />}
    </>
  )
}

export default Clients
