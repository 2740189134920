/* eslint-disable camelcase */
import React from 'react'
import ReactDatePicker from 'react-datepicker'

import styled from 'styled-components'

import { AiOutlineCalendar } from 'react-icons/ai'
import { setAvailableToStart } from '../../../../store/actions/jobPostingActions'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const AvailableToStart = ({ disabled }) => {
  const dispatch = useDispatch()

  const { availability_to_start } = useSelector((state) => state.jobPosting)

  function handleChange(date) {
    const today = new Date()

    // check if date is a past date
    if (date.getTime() < today.getTime()) {
      toast.error('Please choose a future date', { type: 'error' })
    }

    dispatch(setAvailableToStart(date))
  }

  return (
    <DateWrapper>
      <ReactDatePicker
        disabled={disabled}
        selected={availability_to_start && new Date(availability_to_start)}
        onChange={handleChange}
        popperClassName="calendar-popout"
        popperPlacement="bottom"
        popperModifiers={{
          flip: {
            behavior: ['bottom'], // don't allow it to flip to be above
          },
        }}
      />
      <AiOutlineCalendar className="icon" />
    </DateWrapper>
  )
}

export default AvailableToStart

const DateWrapper = styled.div`
  width: 100%;
  position: relative;

  .icon {
    position: absolute;
    top: 50%;
    left: 0.5rem;
    font-size: 1rem;
    transform: translateY(-50%);
    color: #6e7490;
  }

  input {
    width: 100%;
    border: 1px solid #aeb3c9;
    height: 2.5rem;
    padding-left: 2.25rem;
    font-size: 0.875rem;
    color: #6e7490;
  }

  .calendar-popout {
    z-index: 1000;
  }
`
