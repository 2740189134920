import React from 'react'

import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import { H6, P } from '../../../../style/Typography.style'

import { FilterCheckBox } from '../../../../components/dashboard/FilterInput'

import {
  sortTalents,
  filterTalents,
  setBulkAction,
  setBulkItems,
} from '../../../../store/actions/talentActions'

import { setModal } from '../../../../store/actions/listsActions'

const TalentControls = () => {
  const { addToast } = useToasts()

  const talents = useSelector((state) => state.talents.talents)
  const sortOrder = useSelector((state) => state.talents.sortOrder)
  const rowCount = useSelector((state) => state.talents.pagination.rowCount)
  const bulkAction = useSelector((state) => state.talents.bulkAction)
  const selectedAll = useSelector(
    (state) => state.talents.bulkItems.selectedAll
  )
  const checkedItems = useSelector(
    (state) => state.talents.bulkItems.checkedItems
  )
  const uncheckedItems = useSelector(
    (state) => state.talents.bulkItems.uncheckedItems
  )

  const dispatch = useDispatch()

  const handleSelectAll = (e) => {
    const payload = {
      selectedAll: e.target.checked,
    }

    if (payload.selectedAll) {
      payload.uncheckedItems = []
    } else {
      payload.checkedItems = []
    }

    dispatch(setBulkItems(payload))
  }

  const handleSort = (e) => {
    const sortValue = e.target.value
    dispatch(sortTalents(sortValue))
    dispatch(filterTalents())
  }

  const handleBulkAction = (e) => {
    if (!checkedItems.length && !selectedAll) {
      addToast('Please select one or more items before adding a bulk action.', {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }
    const value = e.target.value
    dispatch(setBulkAction(value))
    dispatch(setModal('bulkActionAddToVacancy'))
  }

  const getTotalSelected = () => {
    const totalCheckedItems = parseInt(checkedItems.length)
    const totalUncheckedItems = parseInt(uncheckedItems.length)
    const totalItems = parseInt(rowCount)

    if (selectedAll) {
      return totalItems - totalUncheckedItems
    } else {
      return totalCheckedItems
    }
  }

  return (
    <StyledFormWrapper>
      <div className="sort__wrapper">
        <H6 mb="0">{talents ? rowCount : ''} Results</H6>
        <div className="sort__selection">
          <P>Sort By</P>
          <SelectSort onChange={(e) => handleSort(e)} value={sortOrder}>
            <option value="DESC">Newest First</option>
            <option value="ASC">Oldest First</option>
          </SelectSort>
        </div>
      </div>
      <div className="bulk__action__wrapper">
        <FilterCheckBox
          checked={selectedAll}
          size="small"
          label="Select All"
          name="ProfessionalHeadline"
          onChange={handleSelectAll}
        />
        <H6 mb="0">{getTotalSelected().toString()} Selected</H6>
        <div className="bulk__action__selection">
          <SelectSort onChange={(e) => handleBulkAction(e)} value={bulkAction}>
            <option value="">Bulk Actions</option>
            <option value="bulkAction">Add to vacancy</option>
          </SelectSort>
        </div>
      </div>
    </StyledFormWrapper>
  )
}

const StyledFormWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  position: sticky;
  top: 19vh;
  z-index: 100;
  background: white;

  .sort__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sort__selection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
    }
  }

  .bulk__action__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .bulk__action__selection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
    }
  }
`

const SelectSort = styled.select`
  max-width: 150px;
  height: 2.25rem;
  border: none;
  outline: none;
  background: #fff;
  font-size: 0.75rem;
  font-weight: 300;
`

export default TalentControls
