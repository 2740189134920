import React from 'react'

import styled from 'styled-components'
import { useSelector } from 'react-redux'

import CollapsibleFilter from './CollapsibleFilters/CollapsibleFilter'

const TalentPoolSidebar = () => {
  const { showSideFilters } = useSelector((state) => state.talents)

  return (
    <>
      <TalentPoolSidebarStyled show={showSideFilters}>
        <CollapsibleFilter />
      </TalentPoolSidebarStyled>
    </>
  )
}

export default TalentPoolSidebar

const TalentPoolSidebarStyled = styled.aside`
  width: 100%;
  max-width: 20rem;
  position: sticky;
  top: calc(25vh - 1vh);
  max-height: calc(68vh - 5vh);
  overflow: auto;
  margin-right: 1.5rem;
  display: ${(p) => (p.show ? 'inline-block' : 'none')};

  .clearButton {
    font-size: 0.75rem;
    font-weight: 300;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    gap: 0.5rem;
    color: #1877f2;

    display: none;

    .icon {
      font-size: 1rem;
    }
  }
`
