import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import DashboardInput from '../../../../components/dashboard/DashboardInput'
import { useDispatch, useSelector } from 'react-redux'
import useMutation from '../../../../hooks/useMutation'
import { addLists, setModal } from '../../../../store/actions/listsActions'

const EditList = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const [listName, setListName] = useState('')
  const { selectedList, lists } = useSelector((state) => state.lists)

  const { loading, data, error, handleRequest, success } = useMutation()

  async function handleUpdateList() {
    if (listName.trim() === '')
      return addToast('List Name is required.', {
        appearance: 'error',
        autoDismiss: true,
      })

    handleRequest(
      `/${selectedList.id}/update-list`,
      {
        list_name: listName.trim(),
      },
      'PUT'
    )
  }

  useEffect(() => {
    if (selectedList) {
      setListName(selectedList.list_name)
    }
  }, [])

  // check if the request is success
  useEffect(() => {
    if (data) {
      addToast(`Successfully updated ${listName || ''}`, {
        appearance: 'success',
        autoDismiss: true,
      })

      const newLists = lists.filter((item) => item.id !== selectedList.id)
      const list = lists.find((item) => item.id === selectedList.id)
      list.list_name = listName.trim()

      dispatch(addLists([list, ...newLists]))
      dispatch(setModal(''))
    }
  }, [success])

  // check if the request is error
  useEffect(() => {
    if (error) {
      addToast(error?.response?.data?.message || 'Something went wrong', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }, [error])

  return (
    <MainModal
      title="Edit List"
      buttonText="Update"
      loading={loading}
      onClick={handleUpdateList}
    >
      <DashboardInput
        name="listName"
        label="List Name"
        placeholder=""
        type="text"
        height="2.5rem"
        value={listName}
        handleChange={(e) => setListName(e.target.value)}
      />
    </MainModal>
  )
}

export default EditList
