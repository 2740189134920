import React from 'react'

import { IoMdNotificationsOutline } from 'react-icons/io'

import styled from 'styled-components'

const EmptyNotification = () => {
  return (
    <StyledEmptyNotificationDropdown>
      <IoMdNotificationsOutline size="8rem" />
      <h5>No Notifications Yet</h5>
      <p>You have no notifications right now.</p>
      <p>Come back later</p>
    </StyledEmptyNotificationDropdown>
  )
}

export default EmptyNotification

const StyledEmptyNotificationDropdown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-weight: 300;
    margin: 0;
  }
`
