import sidebarModules from '../types/sidebarModules'
import { updateList } from '../../utils/stateHelpers'

const initialState = {
  modal: '',
  sidebarModules: [],
  selectedRecord: {},
  userType: {},
  pagination: {},
  loading: false,
  notification: {},
  queryParams: {
    searchTerm: '',
    pageNumber: 1,
    pageSize: 10,
  },
}

const sidebarModuleReducer = (state = initialState, action) => {
  switch (action.type) {
    case sidebarModules.SET_LOADING:
      return {
        ...state,
        loading: true,
      }
    case sidebarModules.SET_MODAL:
      return {
        ...state,
        loading: false,
        modal: action.payload,
      }
    case sidebarModules.SET_ERROR:
      return {
        ...state,
        notification: {
          value: 'error',
          message: action.payload,
        },
      }
    case sidebarModules.SET_PAGINATION_OPTIONS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      }
    case sidebarModules.SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          ...action.payload,
        },
      }
    case sidebarModules.FETCH_LIST_LOADING:
      return {
        ...state,
        loading: true,
      }
    case sidebarModules.FETCH_LIST_SUCCESS:
      return {
        ...state,
        pagination: action.payload.pagination,
        sidebarModules: action.payload.data,
        loading: false,
      }
    case sidebarModules.FETCH_LIST_ERROR:
      return {
        ...state,
        notification: {
          value: 'error',
          message:
            action.payload.response.data.message || 'Something went wrong.',
        },
        loading: false,
      }
    case sidebarModules.FETCH_RECORD_LOADING:
      return {
        ...state,
        loading: true,
      }
    case sidebarModules.FETCH_RECORD_SUCCESS:
      return {
        ...state,
        selectedRecord: action.payload,
        loading: false,
      }
    case sidebarModules.FETCH_RECORD_ERROR:
      return {
        ...state,
        notification: {
          value: 'error',
          message:
            action.payload.response.data.message || 'Something went wrong.',
        },
        loading: false,
      }
    case sidebarModules.FETCH_USER_TYPE:
      return {
        ...state,
        userType: action.payload,
        loading: false,
      }
    case sidebarModules.SAVE_DATA_LOADING:
      return {
        ...state,
        loading: true,
      }
    case sidebarModules.ADD_SIDEBAR_MODULE_DATA_SUCCESS:
      return {
        ...state,
        sidebarModules: [action.payload.data, ...state.sidebarModules],
        modal: '',
        notification: {
          value: 'success',
          message: action.payload.message,
        },
        selectedRecord: {},
        loading: false,
      }
    case sidebarModules.UPDATE_DATA_SUCCESS: {
      return {
        ...state,
        sidebarModules: updateList(
          state.sidebarModules,
          'id',
          action.payload.data
        ),
        notification: {
          value: 'success',
          message: action.payload.message,
        },
        selectedRecord: {},
        loading: false,
        modal: '',
      }
    }

    case sidebarModules.SAVE_DATA_ERROR:
      return {
        ...state,
        notification: {
          value: 'error',
          message:
            action.payload.response.data.message || 'Something went wrong.',
        },
        loading: false,
      }

    case sidebarModules.DELETE_SIDEBAR_DATA_SUCCESS: {
      return {
        ...state,
        sidebarModules: state.sidebarModules.filter(
          (e) => e.id !== action.payload.id
        ),
        notification: {
          value: 'success',
          message: action.payload.message,
        },
        selectedRecord: {},
        loading: false,
        modal: '',
      }
    }

    case sidebarModuleReducer.RESET_NOTIFICATION: {
      return {
        ...state,
        notification: {},
      }
    }

    default:
      return state
  }
}

export default sidebarModuleReducer
