export const USER_LOADING = 'USER_LOADING'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const UPDATE_VERIFICATION_CODE = 'UPDATE_VERIFICATION_CODE'
export const VERIFY_USER = 'VERIFY_USER'
export const DELETE_VERIFICATION_CODE = 'DELETE_VERIFICATION_CODE'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const GET_ERRORS = 'GET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const GET_MESSAGES = 'GET_MESSAGES'
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'

// Talents
export const FETCH_TALENTS_SUCCESS = 'FETCH_TALENTS_SUCCESS'
export const UPDATE_TALENTS = 'UPDATE_TALENTS'
export const FETCH_TALENT_SUCCESS = 'FETCH_TALENT_SUCCESS'
export const FETCH_TALENT_ERROR = 'FETCH_TALENT_ERROR'
export const FETCH_TALENTS_ERROR = 'FETCH_TALENTS_ERROR'
export const FETCH_TALENT_LOADING = 'FETCH_TALENT_LOADING'
export const FETCH_TALENTS_LOADING = 'FETCH_TALENTS_LOADING'
export const SELECT_TALENT = 'SELECT_TALENT'
export const SORT_TALENTS = 'SORT_TALENTS'
export const GET_TALENT_BY_ID = 'GET_TALENT_BY_ID'
export const UPDATE_PAGINATION = 'UPDATE_PAGINATION'

// Talents Filter
export const FETCH_PROFESSIONAL_HEADLINE_SUCCESS =
  'FETCH_PROFESSIONAL_HEADLINE_SUCCESS'
export const FETCH_SKILLS_SUCCESS = 'FETCH_SKILLS_SUCCESS'
export const SET_PROFESSIONAL_HEADLINES_FILTER =
  'SET_PROFESSIONAL_HEADLINES_FILTER'
export const FETCH_TALENT_BY_FILTER_SUCCESS = 'FETCH_TALENT_BY_FILTER_SUCCESS'
export const SET_SKILL_FILTER = 'SET_SKILL_FILTER'
export const SET_FIRST_FILTER = 'SET_FIRST_FILTER'
export const SET_SECOND_FILTER = 'SET_SECOND_FILTER'
export const SET_LEVEL_OF_EDUCATION_FILTER = 'SET_LEVEL_OF_EDUCATION_FILTER'
export const SET_HARDWARE_REQUIREMENT = 'SET_HARDWARE_REQUIREMENT'
export const SET_COUNTRY_FILTER = 'SET_COUNTRY_FILTER'

export const SET_IMAGE_FILTER = 'SET_IMAGE_FILTER'
export const SET_FILTER_BY_YEAR = 'SET_FILTER_BY_YEAR'
export const SET_FILTER_QUERY_TERM = 'SET_FILTER_QUERY_TERM'
export const SET_MORE_JOB_TITLE = 'SET_MORE_JOB_TITLE'
export const SET_COMPANY_NAME = 'SET_COMPANY_NAME'
export const SET_SELECTED_HARDWARE = 'SET_SELECTED_HARDWARE'
export const SET_SELECTED_PING = 'SET_SELECTED_PING'
export const SET_SELECTED_TALENT_DISPLAY = 'SET_SELECTED_TALENT_DISPLAY'
export const SET_SELECTED_DOWNLOADMBPS = 'SET_SELECTED_DOWNLOADMBPS'
export const SET_SELECTED_UPLOADMBPS = 'SET_SELECTED_UPLOADMBPS'
export const SET_ALL_OF_THESE_WORDS = 'SET_ALL_OF_THESE_WORDS'
export const SET_SOFT_SKILLS = 'SET_SOFT_SKILLS'
export const SET_OS = 'SET_OS'
export const SET_PROCESSOR = 'SET_PROCESSOR'
export const SET_RAM = 'SET_RAM'
export const TOGGLE_SIDE_FILTERS = 'TOGGLE_SIDE_FILTERS'
export const TOGGLE_TALENT_POPOUT = 'TOGGLE_TALENT_POPOUT'
export const INCREASE_ACTIVE_FILTER = 'INCREASE_ACTIVE_FILTER'

// Skills
export const FETCH_PERSONAL_SKILLS_SUCCESS = 'FETCH_PERSONAL_SKILLS_SUCCESS'
export const FETCH_PERSONAL_SKILLS_ERROR = 'FETCH_PERSONAL_SKILLS_ERROR'
export const FETCH_PERSONAL_SKILLS_LOADING = 'FETCH_PERSONAL_SKILLS_LOADING'

// set download modal
export const SET_DOWNLOAD_MODAL = 'SET_DOWNLOAD_MODAL'
export const SET_CV_PHOTO = 'SET_CV_PHOTO'
export const SET_WFH = 'SET_WFH'
export const SET_REMOTE_WORK_ETHICS = 'SET_REMOTE_WORK_ETHICS'
export const SET_SHOW_LAST_NAME = 'SET_SHOW_LAST_NAME'
export const SET_SHOW_CONTACT_DETAILS = 'SET_SHOW_CONTACT_DETAILS'
export const SET_SHOW_CHAR_REF = 'SET_SHOW_CHAR_REF'
export const SET_REMOTE_SETUP_DATA = 'SET_REMOTE_SETUP_DATA'

// get popular professional headlines
export const FETCH_POPULAR_PROFESSIONAL_HEADLINES_SUCCESS =
  'FETCH_POPULAR_PROFESSIONAL_HEADLINES_SUCCESS'
export const FETCH_POPULAR_PROFESSIONAL_HEADLINES_ERROR =
  'FETCH_POPULAR_PROFESSIONAL_HEADLINES_ERROR'

// modals
export const OPEN_ACTIVITIES_MODAL = 'OPEN_ACTIVITIES_MODAL'
export const CLOSE_ACTIVITIES_MODAL = 'CLOSE_ACTIVITIES_MODAL'

export const SET_ICON_MODAL = 'SET_ICON_MODAL'

export const SET_SHOW_CONFIRM_LEAVE_MODAL = 'SET_SHOW_CONFIRM_LEAVE_MOdal'

// upload cv
export const ADD_TALENT_CV = 'ADD_TALENT_CV'
export const ADD_TALENT = 'ADD_TALENT'

// toggle state
export const TOGGLE_IS_PROFESSIONAL = 'TOGGLE_IS_PROFESSIONAL'
export const TOGGLE_IS_SKILL = 'TOGGLE_IS_SKILL'
export const TOGGLE_IS_EXPERIENCE = 'TOGGLE_IS_EXPERIENCE'
export const SET_GLOBAL_SEARCH_TERM = 'SET_GLOBAL_SEARCH_TERM'

// interview
export const SET_INTERVIEW_STEP = 'SET_INTERVIEW_STEP'

// bulk actions
export const SET_BULK_ACTION = 'SET_BULK_ACTION'

// bulk items
export const SET_BULK_ITEMS = 'SET_BULK_ITEMS'

