import React, { useState } from 'react'
import { BsThreeDots } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { addList } from '../../../../store/actions/listsActions'
import ListOptions from './ListOptions'

const List = ({ title, number, list, active }) => {
  const dispatch = useDispatch()
  const [openOption, setOpenOpen] = useState(false)

  function handleOptions() {
    setOpenOpen((prev) => !prev)
  }

  function handleClick() {
    dispatch(addList(list))
  }

  return (
    <StyledList onClick={handleClick} className={active ? 'active' : ''}>
      <div
        onClick={handleOptions}
        className={openOption ? 'icon-container active' : 'icon-container'}
      >
        <BsThreeDots className="icon" />
      </div>

      <h6>
        {title?.substring(0, 20)}
        {title?.length > 20 ? '...' : ''}
      </h6>
      <p>{`${number || '0'} Applicant${number > 1 ? 's' : ''}`}</p>

      {openOption && <ListOptions setOpenOption={setOpenOpen} list={list} />}
    </StyledList>
  )
}

export default List

const StyledList = styled.div`
  width: 100%;
  max-width: 270px;
  height: 80px;
  background: #fff;
  padding: 1.5rem 0.24rem 1rem 1rem;
  border: 1px solid #eeeef6;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &.active {
    border: 1px solid #1877f2;
  }

  .icon-container {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 1.3rem;
    height: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;

    &:hover,
    &.active {
      background: #eeeef6;
      cursor: pointer;
    }

    .icon {
      font-size: 1rem;
    }
  }

  h6 {
    font-size: 0.875rem;
    font-weight: 500;
    color: #1a1a1a;
  }

  p {
    font-size: 0.75rem;
    font-weight: 300;
    color: #1a1a1a;
  }
`
