import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import useMutation from '../../../../hooks/useMutation'
import MainModal from '../../../../components/dashboard/modal/MainModal'
import Textarea from '../../../../components/Textarea'
import { P } from '../../../../style/Typography.style'
import {
  setModal,
  showApplicants,
  updateLists,
} from '../../../../store/actions/listsActions'

const RemoveFromList = () => {
  const dispatch = useDispatch()
  const { applicant, applicants, selectedList } = useSelector(
    (state) => state.lists
  )

  const [reasons, setReasons] = useState('')

  const { handleRequest, loading, success, error } = useMutation()
  const { addToast } = useToasts()

  function deleteApplicant() {
    if (!applicant?.listId)
      return addToast('List Not Specified', {
        appearance: 'error',
        autoDismiss: true,
      })

    if (reasons === '')
      return addToast('Reason is required', {
        appearance: 'error',
        autoDismiss: true,
      })

    handleRequest(
      `/applicant-list/${applicant?.applicantId}/delete`,
      {
        list_id: applicant?.listId.toString(),
        message: reasons,
      },
      'DELETE'
    )
  }

  // check if reqeust is success
  useEffect(() => {
    if (success) {
      const newApplicants = applicants.filter(
        (user) => user.applicant_id !== applicant.applicantId
      )
      const mainList = selectedList
      mainList.numbersOfApplicantInList = mainList.numbersOfApplicantInList - 1

      dispatch(updateLists(mainList))
      dispatch(showApplicants(newApplicants))
      dispatch(setModal(''))
      addToast(
        `${applicant.applicantName} is removed from ${applicant.listName} and moved back to Talent Pool`,
        {
          appearance: 'info',
          autoDismiss: true,
        }
      )
    }
  }, [success])

  // check if request is error
  useEffect(() => {
    if (error) {
      addToast(
        error?.response?.data?.message ||
          error?.response?.message ||
          'Something went wrong',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    }
  }, [error])

  return (
    <MainModal
      error
      title="Remove from List"
      buttonText="Remove from List"
      onClick={deleteApplicant}
      loading={loading}
    >
      <P>
        {`Are you sure you want to remove ${applicant.applicantName} from the List ${applicant.listName}?`}
      </P>

      <Textarea value={reasons} onChange={(e) => setReasons(e.target.value)} />
    </MainModal>
  )
}

export default RemoveFromList
