/* eslint-disable camelcase */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_SHIFT_AVAILABILITY } from '../../../store/types/jobPosting'
import { SingleDropdownSelect } from '../TalentPool/DropdownSelect'

const Shift = ({ disabled }) => {
  const dispatch = useDispatch()
  const { shift_availability } = useSelector((state) => state.jobPosting)

  return (
    <SingleDropdownSelect
      disabled={disabled}
      value={shift_availability}
      onChange={(e) =>
        dispatch({ type: SET_SHIFT_AVAILABILITY, payload: e.target.value })
      }
      title="Shift Preference"
    >
      <option>Choose Option</option>
      <option>Morning Shift - PH Time</option>
      <option>Mid Shift - PH Time</option>
      <option>Night Shift - PH Time</option>
    </SingleDropdownSelect>
  )
}

export default Shift
