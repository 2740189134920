import React from 'react'

import { connect, useSelector } from 'react-redux'

import styled from 'styled-components'

import { P } from '../../style/Typography.style'

import { addAttachmentFlagToCloudinaryUrl } from '../../utils/utils'

import {
  cvPhotoToggle,
  wfhToggle,
  remoteWorkEthicsToggle,
  lastNameToggle,
  contactDetailsToggle,
  charRefToggle,
} from '../../store/actions/modalActions'

const OtherInfo = ({
  handlePrint,
  cvPhotoToggle,
  wfhToggle,
  remoteWorkEthicsToggle,
  cvPhoto,
  wfh,
  contactDetails,
  remoteWorkEthics,
  showLastName,
  lastNameToggle,
  contactDetailsToggle,
  charRef,
  charRefToggle,
}) => {
  const cvUrl = useSelector((state) => state.talents.uploadedCv.secure_url)
  const applicantName = useSelector((state) => state.talents.talent.name)

  const handleDownloadCV = async () => {
    if (cvUrl) {
      const downloadUrl = addAttachmentFlagToCloudinaryUrl(cvUrl, applicantName)

      try {
        const response = await fetch(downloadUrl)
        if (!response.ok) throw new Error('Network response was not ok')

        const blob = await response.blob()
        const blobUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = blobUrl
        link.download = `${applicantName}.pdf`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        setTimeout(() => window.URL.revokeObjectURL(blobUrl), 100)
      } catch (error) {
        throw new Error(`Error downloading file: ${error.toString()}`)
      }
    } else {
      handlePrint()
    }
  }

  return (
    <StyledOtherInfo>
      <button onClick={handleDownloadCV} className="buttons">
        Download CV
      </button>

      <P>Customize Data</P>

      <div className="checkbox">
        <input
          onChange={cvPhotoToggle}
          checked={cvPhoto}
          className="check"
          type="checkbox"
          id="cvPhoto"
          name="cvPhoto"
        />
        <label htmlFor="cvPhoto">CV Photo</label>
      </div>
      <div className="checkbox">
        <input
          className="check"
          onChange={lastNameToggle}
          checked={showLastName}
          type="checkbox"
          id="contactDetails"
          name="contactDetails"
        />
        <label htmlFor="contactDetails">Last Name</label>
      </div>
      <div className="checkbox">
        <input
          onChange={contactDetailsToggle}
          checked={contactDetails}
          className="check"
          type="checkbox"
          id="cvPhoto"
          name="cvPhoto"
        />
        <label htmlFor="cvPhoto">Contact Details</label>
      </div>
      <div className="checkbox">
        <input
          className="check"
          onChange={wfhToggle}
          checked={wfh}
          type="checkbox"
          id="contactDetails"
          name="contactDetails"
        />
        <label htmlFor="contactDetails">WFH Setup</label>
      </div>
      <div className="checkbox">
        <input
          className="check"
          onChange={remoteWorkEthicsToggle}
          checked={remoteWorkEthics}
          type="checkbox"
          id="contactDetails"
          name="contactDetails"
        />
        <label htmlFor="contactDetails">Remote Fitness Level</label>
      </div>
      <div className="checkbox">
        <input
          className="check"
          onChange={charRefToggle}
          checked={charRef}
          type="checkbox"
          id="characterRef"
          name="characterRef"
        />
        <label htmlFor="contactDetails">Character Reference</label>
      </div>
    </StyledOtherInfo>
  )
}

const mapStateToProps = (state) => ({
  cvPhoto: state.modal.cvPhoto,
  wfh: state.modal.wfh,
  remoteWorkEthics: state.modal.remoteWorkEthics,
  showLastName: state.modal.showLastName,
  contactDetails: state.modal.contactDetails,
  charRef: state.modal.charRef,
})

export default connect(mapStateToProps, {
  cvPhotoToggle,
  wfhToggle,
  remoteWorkEthicsToggle,
  lastNameToggle,
  contactDetailsToggle,
  charRefToggle,
})(OtherInfo)

const StyledOtherInfo = styled.div`
  width: 100%;
  max-width: 250px;

  .buttons {
    width: 100%;
    height: 3.75rem;
    border: none;
    border-radius: 3px;
    background-color: #ff5880;
    color: #fff;
    font-weight: 500;
    font-size: 1.125rem;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 1rem;

    &.display__html {
      background-color: #1877f2;
    }

    &.download__cv {
      background-color: green;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    margin-top: 0.875rem;

    input.check {
      width: 0.75rem;
      height: 0.75rem;
      accent-color: #0e1324;
      background: #fff;
    }

    label {
      font-size: 0.75rem;
      font-weight: 300;
      color: #0e1324;
      margin-left: 0.5rem;
    }
  }
`
