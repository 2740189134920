import React, { memo, useState, useEffect, useRef } from 'react'

import { useSelector } from 'react-redux'

import { Pie } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js'
import { useToasts } from 'react-toast-notifications'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import Loader from '../../../../components/Loader'
import NoData from './NoData'
import DashboardItemTitle from './DashboardItemTitle'

import axiosInstance from '../../../../services/config/AxiosIntance'

ChartJS.register(ArcElement, Tooltip, Legend, Title)

const KPIChart = () => {
    const { addToast } = useToasts()

    const { filters } = useSelector((state) => state.dashboard)

    const [loading, setLoading] = useState(false)
    const [chartData, setChartData] = useState({
        labels: [''],
        datasets: [],
    })

    const chartRef = useRef()

    useEffect(() => {
        setLoading(true)
        const queryParams = new URLSearchParams(filters).toString()

        axiosInstance
            .get(`/dashboard/kpi-chart?${queryParams}`)
            .then((res) => {
                const borderColors = [
                    ...Array(parseInt(res.data.result.labels.length)).keys(),
                ].map(() => 'black')
                setChartData((prev) => ({
                    ...prev,
                    labels: res.data.result.labels,
                    channel_ids: res.data.result.channel_ids,
                    datasets: res?.data?.result?.datasets.map((item) => ({
                        data: item.data,
                        backgroundColor: res.data.result.color_codes,
                        borderWidth: 1,
                        borderColor: borderColors,
                    })),
                }))
            })
            .catch((err) => {
                addToast(
                    err?.response?.message ||
                    err?.response?.data?.message ||
                    'Internal Server Error',
                    {
                        appearance: 'error',
                        autoDismiss: true,
                    }
                )
            })
            .finally(() => {
                setLoading(false)
            })
    }, [filters.dates, filters.job_id, filters.company_id])

    const options = {
        maintainAspectRatio: false,
        plugins: {
            title: {
                color: 'black',
                display: true,
                align: 'start',
                position: 'top',
                text: 'Closed Applications',
                font: {
                    size: 24,
                    family: "'Lato', 'sans-serif'",
                    weight: 300,
                },
            },
            pieceLabel: { mode: 'percentage', precision: 2, fontSize: '100px' },
            legend: {
                position: 'right',
                display: true,
            },
            datalabels: {
                color: 'black',
                align: 'end',
                display: 'auto',
                offset: '4',
                anchor: (value) => {
                    const sum = value.dataset.data.reduce((acc, curr) => acc + curr, 0)
                    const percentValue = (value.dataset.data[value.dataIndex] / sum) * 100
                    return percentValue > 5 ? 'center' : 'end'
                },
                position: 'outside',
                formatter: (value, ctx) => {
                    const total = ctx.chart.getDatasetMeta(0).total
                    return ((value * 100) / total).toFixed(2) + '%'
                },
            },
        },
        responsive: true,
    }

    const component = loading ? (
        <Loader />
    ) : chartData.labels.length ? (
        <Pie
            data={chartData}
            options={options}
            width={50}
            height={100}
            ref={chartRef}
            plugins={[ChartDataLabels]}
        />
    ) : (
        <>
            <DashboardItemTitle title="Closed Applications" />
            <NoData />
        </>
    )

    return component
}

export default memo(KPIChart)
