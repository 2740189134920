import emailTemplateTypes from '../types/emailTemplates'

const initialState = {
  modal: '',
  emailTemplates: [],
  placeholder: '',
  replacedText: '',
  selectedEmailTemplate: {},
  paginationFooter: false,
  dataChanged: true,
}

const emailTemplatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case emailTemplateTypes.SET_MODAL:
      return {
        ...state,
        modal: action.payload,
      }

    case emailTemplateTypes.GET_REPLACED_TEXT:
      return {
        ...state,
        replacedText: action.payload,
      }

    case emailTemplateTypes.SET_PLACEHOLDER:
      return {
        ...state,
        placeholder: action.payload,
      }

    case emailTemplateTypes.ADD_EMAIL_TEMPLATES:
      return {
        ...state,
        emailTemplates: action.payload,
      }

    case emailTemplateTypes.SET_SELECTED_EMAIL_TEMPLATE:
      return {
        ...state,
        selectedEmailTemplate: action.payload,
      }

    case emailTemplateTypes.SHOW_EMAIL_TEMPLATES:
      return {
        ...state,
        emailTemplates: action.payload,
      }

    case emailTemplateTypes.SET_PAGINATION_FOOTER:
      return {
        ...state,
        paginationFooter: action.payload,
      }

    case emailTemplateTypes.DATA_CHANGED:
      return {
        ...state,
        dataChanged: !state.dataChanged,
      }

    default:
      return state
  }
}

export default emailTemplatesReducer
