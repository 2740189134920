import { useDispatch, useSelector } from 'react-redux'

import DashboardInput from '../../../DashboardInput'
import { SingleDropdownSelect } from '../../DropdownSelect'
import ToggleButton from '../../../ToggleButton'

import {
  setSelectedPing,
  setDownloadMBPS,
  setuploadMBPS,
  setAllOfTheseWords,
  setFilterByPhoto,
} from '../../../../../store/actions/talentActions'

const FindProfileFilter = () => {
  const dispatch = useDispatch()

  const { allOfTheseWords, selectedPing, downloadMBPS, uploadMBPS, hasPhoto } =
    useSelector((state) => state.talents)

  return (
    <>
      <ToggleButton
        value={hasPhoto}
        onchange={() => dispatch(setFilterByPhoto())}
        label="With CV Photo"
        mt=".875rem"
        mb="1.5rem"
      />
      <DashboardInput
        name="allWords"
        label="All of these words"
        placeholder="-"
        value={allOfTheseWords}
        type="text"
        handleChange={(e) => dispatch(setAllOfTheseWords(e.target.value))}
      />

      <SingleDropdownSelect
        name="ping"
        title="Ping"
        value={selectedPing}
        onChange={(e) => dispatch(setSelectedPing(e.target.value))}
      >
        <option value="">-</option>
        <option value="[0,5]">Less Than 5 MS</option>
        <option value="[0,19]">Less Than 20 MS</option>
        <option value="[20,999999999]">More Than 20 MS</option>
      </SingleDropdownSelect>

      <SingleDropdownSelect
        name="download"
        title="Download MBPS"
        value={downloadMBPS}
        onChange={(e) => dispatch(setDownloadMBPS(e.target.value))}
      >
        <option value="">-</option>
        <option value="[5000, 19999]">More Than 5MBPS</option>
        <option value="[19999,49999]">More Than 20MBPS</option>
        <option value="[49999,99999999]">More Than 50MBPS</option>
      </SingleDropdownSelect>

      <SingleDropdownSelect
        name="upload"
        title="Upload MBPS"
        value={uploadMBPS}
        onChange={(e) => dispatch(setuploadMBPS(e.target.value))}
      >
        <option value="">-</option>
        <option value="[5000, 19999]">More Than 5MBPS</option>
        <option value="[19999,49999]">More Than 20MBPS</option>
        <option value="[49999,99999999]">More Than 50MBPS</option>
      </SingleDropdownSelect>
    </>
  )
}

export default FindProfileFilter
