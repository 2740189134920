import {
  CLEAR_JOB_POSTING,
  SET_AVAILABLE_TO_START_DATE,
  SET_IS_AVAILABLE_TO_START,
  SET_RECRUITER_ID,
  SET_UPDATE_STATUS_TAB,
  SET_ACTIVE_JOB_ID,
} from '../types/jobPosting'

export function nextStep(state, action) {}

export function setAvailableToStart(date) {
  return {
    type: SET_AVAILABLE_TO_START_DATE,
    payload: date,
  }
}

export function setIsAvalibleToStart(flag) {
  return {
    type: SET_IS_AVAILABLE_TO_START,
    payload: flag,
  }
}

export function clearJobPosting() {
  return {
    type: CLEAR_JOB_POSTING,
  }
}

export function setRecruiterId(id) {
  return {
    type: SET_RECRUITER_ID,
    payload: id,
  }
}

export function setUpdateStatusTab(status) {
  return {
    type: SET_UPDATE_STATUS_TAB,
    payload: status,
  }
}

export function setActiveJobId(jobId) {
  return {
    type: SET_ACTIVE_JOB_ID,
    payload: jobId,
  }
}
