import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import styled from 'styled-components'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share'
import { HiLink } from 'react-icons/hi'
import useCopy from 'use-copy'
import { useToasts } from 'react-toast-notifications'

import Modal from '../../../../components/dashboard/Modal'

import axiosInstance from '../../../../services/config/AxiosIntance'

import { setShareJobPostModal } from '../../../../store/actions/modalActions'

import { baseUrl } from '../../../../constant/constant'

const ShareJobPostModal = () => {
  const { jobId } = useSelector((state) => state.jobPosting)
  const { shareJobPostModal } = useSelector((state) => state.modal)

  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const [token, setToken] = useState(null)

  const [, copy, setCopied] = useCopy(`${baseUrl}/shared-job/${token}`)

  const handleClose = () => {
    dispatch(setShareJobPostModal(false))
  }

  const handleCopyLink = () => {
    copy()
    addToast('Copied shareable link', {
      appearance: 'info',
    })

    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  useEffect(() => {
    ;(async () => {
      try {
        if (jobId) {
          const response = await axiosInstance.put(
            `${baseUrl}/job/validate-token/${jobId}`
          )
          setToken(response?.data?.result?.token)
        }
      } catch (err) {
        console.error(err)
      }
    })()
  }, [jobId])

  return (
    shareJobPostModal && (
      <Modal
        title="Share Job Posting"
        btnFunc={handleClose}
        onClose={handleClose}
        btnTitle="Close"
        noCancel
      >
        <StyledContainer>
          <a title="Facebook">
            <FacebookShareButton
              url={`${baseUrl}/shared-job/${token}`}
              hashtag="#hopla"
              className="share__btn"
            >
              <FacebookIcon size={50} round />
            </FacebookShareButton>
          </a>

          <a title="Linkedin">
            <LinkedinShareButton
              url={`${baseUrl}/shared-job/${token}`}
              className="share__btn"
            >
              <LinkedinIcon size={50} round />
            </LinkedinShareButton>
          </a>

          <a title="Copy to clipboard">
            <HiLink
              className="share__btn link__btn"
              size={50}
              onClick={handleCopyLink}
            ></HiLink>
          </a>
        </StyledContainer>
      </Modal>
    )
  )
}

export default ShareJobPostModal

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .share__btn {
    margin: 0 0.5rem;
  }

  .link__btn {
    border-radius: 50%;
    background: black;
    color: white;
    cursor: pointer;
  }
`
