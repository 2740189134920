import React, { useState } from 'react'

import styled from 'styled-components'

import { FiPlus } from 'react-icons/fi'
import Flex from '../layouts/Flex'
import SaveApplicant from './TalentPool/SaveApplicant'

const FilterButton = () => {
  const [showAddApplicantModal, setShowApplicantModal] = useState(false)

  return (
    <Flex items="center" gap="0.5rem">
      <FilterButtonStyle
        onClick={() => setShowApplicantModal(!showAddApplicantModal)}
      >
        <FiPlus />
        <span>Add Applicant</span>
      </FilterButtonStyle>
      {showAddApplicantModal && (
        <SaveApplicant onClose={() => setShowApplicantModal(false)} />
      )}
    </Flex>
  )
}

export default FilterButton

export const FilterButtonStyle = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0 0.65rem;
  height: 2rem;
  background: #ffffff;
  cursor: pointer;
  border: 0.5px solid #aeb3c9;
  font-size: 0.75rem;
  gap: 0.5rem;

  span {
    white-space: nowrap;
  }
`
