import React from 'react'

import styled from 'styled-components'

import Flex from '../../../../components/layouts/Flex'

const NoData = () => {
  return (
    <Flex
      width="100%"
      height="90%"
      bg="#f3f6ff"
      items="center"
      justify="center"
    >
      <H1>No data found</H1>
    </Flex>
  )
}

const H1 = styled.h1`
  font-weight: 300;
`

export default NoData
