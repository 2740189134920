import React from 'react'

import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch } from 'react-redux'

import TalentCardPopList from '../../../../components/dashboard/TalentPool/TalentCardPopList'
import TalentCardPopout from '../../../../components/dashboard/TalentPool/TalentCardPopout'

import useFeatureFlag from '../../../../hooks/useFeatureFlag'

import { addList, setModal } from '../../../../store/actions/listsActions'

const ListOptions = ({ setOpenOption, list }) => {
  const dispatch = useDispatch()

  const { activeFlag } = useFeatureFlag(
    'FF_RECRUITER_REMOVE_DELETE_LIST_FUNCTION'
  )

  function handleUpdateList() {
    dispatch(setModal('updateList'))
    dispatch(addList(list))
  }

  function handleDelete() {
    dispatch(setModal('deleteList'))
    dispatch(addList(list))
  }

  function handleActivities() {
    dispatch(setModal('listActivities'))
    dispatch(addList(list))
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setOpenOption(false)
      }}
    >
      <TalentCardPopout maxW="175px" width="175px" top="4%" right="10%">
        <TalentCardPopList onClick={handleUpdateList} text="Edit List" />
        <TalentCardPopList
          onClick={handleActivities}
          text="View List Activity"
        />
        {!activeFlag && (
          <TalentCardPopList onClick={handleDelete} text="Delete List" />
        )}
      </TalentCardPopout>
    </OutsideClickHandler>
  )
}

export default ListOptions
