import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_CERTIFICATION } from '../../../store/types/jobPosting'
import DashboardInput from '../DashboardInput'

const Certification = ({ readOnly }) => {
  const dispatch = useDispatch()
  const { certification } = useSelector((state) => state.jobPosting)
  return (
    <DashboardInput
      readOnly={readOnly}
      label="License/Certification"
      placeholder=""
      name="certification"
      type="text"
      height="2.5rem"
      value={certification}
      handleChange={(e) =>
        dispatch({ type: SET_CERTIFICATION, payload: e.target.value })
      }
    />
  )
}

export default Certification
