import emailTemplateTypes from '../types/emailTemplates'

export const setModal = (modalType) => {
  return { type: emailTemplateTypes.SET_MODAL, payload: modalType }
}

export const getReplacedText = (text) => {
  return { type: emailTemplateTypes.GET_REPLACED_TEXT, payload: text }
}

export const setPlaceholder = (placeholder) => {
  return { type: emailTemplateTypes.SET_PLACEHOLDER, payload: placeholder }
}

export const addEmailTemplates = (emailTemplates) => {
  return {
    type: emailTemplateTypes.ADD_EMAIL_TEMPLATES,
    payload: emailTemplates,
  }
}

export const addEmailTemplate = (emailTemplate) => {
  return { type: emailTemplateTypes.ADD_EMAIL_TEMPLATE, payload: emailTemplate }
}

export const showEmailTemplates = (emailTemplates) => {
  return {
    type: emailTemplateTypes.SHOW_EMAIL_TEMPLATES,
    payload: emailTemplates,
  }
}

export const setSelectedEmailTemplate = (emailTemplate) => {
  return {
    type: emailTemplateTypes.SET_SELECTED_EMAIL_TEMPLATE,
    payload: emailTemplate,
  }
}

export const setPaginationFooter = (footer) => {
  return { type: emailTemplateTypes.SET_PAGINATION_FOOTER, payload: footer }
}

export const dataChanged = () => {
  return { type: emailTemplateTypes.DATA_CHANGED }
}
