import Papa from 'papaparse'

class CSVDownloader {
  /**
   * Download Object array to CSV
   * @data object array
   * @fileName string
   */
  static downloadUnparsedCSV(data, filename = 'data.csv') {
    const csv = Papa.unparse(data)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

export default CSVDownloader
