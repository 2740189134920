/* eslint-disable camelcase */
import React, { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment'
import styled from 'styled-components'

import { StyledNotes } from './Notes'

import useFetch from '../../../hooks/useFetch'

import { P } from '../../../style/Typography.style'

const Activities = ({ id }) => {
  const { data, loading, refetch } = useFetch(`/applicant/${id}/activities`)

  const { activities } = useSelector((state) => state.modal)

  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id])

  if (loading) return <P>Loading...</P>

  return (
    <StyledNotes activities={activities}>
      {data && data.resp.length > 0 ? (
        data?.resp?.map(({ activities, id, created_at }) => (
          <StyledActivity
            last={data?.resp[data?.resp?.length - 1].id === id}
            key={id}
          >
            <P size="0.75rem" marginBottom="0.35rem">
              {activities}
            </P>
            <P size="0.625rem" color="#676E92">
              {moment(created_at).format('MMMM Do, YYYY | h:mm A')} {' GMT '}
              {moment(created_at).format('ZZ')}
            </P>
          </StyledActivity>
        ))
      ) : (
        <P>No activity to display at the moment.</P>
      )}
    </StyledNotes>
  )
}

export default memo(Activities)

export const StyledActivity = styled.div`
  width: 100%;
  padding: 0 1rem;
  padding-bottom: 0.5rem;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    border: 1px solid #c4d0e9;
    background: #fff;
  }

  &::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    height: calc(100% - 4px);
    width: 1px;
    background: #c4d0e9;
    display: ${({ last }) => (last ? 'none' : 'block')};
  }
`
