import React from 'react'

import styled from 'styled-components'

const ModalFooter = ({ loading, onClose, handleUpdate, readOnly }) => {
  return (
    <StyledModalFooter>
      <button onClick={() => onClose()} className="outline">
        Cancel
      </button>
      {!readOnly && <button onClick={() => handleUpdate()} className="primary">
        {loading ? 'Loading...' : 'Update'}
      </button>}
    </StyledModalFooter>
  )
}

export default ModalFooter

const StyledModalFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;

  button {
    padding: 0.5rem 1.25rem;
    background: none;
    outline: none;
    border: none;
    border-radius: 100px;
    font-size: 0.75rem;
    font-weight: 500;

    &.outline {
      color: #858aa0;

      &:hover {
        background: #191919;
        color: #fff;
      }
    }

    &.primary {
      color: #fff;
      background: #191919;

      &:hover {
        background: #31374f;
        color: #fff;
      }
    }
  }
`
