import React, { useEffect } from 'react'
// import DatePicker from 'react-datepicker'
// import { FiCalendar, FiClock } from 'react-icons/fi'
// import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'

// import { FilterCheckBox } from '../../../../components/dashboard/FilterInput'
import MainModal from '../../../../components/dashboard/modal/MainModal'
// import { P } from '../../../../style/Typography.style'
import Flex from '../../../../components/layouts/Flex'
import { SingleDropdownSelect } from '../../../../components/dashboard/TalentPool/DropdownSelect'

import { setModal, selectRecruiter, showApplicants } from '../../../../store/actions/listsActions'
import { setRecruiters } from '../../../../store/actions/recruitersActions'
import { updateTalents } from '../../../../store/actions/talentActions'

import useFetch from '../../../../hooks/useFetch'
import useMutation from '../../../../hooks/useMutation'

const AssignSkillTest = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { applicant, applicants, recruiter, moveList, selectedList } = useSelector((state) => state.lists)
  const { talents, talent } = useSelector((state) => state.talents)
  const { recruiters } = useSelector((state) => state.requisitions)

  const { data: recruitersData } = useFetch('/recruiters')

  const { data, loading, success, error, handleRequest } = useMutation()

  function handleSelectRecruiter(e) {
    const selectedRecruiter = {
      id: e.target.value,
    }
    dispatch(selectRecruiter(selectedRecruiter))
  }

  const handleAddTwoDays = () => {
    const newDate = new Date(); 
    let dayOfWeek = newDate.getDay();

    while (dayOfWeek === 0 || dayOfWeek === 6) {
      newDate.setDate(newDate.getDate() + 1);
      dayOfWeek = newDate.getDay();
    }

    newDate.setDate(newDate.getDate() + 2);
    dayOfWeek = newDate.getDay();

    while (dayOfWeek === 0 || dayOfWeek === 6) {
      newDate.setDate(newDate.getDate() + 1);
      dayOfWeek = newDate.getDay();
    }
  };


  // function handleToggleTimeField() {
  //   setTimeField(!timeField)
  // }

  function handleSubmitInterview() {
    if (!recruiter.id) {
      return addToast('Recruiter field is required.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    handleRequest('/schedule-interview', {
      applicant_id: applicant.applicantId.toString(),
      role: selectedList.list_name,
      list_id: applicant.list_id.toString(),
      recruiter_id: recruiter.id,
      stages: 'Skill_assesment'
    })
  }

  useEffect(() => {
    handleAddTwoDays();
  }, [])

  // update recruiter
  useEffect(() => {
    if (recruitersData) {
      dispatch(setRecruiters(recruitersData?.resp))
    }
  }, [recruitersData])


  useEffect(() => {
    if (data) {
      const filteredApplicants = applicants.filter(
        (user) => user.applicant_id !== applicant.applicantId
      )

      const filteredTalents = talents.filter(
        (talent) => talent.id !== applicant.applicantId
      )

      const selectedTalent = talent
      selectedTalent.listName = moveList.list_name

      const changedList = selectedList
      const updatedList = moveList

      changedList.numbersOfApplicantInList -= 1
      updatedList.numbersOfApplicantInList += 1

      dispatch(updateTalents([selectedTalent, ...filteredTalents]))
      dispatch(showApplicants(filteredApplicants))

      dispatch(setModal(''))
      addToast(
        `Applicant ${applicant.applicantName} has been scheduled for a skill assessment.`,
        {
          appearance: 'success',
          autoDismiss: true,
        }
      )
    }
  }, [success])

  //   if request is unsuccessful
  useEffect(() => {
    if (error) {
      if (
        error?.response?.data?.message?.trim() ===
        `Applicant with id: ${applicant?.applicantId} has been schedulled for an interview`
      ) {
        dispatch(setModal(''))
        addToast(
          `Applicant ${applicant.applicantName} has been scheduled for an interview.`,
          {
            appearance: 'success',
            autoDismiss: true,
          }
        )
      } else {
        addToast(error?.response.data?.message || 'Something went wrong', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    }
  }, [error])

  return (
    <>
      <MainModal
        title={`Invite ${applicant.applicantName} for Skill Assessment`}
        buttonText="Add"
        loading={loading}
        onClick={handleSubmitInterview}
      >
        {/* <DateTimeWrapper>
          <P size="0.85rem">Due Date</P>
          <FiCalendar className="datetime-icon" />
          <DatePicker
            className="date-picker"
            selected={interviewDate}
            onChange={(date) => {
              setInterviewDate(date)
            }}
          />
        </DateTimeWrapper>

        <Flex direction="row" gap="0.4rem" margin="1rem 0 0 0">
          <FilterCheckBox
            checked={timeField}
            size="small"
            label="Enable Due Time"
            onChange={handleToggleTimeField}
          />
        </Flex>
        {timeField ? <DateTimeWrapper className="mt">
          <P size="0.85rem">Due Time</P>
          <FiClock className="datetime-icon" />
          <DatePicker
            className="date-picker"
            selected={interviewTime}
            onChange={(date) => setInterviewTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </DateTimeWrapper> : null} */}


        <Flex direction="column" gap="0.4rem" margin="1rem 0 0 0">
          <SingleDropdownSelect
            onChange={(e) => {
              handleSelectRecruiter(e)
            }}
            title="Recruiter"
          >
            <option>Choose an option</option>
            {recruiters?.map((recruiter) => {
              if (recruiter?.Recruiter?.name) {
                return (
                  <option
                    key={recruiter?.Recruiter?.id}
                    value={recruiter?.Recruiter?.id}
                    data-recruiterName={recruiter?.Recruiter?.name}
                  >
                    {recruiter?.Recruiter?.name}
                  </option>
                )
              }
              return null
            })}
          </SingleDropdownSelect>
        </Flex>
      </MainModal>
    </>
  )
}

export default AssignSkillTest

// const DateTimeWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 0.5rem;
//   position: relative;

//   &.mt {
//     margin-top: 1rem;
//   }

//   .datetime-icon {
//     position: absolute;
//     bottom: 0.75rem;
//     left: 0.75rem;
//     font-size: 1rem;
//     color: #aeb3c9;
//     z-index: 1;
//   }

//   .date-picker {
//     width: 100%;
//     height: 2.5rem;
//     border: 1px solid #aeb3c9;
//     padding: 0 2rem;
//     padding-left: 2.5rem;
//     font-size: 0.75rem;
//   }
// `
