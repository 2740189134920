import React from 'react'
import { useToasts } from 'react-toast-notifications'

import styled from 'styled-components'
import useCopy from 'use-copy'

const CopyToClipboard = ({ text, message = '' }) => {
  const [copied, copy, setCopied] = useCopy(text)
  const { addToast } = useToasts()

  const copyText = () => {
    copy()

    const copySuccess = message || `Copied ${text}`

    addToast(copySuccess, { appearance: 'info' })

    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  return (
    <StyledCopy className={copied && 'active'} onClick={copyText}>
      COPY
    </StyledCopy>
  )
}

export default CopyToClipboard

const StyledCopy = styled.button`
  font-size: 0.75rem;
  font-weight: 500;
  color: #1877f2;
  padding: 0.25rem;
  border-radius: 0.25rem;
  border: none;
  outline: none;
  background: none;

  &:hover,
  &.active {
    background: rgba(24, 119, 242, 0.2);
  }
`
