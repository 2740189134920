import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import lock from '../assets/icons/lock.png'
import { Button } from '../components/Button'
import { PasswordInput } from '../components/Input'
import AuthLayout from '../layouts/AuthLayout'
import { H2, P } from '../style/Typography.style'
import { LoginContainer } from './Login'

import { updatePassword } from '../store/actions/authActions'
import { useNavigate } from 'react-router'

import { useToasts } from 'react-toast-notifications'
import { clearErrors } from '../store/actions/errorActions'
import Error from '../components/Error'

import propTypes from 'prop-types'

const UpdatePassword = ({
  updatePassword,
  isLoading,
  error,
  userId,
  isUserVerified,
  clearErrors,
}) => {
  const navigate = useNavigate()

  const { addToast } = useToasts()

  const [password, setPassword] = useState('')
  const [cPassword, setCPassword] = useState('')
  const [errors, setError] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()

    // check if password and confirm password are the same
    if (password !== cPassword) {
      setError("Passwords don't match")
    } else {
      if (
        password.length > 7 &&
        password.match('^(?=.*[0-9])') &&
        password.match('^(?=.*[0-9])') &&
        password.match('^(?=.*[a-z])(?=.*[A-Z])')
      ) {
        updatePassword(password, userId)
      } else {
        setError(
          'Password must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 number'
        )
      }
    }
  }

  // check if user is verified
  useEffect(() => {
    if (isUserVerified) {
      navigate('/')
    }
  }, [isUserVerified])

  // check if error is update password
  useEffect(() => {
    if (error.id === 'VERIFICATION_FAILED') {
      addToast(error.msg, { appearance: 'error', autoDismiss: true })
      clearErrors()
    }
  }, [error])

  return (
    <AuthLayout>
      <LoginContainer onSubmit={handleSubmit}>
        <div className="lockWrapper">
          <img className="lock" src={lock} alt="lock icon" />
        </div>
        <H2>Update your password</H2>

        {errors && <Error error={errors} />}

        <P style={{ marginBottom: '1.5rem', fontSize: '.75rem' }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        </P>

        <PasswordInput
          value={password}
          onChange={(value) => setPassword(value)}
          title="Password"
        />

        <PasswordInput
          value={cPassword}
          onChange={(value) => setCPassword(value)}
          title="Confirm Password"
        />

        <Button>{isLoading ? 'Loading...' : 'Update Password'}</Button>
      </LoginContainer>
    </AuthLayout>
  )
}

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  error: state.error,
  isLoading: state.auth.isLoading,
  verificationCode: state.auth.verificationCode,
  isUserVerified: state.auth.isUserVerified,
})

export default connect(mapStateToProps, { updatePassword, clearErrors })(
  UpdatePassword
)

// eslint-disable-next-line react/no-typos
UpdatePassword.propTypes = {
  userId: propTypes.string,
  isLoading: propTypes.bool,
  error: propTypes.object.isRequired,
  clearErrors: propTypes.func.isRequired,
  verificationCode: propTypes.string,
  isUserVerified: propTypes.bool,
  updatePassword: propTypes.func.isRequired,
}
