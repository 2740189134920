import React, { useState, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import Flex from '../../../../components/layouts/Flex'
import { H6 } from '../../../../style/Typography.style'
import { SingleDropdownSelect } from '../../../../components/dashboard/TalentPool/DropdownSelect'

import { setModal } from '../../../../store/actions/listsActions'

import {
  setBulkAction,
  filterTalents,
} from '../../../../store/actions/talentActions'

import useMutation from '../../../../hooks/useMutation'
import useFetch from '../../../../hooks/useFetch'

const BulkAddToVacancy = () => {
  const [jobId, setJobId] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const checkedItems = useSelector(
    (state) => state.talents.bulkItems.checkedItems
  )
  const uncheckedItems = useSelector(
    (state) => state.talents.bulkItems.uncheckedItems
  )
  const selectedAll = useSelector(
    (state) => state.talents.bulkItems.selectedAll
  )
  const rowCount = useSelector((state) => state.talents.pagination.rowCount)

  const bulkItems = useSelector((state) => state.talents.bulkItems)

  const { data: jobsData } = useFetch(`/jobs/dropdown?status=active`)

  const jobsResult = jobsData?.data

  const { data, loading, handleRequest, success, error } = useMutation()

  const handleSelectJob = (e) => {
    const selectedIndex = e.target.selectedIndex
    const selectedOption = e.target.options[selectedIndex]
    const label = selectedOption.textContent
    setJobTitle(label)
    setJobId(e.target.value)
  }

  const handleSubmit = () => {
    if (!jobId) {
      return addToast('Vacancy field is required.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    const payload = {
      bulkItems,
      job: {
        id: jobId.toString(),
        role: jobTitle,
      },
    }
    handleRequest('/screening/add-applicants', payload, 'POST')
  }

  const handleClose = () => {
    dispatch(setModal(''))
    dispatch(setBulkAction(''))
  }

  const getTotalSelected = () => {
    const totalCheckedItems = parseInt(checkedItems.length)
    const totalUncheckedItems = parseInt(uncheckedItems.length)
    const totalItems = parseInt(rowCount)

    if (selectedAll) {
      return totalItems - totalUncheckedItems
    } else {
      return totalCheckedItems
    }
  }

  // check if request was successful.
  useEffect(() => {
    if (data) {
      addToast(data.message, {
        appearance: 'success',
        autoDismiss: true,
      })
      dispatch(filterTalents())
      dispatch(setBulkAction(''))
      dispatch(setModal(''))
    }
  }, [success])

  useEffect(() => {
    if (error) {
      addToast(
        error?.response?.data?.message ||
          error?.response?.message ||
          'Something went wrong',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    }
  }, [error])

  return (
    <>
      <MainModal
        onClose={handleClose}
        title={`Add Applicants to Vacancy`}
        buttonText="Submit"
        loading={loading}
        onClick={handleSubmit}
      >
        <H6>Selected Applicants: {getTotalSelected().toString()}</H6>
        <Flex direction="column" gap="0.4rem">
          <SingleDropdownSelect
            onChange={(e) => {
              handleSelectJob(e)
            }}
            title="Vacancy"
          >
            <option>Choose an option</option>
            {jobsResult?.map((job) => {
              if (job.status === 'active') {
                return (
                  <option key={job?.id} value={job?.id}>
                    {job?.title}
                  </option>
                )
              } else {
                return null
              }
            })}
          </SingleDropdownSelect>
        </Flex>
      </MainModal>
    </>
  )
}

export default BulkAddToVacancy
