import moment from 'moment'

import axiosInstance from '../../services/config/AxiosIntance'

import userTypes from '../types/userTypes'

export const setModal = (modalType) => {
  return { type: userTypes.SET_MODAL, payload: modalType }
}
export const setErrorMessage = (message) => {
  return { type: userTypes.SET_ERROR, payload: message }
}
export const insertRecord = (formData) => async (dispatch) => {
  dispatch({ type: userTypes.SAVE_DATA_LOADING })
  try {
    const result = await axiosInstance.post('/user-type/create', formData)
    const data = result.data.data

    const successMessage = result.data.message
    dispatch({
      type: userTypes.ADD_USER_TYPE_DATA_SUCCESS,
      payload: { data: data, message: successMessage },
    })
  } catch (error) {
    dispatch({ type: userTypes.SAVE_USER_TYPE_DATA_ERROR, payload: error })
  }
}
export const updateRecord = (id, formData) => async (dispatch, getState) => {
  const selectedRecord = getState().users.selectedRecord
  dispatch({ type: userTypes.SAVE_DATA_LOADING })
  try {
    const result = await axiosInstance.put(`/user-type/${id}/update`, formData)
    const data = formData
    data.id = id
    data.userType = formData.user_type
    data.created_at = selectedRecord.created_at
    data.updated_at = moment()
    const successMessage = result.data.message
    dispatch({
      type: userTypes.UPDATE_DATA_SUCCESS,
      payload: { data: data, id: id, message: successMessage },
    })
  } catch (error) {
    dispatch({ type: userTypes.SAVE_USER_TYPE_DATA_ERROR, payload: error })
  }
}

export const fetchList =
  (url = '/user-types/paged') =>
  async (dispatch) => {
    dispatch({ type: userTypes.FETCH_LIST_LOADING })
    try {
      const { data } = await axiosInstance.get(url)
      let result
      if (data?.resp) {
        result = data.resp
      } else {
        result = []
      }
      dispatch({ type: userTypes.FETCH_LIST_SUCCESS, payload: result })
    } catch (error) {
      dispatch({ type: userTypes.FETCH_LIST_ERROR, payload: error })
    }
  }
export const fetchRecord = (value) => async (dispatch) => {
  dispatch({ type: userTypes.FETCH_RECORD_LOADING })
  try {
    let result
    if (value.id) {
      await axiosInstance.get(`/user-type?id=${value.id}`)
      result = value
    } else {
      result = {}
    }
    dispatch({ type: userTypes.FETCH_RECORD_SUCCESS, payload: result })
  } catch (error) {
    dispatch({ type: userTypes.FETCH_RECORD_ERROR, payload: error })
  }
}
export const setPagination = (options) => {
  return { type: userTypes.SET_PAGINATION_OPTIONS, payload: options }
}
export const setQueryParams = (params) => {
  return { type: userTypes.SET_QUERY_PARAMS, payload: params }
}
