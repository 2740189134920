import React from 'react'
import { useNavigate } from 'react-router'

import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch } from 'react-redux'

import TalentCardPopList from '../../../../components/dashboard/TalentPool/TalentCardPopList'
import TalentCardPopout from '../../../../components/dashboard/TalentPool/TalentCardPopout'

import {
  setModal,
  fetchRecord,
} from '../../../../store/actions/userTypeActions'

const ActionsMenu = ({ setShowMenu, value }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleOutsideClick = () => setShowMenu(false)

  const handleUpdateModuleModal = (e) => {
    e.stopPropagation()
    dispatch(fetchRecord(value))
    dispatch(setModal('updateUserType'))
  }

  const handleViewModules = () => {
    navigate(`/user-types/${value.id}/modules`)
    dispatch(fetchRecord(value))
    dispatch(setModal(''))
  }

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <TalentCardPopout maxW="199px" width="199px" top="0" left="55%">
        <TalentCardPopList
          onClick={handleUpdateModuleModal}
          text="Edit User Type"
        />
        <TalentCardPopList onClick={handleViewModules} text="View Modules" />
      </TalentCardPopout>
    </OutsideClickHandler>
  )
}

export default ActionsMenu
