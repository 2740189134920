import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Joi from 'joi'

import Flex from '../../../../components/layouts/Flex'
import MainModal from '../../../../components/dashboard/modal/MainModal'
import { SingleDropdownSelect } from '../../../../components/dashboard/TalentPool/DropdownSelect'
import TagInput from '../../../../components/dashboard/controls/TagInput'
import DashboardInput from '../../../../components/dashboard/DashboardInput'
import DashboardPhoneInput from '../../../../components/dashboard/DashboardPhoneInput'
import { FilterCheckBox } from '../../../../components/dashboard/FilterInput'

import useFetch from '../../../../hooks/useFetch'
import {
  setErrorMessage,
  insertRecord,
  updateRecord,
} from '../../../../store/actions/userActions'

import { capitalize } from '../../../../utils/capitalize'
import { separateFullName } from '../../../../utils/separateFullName'
import { validateEmail } from '../../../../utils/validations'
import { isValidJson } from '../../../../utils/utils'

const SaveUser = () => {
  const dispatch = useDispatch()

  const { loading, selectedRecord } = useSelector((state) => state.users)

  const { data: userTypeData } = useFetch('/user-types')

  const [mode, setMode] = useState('create')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [country, setCountry] = useState('')
  const [city, setCity] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [userType, setUserType] = useState('')
  const [calendarUrls, setCalendarUrls] = useState([])
  const [isRecruiterAssistant, setIsRecruiterAssistant] = useState(false)

  useEffect(() => {
    if (Object.keys(selectedRecord).length) {
      setMode('update')
      const name = separateFullName(
        selectedRecord.adminName ||
          selectedRecord.recruiterName ||
          selectedRecord.applicantName ||
          selectedRecord.interviewerName ||
          selectedRecord.cdmName
      )

      setFirstName(name?.firstName)
      setLastName(name?.lastName)
      setUserType(selectedRecord.userType)
      setIsRecruiterAssistant(selectedRecord.recruiter_assistant)
      setEmail(selectedRecord.email)
      setCountry(selectedRecord.country)
      setCity(selectedRecord.city)
      setPhoneNumber(selectedRecord.phone_number)

      if (isValidJson(selectedRecord?.calendar_url)) {
        setCalendarUrls(JSON.parse(selectedRecord.calendar_url))
      } else {
        setCalendarUrls([selectedRecord.calendar_url])
      }
    }
  }, [selectedRecord])

  function handleSaveUser() {
    // For some reason, calendarUrls becomes [null] and the only reliable way to check is to use stringify()
    if (JSON.stringify(calendarUrls) !== JSON.stringify([null])) {
      const joiSchema = Joi.object({
        calenderUrl: Joi.array().items(
          Joi.string().uri({ scheme: ['http', 'https'] })
        ),
      })
      const validateRecruiterCalendly = joiSchema.validate({
        calenderUrl: calendarUrls,
      })

      if (validateRecruiterCalendly?.error?.message) {
        return dispatch(setErrorMessage('Invalid Recruiter Calendly Urls'))
      }
    }

    if (firstName.trim() === '') {
      return dispatch(setErrorMessage('First Name is required.'))
    }
    if (lastName.trim() === '') {
      return dispatch(setErrorMessage('Last Name is required.'))
    }
    if (userType.trim() === '') {
      return dispatch(setErrorMessage('User Type is required.'))
    }
    if (email.trim() === '') {
      return dispatch(setErrorMessage('Email Address is required.'))
    }
    if (!validateEmail(email.trim())) {
      return dispatch(setErrorMessage('Invalid Email Address.'))
    }

    const request = {
      first_name: firstName,
      last_name: lastName,
      email,
      country,
      city,
      phone_number: phoneNumber,
      user_type: userType,
      is_recruiter_assistant: isRecruiterAssistant?.toString(),
      calendar_url: calendarUrls,
    }
    if (selectedRecord?.id) {
      const currentUserId = Number(localStorage.getItem('id'))

      if (selectedRecord?.id === currentUserId) {
        const currentUser = JSON.parse(localStorage.getItem('user'))
        currentUser.user_type = userType
        currentUser.name = firstName + ' ' + lastName
        const updatedUser = JSON.stringify(currentUser)
        localStorage.setItem('user', updatedUser)
      }
      dispatch(updateRecord(selectedRecord?.id, request))
      setTimeout(function () {
        window.location.reload()
      }, 2000)
    } else {
      dispatch(insertRecord(request))
    }
  }

  return (
    <MainModal
      title={mode === 'create' ? 'Add User' : 'Update User'}
      onClick={handleSaveUser}
      buttonText="Submit"
      loading={loading}
      size="large"
    >
      <Flex gap="0.4rem">
        <DashboardInput
          name="firstName"
          label="First Name *"
          placeholder=""
          type="text"
          height="2.5rem"
          value={firstName}
          handleChange={(e) => setFirstName(e.target.value)}
        />
      </Flex>
      <Flex gap="0.4rem">
        <DashboardInput
          name="lastName"
          label="Last Name *"
          placeholder=""
          type="text"
          height="2.5rem"
          value={lastName}
          handleChange={(e) => setLastName(e.target.value)}
        />
      </Flex>
      <Flex gap="0.4rem">
        <SingleDropdownSelect
          onChange={(e) => {
            setUserType(e.target.value)
          }}
          value={userType}
          title="User Type *"
        >
          <option>Choose an option</option>
          {userTypeData?.resp?.map((userType) => {
            if (userType?.id && userType?.id !== 2 && userType?.id !== 6) {
              return (
                <option key={userType?.id} value={userType?.user_type}>
                  {capitalize(
                    userType?.user_type === 'company_admin'
                      ? 'Company Admin'
                      : userType?.user_type
                  )}
                </option>
              )
            }
            return null
          })}
        </SingleDropdownSelect>
      </Flex>
      {userType === 'recruiter' && (
        <Flex>
          <FilterCheckBox
            checked={isRecruiterAssistant}
            size="small"
            top="-.05rem"
            right="-1.75rem"
            label="Recruiter Assistant"
            name="recruiterAssistant"
            onChange={() => setIsRecruiterAssistant(!isRecruiterAssistant)}
          />
        </Flex>
      )}
      <Flex gap="0.4rem" margin={userType === 'recruiter' && '1rem 0 0 0'}>
        <DashboardInput
          name="email"
          label="Email Address *"
          placeholder=""
          type="text"
          height="2.5rem"
          value={email}
          handleChange={(e) => setEmail(e.target.value)}
        />
      </Flex>
      <Flex gap="0.4rem">
        <DashboardInput
          name="country"
          label="Country"
          placeholder=""
          type="text"
          height="2.5rem"
          value={country}
          handleChange={(e) => setCountry(e.target.value)}
        />
      </Flex>
      <Flex gap="0.4rem">
        <DashboardInput
          name="city"
          label="City"
          placeholder=""
          type="text"
          height="2.5rem"
          value={city}
          handleChange={(e) => setCity(e.target.value)}
        />
      </Flex>
      <Flex gap="0.4rem">
        <DashboardPhoneInput
          name="phoneNumber"
          label="Contact Number"
          placeholder=""
          type="text"
          height="2.5rem"
          value={phoneNumber}
          handleChange={(e) => setPhoneNumber(e)}
        />
      </Flex>
      {userType === 'recruiter' || userType === 'coordinator' ? (
        <Flex gap="0.4rem">
          <TagInput
            label="Calendly Urls"
            setTags={setCalendarUrls}
            tags={calendarUrls}
          />
        </Flex>
      ) : null}
    </MainModal>
  )
}

export default SaveUser
