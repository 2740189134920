import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import DashboardButton from '../../../../components/dashboard/DashboardButton'
import FilterInput from '../../../../components/dashboard/FilterInput'

import Flex from '../../../../components/layouts/Flex'
import axiosInstance from '../../../../services/config/AxiosIntance'
import {
  addLists,
  showApplicants,
  addList,
  setPagination,
} from '../../../../store/actions/listsActions'

const HeaderFilter = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const [listFilter, setListFilter] = useState('')
  const [applicantFilter, setApplicantFilter] = useState('')
  const [loading, setLoading] = useState(false)

  // function toggleCreateList() {
  //   dispatch(setModal('createList'))
  // }

  async function handleFilter(e) {
    e.preventDefault()

    setLoading(true)
    if (applicantFilter.trim() === '') {
      try {
        const { data } = await axiosInstance.get(
          `/list-names?searchName=${listFilter}`
        )
        dispatch(addLists(data.resp))

        if (applicantFilter.trim() === '') {
          dispatch(addList(data.resp[0]))
        }
        setLoading(false)
      } catch (err) {
        addToast.apply(
          err?.response?.data?.message || 'Something went wrong.',
          {
            appearance: 'error',
            autoDismiss: true,
          }
        )
        setLoading(false)
      }
    } else {
      try {
        const { data } = await axiosInstance.get(
          `/applicant-list?searchName=${applicantFilter}`
        )
        if (data?.resp?.applicantList) {
          dispatch(showApplicants(data.resp.applicantList))
        } else {
          dispatch(showApplicants([]))
        }
        dispatch(setPagination(data.resp.pagination))
        setLoading(false)
      } catch (err) {
        addToast(err?.response?.data?.message || 'Something went wrong.', {
          appearance: 'error',
          autoDismiss: true,
        })
        setLoading(false)
      }
    }
  }

  return (
    <Flex
      width="100%"
      margin="1.5rem 0 0 0"
      items="center"
      justify="space-between"
    >
      <Flex
        width="100%"
        as="form"
        items="center"
        justify="flex-start"
        gap="1rem"
        onSubmit={handleFilter}
      >
        <FilterInput
          whiteSpace="nowrap"
          maxW="300px"
          onChange={(e) => setListFilter(e.target.value)}
          handleClear={() => setListFilter('')}
          value={listFilter}
          title="Search Lists"
          placeholder="List Name, Tags"
        />
        <FilterInput
          maxW="300px"
          whiteSpace="nowrap"
          onChange={(e) => setApplicantFilter(e.target.value)}
          handleClear={() => setApplicantFilter('')}
          value={applicantFilter}
          title="Search Applicant"
          placeholder="Name, Job Title"
        />
        <DashboardButton
          text={loading ? 'Loading...' : 'Search'}
          type="submit"
        />
      </Flex>
      {/* 
      <Flex width="180px" items="center" gap="0.5rem">
        <DashboardButton
          text="Create New List"
          type="button"
          notIcon
          externalIcon={<FiPlus className="icon" />}
          maxWidth="180px"
          onClick={toggleCreateList}
        />
      </Flex> */}
    </Flex>
  )
}

export default HeaderFilter
