const listTypes = {
  SET_MODAL: 'SET_MODAL',
  ADD_LISTS: 'ADD_LISTS',
  UPDATE_LISTS: 'UPDATE_LISTS',
  ADD_LIST: 'ADD_LIST',
  SELECT_APPLICANT: 'SELECT_APPLICANT',
  SELECT_RECRUITER: 'SELECT_RECRUITER',
  SHOW_APPLICANTS: 'SHOW_APPLICANTS',
  MOVE_LIST: 'MOVE_LIST',
  SELECTED_APPLICANT_LIST: 'SELECTED_APPLICANT_LIST',
  SET_PAGINATION: 'SET_PAGINATION',
}

export default listTypes
