import { connect } from 'react-redux'
import { Outlet } from 'react-router'
import Login from '../pages/Login'

const PrivateRoute = ({ token, children, ...rest }) => {
  return token ? <Outlet /> : <Login />
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
})

export default connect(mapStateToProps, {})(PrivateRoute)
