import React from 'react'

import styled from 'styled-components'

import DefaultTemplate from './template/DefaultTemplate'

const Preview = ({ downloadRef }) => {
  return (
    <StyledPreview>
      <DefaultTemplate downloadRef={downloadRef} />
    </StyledPreview>
  )
}

export default Preview

const StyledPreview = styled.div`
  width: 100%;
`
