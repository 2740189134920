import React, { memo, useState, useEffect, useMemo, useRef } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { Bar, getElementAtEvent } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
  Tooltip,
  Title,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { useToasts } from 'react-toast-notifications'

import Loader from '../../../../components/Loader'
import NoData from './NoData'
import DashboardItemTitle from './DashboardItemTitle'

import axiosInstance from '../../../../services/config/AxiosIntance'

import {
  setFilters,
  setTableFilters
} from '../../../../store/actions/dashboardActions'

ChartJS.register(CategoryScale, LinearScale, BarElement, Legend, Tooltip, Title)

const RecruitmentStageFunnelChart = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { filters } = useSelector((state) => state.dashboard)

  const [loading, setLoading] = useState(false)
  const [chartData, setChartData] = useState({
    labels: [''],
    datasets: [],
  })

  const chartRef = useRef()

  useEffect(() => {
    setLoading(true)
    const queryParams = new URLSearchParams(filters).toString()
    axiosInstance
      .get(`/dashboard/recruitmentStageFunnel?${queryParams}`)
      .then((res) => {
        setChartData((prev) => ({
          ...prev,
          datasets: res?.data?.result?.map((e) => ({
            stage_id: e.stage_id,
            label: e.stage,
            data: [e.interviews],
            backgroundColor: e.color_code,
            borderColor: 'black',
            borderWidth: 1,
            barPercentage: 1,
            tension: 1,
          })),
        }))
      })
      .catch((err) => {
        addToast(
          err?.response?.message ||
          err?.response?.data?.message ||
          'Internal Server Error',
          {
            appearance: 'error',
            autoDismiss: true,
          }
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }, [filters.dates, filters.job_id, filters.company_id])

  useEffect(() => {
    if (chartData.datasets.length) {
      const selectedItem = chartData.datasets.map((e) => {
        if (e.stage_id === filters.stage_id) {
          return {
            ...e,
            borderColor: 'red',
          }
        } else {
          return {
            ...e,
            borderColor: 'black',
          }
        }
      })
      setChartData((prev) => ({
        ...prev,
        datasets: selectedItem,
      }))
    }
  }, [filters.stage_id])

  const handleBarElementClicked = (e) => {
    const event = getElementAtEvent(chartRef.current, e)
    if (event.length) {
      const datasetIndex = event[0].datasetIndex
      const stage = chartData.datasets[datasetIndex]
      dispatch(setFilters({ stage_id: stage.stage_id }))
      dispatch(setTableFilters({ stage: stage.label }))
    }
  }

  const options = useMemo(
    () => ({
      type: 'bar',
      data: chartData,
      maintainAspectRatio: false,
      plugins: {
        title: {
          color: 'black',
          display: true,
          align: 'start',
          position: 'top',
          text: 'Recruitment Stage Funnel',
          font: {
            size: 24,
            family: "'Lato', 'sans-serif'",
            weight: 300,
          },
        },
        datalabels: {
          display: true,
          color: 'black',
          // anchor: 'end',
          align: 'end',
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
          },
        },
      },
      responsive: true,
    }),
    []
  )
  const component = loading ? (
    <Loader />
  ) : chartData.datasets.length ? (
    <Bar
      data={chartData}
      options={options}
      width={50}
      height={100}
      onClick={handleBarElementClicked}
      ref={chartRef}
      plugins={[ChartDataLabels]}
    />
  ) : (
    <>
      <DashboardItemTitle title="Recruitment Stage Funnel" />
      <NoData />
    </>
  )

  return component
}

export default memo(RecruitmentStageFunnelChart)
