import React from 'react'

import styled from 'styled-components'
import JobListingWrapper from './JobListingWrapper'

const JobGridLayout = ({ children }) => {
  return (
    <StyledJobGridLayout>
      <JobListingWrapper />

      <JobListingContentWrapper>{children}</JobListingContentWrapper>
    </StyledJobGridLayout>
  )
}

export default JobGridLayout

const StyledJobGridLayout = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 386px 1fr;
  gap: 2rem;
  margin-top: 1.5rem;
  height: calc(100vh - 12rem);
  position: relative;
`

const JobListingContentWrapper = styled.div`
  width: 100%;
  height: 92%;
`
