import React, { useState, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import { setModal } from '../../../../store/actions/listsActions'
import { setInterviews } from '../../../../store/actions/recruitersActions'

import useMutation from '../../../../hooks/useMutation'

import styled from 'styled-components'
import { P } from '../../../../style/Typography.style'

const ChangeInterviewLink = () => {
  const [interviewLink, setInterviewLink] = useState('')
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { selectedInterview, interviews } = useSelector(
    (state) => state.requisitions
  )

  const { data, loading, handleRequest, success, error } = useMutation()

  function handleSubmit() {
    if (!isValidUrl(interviewLink)) {
      return addToast('Invalid interview link.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    handleRequest(
      `/interviews/${selectedInterview.id}/update`,
      {
        applicant_id: selectedInterview.applicant_id.toString(),
        meet_url: interviewLink
      },
      'PUT'
    )
  }

  const isValidUrl = urlString => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
    return !!urlPattern.test(urlString);
  }

  // check if request was successful.
  useEffect(() => {
    if (data) {
      const filteredInterview = interviews.filter(
        (interview) => interview.id !== selectedInterview.id
      )

      const updatedInterview = selectedInterview
      updatedInterview.meet_url = interviewLink

      dispatch(setInterviews([updatedInterview, ...filteredInterview]))

      addToast('Interviewer updated successfully!', {
        appearance: 'success',
        autoDismiss: true,
      })

      dispatch(setModal(''))
    }
  }, [success])

  // check if request was unsuccessful.
  useEffect(() => {
    if (error) {
      addToast(
        error.message ||
        error.data.message ||
        error.response.data.message ||
        'something went wrong.',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    }
  }, [error])

  return (
    <>
      <MainModal
        title={`Change Interview Link`}
        buttonText="Submit"
        loading={loading}
        onClick={handleSubmit}
      >
        <TextBoxContainer>
          <P>
            Link
          </P>
          <input
            type="text"
            placeholder="Enter new interview link"
            onChange={(e) => setInterviewLink(e.target.value)}
          />
        </TextBoxContainer>

      </MainModal>
    </>
  )
}


export default ChangeInterviewLink

const TextBoxContainer = styled.div`
  margin-top: 2rem;

  input {
    width: 100%;
    height: 2.5rem;
    border: 1px solid #aeb3c9;
    margin-top: 0.875rem;
    outline: none;
    border-radius: none;
    padding: 0.75rem;
    font-size: 0.875rem;
    font-weight: 300;

    &:focus,
    &:hover {
      border: 1px solid #1a1a1a;
    }
  }
`

