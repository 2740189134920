import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { useToasts } from 'react-toast-notifications'

import Heading from '../../../components/dashboard/Heading'
import HeaderFilter from './components/HeaderFilter'
import UserListWrapper from './components/UserListWrapper'
import SaveUser from './components/SaveUser'
import PasswordReset from './components/PasswordReset'
import UpdateUserStatus from './components/UpdateUserStatus'

import { convertObjectToUrlParams } from '../../../utils/stateHelpers'

import { setModal, fetchList } from '../../../store/actions/userActions'

const Users = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { modal, notification, queryParams } = useSelector(
    (state) => state.users
  )

  useEffect(() => {
    if (notification.value === 'success') {
      addToast(notification?.message, {
        appearance: 'success',
        autoDismiss: true,
      })
    }
    if (notification.value === 'error') {
      addToast(notification?.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }, [notification])

  useEffect(() => {
    const currentRole = JSON.parse(localStorage.getItem('user')).user_type
    const allowedAccess = ['admin']
    if (!allowedAccess.includes(currentRole)) {
      window.location.replace('/')
    }
  }, [])

  useEffect(() => {
    dispatch(fetchList(`/users?${convertObjectToUrlParams(queryParams)}`))
  }, [queryParams])

  const handleCreateUser = () => {
    dispatch(setModal('createUser'))
  }

  const handlePWreset = () => {
    dispatch(setModal('pwReset'))
  }

  const handleFetchList = (filters, url) => {
    dispatch(fetchList(filters, url))
  }

  return (
    <>
      {/* User Lists heading */}
      <Heading title="User Lists" subTitle={moment().format('MMMM Do, YYYY')} />

      {/* User Lists filter, it contains the search filter */}
      <HeaderFilter
        createUser={handleCreateUser}
        fetchList={handleFetchList}
        pwReset={handlePWreset}
      />

      {/* Contains list of users */}
      <UserListWrapper fetchUserList={handleFetchList} />
      
      {/* Modal section */}
      {(modal === 'createUser' || modal === 'updateUser') && <SaveUser />}
      {modal === 'updateStatus' && <UpdateUserStatus />}
      {modal === 'pwReset' && <PasswordReset />}
    </>
  )
}

export default Users
