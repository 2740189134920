import React from 'react'

import styled from 'styled-components'

import Flex from '../../../../components/layouts/Flex'
import { H6 } from '../../../../style/Typography.style'

import pencil from '../../../../assets/icons/pencil.svg'

const EditSectionHeader = ({ title = '', onClick, margin }) => {
  return (
    <Flex margin={margin} width="100%" items="center" justify="space-between">
      <H6 weight="700" size="1rem" mb="0">
        {title}
      </H6>

      <StyledIcon onClick={onClick} src={pencil} alt="pencil icon" />
    </Flex>
  )
}

export default EditSectionHeader

const StyledIcon = styled.img`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
`
