import React from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

const Footer = () => {
  const date = new Date()
  const year = date.getFullYear()
  return (
    <StyledFooter>
      <Link to="/">{`Hopla.Online©${year}`}</Link>
      <Link to="/terms">Terms & Conditions</Link>
      <Link to="/privacy">privacy policy</Link>
    </StyledFooter>
  )
}

export default Footer

const StyledFooter = styled.footer`
  position: sticky;
  top: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 0.75rem;

  p,
  a {
    font-size: 0.625rem;
    color: #676e92;
    text-transform: capitalize;
  }

  a {
    text-decoration: none;
    margin-left: 3.125rem;
  }
`
