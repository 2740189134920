import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import ErrorModalHead from '../../../../components/dashboard/modal/ErrorModalHead'
import InfoModalHead from '../../../../components/dashboard/modal/InfoModalHead'

import { updateUserStatus } from '../../../../store/actions/userActions'

import { capitalize } from '../../../../utils/capitalize'

import { P } from '../../../../style/Typography.style'

const UpdateUserStatus = () => {
  const dispatch = useDispatch()

  const [currentStatus, setCurrentStatus] = useState('deactivate')
  const [message, setMessage] = useState('')

  const { selectedRecord, loading } = useSelector((state) => state.users)

  function handleUpdateUserStatus() {
    dispatch(updateUserStatus(selectedRecord.id))
  }

  useEffect(() => {
    if (selectedRecord.status === 1) {
      setCurrentStatus('deactivate')
      setMessage('This action cannot be undone.')
    } else if (selectedRecord.status === 0) {
      setCurrentStatus('activate')
      setMessage('Activating this user will grant them access to the system.')
    }
  }, [])

  const userName =
    selectedRecord.applicantName ||
    selectedRecord.adminName ||
    selectedRecord.recruiterName ||
    selectedRecord.pocName

  return (
    <MainModal
      buttonText={`${capitalize(currentStatus)} User`}
      loading={loading}
      onClick={handleUpdateUserStatus}
      error={selectedRecord.status === 1}
      info={selectedRecord.status === 0}
      header={
        selectedRecord.status === 0 ? (
          <InfoModalHead title={`${capitalize(currentStatus)} ${userName}`} />
        ) : (
          <ErrorModalHead title={`${capitalize(currentStatus)} ${userName}`} />
        )
      }
    >
      {/*  */}
      <P>
        Are you sure you want to {currentStatus} <strong>{userName}</strong>?{' '}
        {message}.
      </P>
    </MainModal>
  )
}

export default UpdateUserStatus
