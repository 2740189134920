import React, { useEffect, useCallback, useMemo } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'

import Table from '../../../../components/Table'
import Flex from '../../../../components/layouts/Flex'
import { P } from '../../../../style/Typography.style'
import TableAction from './TableAction'

import {
  setModal,
  setPagination,
  setQueryParams,
} from '../../../../store/actions/sidebarModuleActions'

import { convertObjectToUrlParams } from '../../../../utils/stateHelpers'

const ModuleList = ({ fetchModuleList }) => {
  const { id } = useParams()
  const { sidebarModules, pagination, loading, queryParams } = useSelector(
    (state) => state.sidebarModules
  )

  const dispatch = useDispatch()

  const url = `/user-types/${id}/modules/paged?${convertObjectToUrlParams(
    queryParams
  )}`

  const fetchData = useCallback((queryParams) => {
    fetchModuleList(convertObjectToUrlParams(queryParams))
  }, [])

  const handlePagination = (option) => {
    dispatch(setPagination(option))
    if ('page' in option) {
      dispatch(setQueryParams({ pageNumber: option.page }))
    }
    if ('pageSize' in option) {
      dispatch(setQueryParams({ pageSize: option.pageSize }))
      dispatch(setQueryParams({ pageNumber: 1 }))
    }
  }

  useEffect(() => {
    fetchData(url)
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Action',
        accessor: 'actions',
        disableSortBy: true,
        Cell: ({ value }) => <TableAction value={value} />,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Route',
        accessor: 'route',
      },
    ],
    []
  )

  const tableData = sidebarModules?.map(
    (item) =>
      ({
        actions: item,
        name: item.name,
        description: item.description,
        route: item.route,
      } || '')
  )

  return (
    <StyledModuleList>
      {sidebarModules?.length > 0 ? (
        <Table
          data={tableData}
          columns={columns}
          loading={loading}
          totalPage={pagination?.pageCount}
          currentPage={pagination?.page}
          pageSize={pagination?.pageSize}
          rowCount={pagination?.rowCount}
          fetchData={fetchData}
          setNewPageSize={(e) => handlePagination({ pageSize: e })}
          setCurrentPage={(e) => handlePagination({ page: e })}
          url={url}
          noData="User Types is empty"
          height="calc(105vh - 16.3rem)"
          onClick={() => dispatch(setModal(''))}
        />
      ) : (
        <Flex
          height="calc(100vh - 15rem)"
          bg="#fff"
          justify="center"
          items="center"
        >
          <P>Module List is empty</P>
        </Flex>
      )}
    </StyledModuleList>
  )
}

export default ModuleList

const StyledModuleList = styled.div`
  width: 100%;
  height: 100%;
`
