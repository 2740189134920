/* eslint-disable camelcase */
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'
import Select from '../../../../components/dashboard/controls/Select'
import MainModal from '../../../../components/dashboard/modal/MainModal'
import { StyledActivity } from '../../../../components/dashboard/TalentPool/Activities'
import Flex from '../../../../components/layouts/Flex'
import axiosInstance from '../../../../services/config/AxiosIntance'
import { P } from '../../../../style/Typography.style'

const ListActivities = () => {
  const { selectedList } = useSelector((state) => state.lists)

  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const [activitiesFilter, setActivitiesFilter] = useState('All Time')

  const options = useMemo(
    () => [
      { value: 'All Time', label: 'All Time' },
      { value: 'lastHour', label: 'Last Hour' },
      { value: 'last24hours', label: 'Last 24 Hours' },
      { value: 'last7days', label: 'Last 7 Days' },
      { value: 'last4weeks ', label: 'Last 4 Weeks' },
    ],
    []
  )

  // fetch activities by filter.
  async function getActivitiesByFilter(filter) {
    setLoading(true)
    let url = ''

    if (filter === 'All Time') {
      url = `/list/${selectedList.id}/activities`
    } else {
      url = `/list/${selectedList.id}/activities?filter=${filter}`
    }

    try {
      const { data } = await axiosInstance.get(url)

      setData(data?.resp)
      setLoading(false)
    } catch (error) {
      addToast(
        error?.response?.data?.message ||
          error.response.message ||
          'Something went wrong.',
        {
          appearance: 'success',
          autoDismiss: true,
        }
      )
      setLoading(false)
    }
  }

  useEffect(() => {
    getActivitiesByFilter(activitiesFilter)
  }, [activitiesFilter])

  return (
    <MainModal title={`${selectedList.list_name} Activity`} buttonText="Close">
      <Flex gap="1rem" items="center">
        <P>Showing Activity from</P>
        <Select
          value={activitiesFilter}
          onchange={(e) => setActivitiesFilter(e.target.value)}
          maxW="267px"
          height="2rem"
          options={options}
        />
      </Flex>
      {loading ? (
        <Flex height="8rem" bg="#fff" items="center" justify="center">
          <P>Loading...</P>
        </Flex>
      ) : (
        <ActivitiesWrapper>
          {data && data.length > 0 ? (
            data.map(({ activities, created_at, id }, index) => (
              <StyledActivity last={data.length === index + 1} key={id}>
                <P size="0.75rem" marginBottom="0.35rem">
                  {activities}
                </P>
                <P size="0.625rem" color="#676E92">
                  {moment(created_at).format('MMMM Do, YYYY | h:mm A')}{' '}
                  {' GMT '}
                  {moment(created_at).format('ZZ')}
                </P>
              </StyledActivity>
            ))
          ) : (
            <Flex height="8rem" bg="#fff" items="center" justify="center">
              <P>Activity table is empty</P>
            </Flex>
          )}
        </ActivitiesWrapper>
      )}
    </MainModal>
  )
}

export default ListActivities

const ActivitiesWrapper = styled.div`
  width: 100%;
  height: 300px;
  overflow-y: auto;
  margin-top: 2rem;
`
