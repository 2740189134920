import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  AUTH_ERROR,
  USER_LOADING,
  USER_LOADED,
  LOGOUT,
  UPDATE_VERIFICATION_CODE,
  VERIFY_USER,
  DELETE_VERIFICATION_CODE,
} from '../actions/types'

const intialState = {
  isLoading: false,
  token: localStorage.getItem('recruiterToken') || null,
  isLogin: false,
  user: null,
  userId: localStorage.getItem('id') || null,
  verificationCode:
    JSON.parse(localStorage.getItem('verificationCode')) || null,
  isUserVerified: false,
}

const authReducer = (state = intialState, action) => {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      }

    case USER_LOADED:
      return {
        ...state,
        isLoading: false,
        isLogin: true,
        user: action.payload,
      }

    case UPDATE_VERIFICATION_CODE:
      localStorage.setItem(
        'verificationCode',
        JSON.stringify(action.payload.verificationCode)
      )
      return {
        ...state,
        verificationCode: action.payload.verificationCode,
        userId: action.payload.userId,
        isLoading: false,
      }

    case DELETE_VERIFICATION_CODE:
      localStorage.removeItem('verificationCode')
      return {
        ...state,
        verificationCode: null,
      }

    case VERIFY_USER:
      localStorage.setItem('recruiterToken', action.payload)
      return {
        ...state,
        token: action.payload,
        isUserVerified: true,
        isLogin: true,
        isLoading: false,
      }

    case LOGIN_SUCCESS:
      localStorage.setItem('recruiterToken', action.payload.token)
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        isLogin: true,
        isLoading: false,
      }

    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('recruiterToken')
      localStorage.removeItem('verificationCode')
      return {
        ...state,
        isLoading: false,
        token: null,
        user: null,
        isLogin: false,
      }

    default:
      return state
  }
}

export default authReducer
