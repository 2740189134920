import React, { useEffect, useState } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'
import { sanitize } from 'dompurify'
import styled from 'styled-components'
import { HiOutlineDotsHorizontal } from 'react-icons/hi'
import { IoCloseOutline } from 'react-icons/io5'
import Avatar from 'react-avatar'

import { H2, P } from '../../../style/Typography.style'

import WorkExperienceCard from './WorkExperienceCard'
import TechnicalSkillCard from './TechnicalSkillCard'
import TalentCardPopout from './TalentCardPopout'
import TalentCardPopList from './TalentCardPopList'
import CVImage from './CVImage'
import EmptyDetails from './EmptyDetails'
import Loader from '../../Loader'
import DownloadCVModal from '../../cv/DownloadCVModal'

import { downloadModal } from '../../../store/actions/modalActions'

import { SET_DOWNLOAD_MODAL } from '../../../store/actions/types'

import axiosInstance from '../../../services/config/AxiosIntance'

import useFetch from '../../../hooks/useFetch'

import { addCv } from '../../../store/actions/talentActions'

import { markText, getCVImage } from '../../../utils/utils'

const TalentDetails = ({ talent, setShowDetails }) => {
  const dispatch = useDispatch()
  const [professionalHeadline, setProfessionalHeadline] = useState('')
  const [professionalSummary, setProfessionalSummary] = useState('')
  const [WorkExp, setWorkExp] = useState([])
  const [TechnicalSkills, setTechnicalSkills] = useState([])
  const [showMoreOption, setShowMoreOption] = useState(false)
  const [loading, setLoading] = useState(false)

  const { uploadedCv, globalSearchTerm } = useSelector((state) => state.talents)
  const { data: remoteData } = useFetch(`user/${talent.user_id}/remote-work`)

  const [activeMenu, setActiveMenu] = React.useState(0)

  const markAndSanitize = (str) => {
    return sanitize(markText(str, globalSearchTerm))
  }

  const remoteDataResp = remoteData && remoteData?.resp

  const compSpec =
    remoteDataResp && remoteData?.resp?.computer_specification !== ''
      ? JSON.parse(remoteData?.resp?.computer_specification)
      : null

  const peripherals =
    remoteDataResp && remoteData?.resp?.peripherals !== ''
      ? JSON.parse(remoteData?.resp?.peripherals)
      : null

  const ethics =
    remoteDataResp && remoteData?.resp?.meta !== ''
      ? JSON.parse(remoteData?.resp?.meta)
      : null

  useEffect(() => {
    talent.meta &&
    JSON.parse(talent.meta).experienceState &&
    JSON.parse(talent.meta).experienceState.length > 0
      ? setWorkExp(JSON.parse(talent.meta).experienceState)
      : setWorkExp([])

    talent.meta &&
    JSON.parse(talent.meta).fullSkills &&
    JSON.parse(talent.meta).fullSkills[0] &&
    JSON.parse(talent.meta).fullSkills[0].length > 0
      ? setTechnicalSkills(JSON.parse(talent.meta).fullSkills[0])
      : setTechnicalSkills([])

    setProfessionalSummary(
      talent && talent.professional_summary
        ? JSON.parse(talent.professional_summary)
        : ''
    )

    const lists = []

    talent &&
      talent.professional_headline &&
      JSON.parse(talent.professional_headline) &&
      JSON.parse(talent.professional_headline).length > 0 &&
      JSON.parse(talent.professional_headline).forEach(({ item }) => {
        lists.push(item)
      })
    setProfessionalHeadline(lists.splice(' • ').join(' • '))
  }, [talent])

  useEffect(() => {
    setLoading(true)
    if (talent.cv_public_id) {
      axiosInstance
        .get(`${talent.id}/uploaded-cv`)
        .then((res) => {
          dispatch(addCv(res.data.resp))
          setLoading(false)
        })
        .catch((err) => {
          if (err.response.data.http_code === 404) {
            dispatch(addCv([]))
            setLoading(false)
          }
        })
    } else {
      dispatch(addCv([]))
      setLoading(false)
    }
  }, [talent])

  return (
    <StyledTalentDetails>
      <DownloadCVModal />
      {uploadedCv && uploadedCv.length <= 0 && !loading && (
        <div className="talentHeading">
          <Avatar
            className="avatar"
            color="#1A1A1A"
            name={talent.name || 'Talent Name'}
            src={getCVImage(talent)}
          />
          <div>
            <H2
              weight="500"
              align="left"
              mb="0"
              dangerouslySetInnerHTML={{
                __html: talent.name
                  ? markAndSanitize(talent.name)
                  : 'Talent Name',
              }}
            ></H2>
            <P
              size=".75rem"
              dangerouslySetInnerHTML={{
                __html: markAndSanitize(professionalHeadline),
              }}
            ></P>
          </div>
        </div>
      )}
      <div className="iconsButton">
        <HiOutlineDotsHorizontal
          onClick={() => {
            setShowMoreOption(!showMoreOption)
            setActiveMenu(0)
          }}
          className="icon"
        />
        <IoCloseOutline
          onClick={() => setShowDetails(false)}
          className="icon"
        />
      </div>
      {TechnicalSkills.length === 0 &&
        WorkExp.length === 0 &&
        !talent.professional_summary &&
        uploadedCv.length === 0 &&
        !loading && <EmptyDetails />}
      {showMoreOption && (
        <TalentCardPopout left="56%" top="11%">
          <TalentCardPopList
            active={activeMenu === 3}
            onClick={() => {
              setActiveMenu(3)
              dispatch({ type: SET_DOWNLOAD_MODAL, payload: true })
            }}
            text="Download CV"
          />
        </TalentCardPopout>
      )}
      {uploadedCv && uploadedCv.public_id ? (
        <>
          {typeof uploadedCv === 'object' &&
            [...Array(uploadedCv?.pages).keys()].map((page) => (
              <CVImage
                key={page}
                url={uploadedCv?.url?.slice(0, -3) + 'jpg'}
                pageNumber={page + 1}
              />
            ))}
        </>
      ) : (
        <>{loading && <Loader />}</>
      )}
      {uploadedCv && uploadedCv.length <= 0 && !loading && (
        <div className="talentContent">
          {talent.professional_summary && (
            <P
              size="0.8125rem"
              weight="500"
              textTransform="uppercase"
              marginBottom=".5rem"
            >
              PERSONAL PROFILE
            </P>
          )}

          {talent.professional_summary && (
            <P
              size=".75rem"
              dangerouslySetInnerHTML={{
                __html: markAndSanitize(professionalSummary),
              }}
            />
          )}

          {/* work experience */}
          {WorkExp.length > 0 && (
            <P size="0.8125rem" weight="500" textTransform="uppercase">
              WORK EXPERIENCE
            </P>
          )}

          {WorkExp.map(
            (
              {
                experienceFrom,
                experienceTo,
                companyName,
                companyLocation,
                jobTitle,
                jobDescriptionHtml,
                experienceCurrentStatus,
              },
              index
            ) => (
              <WorkExperienceCard
                key={index}
                from={experienceFrom}
                to={experienceTo}
                company={companyName}
                location={companyLocation}
                description={jobDescriptionHtml}
                title={jobTitle}
                experienceCurrentStatus={experienceCurrentStatus}
              />
            )
          )}

          {/* Technical Skills */}
          {TechnicalSkills.length > 0 && (
            <P
              size="0.8125rem"
              weight="500"
              marginTop="2rem"
              textTransform="uppercase"
              marginBottom="1rem"
            >
              TECHNICAL SKILLS
            </P>
          )}
          {TechnicalSkills.map(({ skill, experience, id }) => (
            <TechnicalSkillCard
              key={id}
              skill={skill}
              experience={experience}
            />
          ))}
        </div>
      )}

      {remoteDataResp && (
        <>
          <P
            weight="500"
            textTransform="uppercase"
            marginBottom="1rem"
            padding="0 1.5rem"
          >
            WFH Setup
          </P>

          <div className="two-grid">
            <P weight="300" size=".75rem">
              Operating System
            </P>
            <P weight="500" size=".75rem">
              {compSpec?.os ? compSpec?.os : 'not specified'}
            </P>
          </div>
          <div className="two-grid">
            <P weight="300" size=".75rem">
              Processor
            </P>
            <P weight="500" size=".75rem">
              {compSpec?.processor ? compSpec?.processor : 'not specified'}
            </P>
          </div>

          <div className="two-grid">
            <P weight="300" size=".75rem">
              Installed Ram
            </P>
            <P weight="500" size=".75rem">
              {compSpec?.ram ? `${compSpec?.ram} RAM` : 'not specified'}
            </P>
          </div>

          <div className="two-grid">
            <P weight="300" size=".75rem">
              Os Version
            </P>
            <P weight="500" size=".75rem">
              {compSpec?.osVersion ? compSpec?.osVersion : 'not specified'}
            </P>
          </div>

          <div className="two-grid">
            <P weight="300" size=".75rem">
              WebCam
            </P>
            <P weight="500" size=".75rem">
              {peripherals?.webCamera
                ? peripherals?.webCamera
                : 'not specified'}
            </P>
          </div>

          <div className="two-grid">
            <P weight="300" size=".75rem">
              Headset
            </P>
            <P weight="500" size=".75rem">
              {peripherals?.headSet ? peripherals?.headSet : 'not specified'}
            </P>
          </div>

          <div className="two-grid bottom">
            <P weight="300" size=".75rem">
              Microphone
            </P>
            <P weight="500" size=".75rem">
              {peripherals?.microphone
                ? peripherals?.microphone
                : 'not specified'}
            </P>
          </div>

          <P
            weight="500"
            textTransform="uppercase"
            marginBottom="1rem"
            padding="0 1.5rem"
          >
            Remote Work Ethics Responses
          </P>

          <div className="two-grid">
            <P weight="300" size=".75rem">
              Work Area
            </P>
            <P weight="500" size=".75rem">
              {ethics?.workStation ? ethics?.workStation : 'not specified'}
            </P>
          </div>

          <div className="two-grid">
            <P weight="300" size=".75rem">
              Device
            </P>
            <P weight="500" size=".75rem">
              {ethics?.device ? ethics?.device : 'not specified'}
            </P>
          </div>

          <div className="two-grid">
            <P weight="300" size=".75rem">
              Schedule
            </P>
            <P weight="500" size=".75rem">
              {ethics?.workSchedule ? ethics?.workSchedule : 'not specified'}
            </P>
          </div>

          <div className="two-grid">
            <P weight="300" size=".75rem">
              Shifts
            </P>
            <P weight="500" size=".75rem">
              {ethics?.workShift ? ethics?.workShift : 'not specified'}
            </P>
          </div>

          <div className="two-grid">
            <P weight="300" size=".75rem">
              Time Tracker
            </P>
            <P weight="500" size=".75rem">
              {ethics?.timeTracker ? ethics?.timeTracker : 'not specified'}
            </P>
          </div>
        </>
      )}
    </StyledTalentDetails>
  )
}

const mapStateToProps = (state) => ({
  talent: state.talents.talent,
  talents: state.talents.talents,
})

export default connect(mapStateToProps, {
  downloadModal,
})(TalentDetails)

const StyledTalentDetails = styled.div`
  position: sticky;
  top: 19vh;
  width: 100%;
  background: #fff;
  height: 100%;
  min-height: 85vh;
  max-height: 85vh;
  margin-left: 1.2rem;
  overflow-y: auto;
  padding-bottom: 8rem;

  .moreOption {
    position: absolute;
    top: 4rem;
    right: 1.5rem;
    background: #fff;
    padding: 1rem 1.5rem;
    box-shadow: 3px 3px 7px rgba(178, 178, 203, 0.2);

    p {
      margin-bottom: 0.6rem;
      cursor: pointer;

      &:hover {
        font-weight: 500;
      }
    }
  }

  .iconsButton {
    position: absolute;
    top: 2.8rem;
    right: 1.5rem;

    .icon {
      font-size: 1rem;
      color: #31374f;
      margin-right: 1.5rem;
      cursor: pointer;
      position: relative;
      z-index: 20;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .talentHeading {
    border-bottom: 1px solid #dfdfe9;
    padding: 1.5rem 1.5rem 0.875rem 1.5rem;
    display: flex;

    img {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      margin-right: 0.9rem;
      object-fit: cover;
    }

    .avatar {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      margin-right: 0.9rem;
    }
  }

  .two-grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 0.5rem;
    margin-bottom: 1rem;
    margin-left: 1.5rem;
    max-width: 100%;
    padding-right: 1rem;

    &.bottom {
      margin-bottom: 3rem;
    }

    .grid-item {
      margin-bottom: 0.5rem;
    }
  }

  .talentContent {
    width: 100%;
    height: auto;
    max-height: 64vh;
    padding: 1.5rem;
    padding-top: 1.3rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #edf0f4;
      border-radius: 100px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #c9d1dc;
      border-radius: 100px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #31374f;
    }
  }
`
