import React from 'react'

import styled from 'styled-components';
import Mark from './Mark';

const MarkDetails = ({ text="", isCorrect, onCheck, onClose }) => {
  return (
    <StyledMarkDetails>
        <div className='mark-wrapper'>
            <Mark disable={isCorrect} onClick={onCheck} type="correct" isActive={isCorrect} />
            <Mark disable={!isCorrect} onClick={onClose} isActive={!isCorrect} />
        </div>

        <p>{text}</p>
    </StyledMarkDetails>
  )
}

export default MarkDetails

const StyledMarkDetails = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    .mark-wrapper {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    p {
        margin: 0;
        padding: 0;
        font-size: 0.875rem;
        font-weight: 300;
        color: #1A1A1A;
    }
`