import React from 'react'

import styled from 'styled-components'

const checkStatus = (status) => {
  switch (status) {
    case 'listed':
      return 'background: rgba(24, 119, 242, 0.2); color: #1877F2;'
    case 'active':
      return 'background: #E0F5EB; color: #47A276;'
    case 'pooling':
      return 'background: #E7E7FF; color: #7775C3;'
    case 'closed':
      return 'background: #F1F3FD; color: #AEB3C9;'
    case 'unlisted':
      return 'background: rgba(103, 110, 146, 0.2); color: #676E92;'
    case 'accepted':
      return 'background-color: rgba(92, 202, 149, 0.2); color: #176a42;'
    case 'rejected':
    case 'declined':
      return 'background: #DC292920; color: #DC2929;'
    default:
      return 'background: rgba(103, 110, 146, 0.2); color: #676E92;'
  }
}

const Tags = ({ status, text, rounded }) => {
  return <StyledTags status={status} rounded={rounded}>{text}</StyledTags>
}

export default Tags

const StyledTags = styled.span`
  ${({ status }) => checkStatus(status?.toLowerCase())}
  padding: 0.35rem 0.65rem;
  border-radius: ${( rounded ) => rounded ? '100px' : '5px'};
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  text-overflow: ellipsis;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
`
