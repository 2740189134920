import { useState } from 'react'
import axiosInstance from '../services/config/AxiosIntance'

function useMutation() {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSucess] = useState(false)

  function handleRequest(url, body, method = 'POST') {
    setLoading(true)
    setSucess(false)

    axiosInstance({
      method,
      url,
      data: body,
    })
      .then((res) => {
        setData(res.data)
        setSucess(true)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function handleUpdateRequest(url, body, method = 'PUT') {
    setLoading(true)
    setSucess(false)

    axiosInstance({
      method,
      url,
      data: body,
    })
      .then((res) => {
        setData(res.data)
        setSucess(true)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return { data, error, handleRequest, handleUpdateRequest, loading, success }
}

export default useMutation
