import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import Flex from '../../../../components/layouts/Flex'
import RichTextbox from '../../../../components/dashboard/controls/RichTextBox'
import MainModal from '../../../../components/dashboard/modal/MainModal'

import { P } from '../../../../style/Typography.style'

import axiosInstance from '../../../../services/config/AxiosIntance'

import {
  setModal,
  setFormData,
  setNotification,
  setSelectedRecord,
  setListChanged
} from '../../../../store/actions/clientActions'

const ContactListingModal = () => {
  const dispatch = useDispatch()

  const modal = useSelector((state) => state.clients.modal)
  const contactListingFormData = useSelector(
    (state) => state.clients.formData.contactListing
  )
  const selectedJobListing = useSelector(
    (state) => state.clients.selectedRecord.jobListing
  )

  const handleClose = () => {
    const removed = modal.filter((m) => m !== 'contactListingModal')
    dispatch(setModal(removed))
  }

  const handleFieldChange = (name, value) =>
    dispatch(setFormData('contactListing', { [name]: value }))
  const handleSubmit = async () => {
    try {
      let response
      if (contactListingFormData?.id) {
        
        response = await axiosInstance.put(
          `/contact-listing/${contactListingFormData.id}/update`,
          contactListingFormData
        )
      } else {
        const request = {
          ...contactListingFormData,
          poc_id: selectedJobListing.poc_id,
          job_id: selectedJobListing.id,
        }
        response = await axiosInstance.post(
          '/contact-listing/create',
          request
        )
      }
      dispatch(setNotification('success', response?.data?.message))
      const result = { ...response?.data?.data }

      delete result?.created_at
      delete result?.updated_at

      dispatch(setSelectedRecord('contactListing', result))
    } catch (error) {
      dispatch(setNotification('error', error?.response?.data?.message))
    } finally {
      dispatch(setListChanged('jobListing'))
      handleClose()
    }
  }

  return (
    <MainModal
      title="Contact Listing Modal"
      buttonText="Submit"
      size="large"
      onClick={handleSubmit}
      onClose={handleClose}
    >
      <Flex direction="column">
        <P weight="500" size="16px">
          Recommendations for the candidates
        </P>
        <Flex width="100%" margin="0.5rem 0">
          <RichTextbox
            content={contactListingFormData?.recommendations}
            setContent={(e) => handleFieldChange('recommendations', e)}
          />
        </Flex>
        <P weight="500" size="16px">
          General Structure
        </P>
        <Flex width="100%" margin="0.5rem 0">
          <RichTextbox
            content={contactListingFormData?.general_structure}
            setContent={(e) => handleFieldChange('general_structure', e)}
          />
        </Flex>
        <P weight="500" size="16px">
          Client&apos;s Questions
        </P>
        <Flex width="100%" margin="0.5rem 0">
          <RichTextbox
            content={contactListingFormData?.client_questions}
            setContent={(e) => handleFieldChange('client_questions', e)}
          />
        </Flex>
        <P weight="500" size="16px">
          Notes
        </P>
        <Flex width="100%" margin="0.5rem 0">
          <RichTextbox
            content={contactListingFormData?.notes}
            setContent={(e) => handleFieldChange('notes', e)}
          />
        </Flex>
      </Flex>
    </MainModal>
  )
}

export default ContactListingModal
