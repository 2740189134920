import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router'

import { useToasts } from 'react-toast-notifications'
import { useSelector, useDispatch } from 'react-redux'

import JobListingHome from './JobListingHome'

import { setNotification } from '../../../store/actions/jobListingActions'

const JobListing = () => {
  const dispatch = useDispatch()

  const { notification } = useSelector((state) => state.jobListing)

  const { addToast } = useToasts()

  useEffect(() => {
    if (notification.type === 'success') {
      if (notification?.message) {
        addToast(notification?.message, {
          appearance: 'success',
          autoDismiss: true,
        })
      }
    }
    if (notification.type === 'error') {
      if (notification?.message) {
        addToast(notification?.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      dispatch(setNotification('', ''))
    }
  }, [notification])

  return (
    <Routes>
      <Route path="/*" element={<JobListingHome />} />
    </Routes>
  )
}

export default JobListing
