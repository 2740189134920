import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleDropdownSelect } from '../../DropdownSelect'

import { setLevelOfEducation } from '../../../../../store/actions/talentActions'

const EducationFilter = () => {
  const dispatch = useDispatch()

  const { levelOfEducation } = useSelector((state) => state.talents)

  const options = [
    'Junior High School',
    'Senior High School',
    "Bachelor's",
    "Master's",
    'Doctorate',
  ]

  return (
    <>
      <SingleDropdownSelect
        name="levelOfEducation"
        title="Level of Education"
        value={levelOfEducation}
        onChange={(e) => dispatch(setLevelOfEducation(e.target.value))}
      >
        <option>Choose Option</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </SingleDropdownSelect>
    </>
  )
}

export default EducationFilter
