import React, { useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import DashboardButton from '../../../../components/dashboard/DashboardButton'
import FilterInput from '../../../../components/dashboard/FilterInput'
import Flex from '../../../../components/layouts/Flex'
import axiosInstance from '../../../../services/config/AxiosIntance'

import {
  setModal,
  showEmailTemplates
} from '../../../../store/actions/emailTemplateActions'

const HeaderFilter = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const [textFilter, setTextFilter] = useState('')
  const [loading, setLoading] = useState(false)

  function toggleCreateUser() {
    dispatch(setModal('createEmailTemplate'))
  }

  async function handleFilter(e) {
    e.preventDefault()
    setLoading(true)

    try {
      const { data } = await axiosInstance.get(
        `/email-template/table?searchTerm=${textFilter}`
      )
      if (data?.emailTemplates) {
        dispatch(showEmailTemplates(data.emailTemplates))
      } else {
        dispatch(showEmailTemplates([]))
      }
      setLoading(false)
    } catch (err) {
      addToast(err?.response?.data?.message || 'Something went wrong.', {
        appearance: 'error',
        autoDismiss: true,
      })
      setLoading(false)
    }

  }

  return (
    <Flex
      width="100%"
      margin="1.5rem 0 0 0"
      items="center"
      justify="space-between"
    >
      <Flex
        width="100%"
        as="form"
        items="center"
        justify="flex-start"
        gap="1rem"
        onSubmit={handleFilter}
      >
        <FilterInput
          maxW="300px"
          whiteSpace="nowrap"
          onChange={(e) => setTextFilter(e.target.value)}
          value={textFilter}
          placeholder="Search..."
          handleClear={() => setTextFilter('')}
        />
        <DashboardButton
          text={loading ? 'Loading...' : 'Search'}
          type="submit"
        />
      </Flex>

      <Flex width="200px" items="center" gap="0.5rem">
        <DashboardButton
          text="Add Email Template"
          type="button"
          notIcon
          externalIcon={<FiPlus className="icon" />}
          maxWidth="180px"
          onClick={toggleCreateUser}
        />
      </Flex>
    </Flex>
  )
}

export default HeaderFilter
