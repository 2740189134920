import { useEffect, useState } from 'react'
import axiosInstance from '../services/config/AxiosIntance'

function useFetch(url) {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  function fetchData() {
    setLoading(true)

    axiosInstance
      .get(url)
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (url) {
      fetchData()
    }
  }, [url])

  return { data, error, loading, refetch: fetchData }
}

export default useFetch
