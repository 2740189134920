import React from 'react'
import styled from 'styled-components'

import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { useDispatch, useSelector } from 'react-redux'
import { SWITCH_STEP } from '../../../../store/types/jobPosting'

const DropProgress = ({
  last,
  text,
  active,
  postActive,
  remotePage,
  reachRemotePage,
  value,
}) => {
  const dispatch = useDispatch()

  return (
    <StyledProgress
      as="button"
      onClick={() => {
        if (active || postActive) {
          dispatch({ type: SWITCH_STEP, payload: value })
          localStorage.setItem('step', value)
        }
      }}
      disabled={remotePage > reachRemotePage}
      last={last}
    >
      <div
        className={
          active
            ? 'circle-dot active'
            : postActive
            ? 'circle-dot postActive'
            : 'circle-dot'
        }
      />
      <p>{text}</p>
    </StyledProgress>
  )
}

const CreateJobSidebar = () => {
  const { step, reachedStep } = useSelector((state) => state.jobPosting)

  return (
    <StyledSidebar>
      <div className="in">
        <CircularProgressbar
          value={step}
          maxValue={4}
          text={`${step} of 4`}
          strokeWidth={5}
          styles={buildStyles({
            strokeLinecap: 'butt',
            textSize: '.8rem',
            pathTransitionDuration: 0.5,
            pathColor: `#1a1a1a`,
            textColor: '#31374F',
            trailColor: '#E9EBF2',
          })}
        />
      </div>

      <div className="sidebar-content">
        <DropProgress
          active={step === 1}
          value={1}
          postActive={step > 1 || reachedStep > 1}
          text="Job Details"
          last={true}
          remotePage={step}
          reachRemotePage={reachedStep}
        />
        <DropProgress
          active={step === 2}
          value={2}
          postActive={step > 2 || reachedStep > 2}
          text="Payment and Benefits"
          remotePage={step}
          reachRemotePage={reachedStep}
        />
        <DropProgress
          active={step === 3}
          value={3}
          postActive={step > 3 || reachedStep > 3}
          text="Qualifications"
          remotePage={step}
          reachRemotePage={reachedStep}
        />
        <DropProgress
          active={step === 4}
          value={4}
          postActive={step > 4 || reachedStep > 4}
          text="Review Post"
          remotePage={step}
          reachRemotePage={reachedStep}
        />
      </div>
    </StyledSidebar>
  )
}

export default CreateJobSidebar

const StyledSidebar = styled.aside`
  width: 100%;
  max-width: 164px;
  margin-right: 4rem;
  position: sticky;
  top: 8rem;

  @media (max-width: 768px) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: none;
    top: 5rem;
    max-width: 100%;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 3;
    margin-right: 0;
    gap: 2rem;

    .in {
      width: 100%;
      max-width: 120px;
    }
  }

  .sidebar-content {
    margin-top: 2rem;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
      margin-top: 0;
    }
  }
`

export const StyledProgress = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  background: none;
  outline: none;
  border: none;

  @media (max-width: 768px) {
    align-items: center;
    gap: 1rem;
  }

  .circle-dot {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background: #e9ebf2;
    position: relative;
    margin-bottom: 2.5rem;
    cursor: pointer;

    @media (max-width: 768px) {
      margin-bottom: 0;
    }

    &:after {
      content: '';
      width: 1px;
      height: 2.5rem;
      background: #e9ebf2;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      display: ${(p) => (p.last ? 'none' : 'block')};

      @media (max-width: 768px) {
        height: 1rem;
      }
    }

    &.active {
      background: #1a1a1a;

      &:after {
        background: #1a1a1a;
      }
    }

    &.postActive {
      border: 1px solid #1a1a1a;
      background: #fff;

      &:after {
        background: #1a1a1a;
      }
    }
  }

  p {
    font-size: 0.75rem;
    font-weight: 300;
    margin-left: 2rem;

    @media (max-width: 768px) {
      text-align: center;
      margin-left: 0;
      margin-bottom: 0;
    }
  }
`
