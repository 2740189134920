import React, { useState } from 'react'

import styled from 'styled-components'
import { BsThreeDots } from 'react-icons/bs'

import ActionsMenu from './ActionsMenu'

const TableAction = ({ value, onEditClient, onChangeClientStatus }) => {
  const [showMenu, setShowMenu] = useState(false)

  const handleEditClient = () => {
    setShowMenu((prevState) => !prevState)
    onEditClient(value)
  }
  const handleChangeClientStatus = () => {
    setShowMenu((prevState) => !prevState)
    onChangeClientStatus(value)
  }

  return (
    <StyledTableAction>
      <div
        onClick={(e) => {
          e.stopPropagation()
          setShowMenu((prevState) => !prevState)
        }}
        className={showMenu ? 'icon-container active' : 'icon-container'}
      >
        <BsThreeDots className="icon" />
      </div>
      {showMenu && (
        <ActionsMenu
          value={value}
          setShowMenu={setShowMenu}
          onEditClient={handleEditClient}
          onChangeClientStatus={handleChangeClientStatus}
        />
      )}
    </StyledTableAction>
  )
}

export default TableAction

const StyledTableAction = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 0.4rem;

  .icon-container {
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover,
    &.active {
      background: #eeeef6;
    }

    .icon {
      font-size: 1rem;
      color: #1a1a1a;
    }
  }
`
