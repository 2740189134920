/* eslint-disable no-unused-expressions */
import React, { useMemo } from 'react'
import { useToasts } from 'react-toast-notifications'

import styled from 'styled-components'
import useFetch from '../../hooks/useFetch'
import axiosInstance from '../../services/config/AxiosIntance'
import { H2 } from '../../style/Typography.style'
import MarkDetails from '../MarkDetails'

const WorkEthicsToggler = ({ userId }) => {
  const { data, refetch } = useFetch(`/user/${userId}/remote-work`)
  const { addToast } = useToasts()

  const meta = useMemo(
    () => (data?.resp?.meta ? JSON.parse(data?.resp?.meta) : null),
    [data?.resp?.meta]
  )

  if (!meta) return null

  function updateRemoteWork(data) {
    const formData = new FormData()
    formData.append('workEthicsRecruiterResonses', JSON.stringify(data))

    axiosInstance
      .post(`/user/${userId}/remote-work`, formData)
      .then((res) => {
        refetch()
        addToast('Remote Work Ethics Responses Updated.', {
          appearance: 'success',
        })
      })
      .catch((err) => {
        addToast(
          err?.response?.message ||
            err?.response?.data?.message ||
            err?.response?.data ||
            'Error updating Remote Work Ethics Responses.',
          { appearance: 'error' }
        )
      })
  }

  function handleWorkStationCheck(input) {
    updateRemoteWork(input)
  }

  return (
    <StyledWorkEthicsToggler>
      <H2 mb="0.5rem" size="1rem" align="left">
        Remote Work Ethics Responses
      </H2>
      <MarkDetails
        onCheck={() =>
          handleWorkStationCheck({
            workStation: true,
          })
        }
        onClose={() =>
          handleWorkStationCheck({
            workStation: false,
          })
        }
        isCorrect={data?.resp?.work_station === 1}
        text={`Working from ${meta?.workStation || 'the bedroom'}`}
      />
      <MarkDetails
        onCheck={() =>
          handleWorkStationCheck({
            device: true,
          })
        }
        onClose={() =>
          handleWorkStationCheck({
            device: false,
          })
        }
        isCorrect={data?.resp?.device === 1}
        text={meta?.device || 'Have a desktop dedicated for my use only'}
      />
      <MarkDetails
        isCorrect={data?.resp?.is_fixed_schedule === 1}
        onCheck={() =>
          handleWorkStationCheck({
            workSchedule: true,
          })
        }
        onClose={() =>
          handleWorkStationCheck({
            workSchedule: false,
          })
        }
        text={
          meta?.workSchedule ||
          'Looking for a full-time job with a fixed schedule'
        }
      />
      <MarkDetails
        onCheck={() =>
          handleWorkStationCheck({
            workShift: true,
          })
        }
        onClose={() =>
          handleWorkStationCheck({
            workShift: false,
          })
        }
        isCorrect={data?.resp?.willing_to_work_shift === 1}
        text={`Working from ${meta?.workShift || 'mornings'}`}
      />
      <MarkDetails
        isCorrect={data?.resp?.time_tracker === 1}
        onCheck={() =>
          handleWorkStationCheck({
            timeTracker: true,
          })
        }
        onClose={() =>
          handleWorkStationCheck({
            timeTracker: false,
          })
        }
        text={
          meta?.timeTracker ||
          'Understand how time-tracking can achieve full-transparency to build long-term trust'
        }
      />
    </StyledWorkEthicsToggler>
  )
}

export default WorkEthicsToggler

const StyledWorkEthicsToggler = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
  margin-bottom: 2rem;
`
