import React, { useState } from 'react'
import styled from 'styled-components'

import { useSelector, useDispatch } from 'react-redux'

import { RiPencilFill } from 'react-icons/ri'
import { AiFillSave } from 'react-icons/ai'
import { ClipLoader } from 'react-spinners'

import { P } from '../../../../../style/Typography.style'

import Flex from '../../../../../components/layouts/Flex'
import RichTextbox from '../../../../../components/dashboard/controls/RichTextBox'
import DashboardButton from '../../../../../components/dashboard/DashboardButton'
import CopyToClipboard from '../../../../../components/CopyToClipboard'

import axiosInstance from '../../../../../services/config/AxiosIntance'

import {
  setFormData,
  setNotification,
  setSelectedRecord,
} from '../../../../../store/actions/clientActions'

const ContactListingForm = () => {
  const dispatch = useDispatch()

  const loadingContactListing = useSelector(
    (state) => state.clients.loading.contactListing
  )

  const selectedActiveListing = useSelector(
    (state) => state.clients.selectedRecord.activeListing
  )

  const fields = useSelector((state) => state.clients.formData.contactListing)

  const [readOnly, setReadOnly] = useState(true)

  const handleReadOnly = () => setReadOnly(!readOnly)

  const handleSave = async () => {
    try {
      setReadOnly(!readOnly)
      let response
      if (fields?.id) {
        response = await axiosInstance.put(
          `/contact-listing/${fields.id}/update`,
          fields
        )
      } else {
        response = await axiosInstance.post('/contact-listing/create', fields)
      }
      dispatch(setNotification('success', response?.data?.message))
      const result = { ...response?.data?.data }

      delete result?.created_at
      delete result?.updated_at

      dispatch(setSelectedRecord('contactListing', result))
    } catch (error) {
      dispatch(setNotification('error', error?.response?.data?.message))
    }
  }

  const handleFieldChange = (name, value) =>
    dispatch(setFormData('contactListing', { [name]: value }))

  return (
    <StyledContactListingForm>
      <div className="poc_header">
        <Flex margin="0 0 0.5rem 0" items="center">
          <P weight="500" size="16px">
            POC: {selectedActiveListing?.poc_name}
          </P>
        </Flex>
        <Flex margin="0 0 0.5rem 0" items="center" gap="0.75rem">
          <P weight="500" size="1rem" align="left">
            POC Email: {selectedActiveListing?.poc_email}
          </P>
          <CopyToClipboard text={selectedActiveListing?.poc_email} />
        </Flex>
        <Flex margin="1rem 0" justify="flex-end">
          {readOnly ? (
            <DashboardButton
              text="Edit"
              icon={<RiPencilFill className="icon" />}
              onClick={handleReadOnly}
            />
          ) : (
            <DashboardButton
              text="Save"
              icon={<AiFillSave className="icon" />}
              onClick={handleSave}
            />
          )}
        </Flex>
      </div>

      {loadingContactListing ? (
        <Flex bg="#fff" justify="center" items="center">
          <ClipLoader color="#1a1a1a" size="2rem" />
        </Flex>
      ) : (
        <div className="poc_details">
          <Flex direction="column">
            <Flex direction="row" justify="space-between" width="100%">
              <P weight="500" size="16px">
                Recommendations for the candidates
              </P>
            </Flex>
            {readOnly ? (
              <Flex width="100%" margin="0.5rem 0">
                <P
                  dangerouslySetInnerHTML={{
                    __html: fields?.recommendations || 'No text at the moment',
                  }}
                ></P>
              </Flex>
            ) : (
              <RichTextbox
                content={fields?.recommendations}
                isReadOnly={readOnly}
                setContent={(e) => handleFieldChange('recommendations', e)}
              />
            )}
          </Flex>
          <Flex direction="column">
            <Flex direction="row" justify="space-between" width="100%">
              <P weight="500" size="16px">
                General Structure
              </P>
            </Flex>
            {readOnly ? (
              <Flex width="100%" margin="0.5rem 0">
                <P
                  dangerouslySetInnerHTML={{
                    __html:
                      fields?.general_structure || 'No text at the moment',
                  }}
                ></P>
              </Flex>
            ) : (
              <RichTextbox
                content={fields?.general_structure}
                isReadOnly={readOnly}
                setContent={(e) => handleFieldChange('general_structure', e)}
              />
            )}
          </Flex>
          <Flex direction="column">
            <Flex direction="row" justify="space-between" width="100%">
              <P weight="500" size="16px">
                Client&apos;s Questions
              </P>
            </Flex>
            {readOnly ? (
              <Flex width="100%" margin="0.5rem 0">
                <P
                  dangerouslySetInnerHTML={{
                    __html: fields?.client_questions || 'No text at the moment',
                  }}
                ></P>
              </Flex>
            ) : (
              <RichTextbox
                content={fields?.client_questions}
                isReadOnly={readOnly}
                setContent={(e) => handleFieldChange('client_questions', e)}
              />
            )}
          </Flex>
          <Flex direction="column">
            <P weight="500" size="16px">
              Notes
            </P>
            {readOnly ? (
              <Flex width="100%" margin="0.5rem 0">
                <P
                  dangerouslySetInnerHTML={{
                    __html: fields?.notes || 'No text at the moment',
                  }}
                ></P>
              </Flex>
            ) : (
              <RichTextbox
                content={fields?.notes}
                isReadOnly={readOnly}
                setContent={(e) => handleFieldChange('notes', e)}
              />
            )}
          </Flex>
        </div>
      )}
    </StyledContactListingForm>
  )
}

export default ContactListingForm

const StyledContactListingForm = styled.div`
  height: calc(100% + 110px);
  position: relative;
  bottom: 25px;

  .poc_header {
    display: flex;
    flex-direction: column;
    position: fixed;
    background: #ffff;
    width: 100%;
    max-width: 701px;
    z-index: 100;
  }

  .poc_details {
    position: relative;
    top: 132px;
  }

  .edit_link {
    color: #1877f2;
    cursor: pointer;
  }
`
