import React from 'react'

import styled from 'styled-components'

const DashboardWrapper = ({ children }) => {
  return <StyledDashboardWrapper>{children}</StyledDashboardWrapper>
}

export default DashboardWrapper

const StyledDashboardWrapper = styled.div`
  width: 100%;
  height: 345vh;
  display: flex;
  flex-direction: column;
  border-bottom: none;
  margin-top: 1rem;
  padding: 1rem 0;

  .report-container {
    padding-bottom:  5rem;
  }
`
