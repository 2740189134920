import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

import check from '../assets/icons/check-circle.svg'
import AuthLayout from '../layouts/AuthLayout'
import { StyledVerify } from './VerificationEmail'

const ResetSuccess = () => {
  const navigate = useNavigate()

  useEffect(() => {
    // redirect to login page after 5 seconds
    setTimeout(() => {
      navigate('/login')
    }, 3000)
  }, [])

  const handleRedirect = () => {
    navigate('/login')
  }
  return (
    <AuthLayout width="500px">
      <StyledVerify>
        <img src={check} alt="mail" className="icon" />
        <h1>Password Changed Successfully</h1>
        <p>
          <span onClick={handleRedirect}>Click here</span> if you’re not
          automatically redirected to the login page in 5 seconds. Login to
          start using your HOPLA Careers account
        </p>
      </StyledVerify>
    </AuthLayout>
  )
}

export default ResetSuccess
