import React, { useState, useEffect } from 'react'

import { sanitize } from 'dompurify'
import styled from 'styled-components'
import OutsideClickHandler from 'react-outside-click-handler'
import { HiOutlineDotsHorizontal } from 'react-icons/hi'
import moment from 'moment'
import { connect, useDispatch, useSelector } from 'react-redux'

import { P } from '../../../style/Typography.style'

import CvProgress from './CvProgress'
import TalentCardPopout from './TalentCardPopout'
import TalentCardPopList from './TalentCardPopList'
import ApplicantDetailsModal from './ApplicantDetailsModal'
import IconModal from './IconModal'
import ShowLists from './ShowLists'
import Tags from '../../Tags'
import SaveApplicant from './SaveApplicant'
import { FilterCheckBox } from '../FilterInput'

import Flex from '../../layouts/Flex'

import {
  OPEN_ACTIVITIES_MODAL,
  SET_DOWNLOAD_MODAL,
  SET_ICON_MODAL,
} from '../../../store/actions/types'

import {
  addList,
  selectApplicant,
  setModal,
} from '../../../store/actions/listsActions'
import {
  getTalent,
  selectTalent,
  setBulkItems,
} from '../../../store/actions/talentActions'
import { setEditApplicantModal } from '../../../store/actions/modalActions'

import { capitalize } from '../../../utils/capitalize'
import { markText } from '../../../utils/utils'

const TalentCard = ({
  getTalent,
  selectApplicant,
  talent,
  index,
  setShowDetails,
  setActive,
  active,
}) => {
  const dispatch = useDispatch()

  const [meta] = useState(() => JSON.parse(talent.meta))
  const [skills, setSkills] = useState([])
  const [softSkills, setSoftSkills] = useState([])
  const [educationBackground, setEducationBackground] = useState([])
  const [profesionalHeadlines, setProfesionalHeadlines] = useState(null)
  const [showPopout, setShowPopout] = useState(false)
  const [activeMenu, setActiveMenu] = useState(0)
  const [showLists, setShowLists] = useState(false)

  let speedTest = talent?.speed_test

  if (typeof talent?.speed_test === 'string' && talent?.speed_test) {
    speedTest = JSON.parse(talent?.speed_test)
  }

  const { activities, iconModal, editApplicantModal } = useSelector(
    (state) => state.modal
  )
  const selectedAll = useSelector(
    (state) => state.talents.bulkItems.selectedAll
  )
  const checkedItems = useSelector(
    (state) => state.talents.bulkItems.checkedItems
  )
  const uncheckedItems = useSelector(
    (state) => state.talents.bulkItems.uncheckedItems
  )
  const uncheckedItemList = uncheckedItems.map((e) => e.id)
  const checkedItemList = checkedItems.map((e) => e.id)

  const { lists } = useSelector((state) => state.lists)
  const { globalSearchTerm } = useSelector((state) => state.talents)

  const markAndSanitize = (str) => {
    return sanitize(markText(str, globalSearchTerm))
  }

  useEffect(() => {
    const lists = []
    const softSkillList = []
    const headlines = []

    const profesionalHeadline = talent.professional_headline
      ? JSON.parse(talent.professional_headline)
      : null
    if (profesionalHeadline) {
      profesionalHeadline &&
        profesionalHeadline.forEach((headline) => {
          if ((headline && headline.label) || headline.item) {
            headlines.push(headline.label || headline.item)
          }
        })
    }
    meta &&
      meta.fullSkills &&
      meta.fullSkills[0] &&
      meta.fullSkills[0].length > 0 &&
      meta.fullSkills[0].forEach(({ skill }) => {
        lists.push(skill)
      })

    setSkills(lists.splice(', ').join())

    meta &&
      meta.fullSkills &&
      meta.fullSkills[1] &&
      meta.fullSkills[1].length > 0 &&
      meta.fullSkills[1].forEach((skill) => {
        softSkillList.push(skill.value)
      })
    setSoftSkills(softSkillList.splice(', ').join())

    const educationBackgroundList = meta?.educationalData?.map(
      (e) => `${e?.educationLevel} - ${e?.fieldOfStudy}, ${e?.school}`
    )

    setEducationBackground(educationBackgroundList?.splice(', ').join())

    if (headlines.length > 0) {
      setProfesionalHeadlines(headlines.splice(', ').join())
    }
  }, [talent])

  useEffect(() => {
    if (index === 0) {
      getTalent(talent.id)
      setShowDetails(true)
      setActive(talent?.email)
    }
  }, [])

  function handleSelectedList() {
    const selectedList = lists.find(
      (list) => list.list_name === talent.listName
    )

    dispatch(addList(selectedList))
  }

  const handleCheck = (talent) => {
    let updatedCheckedItems = []
    let updatedUncheckedItems = []

    if (selectedAll) {
      if (uncheckedItemList.includes(talent.id)) {
        updatedUncheckedItems = uncheckedItemList.filter((e) => e !== talent.id)
      } else {
        updatedUncheckedItems = [...uncheckedItems, talent]
      }
    } else {
      if (checkedItemList.includes(talent.id)) {
        updatedCheckedItems = checkedItemList.filter((e) => e !== talent.id)
      } else {
        updatedCheckedItems = [...checkedItems, talent]
      }
    }

    const payload = {
      checkedItems: updatedCheckedItems,
      uncheckedItems: updatedUncheckedItems,
    }
    dispatch(setBulkItems(payload))
  }

  const getChecked = (talentId) => {
    if (selectedAll) {
      return !uncheckedItemList.includes(talentId)
    } else {
      return checkedItemList.includes(talentId)
    }
  }

  const computerSpecs =
    talent?.computer_specification && JSON.parse(talent?.computer_specification)

  return (
    <StyledTalentCard
      className={`${active === talent?.email ? 'active' : ''}`}
      onClick={() => {
        getTalent(talent.id)
        setShowDetails(true)
        setActive(talent.email)
        selectApplicant({
          applicantId: talent.id,
          listId: '',
          applicantName: talent.name,
          listName: '',
        })
      }}
    >
      <FilterCheckBox
        checked={getChecked(talent.id)}
        size="small"
        // onChange={() => handleCheck(talent.id)}
        onChange={() =>
          handleCheck({
            id: talent.id,
            name: talent.name,
            email: talent.email,
            phone_number: talent.phone_number,
          })
        }
        mr="0"
      />
      {/* first section */}
      <div className="wrapper">
        <P
          size=".75rem"
          marginBottom=".5rem"
          weight="500"
          dangerouslySetInnerHTML={{
            __html:
              talent.name || talent.name === ' '
                ? markAndSanitize(capitalize(talent.name))
                : 'Name',
          }}
        ></P>

        <div>
          { talent?.stage_id === 8 || talent?.stage_id === null ? (
            <p className="talentAvailable">
               <span>Status: {'Available'}</span><br/>
             </p>  
          ) : (
            <p className="talentUnderVacancy">
            <span>Status: {'Under active vacancy'}</span><br/>
            <span>Current Stage: {talent?.stage_name}</span>
          </p>

          )}  
        </div>

        <P
          size=".75rem"
          marginBottom=".5rem"
          dangerouslySetInnerHTML={{
            __html: `${talent.city ? markAndSanitize(talent.city) + ', ' : ''}${
              talent.country ? markAndSanitize(talent.country) : ''
            }`,
          }}
        ></P>
        {meta && meta.experienceState && meta.experienceState.length > 0
          ? meta.experienceState.map((item, index) => (
              <P
                size=".75rem"
                key={index}
                dangerouslySetInnerHTML={{
                  __html: `${
                    item.jobTitle || item.jobTitle === ''
                      ? markAndSanitize(item.jobTitle) + ' - '
                      : 'No Job Title'
                  } ${
                    item.companyName || item.companyName === ''
                      ? markAndSanitize(item.companyName)
                      : 'No Company Name'
                  }`,
                }}
              ></P>
            ))
          : null}
        {/* Professional headlines section */}
        <P size=".75rem" marginBottom=".5rem" marginTop=".5rem" weight="500">
          Professional Headlines
        </P>
        <P
          size=".75rem"
          dangerouslySetInnerHTML={{
            __html:
              markAndSanitize(profesionalHeadlines) ||
              'No Professional Headlines',
          }}
        ></P>

        {/* Network connectivity section */}
        <P size=".75rem" marginBottom=".5rem" marginTop=".5rem" weight="500">
          Network Connectivity
        </P>
        <P size=".75rem">{`Download (MBPS): ${
          speedTest?.downloadSpeed || 0
        }, Upload (MBPS): ${speedTest?.uploadSpeed || 0}, Ping (MS): ${
          speedTest?.pingTime || 0
        }`}</P>

        {/* Hardware section */}
        {(computerSpecs?.os ||
          computerSpecs?.processor ||
          computerSpecs?.ram) && (
          <P size=".75rem" marginBottom=".5rem" marginTop=".5rem" weight="500">
            Hardware
          </P>
        )}

        <P
          size=".75rem"
          dangerouslySetInnerHTML={{
            __html: `${
              computerSpecs?.os
                ? `OS: ${markAndSanitize(computerSpecs.os)}, `
                : ''
            } ${
              computerSpecs?.processor
                ? `Processor: ${markAndSanitize(computerSpecs.processor)}, `
                : ''
            } ${
              computerSpecs?.ram
                ? `RAM: ${markAndSanitize(computerSpecs.ram)}`
                : ''
            }`,
          }}
        ></P>

        {/* Technical skills section */}
        <P size=".75rem" marginBottom=".5rem" marginTop=".5rem" weight="500">
          Technical Skills
        </P>
        <P
          size=".75rem"
          dangerouslySetInnerHTML={{
            __html: `${
              skills.length > 0
                ? markAndSanitize(skills)
                : 'No Technical Skills'
            }`,
          }}
        ></P>

        {/* Soft skills section */}
        <P size=".75rem" marginBottom=".5rem" marginTop=".5rem" weight="500">
          Soft Skills
        </P>
        <P
          size=".75rem"
          dangerouslySetInnerHTML={{
            __html: `${
              softSkills.length > 0
                ? markAndSanitize(softSkills)
                : 'No Soft Skills'
            }`,
          }}
        ></P>

        {/* Educational background */}
        <P size=".75rem" marginBottom=".5rem" marginTop=".5rem" weight="500">
          Educational Background
        </P>
        <P
          size=".75rem"
          dangerouslySetInnerHTML={{
            __html: `${
              skills.length > 0 ? markAndSanitize(educationBackground) : ''
            }`,
          }}
        ></P>

        {/* Years of experience */}
        {talent?.years_of_experience ? (
          <>
            <P
              size=".75rem"
              marginBottom=".5rem"
              marginTop=".5rem"
              weight="500"
            >
              Years of Experience
            </P>
            <P size=".75rem">{talent.years_of_experience}</P>
          </>
        ) : null}

        {/* progress */}
        <CvProgress progress={talent.cv_progress} />

        {/* footer section */}
        <Flex margin="1rem 0 0 0" items="center" justify="space-between">
          {/* recent update */}
          <p className="time">
            Created {moment(talent.created_at).format('MMM Do YY')}
          </p>
          {talent?.role && <Tags status="listed" text={talent.role} />}

          {talent?.applicant_status === 'DECLINED' && (
            <Tags status="declined" text="Declined" />
          )}
          {talent?.applicant_status === 'ACCEPTED' && (
            <Tags status="accepted" text="Accepted" />
          )}
        </Flex>

        {/* horizontal Menu */}
        <HiOutlineDotsHorizontal
          onClick={() => {
            handleSelectedList()
            setShowPopout(!showPopout)
          }}
          className="icon"
        />
      </div>

      {/* popup */}
      {active === talent?.email && showPopout && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setShowPopout(false)
          }}
        >
          <TalentCardPopout left="101%">
            {talent?.applicant_status !== 'DECLINED' && (
              <TalentCardPopList
                active={activeMenu === 1}
                rightArrow={true}
                onClick={() => {
                  setActive(1)
                  setShowLists((prev) => !prev)
                }}
                text={`${
                  talent?.stage_id && talent?.stage_id !== 8
                    ? 'Tag to Pooling'
                    : 'Add to Vacancy'
                }`}
              />
            )}
            {talent?.applicant_status === 'DECLINED' && (
              <TalentCardPopList
                active={activeMenu === 2}
                onClick={() => {
                  setActive(2)
                  dispatch({ type: SET_ICON_MODAL, payload: true })
                }}
                text="Archive Applicant"
              />
            )}
            <TalentCardPopList
              active={activeMenu === 4}
              onClick={() => {
                setActiveMenu(4)
                dispatch({ type: SET_DOWNLOAD_MODAL, payload: true })
              }}
              text="Download CV"
            />
            <TalentCardPopList
              active={activeMenu === 5}
              onClick={() => {
                setActiveMenu(5)
                dispatch({ type: OPEN_ACTIVITIES_MODAL, payload: 'activities' })
              }}
              text="View Profile"
            />

            {talent?.cv_url && (
              <TalentCardPopList
                active={activeMenu === 6}
                onClick={() => {
                  setActiveMenu(6)
                  dispatch(setEditApplicantModal(true))
                }}
                text="Edit Profile"
              />
            )}

            {talent?.applicant_status === 'DECLINED' && (
              <TalentCardPopList
                active={activeMenu === 8}
                onClick={() => {
                  setActive(8)
                  dispatch(setModal('reactivateApplicant'))
                }}
                text="Reactivate Applicant"
              />
            )}
          </TalentCardPopout>
        </OutsideClickHandler>
      )}

      {active === talent?.email && activities !== '' && (
        <ApplicantDetailsModal talent={talent} />
      )}

      {active === talent?.email && iconModal && (
        <IconModal
          name={talent.name}
          onClose={() => dispatch({ type: SET_ICON_MODAL, payload: '' })}
        />
      )}

      {active === talent?.email && showLists && (
        <ShowLists setShowLists={setShowLists} talent={talent} />
      )}

      {editApplicantModal && (
        <SaveApplicant
          onClose={() => dispatch(setEditApplicantModal(false))}
          isEdit
        />
      )}
    </StyledTalentCard>
  )
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, {
  getTalent,
  selectTalent,
  selectApplicant,
})(TalentCard)

const StyledTalentCard = styled.div`
  display: flex;
  align-items: flex-start;

  background: #fff;
  border: 1px solid #eeeef7;
  padding: 1rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 4px;
  box-sizing: border-box;

  &:hover,
  &.active {
    border: 1px solid #7775c4;
  }

  .wrapper {
    width: 100%;

    .time {
      color: #1877f2;
      font-size: 0.75rem;
      font-weight: 500;
      margin: 0;
    }

    .talentUnderVacancy {
      color: orange;
      font-size: 0.75rem;
      font-weight: 500;
      margin: 0;
    }

    .talentAvailable {
      color: green;
      font-size: 0.75rem;
      font-weight: 500;
      margin: 0;
    }

    .icon {
      position: absolute;
      top: 0.5rem;
      right: 1rem;
      font-size: 1rem;
      box-sizing: content-box;
      padding: 0.5rem;
      transition: background 0.2s ease-in-out;

      &:hover {
        background: #eeeef6;
      }
    }
  }
`
