import React from 'react'

import styled from 'styled-components'
import { P } from '../../../style/Typography.style'

const CharRefCard = ({ crName, crEmail, crPhone }) => {
  return (
    <StyledCarRef>
      <div>
        <P size=".75rem" weight="500" marginBottom="2px">
          {crName}
        </P>
        <P size=".75rem">{crEmail}</P>
        <P size=".75rem">{crPhone}</P>
      </div>
    </StyledCarRef>
  )
}

export default CharRefCard

const StyledCarRef = styled.div`
  width: 100%;
  display: flex;
  max-width: 300px;
  justify-content: space-between;
  margin-bottom: 1rem;
`
