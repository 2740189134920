import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment'
import { FaSmile } from 'react-icons/fa'
import { FiPlus } from 'react-icons/fi'
import { RiPencilFill } from 'react-icons/ri'
import styled from 'styled-components'

import { H6, P } from '../../../../../style/Typography.style'
import Flex from '../../../../../components/layouts/Flex'
import RichTextBox from '../../../../../components/dashboard/controls/RichTextBox'
import DashboardButton from '../../../../../components/dashboard/DashboardButton'

import axiosInstance from '../../../../../services/config/AxiosIntance'

import {
  setNotification,
  setModal,
  setFormData,
} from '../../../../../store/actions/clientActions'

const JobListingDetails = ({ jobId }) => {
  const dispatch = useDispatch()

  const [comment, setComment] = useState('')
  const [comments, setComments] = useState([])

  const { modal } = useSelector((state) => state.clients)
  const contactListing = useSelector(
    (state) => state.clients.formData.contactListing
  )
  const selectedContact = useSelector(
    (state) => state.clients.selectedRecord.contact
  )
  const selectedContactListing = useSelector(
    (state) => state.clients.selectedRecord.contactListing
  )
  const jobListingListChanged = useSelector(
    (state) => state.clients.listChanged.jobListing
  )

  const handleFieldChanged = (name, value) =>
    dispatch(setFormData('contactListing', { [name]: value }))

  const handleCommentChanged = (e) => setComment(e)

  useEffect(() => {
    const pocId = selectedContact.id
    axiosInstance
      .get(`/contact-listing/comments?poc_id=${pocId}&job_id=${jobId}`)
      .then((response) => {
        const result = response.data.contactListingDetails
        const updatedContactListing = {
          id: result.contact_listing_id,
          recommendations: result.recommendations,
          general_structure: result.general_structure,
          client_questions: result.client_questions,
          notes: result.notes,
          poc_id: result.poc_id,
          job_id: result.job_id,
        }

        dispatch(setFormData('contactListing', updatedContactListing))
        setComments(response.data.comments)
      })
  }, [selectedContactListing.id, jobListingListChanged])

  const handleSubmitComment = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'))
      const response = await axiosInstance.post(
        '/contact-listing-comment/create',
        {
          comment: comment,
          contact_listing_id: contactListing.contact_listing_id,
          user_id: localStorage.getItem('id'),
          user_name: user.name,
        }
      )

      setComments([...comments, response.data.data])
      setComment('')
      dispatch(setNotification('success', response?.data?.message))
    } catch (error) {
      dispatch(setNotification('error', error?.response?.data?.message))
    }
  }

  const handleAddContactListing = () => {
    dispatch(setModal([...modal, 'contactListingModal']))
    const newContactListing = {
      id: contactListing.contact_listing_id,
      recommendations: contactListing.recommendations,
      general_structure: contactListing.general_structure,
      client_questions: contactListing.client_questions,
      notes: contactListing.notes,
      poc_id: contactListing.poc_id,
      job_id: contactListing.job_id,
    }

    dispatch(setFormData('contactListing', newContactListing))
  }

  const handleEditContactListing = () => {
    dispatch(setModal([...modal, 'contactListingModal']))
    const updatedContactListing = {
      id: contactListing.contact_listing_id,
      recommendations: contactListing.recommendations,
      general_structure: contactListing.general_structure,
      client_questions: contactListing.client_questions,
      notes: contactListing.notes,
    }
    dispatch(setFormData('contactListing', updatedContactListing))
  }

  return (
    <StyledJobDetails>
      <div className="details">
        {contactListing.contact_listing_id && (
          <div className="edit__contact-listing">
            <DashboardButton
              text="Edit"
              icon={<RiPencilFill className="icon" />}
              onClick={handleEditContactListing}
            />
          </div>
        )}

        <H6 weight="600" size="1rem" align="left">
          Recommendations for the candidates
        </H6>
        <P
          weight="300"
          size="14px"
          textAlign="justified"
          marginBottom="1rem"
          dangerouslySetInnerHTML={{
            __html: contactListing?.recommendations || 'No text at the moment',
          }}
          setContent={(e) => handleFieldChanged('recommendations', e)}
        ></P>
        <H6 weight="600" size="1rem" align="left">
          General Structure
        </H6>
        <P
          weight="300"
          size="14px"
          textAlign="justified"
          marginBottom="1rem"
          dangerouslySetInnerHTML={{
            __html:
              contactListing?.general_structure || 'No text at the moment',
          }}
          setContent={(e) => handleFieldChanged('general_structure', e)}
        ></P>
        <H6 weight="600" size="1rem" align="left">
          POC&apos;s Questions
        </H6>
        <P
          weight="300"
          size="14px"
          textAlign="justified"
          marginBottom="1rem"
          dangerouslySetInnerHTML={{
            __html: contactListing?.client_questions || 'No text at the moment',
          }}
          setContent={(e) => handleFieldChanged('client_questions', e)}
        ></P>

        <H6 weight="600" size="1rem" align="left">
          Notes
        </H6>
        <P
          weight="300"
          size="14px"
          textAlign="justified"
          marginBottom="1rem"
          dangerouslySetInnerHTML={{
            __html: contactListing?.notes || 'No text at the moment',
          }}
          setContent={(e) => handleFieldChanged('notes', e)}
        ></P>
        {contactListing.id && (
          <div className="comment__section">
            <H6 weight="600" size="1rem" align="left">
              Comments
            </H6>
            <div className="comments">
              {comments?.map((result) => (
                <div key={result?.comment_id}>
                  <Flex items="center" gap="14px">
                    <FaSmile className="avatar" />
                    <div>
                      <H6 weight="600" size="1rem" align="left" mb="0">
                        {result?.user_name}
                      </H6>
                      <P weight="300" size="14px" textAlign="justified">
                        {moment(result?.created_at).format('lll')}
                      </P>
                    </div>
                  </Flex>
                  <Flex padding="0.5rem 0 0 3.1rem">
                    <P
                      weight="300"
                      size="14px"
                      textAlign="justified"
                      dangerouslySetInnerHTML={{
                        __html: result?.comment,
                      }}
                    ></P>
                  </Flex>
                </div>
              ))}
            </div>
            <Flex direction="column">
              <P weight="500" size="16px">
                Add Comment
              </P>
              <RichTextBox
                content={comment}
                setContent={handleCommentChanged}
              />
            </Flex>
          </div>
        )}
      </div>
      <div className="button__wrapper">
        {contactListing.id ? (
          <Flex justify="flex-end">
            <DashboardButton
              maxWidth="150px"
              text="Add comment"
              icon={<FiPlus className="icon" />}
              onClick={handleSubmitComment}
            />
          </Flex>
        ) : (
          <Flex justify="flex-end">
            <DashboardButton
              maxWidth="180px"
              text="Add contact listing"
              icon={<FiPlus className="icon" />}
              onClick={handleAddContactListing}
            />
          </Flex>
        )}
      </div>
    </StyledJobDetails>
  )
}

const StyledJobDetails = styled.div`
  .details {
    background: #f2f5f9;
    border-radius: 5px;
    padding: 0.5rem 1rem 0 1rem;

    .comments {
      padding-left: 1rem;

      .avatar {
        color: #ff7b9b;
        font-size: 36px;
      }
    }
  }

  .edit__contact-listing {
    width: 100px;
    position: absolute;
    right: 0;
    margin-right: 0.5rem;
  }

  .button__wrapper {
    margin-top: 0.5rem;
  }
`

export default JobListingDetails
