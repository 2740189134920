/* eslint-disable camelcase */
import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { IoCloseSharp } from 'react-icons/io5'
import { useToasts } from 'react-toast-notifications'
import { toast, ToastContainer } from 'react-toastify'
import Select from 'react-select'

import EducationalLevel from '../../../../components/dashboard/jobPosting/EducationalLevel'
import ExperienceInput from '../../../../components/dashboard/jobPosting/ExperienceInput'
import Flex from '../../../../components/layouts/Flex'
import { Close, Grid, MultiSelectWrapper } from './Qualification'
import HardWareRequirement from '../../../../components/dashboard/jobPosting/HardWareRequirement'
import Certification from '../../../../components/dashboard/jobPosting/Certification'
import Software from '../../../../components/dashboard/jobPosting/Software'
import Shift from '../../../../components/dashboard/jobPosting/Shift'
import Modal from '../../../../components/dashboard/Modal'
import AddSectionButton from '../../../../components/dashboard/controls/AddSectionButton'
import AvailableToStart from './AvailableToStart'

import {
  SET_CERTIFICATION,
  SET_EDUCATION,
  SET_EXPERIENCE,
  SET_HARDWARE_REQUIREMENT,
  SET_IS_CERTIFICATION,
  SET_IS_EDUCATION,
  SET_IS_EXPERIENCE,
  SET_IS_HARDWARE,
  SET_IS_LANGUAGE,
  SET_IS_PERSONAL_SKILLS,
  SET_IS_SHIFT,
  SET_IS_SOFTWARE,
  SET_LANGUAGE,
  SET_PERSONAL_SKILLS,
  SET_SHIFT_AVAILABILITY,
  SET_SOFTWARE,
  SET_TECHNICAL_SKILLS,
} from '../../../../store/types/jobPosting'
import { GET_SKILLS } from '../../../../store/types/skills'
import {
  SET_REVIEW_POST_MODAL,
  SHOW_MODAL,
  HIDE_MODAL,
} from '../../../../store/types/modal'

import {
  setAvailableToStart,
  setIsAvalibleToStart,
} from '../../../../store/actions/jobPostingActions'

import { languages } from '../../../../constant/languages'

import getSkills from '../../../../services/request/getSoftSkills'

const QualificationModal = () => {
  const dispatch = useDispatch()

  const {
    isEducation,
    isLanguage,
    isShift,
    isExperience,
    isCertification,
    isSoftware,
    isHardware,
    isSkill,
    personal_skills,
    language,
    experience,
    education,
    technical_skills,
    hardware_requirement,
    certification,
    shift_availability,
    software,
    isAvalableToStart,
    availability_to_start,
    isPersonalSkills,
  } = useSelector((state) => state.jobPosting)

  const personalSkills = useSelector((state) => state.skills.personalSkills)
  const softSkills = useSelector((state) => state.skills.technicalSkills)

  const [newPersonalSkills, setNewPersonalSkills] = React.useState([])
  const [newSoftSkills, setNewSoftSkills] = React.useState([])
  const [newLanguages, setNewLanguages] = React.useState([])

  const [, setIntialState] = React.useState({
    experience: [],
    technical_skills: '',
    education: '',
    hardware: [],
    language: [],
    certification: '',
    personalSkills: [],
    shift: [],
    software: '',
    isEducation,
    isExperience,
    isHardware,
    isCertification,
    isLanguage,
    isShift,
    isSkill,
    isSoftware,
    isAvalableToStart,
    availability_to_start,
    isPersonalSkills,
  })

  const { addToast } = useToasts()

  // handle first experience years input
  const handleExperienceYearOne = (e) => {
    const cloneExperience = [...experience]
    cloneExperience[0] = {
      years: e.target.value,
      specification: cloneExperience[0].specification || '',
    }

    dispatch({ type: SET_EXPERIENCE, payload: cloneExperience })
  }

  // handle first experience specification input
  const handleExperienceSpecificationOne = (e) => {
    const cloneExperience = [...experience]
    cloneExperience[0] = {
      years: cloneExperience[0].years,
      specification: e.target.value,
    }

    dispatch({ type: SET_EXPERIENCE, payload: cloneExperience })
  }

  // handle first experience years input
  const handleExperienceYearTwo = (e) => {
    const cloneExperience = [...experience]
    cloneExperience[1] = {
      years: e.target.value,
      specification: cloneExperience[1].specification || '',
    }

    dispatch({ type: SET_EXPERIENCE, payload: cloneExperience })
  }

  // handle first experience specification input
  const handleExperienceSpecificationTwo = (e) => {
    const cloneExperience = [...experience]
    cloneExperience[1] = {
      years: cloneExperience[1].years || '',
      specification: e.target.value,
    }

    dispatch({ type: SET_EXPERIENCE, payload: cloneExperience })
  }

  const loadSkills = async () => {
    getSkills()
      .then((res) => {
        dispatch({
          type: GET_SKILLS,
          payload: {
            personalSkills: res.data.data.personalSkills,
            technicalSkills: res.data.data.hardSkills,
          },
        })
      })
      .catch((err) => {
        addToast(
          err.response.data.message ||
            err.response.message ||
            'something went wrong while fetching clients data',
          {
            appearance: 'error',
            autoDismiss: true,
          }
        )
      })
  }

  useEffect(() => {
    loadSkills()

    setIntialState((prevState) => ({
      ...prevState,
      experience,
      education,
      technical_skills,
      hardware_requirement,
      language,
      certification,
      shift_availability,
      software,
      personalSkills: personal_skills,
      isEducation,
      isExperience,
      isHardware,
      isCertification,
      isLanguage,
      isShift,
      isSkill,
      isSoftware,
      availability_to_start,
      isAvalableToStart,
      isPersonalSkills,
    }))

    if (personalSkills.length !== 0) {
      personalSkills.map((skill) =>
        setNewPersonalSkills((prevState) => [
          ...prevState,
          { value: skill, label: skill },
        ])
      )
    }

    if (softSkills.length !== 0) {
      softSkills.map((skill) =>
        setNewSoftSkills((prevState) => [
          ...prevState,
          { value: skill, label: skill },
        ])
      )
    }

    if (languages.length !== 0) {
      languages.map((lang) =>
        setNewLanguages((prevState) => [
          ...prevState,
          { value: lang.name, label: lang.name },
        ])
      )
    }
  }, [])

  const handleQualificationUpdate = () => {
    if (experience[0].specification === '')
      return toast('Please enter experience specification', { type: 'error' })
    if (experience[0].years === '')
      return toast('Please enter experience years', { type: 'error' })

    if (technical_skills.length === 0)
      return toast('Please select technical skills', { type: 'error' })

    dispatch({ type: SHOW_MODAL })
    dispatch({ type: SET_REVIEW_POST_MODAL, payload: '' })
  }

  const onClose = () => dispatch({ type: HIDE_MODAL })

  const handleTechnicalSkillsChanged = (value) =>
    dispatch({ type: SET_TECHNICAL_SKILLS, payload: value })

  const handleCloseAvailabilityToStart = () => {
    dispatch(setAvailableToStart(''))
    dispatch(setIsAvalibleToStart(!isAvalableToStart))
  }

  const handleCloseEducationalLevel = () => {
    dispatch({ type: SET_EDUCATION, payload: '' })
    dispatch({ type: SET_IS_EDUCATION })
  }

  const handleCloseExperience = () => {
    dispatch({
      type: SET_EXPERIENCE,
      payload: [
        {
          years: '',
          specification: '',
        },
        {
          years: '',
          specification: '',
        },
      ],
    })
    dispatch({ type: SET_IS_EXPERIENCE })
  }

  const handleCloseHardwareRequirements = () => {
    dispatch({
      type: SET_HARDWARE_REQUIREMENT,
      payload: [],
    })
    dispatch({ type: SET_IS_HARDWARE })
  }

  const handleLanguagesChanged = (value) =>
    dispatch({ type: SET_LANGUAGE, payload: value })

  const handleCloseLanguages = () => {
    dispatch({ type: SET_LANGUAGE, payload: [] })
    dispatch({ type: SET_IS_LANGUAGE })
  }

  const handleCloseCertifications = () => {
    dispatch({ type: SET_CERTIFICATION, payload: '' })
    dispatch({ type: SET_IS_CERTIFICATION })
  }

  const handlePersonalSkillsChanged = (value) => {
    dispatch({ type: SET_PERSONAL_SKILLS, payload: value })
  }

  const handleClosePersonalSkills = () => {
    dispatch({ type: SET_PERSONAL_SKILLS, payload: [] })
    dispatch({ type: SET_IS_PERSONAL_SKILLS })
  }

  const handleCloseShifts = () => {
    dispatch({ type: SET_SHIFT_AVAILABILITY, payload: '' })
    dispatch({ type: SET_IS_SHIFT })
  }

  const handleCloseSoftware = () => {
    dispatch({ type: SET_SOFTWARE, payload: '' })
    dispatch({ type: SET_IS_SOFTWARE })
  }

  const handleToggleAvailableToStart = () =>
    dispatch(setIsAvalibleToStart(!isAvalableToStart))

  const handleToggleEducation = () => dispatch({ type: SET_IS_EDUCATION })

  const handleToggleLanguage = () => dispatch({ type: SET_IS_LANGUAGE })

  const handleToggleShift = () => dispatch({ type: SET_IS_SHIFT })

  const handleToggleCertification = () =>
    dispatch({ type: SET_IS_CERTIFICATION })

  const handleToggleSoftware = () => dispatch({ type: SET_IS_SOFTWARE })

  const handleToggleHardwareRequirements = () =>
    dispatch({ type: SET_IS_HARDWARE })

  const handleTogglePersonalSkills = () =>
    dispatch({ type: SET_IS_PERSONAL_SKILLS })

  return (
    <Modal
      height="38.188rem"
      title="Qualifications"
      btnFunc={handleQualificationUpdate}
      btnTitle="Update"
      onClose={onClose}
    >
      <ExperienceInput
        onChangeExperience={handleExperienceYearOne}
        onChangeSpecification={handleExperienceSpecificationOne}
        years={experience[0].years}
        specification={experience[0].specification}
      />

      <MultiSelectWrapper>
        <label>Required Technical Skills</label>
        <Select
          placeholder="Press enter to add more..."
          className="slider"
          options={newSoftSkills}
          isMulti
          value={technical_skills}
          onChange={handleTechnicalSkillsChanged}
        />
      </MultiSelectWrapper>

      {/* Availability to Start */}
      {isAvalableToStart && (
        <Flex margin="1.5rem 0 0 0" width="100%" position="relative">
          <MultiSelectWrapper>
            <label>Availability to Start</label>

            <AvailableToStart />
          </MultiSelectWrapper>
          <Close bottom="-16%" onClick={handleCloseAvailabilityToStart}>
            <IoCloseSharp className="icon" />
          </Close>
        </Flex>
      )}

      {/* add Educational Level */}
      {isEducation && (
        <Flex margin="1.5rem 0 0 0" width="100%" position="relative">
          <EducationalLevel />
          <Close onClick={handleCloseEducationalLevel}>
            <IoCloseSharp className="icon" />
          </Close>
        </Flex>
      )}

      {/* Experience added */}
      {isExperience && (
        <Flex margin=".5rem 0 0 0" width="100%" position="relative">
          <ExperienceInput
            onChangeExperience={handleExperienceYearTwo}
            onChangeSpecification={handleExperienceSpecificationTwo}
            years={experience[1].years}
            specification={experience[1].specification}
          />
          <Close onClick={handleCloseExperience}>
            <IoCloseSharp className="icon" />
          </Close>
        </Flex>
      )}

      {/* Hardware Requirements added */}
      {isHardware && (
        <Flex margin=".5rem 0 0 0" width="100%" position="relative">
          <HardWareRequirement data={hardware_requirement} />
          <Close onClick={handleCloseHardwareRequirements}>
            <IoCloseSharp className="icon" />
          </Close>
        </Flex>
      )}

      {/* Languages added */}
      {isLanguage && (
        <Flex width="100%" position="relative">
          <MultiSelectWrapper>
            <label>Language</label>
            <Select
              placeholder="Press enter to add more..."
              className="slider"
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              options={newLanguages}
              value={language}
              onChange={handleLanguagesChanged}
              isMulti
            />
          </MultiSelectWrapper>
          <Close onClick={handleCloseLanguages}>
            <IoCloseSharp className="icon" />
          </Close>
        </Flex>
      )}

      {/* Certification added */}
      {isCertification && (
        <Flex margin="1.5rem 0 0 0" width="100%" position="relative">
          <Certification />
          <Close onClick={handleCloseCertifications}>
            <IoCloseSharp className="icon" />
          </Close>
        </Flex>
      )}

      {/* Personal Skills added */}
      {isPersonalSkills && (
        <Flex width="100%" position="relative">
          <MultiSelectWrapper>
            <label>Personal Skills</label>
            <Select
              placeholder="Press enter to add more..."
              className="slider"
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              options={newPersonalSkills}
              value={personal_skills}
              onChange={handlePersonalSkillsChanged}
              isMulti
            />
          </MultiSelectWrapper>
          <Close bottom="2%" onClick={handleClosePersonalSkills}>
            <IoCloseSharp className="icon" />
          </Close>
        </Flex>
      )}

      {/* Sift added */}
      {isShift && (
        <Flex margin="1.5rem 0 0 0" width="100%" position="relative">
          <Shift />
          <Close onClick={handleCloseShifts}>
            <IoCloseSharp className="icon" />
          </Close>
        </Flex>
      )}

      {/* Software added */}
      {isSoftware && (
        <Flex margin=".5rem 0 0 0" width="100%" position="relative">
          <Software />
          <Close bottom="-16%" onClick={handleCloseSoftware}>
            <IoCloseSharp className="icon" />
          </Close>
        </Flex>
      )}

      <Grid>
        <AddSectionButton
          onClick={handleToggleAvailableToStart}
          text="Availability to Start"
          active={isAvalableToStart}
        />
        <AddSectionButton
          onClick={handleToggleEducation}
          text="Education"
          active={isEducation}
        />
        <AddSectionButton
          onClick={handleToggleLanguage}
          text="Language"
          active={isLanguage}
        />
        <AddSectionButton
          onClick={handleToggleShift}
          text="Shift Preference"
          active={isShift}
        />
        <AddSectionButton
          onClick={handleToggleCertification}
          text="License/Certification"
          active={isCertification}
        />
        <AddSectionButton
          onClick={handleToggleSoftware}
          text="Software"
          active={isSoftware}
        />
        <AddSectionButton
          onClick={handleToggleHardwareRequirements}
          text="Hardware Requirements"
          active={isHardware}
        />
        <AddSectionButton
          onClick={handleTogglePersonalSkills}
          text="Personal Skills"
          active={isPersonalSkills}
        />
      </Grid>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Modal>
  )
}

export default QualificationModal
