import dashboardTypes from '../types/dashboard'

export const setFilters = (filter) => {
  return { type: dashboardTypes.SET_FILTERS, payload: filter }
}
export const setTableFilters = (filter) => {
  return { type: dashboardTypes.SET_TABLE_FILTERS, payload: filter }
}
export const clearFilters = () => ({
  type: dashboardTypes.CLEAR_FILTERS,
})
export const setDownload = (data) => {
  return { type: dashboardTypes.SET_DOWNLOAD, payload: data }
}
export const setFooter = (data) => {
  return { type: dashboardTypes.SET_FOOTER, payload: data }
}

