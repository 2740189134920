import React from 'react'
import { Route, Routes, Navigate } from 'react-router'

import TalentPool from './talent-pool'
import Users from './users'
import UserTypes from './user-types'
import SidebarModules from './sidebar-modules'
import EmailTemplates from './email-templates'
import Clients from './clients'
import JobPosting from './job-posting'
import Lists from './lists'
import Requisitions from './requisitions'
import JobListing from './job-listing'
import Dashboard from './dashboard'

import DashboardLayout from '../../layouts/DashboardLayout'

const currentUser = localStorage.getItem('user')
const role = JSON.parse(currentUser)?.user_type

const Pages = () => {
  return (
    <DashboardLayout>
      <Routes>
        {/* Dashboard page. It is also the default home page */}
        <Route exact path="/" element={<Navigate to="/dashboard" replace />} />
        <Route path="/dashboard/*" element={<Dashboard />} />

        {/* Admin page is linked here with other sub routes related to it */}
        {role === 'admin' && <Route path="/users/*" element={<Users />} />}

        {/* User Types page */}
        {<Route path="/user-types" element={<UserTypes />} />}

        {/* Sidebar modules page */}
        {<Route path="/user-types/:id/modules" element={<SidebarModules />} />}

        {/* Email template page is linked here with other sub routes related to it */}
        {role === 'admin' && (
          <Route path="/email-templates/*" element={<EmailTemplates />} />
        )}

        {/* Client page is linked here with other sub routes related to it */}
        {['admin'].includes(role) && (
          <Route path="/clients" element={<Clients />} />
        )}

        {/* job posting page is linked here with other sub pages created as sub-route in job posting index file */}
        {role !== 'poc' && (
          <Route path="/job-posting/*" element={<JobPosting />} />
        )}

        {/* job listing page is linked here with other sub pages created */}
        {role !== 'poc' && (
          <Route path="/job-listing/*" element={<JobListing />} />
        )}

        {/* listing page is linked here with other sub routes related to it */}
        <Route path="/lists/*" element={<Lists />} />

        {/* Requisitions page is linked here with other sub routes related to it */}
        {role !== 'poc' && (
          <Route path="/requisitions/*" element={<Requisitions />} />
        )}

        {/* Talent Pool page is linked here with other sub routes related to it */}
        {role !== 'poc' && (
          <Route path="/talent-pool" element={<TalentPool />} />
        )}

        {/* Fallback route for any other route not matched */}
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </DashboardLayout>
  )
}

export default Pages
