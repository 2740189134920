import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'

import { setSelectedRecord } from '../../../../../store/actions/clientActions'

const ActiveListing = ({ data }) => {
  const dispatch = useDispatch()

  const { selectedRecord } = useSelector((state) => state.clients)
  const { activeListing } = selectedRecord

  const handleClick = () => {
    dispatch(setSelectedRecord('activeListing', data))
  }

  return (
    <>
      <StyledActiveListing>
        <div
          className={`client_section ${
            activeListing.job_id === data.job_id &&
            activeListing.poc_id === data.poc_id &&
            'clicked'
          }`}
          onClick={handleClick}
        >
          <div className="client_header">
            <h5 className="client_header_text">{data?.job_title}</h5>
          </div>
          <div className="client_body">
            <h5 className="client_body_text">{data?.poc_name}</h5>
            <h5 className="poc_email">{data?.poc_email}</h5>
            <h5 className="listing_date">Created at {data?.date}</h5>
          </div>
        </div>
      </StyledActiveListing>
    </>
  )
}

const StyledActiveListing = styled.div`
  .client_section {
    background: #f2f5f9;
    border: 1px solid #dfe2ec;
    border-radius: 3px;
    padding-left: 17px;
    margin: 12px 0px 8px 0px;
    gap: 4px;
    cursor: pointer;

    .client_header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: baseline;
      padding-top: 11px;
      margin: 0 !important;

      .client_header_text {
        font-weight: 500;
        font-size: 16px;
      }
    }
    .client_body {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.5rem;

      h5 {
        display: inline;
        font-size: 14px;
      }
      .client_body_text {
        font-weight: 300;
      }

      .poc_email {
        display: none;
      }

      .listing_date {
        padding-right: 1rem;
        font-size: 0.75rem;
        font-weight: 300;
        color: rgb(103, 110, 146);
      }
    }

    &.clicked {
      border-left: 6px solid #075bc9;
    }
  }
`

export default ActiveListing
