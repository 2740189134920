/* eslint-disable camelcase */
import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import TagInput from '../controls/TagInput'

import { SET_HARDWARE_REQUIREMENT } from '../../../store/types/jobPosting'

const HardWareRequirement = ({ disabled, data = null }) => {
  const dispatch = useDispatch()
  const { hardware_requirement } = useSelector((state) => state.jobPosting)

  useEffect(() => {
    let hardwareReqs
    if (data?.length) {
      hardwareReqs = data
    } else {
      hardwareReqs = hardware_requirement
    }

    dispatch({ type: SET_HARDWARE_REQUIREMENT, payload: hardwareReqs })
  }, [])

  const handleOnchange = (tags) => {
    dispatch({ type: SET_HARDWARE_REQUIREMENT, payload: tags })
  }

  return (
    <TagInput
      disabled={disabled}
      label="Hardware Requirements"
      setTags={handleOnchange}
      tags={hardware_requirement}
    />
  )
}

export default HardWareRequirement
