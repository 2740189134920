import dashboardTypes from '../types/dashboard'

const user = JSON.parse(localStorage.getItem('user'))

const currentDate = new Date()

const nextDay = new Date()
nextDay.setDate(nextDay.getDate() + 1)

const initialState = {
  filters: {
    dates: [currentDate, nextDay],
    stage_id: 8,
    channel_id: '',
    job_id: [],
    company_id: [],
    recruiter_id: user?.user_type === 'recruiter' ? user?.recruiterId : '',
  },
  tableFilters: {
    stage: 'Closed',
    channel: 'None',
  },
  download: '',
  footer: {
    dates: [currentDate, nextDay],
    job: 'All Jobs',
    company: 'All Companies',
    recruiter_id: user?.user_type === 'recruiter' ? user?.name : '',
  },
}

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case dashboardTypes.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }
    case dashboardTypes.SET_TABLE_FILTERS:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          ...action.payload,
        },
      }

    case dashboardTypes.CLEAR_FILTERS:
      return {
        ...state,
        filters: {
          ...initialState.filters,
        },
        tableFilters: {
          ...initialState.tableFilters,
        },
        footer: {
          ...initialState.footer
        }
      }
    case dashboardTypes.SET_DOWNLOAD:
      return {
        ...state,
        download: action.payload
      }
    case dashboardTypes.SET_FOOTER:
      return {
        ...state,
        footer: {
          ...state.footer,
          ...action.payload,
        },
      }
    default:
      return state
  }
}

export default dashboardReducer
