/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import ListSelector from '../../../../components/dashboard/controls/ListSelector'
import TalentCardPopList from '../../../../components/dashboard/TalentPool/TalentCardPopList'
import TalentCardPopout from '../../../../components/dashboard/TalentPool/TalentCardPopout'
import useMutation from '../../../../hooks/useMutation'
import {
  moveList,
  selectApplicantList,
  setModal,
  showApplicants,
} from '../../../../store/actions/listsActions'
import { setInterviewStage } from '../../../../store/actions/recruitersActions'
import featureFlags from '../../../../featureFlags'

const ActionMenu = ({ setShowMenu, value }) => {
  const dispatch = useDispatch()
  const [menuState, setMenuState] = useState('')
  const [showMoveList, setShowMoveList] = useState(false)
  const [showCreateActionList, setShowCreateActionList] = useState(false)
  const { addToast } = useToasts()

  const { data, loading, success, error } = useMutation()

  const { lists, applicant, applicants } = useSelector((state) => state.lists)

  // function handleDeleteApplicant(e) {
  //   e.stopPropagation()
  //   dispatch(setModal('removeFromList'))
  // }

  function handleMoveApplicant(e, list) {
    e.stopPropagation()
    dispatch(setModal('moveToList'))
    dispatch(moveList(list))
  }

  function handleInviteToInterview(e) {
    e.stopPropagation()
    dispatch(setInterviewStage('first_interview'))
    dispatch(setModal('inviteToInterview'))
  }
  function handleDeclineApplicant(e) {
    e.stopPropagation()
    dispatch(setModal('rejectApplicant'))
  }
  function handleMoveToTalentPool(e) {
    e.stopPropagation()
    dispatch(setModal('moveToTalentPool'))
  }
  function handleAssignSkillTest(e) {
    e.stopPropagation()
    dispatch(setInterviewStage('Skill_assesment'))
    dispatch(setModal('assignSkillTest'))
  }

  // function handleMarkAsReview(e) {
  //   e.stopPropagation()
  //   handleRequest(
  //     `applicant-list/${applicant.applicantId}/update`,
  //     { tags: 'Reviewed' },
  //     'PUT'
  //   )
  // }

  // if request is successful
  useEffect(() => {
    if (data) {
      const filteredApplicants = applicants.filter(
        (user) => user.applicant_id !== applicant.applicantId
      )

      const selectedApplicant = applicants.find(
        (user) => user.applicant_id === applicant.applicantId
      )

      selectedApplicant.tags = 'Reviewed'

      dispatch(showApplicants([selectedApplicant, ...filteredApplicants]))

      addToast(`${applicant.applicantName} has been tagged as REVIEWED`, {
        appearance: 'success',
        autoDismiss: true,
      })
    }
  }, [success])

  // if request is unsuccessful
  useEffect(() => {
    if (error) {
      addToast(
        error?.response?.data ||
          error?.response?.data?.message ||
          error?.response?.message ||
          'Something went wrong',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    }
  }, [error])

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowMenu(false)
      }}
    >
      <TalentCardPopout maxW="199px" width="199px" top="0" left="76%">
        {/* menu state to show the list of menus and select the one you want to move the applicant too */}
        {!featureFlags.lists.DISABLE_LIST_FUNCTIONALITIES && showMoveList && (
          <TalentCardPopout maxW="240px" width="240px" top="0" left="103%">
            {lists.map((list, index) => {
              return (
                <ListSelector
                  key={list.id}
                  text={`
                    ${list.list_name.substring(0, 20)}
                    ${list.list_name.length > 20 ? '...' : ''}`}
                  active={list.id === applicant.listId}
                  onClick={(e) => handleMoveApplicant(e, list)}
                  loading={loading}
                />
              )
            })}
          </TalentCardPopout>
        )}
        {showCreateActionList && (
          <TalentCardPopout maxW="240px" width="240px" top="30%" left="103%">
            <ListSelector
              key={1}
              text={'Invite for interview'}
              // active={list.id === applicant.listId}
              onClick={handleInviteToInterview}
              loading={loading}
            />
            <ListSelector
              key={2}
              text={'Decline'}
              // active={list.id === applicant.listId}
              onClick={handleDeclineApplicant}
              loading={loading}
            />
            <ListSelector
              key={3}
              text={'Put to Talent Pool'}
              // active={list.id === applicant.listId}
              onClick={handleMoveToTalentPool}
              loading={loading}
            />
            <ListSelector
              key={4}
              text={'Assign a Skills test'}
              // active={list.id === applicant.listId}
              onClick={handleAssignSkillTest}
              loading={loading}
            />
          </TalentCardPopout>
        )}
        {!featureFlags.lists.DISABLE_LIST_FUNCTIONALITIES && (
          <>
            <TalentCardPopList
              active={menuState === 'moveList'}
              onClick={(e) => {
                e.stopPropagation()
                setShowMoveList(!showMoveList)
                setShowCreateActionList(false)
                setMenuState((prevState) =>
                  prevState === 'moveList' ? '' : 'moveList'
                )
              }}
              text="Change Vacancy"
            />
            <TalentCardPopList
              active={menuState === 'createAction'}
              onClick={(e) => {
                e.stopPropagation()
                setShowCreateActionList(!showCreateActionList)
                setShowMoveList(false)
                setMenuState((prevState) =>
                  prevState === 'createAction' ? '' : 'createAction'
                )
              }}
              text="Create action"
            />
          </>
        )}

        {/* <TalentCardPopList
          onClick={handleMarkAsReview}
          text="Tag as Reviewed"
        /> */}
        <TalentCardPopList
          onClick={(e) => {
            e.stopPropagation()
            dispatch(setModal('viewListActivities'))
          }}
          text="View Applicant Activity"
        />
        <TalentCardPopList
          onClick={(e) => {
            e.stopPropagation()
            dispatch(setModal('updateScore'))
            dispatch(selectApplicantList(value))
          }}
          text="Update Score"
        />
        {/* <TalentCardPopList
          onClick={handleDeleteApplicant}
          text="Remove From List"
        /> */}
      </TalentCardPopout>
    </OutsideClickHandler>
  )
}

export default ActionMenu
