import React from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'

import styled from 'styled-components'
import CvWrapper from './CvWrapper'

import { downloadModal } from '../../store/actions/modalActions'

const DownloadCVModal = ({ downloadModal, modal }) => {
  return ReactDOM.createPortal(
    <StyledDownloadCVModal className={modal ? 'show' : ''}>
      <div onClick={() => downloadModal()} className="overlay" />
      <div className="content">
        <CvWrapper />
      </div>
    </StyledDownloadCVModal>,
    document.getElementById('modal')
  )
}

const mapStateToProps = (state) => ({
  modal: state.modal.downloadModal,
})

export default connect(mapStateToProps, { downloadModal })(DownloadCVModal)

const StyledDownloadCVModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  max-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 400;
  display: none;
  justify-content: center;
  align-items: flex-start;
  padding: 0 2rem;
  padding-top: 5rem;

  &.show {
    display: flex;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    max-width: 1582px;
    z-index: 1;
    background-color: #fff;
    padding: 2.5rem 2rem;
  }
`
