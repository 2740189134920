import React from 'react'

import styled from 'styled-components'

import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { BsCheck2Circle } from 'react-icons/bs'
import { FaShareAlt } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { IoChevronForwardOutline } from 'react-icons/io5'

import { H2, P } from '../../../style/Typography.style'

import ShareJobPostModal from './components/ShareJobPostModal'
import Flex from '../../../components/layouts/Flex'
import { IconButton } from '../../../components/Button'
import Modal from '../../../components/dashboard/Modal'

import {
  StepOne,
  StepThree,
  StepTwo,
} from '../../../components/dashboard/jobPosting/share'

import { setShareJobPostModal } from '../../../store/actions/modalActions'

const PublishJobPosting = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { shareStep } = useSelector((state) => state.modal)

  const jobTitle = localStorage.getItem('jobTitle')

  const handleOpenShareModal = () => {
    dispatch(setShareJobPostModal(true))
  }

  return (
    <Flex items="center" justify="center" direction="column" height="100%">
      <CenteredIcon />
      <div>
        <H2 mb=".5rem" textAlign="center">
          Successfully Published Job Posting
        </H2>
        <P maxWidth="439px" textAlign="center">
          Job Posting for {jobTitle || 'No Title'} has been published{' '}
          <Linked to="/">view here</Linked> or go back to{' '}
          <Linked to="/">dashboard</Linked>
        </P>
      </div>

      <Flex
        mobileDirection="column"
        maxw="688px"
        margin="2rem 0 0 0"
        gap="24px"
        width="100%"
      >
        <IconButton handleClick={() => navigate('/job-posting/create')}>
          <FiEdit className="leftIcon" />
          Create a new job post
          <IoChevronForwardOutline className="rightIcon" />
        </IconButton>
        <IconButton
          border="1px solid #1877F2"
          color="#1877F2"
          hoverColor="#70AEFF"
          justify="flex-start"
          gap="1.5rem"
          handleClick={handleOpenShareModal}
        >
          <FaShareAlt className="leftIcon" />
          Share Job Post
        </IconButton>
      </Flex>

      {/* modal */}
      {shareStep > 0 && (
        <Modal publish title="Post to Facebook" btnTitle="Next">
          {shareStep === 1 && <StepOne />}
          {shareStep === 2 && <StepTwo />}
          {shareStep === 3 && <StepThree />}
        </Modal>
      )}

      <ShareJobPostModal />
    </Flex>
  )
}

export default PublishJobPosting

const CenteredIcon = styled(BsCheck2Circle)`
  font-size: 4rem;
  margin-bottom: 1.5rem;
  color: #5cca95;
`
const Linked = styled(Link)`
  text-decoration: none;
`
