import io from 'socket.io-client'
import { baseUrl } from '../constant/constant'

class SocketIOClient {
  constructor() {
    this.socket = null

    this.connect()
  }

  connect() {
    const token = localStorage.getItem('recruiterToken')
    this.socket = io(baseUrl, {
      query: {
        token: token,
      },
    })
  }

  disconnect() {
    this.emit('logout')
    this.socket.disconnect()
  }

  on(eventName, callback) {
    this.socket.on(eventName, callback)
  }

  off(eventName, callback) {
    this.socket.off(eventName, callback)
  }

  emit(eventName, data) {
    this.socket.emit(eventName, data)
  }
}

export default SocketIOClient
