import React, { useMemo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'

import axiosInstance from '../../../../services/config/AxiosIntance'

import DashboardItemTitle from './DashboardItemTitle'
import NoData from './NoData'
import RecruitmentStageFunnelTable from './RecruitmentStageFunnelTable'

import Loader from '../../../../components/Loader'
import Flex from '../../../../components/layouts/Flex'

const RecruitmentStageFunnelList = () => {
  const { addToast } = useToasts()

  const filters = useSelector((state) => state.dashboard.filters)
  const tableFilters = useSelector((state) => state.dashboard.tableFilters)

  const [loading, setLoading] = useState(false)
  const [columns, setColumns] = useState([])
  const [tableData, setTableData] = useState([])
  const [stageBindings, setStageBindings] = useState({})

  useEffect(() => {
    setLoading(true)
    const queryParams = new URLSearchParams(filters).toString()
    if (filters.dates.length) {
      axiosInstance
        .get(`/dashboard/recruitmentStageFunnelTable?${queryParams}`)
        .then((res) => {
          setColumns(res.data.result.columns)
          setTableData(res.data.result.tableData)
          setStageBindings(res.data.result.stageBindings)
        })
        .catch((err) => {
          addToast(
            err?.response?.message ||
            err?.response?.data?.message ||
            'Internal Server Error',
            {
              appearance: 'error',
              autoDismiss: true,
            }
          )
        })
        .finally(() => setLoading(false))
    }
  }, [filters])

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Month',
        accessor: 'month',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      ...columns.map((e, i) => {
        return {
          ...e,
          Aggregated: ({ value }) => {
            return value
          },
          Cell: ({ row, value }) => {
            let result = 0
            if (
              e.accessor === 'applicant_screening' ||
              e.accessor === 'first_interview'
            ) {
              result = ''
            } else {
              const rowValue = value || 0
              if (row.original.name === 'From initial int') {
                const firstInterviewValue = row.original.first_interview || 0
                result = firstInterviewValue
                  ? (rowValue / firstInterviewValue) * 100
                  : 0
              }
              if (row.original.name === 'From prev step') {
                const prevStageValue =
                  row.original[stageBindings[e.accessor]] || 0
                result = prevStageValue
                  ? (rowValue / row.original[stageBindings[e.accessor]]) * 100
                  : 0
              }
              return result.toFixed(2) + '%'
            }
          },
        }
      }),
    ],
    [columns]
  )
  return (
    <StyledRecruitmentStageFunnelList>
      <Flex justify="space-between">
        <DashboardItemTitle title="Recruitment Stage Funnel List" />
        <Flex gap="0.5rem">
          <FilterItem>Stage: {tableFilters.stage}</FilterItem>
          <FilterItem>Channel: {tableFilters.channel}</FilterItem>
        </Flex>
      </Flex>
      {tableData.length ? (
        <>
          {loading ? (
            <Loader />
          ) : (
            <RecruitmentStageFunnelTable
              data={tableData}
              columns={tableColumns}
            ></RecruitmentStageFunnelTable>
          )}
        </>
      ) : (
        <NoData />
      )}
    </StyledRecruitmentStageFunnelList>
  )
}

export default RecruitmentStageFunnelList

const StyledRecruitmentStageFunnelList = styled.div`
  width: 100%;
  height: 100%;
`
const FilterItem = styled.div`
  padding: 0.35rem 0.65rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: black;
  color: white;
  border-radius: 100px;
  word-spacing: 3px;
`
