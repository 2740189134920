import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'
import { useToasts } from 'react-toast-notifications'

import MainModal from '../../../../components/dashboard/modal/MainModal'

import { P } from '../../../../style/Typography.style'

import { setModal } from '../../../../store/actions/listsActions'
import { updateTalents } from '../../../../store/actions/talentActions'

import useFetch from '../../../../hooks/useFetch'

import axiosInstance from '../../../../services/config/AxiosIntance'

const ReactivateApplicant = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { talent, talents } = useSelector((state) => state.talents)
  const [loading, setLoading] = useState(false)

  const { data, error } = useFetch(`${talent.id}/rejected`)

  useEffect(() => {
    if (error) {
      addToast(error?.response?.data?.message || 'Something went wrong.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }, [error])

  async function handleApplicantReactivate() {
    setLoading(true)
    try {
      await axiosInstance.put(`/${talent.id}/reactivate`)

      const filteredTalents = talents.filter(
        (applicant) => applicant.id !== talent.id
      )
      const selectedTalent = talent
      selectedTalent.applicant_status = 'UNLISTED'

      dispatch(updateTalents([selectedTalent, ...filteredTalents]))

      addToast(
        `${talent.name} is reactivated and declined tag as been removed.`,
        {
          appearance: 'success',
          autoDismiss: true,
        }
      )
      setLoading(false)
      dispatch(setModal(''))
    } catch (error) {
      setLoading(false)
      addToast(error?.response?.data?.message || 'Something went wrong.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }
  return (
    <MainModal
      info
      title="Reactivate Applicant"
      buttonText="Reactivate Applicant"
      onClick={handleApplicantReactivate}
      loading={loading}
    >
      <P padding="0 0 2rem 0">
        {talent?.name} has been rejected on{' '}
        {moment(data?.created_at).format('DD/MM/YYYY')} by [RECRUITER].
        Reactivating will enable this applicant to be processed by recruiters as
        a potential hire.{' '}
      </P>
      <P padding="0 0 0.5rem 0" color="#DC2929">
        REASON FOR REJECTION:
      </P>
      <P>{data?.message}</P>
    </MainModal>
  )
}

export default ReactivateApplicant
