import React from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import { IoCloseOutline } from 'react-icons/io5'
import { HIDE_MODAL } from '../../store/types/modal'
import ModalSteps from './ModalSteps'

const Modal = ({
  title,
  children,
  btnFunc,
  btnTitle,
  noCancel,
  publish,
  height,
  minHeight,
  onClose,
}) => {
  const dispatch = useDispatch()
  const { showModal } = useSelector((state) => state.modal)

  function handleOnClose() {
    if (onClose) {
      onClose()
    } else {
      dispatch({ type: HIDE_MODAL })
    }
  }

  return ReactDOM.createPortal(
    <ModalContainer
      height={height}
      minHeight={minHeight}
      publish={publish}
      className={showModal ? 'active' : ''}
    >
      <div className="overlay" onClick={handleOnClose} />
      <div className={noCancel ? 'modal-container resize' : 'modal-container'}>
        <div className="modal-header">
          <h1>{title}</h1>
          {publish && <ModalSteps />}
          <IoCloseOutline onClick={handleOnClose} className="close-button" />
        </div>

        <div className="modal-body">{children}</div>

        <div className="modal-footer">
          {!noCancel && (
            <button className="btn btn-close" onClick={handleOnClose}>
              Cancel
            </button>
          )}
          <button
            onClick={btnFunc}
            className={noCancel ? 'btn btn-primary active' : 'btn btn-primary'}
          >
            {btnTitle}
          </button>
        </div>
      </div>
    </ModalContainer>,
    document.getElementById('modal')
  )
}

export default Modal

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  background-color: rgba(71, 86, 116, 0.2);
  z-index: 102;
  align-items: center;
  justify-content: center;
  display: none;

  &.active {
    display: flex;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    cursor: pointer;
  }

  .modal-container {
    position: relative;
    width: 100%;
    max-width: 689px;
    min-height: ${({ minHeight }) => minHeight || 'auto'};
    height: ${({ height }) => height || '38.188rem'};
    max-height: 38.188rem;
    background-color: #fff;
    padding: 1rem 2.5rem;

    &.resize {
      max-width: 500px;
      height: 450px;
    }

    .modal-header {
      display: flex;
      flex-direction: ${({ publish }) => (publish ? 'column' : 'row')};
      align-items: center;
      justify-content: ${({ publish }) => (publish ? 'center' : 'flex-start')};
      position: relative;
      border-bottom: none;

      h1 {
        font-weight: 300;
        font-size: 1.5rem;
        letter-spacing: 0.03em;
        line-height: 1.5;
        color: #31374f;
      }

      .close-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1rem;
        font-size: 1rem;
        color: #858aa0;
        cursor: pointer;
      }
    }

    .modal-body {
      width: 100%;
      height: calc(100% - 10.5rem);
      overflow-y: auto;
      overflow-x: hidden;
      border: none;

      &.active {
        height: 100%;
      }

      &::-webkit-scrollbar {
        width: 5px;
        background: #edf0f4;
      }

      &::-webkit-scrollbar-thumb {
        background: #c9d1dc;
      }
    }

    .modal-footer {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: none;

      .btn {
        font-size: 0.75rem;
        font-weight: 400;
        color: #858aa0;
        padding: 0.75rem 1.3rem;
        border-radius: 100px;
        background: none;
        outline: none;
        cursor: pointer;

        &.btn-close {
          border: none;
        }

        &.btn-primary {
          border: 1px solid #1a1a1a;
          background-color: #1a1a1a;
          color: #fff;
          margin-left: 1rem;

          &:hover,
          &.active {
            border: 1px solid #1a1a1a;
            background-color: #1a1a1a;
            color: #fff;

            &:hover {
              background-color: #31374f;
              border: 1px solid #31374f;
              color: #fff;
            }
          }
        }
      }
    }
  }
`
