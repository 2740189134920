import React from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

import { H2, P } from '../../style/Typography.style'

const Heading = ({ title, subTitle, link }) => {
  return (
    <Styledheader>
      <div>
        <H2 align="left" mt="1.125rem" mb=".4rem">
          {title}
        </H2>
        <P>{subTitle}</P>
      </div>

      {link && <ActionButton to={link}>Manage Page</ActionButton>}
    </Styledheader>
  )
}

export default Heading

const Styledheader = styled.div`
  width: 100%;
  background: #f9f9fc;
  position: sticky;
  top: 0;
  z-index: 101;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
`

const ActionButton = styled(Link)`
  background: #f9f9fc;
  padding: 0.75rem 1rem;
  background: #fff;
  border: 1px solid #e5e5e5;
  text-decoration: none;
  color: #31374f;
  font-size: 0.75rem;
  font-weight: 300;
`
