import React from 'react'

import styled from 'styled-components'

const RadioButton = ({ name, value, onChange, id, checked }) => {
  return (
    <StyledRadioButton
      type="radio"
      name={name}
      checked={checked}
      value={value}
      onChange={onChange}
      id={id}
    />
  )
}

export default RadioButton

const StyledRadioButton = styled.input`
  width: 2rem;
  height: 2rem;
  border: 1px solid #aeb3c9;
  accent-color: #dc2929;
  cursor: pointer;
`
