import React, { useState } from 'react'

import styled from 'styled-components'
import TalentDetails from './TalentDetails'
import TalentLists from './TalentLists'
import TalentPoolSidebar from './TalentPoolSidebar'

const TalentPoolWrapper = () => {
  const [showDetails, setShowDetails] = useState(false)

  return (
    <TalentPoolWrapperStyled>
      <TalentPoolSidebar />
      <TalentLists setShowDetails={setShowDetails} showDetails={showDetails} />
      {showDetails && (
        <TalentDetails
          setShowDetails={setShowDetails}
          showDetails={showDetails}
        />
      )}
    </TalentPoolWrapperStyled>
  )
}

export default TalentPoolWrapper

const TalentPoolWrapperStyled = styled.section`
  width: 100%;
  display: flex;
  margin-top: 1rem;
`
