import React from 'react'
import styled from 'styled-components'

// icon
import { FaUserCircle } from 'react-icons/fa'
import { P } from '../../style/Typography.style'

import { capitalize } from '../../utils/capitalize'

const SidebarProfileCard = ({ show, currentUser }) => {
  return (
    <StyledSidebarProfileCard show={show}>
      <FaUserCircle className="icon" />
      {show && (
        <div className="userDetails">
          <P weight="500">{capitalize(currentUser?.name)}</P>
          <P size=".75rem">{capitalize(currentUser?.user_type)}</P>
        </div>
      )}
    </StyledSidebarProfileCard>
  )
}

export default SidebarProfileCard

const StyledSidebarProfileCard = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #ededfa;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  .icon {
    font-size: 1.5rem;
    color: #1a1a1a;
    margin-right: ${(p) => (p.show ? '1rem' : '0')};
  }
`
