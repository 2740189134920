import React from 'react'

import Select from 'react-select'
import styled from 'styled-components'

const DropdownSelect = ({ options, id, selected, onChange }) => {
  return (
    <StyledWrapper id={id}>
      <Select
        options={options}
        value={selected ?? options[0]}
        onChange={onChange}
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  width: 100%;
`

export default DropdownSelect
