import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ChangeInterviewer from './components/ChangeInterviewer'
import AddAskingSalary from './components/AddAskingSalary'
import ChangeVacancy from './components/ChangeVacancy'
import AddSchedule from './components/AddSchedule'
import UpdateStage from './components/UpdateStage'
import HeaderFilter from './components/HeadFilter'
import RejectApplicant from './components/RejectApplicant'
import AcceptApplicant from './components/AcceptApplicant'
import RequisitionListWrapper from './components/RequisitionListWrapper'
import ChangeInterviewLink from './components/ChangeInterviewLink'
import Heading from '../../../components/dashboard/Heading'
import DownloadCVModal from '../../../components/cv/DownloadCVModal'
import ApplicantDetailsModal from '../../../components/dashboard/TalentPool/ApplicantDetailsModal'

import useFetch from '../../../hooks/useFetch'

import { setRecruiters } from '../../../store/actions/recruitersActions'
import { updateTalents } from '../../../store/actions/talentActions'

const Requisitions = () => {
  const dispatch = useDispatch()
  const { modal } = useSelector((state) => state.lists)

  const { data: recruiters } = useFetch('/users/all?userTypeId=[1,5]')
  const { data: talents } = useFetch(`/applicants`)

  useEffect(() => {
    if (talents) {
      dispatch(updateTalents(talents.applicants))
    }
  }, [talents])

  useEffect(() => {
    if (recruiters) {
      dispatch(setRecruiters(recruiters?.resp))
    }
  }, [recruiters])

  return (
    <>
      {/* requisitions heading */}
      <Heading
        title="Requisitions"
        subTitle={moment().format('MMMM Do, YYYY')}
      />

      {/* requisitions filter, this is used to filter the table data */}
      <HeaderFilter />

      {/* requisition list wrapper */}
      <RequisitionListWrapper />

      {/* modals */}
      {modal.includes('changeRecruiter') && <ChangeInterviewer />}
      {modal.includes('changeVacancy') && <ChangeVacancy />}
      {modal.includes('editSchedule') && <UpdateStage />}
      {modal.includes('addInterviewSchedule') && <AddSchedule />}
      {modal.includes('applicantDetails') && <ApplicantDetailsModal />}
      {modal.includes('rejectApplicants') && <RejectApplicant />}
      {modal.includes('acceptApplicant') && <AcceptApplicant />}
      {modal.includes('changeInterviewLink') && <ChangeInterviewLink />}
      {modal.includes('addAskingSalary') && <AddAskingSalary />}
      <DownloadCVModal />
    </>
  )
}

export default Requisitions
