const emailTemplateTypes = {
  SET_MODAL: 'SET_MODAL',
  GET_REPLACED_TEXT: 'GET_REPLACED_TEXT',
  SET_PLACEHOLDER: 'SET_PLACEHOLDER',
  ADD_EMAIL_TEMPLATES: 'ADD_EMAIL_TEMPLATES',
  ADD_EMAIL_TEMPLATE: 'ADD_EMAIL_TEMPLATE',
  SHOW_EMAIL_TEMPLATES: 'SHOW_EMAIL_TEMPLATES',
  SET_SELECTED_EMAIL_TEMPLATE: 'SET_SELECTED_EMAIL_TEMPLATE',
  SET_PAGINATION_FOOTER: 'SET_PAGINATION_FOOTER',
  DATA_CHANGED: 'DATA_CHANGED',
}

export default emailTemplateTypes
