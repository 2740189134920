import React from 'react'

import { useSelector, useDispatch } from 'react-redux'

import styled from 'styled-components'
import { RiPencilFill } from 'react-icons/ri'
import { FaXmark, FaCheck } from 'react-icons/fa6'

import { equals } from '../../../../../utils/utils'

import {
  setList,
  setFormData,
  setSelectedRecord,
  setModal,
} from '../../../../../store/actions/clientActions'

const ContactListing = ({ data, renderedFrom = 'contactForm' }) => {
  const dispatch = useDispatch()

  const { modal } = useSelector((state) => state.clients)

  const contactLists = useSelector((state) => state.clients.lists.contact)
  const selectedContact = useSelector(
    (state) => state.clients.selectedRecord.contact
  )

  const handleEditContact = (e) => {
    e.stopPropagation()
    dispatch(setFormData('contact', data))
    dispatch(setSelectedRecord('contact', data))

    if (renderedFrom === 'clientDetailsModal') {
      dispatch(setModal([...modal, 'contactFormModal']))
    }
  }

  const handleUpdateStatus = (e) => {
    e.stopPropagation()
    const updatedContacts = contactLists.map((current) => {
      if (equals(data, current)) {
        if (current.status === 'Inactive') {
          return {
            ...current,
            status: 'Active',
          }
        }
        return {
          ...current,
          status: 'Inactive',
        }
      }
      return current
    })
    dispatch(setList({ listName: 'contact', data: updatedContacts }))
  }

  const handleClick = () => {
    if (renderedFrom === 'clientDetailsModal') {
      dispatch(setSelectedRecord('contact', data))
    }
  }

  const updateStatusIcon =
    renderedFrom === 'contactForm' ? (
      data?.status === 'Active' ? (
        <FaXmark onClick={(e) => handleUpdateStatus(e)} />
      ) : (
        <FaCheck onClick={(e) => handleUpdateStatus(e)} />
      )
    ) : null

  return (
    <>
      <StyledContactFormListing onClick={handleClick}>
        <div
          className={`poc_section ${renderedFrom} ${
            selectedContact.id === data.id &&
            renderedFrom === 'clientDetailsModal' &&
            'clicked'
          }`}
        >
          <div className="poc_header">
            <h5 className="poc_header_text">
              {data?.first_name +
                ' ' +
                data?.middle_name +
                ' ' +
                data?.last_name}
            </h5>
            <div className="poc_form_icons">
              <RiPencilFill onClick={handleEditContact} />
              {updateStatusIcon}
            </div>
          </div>
          <div className="poc_body">
            <div className="poc_contact_details">
              <h5>{data?.email}</h5>
              {', '}
              <h5>{data?.phone_number}</h5>
            </div>
            <h5 className={`status__name ${data?.status.toLowerCase()}`}>
              {data?.status}
            </h5>
          </div>
          <div className="poc_location">
            <h5>
              {data?.city}, {data?.country}
            </h5>
          </div>
        </div>
      </StyledContactFormListing>
    </>
  )
}

const StyledContactFormListing = styled.div`
  h5 {
    display: inline;
    font-size: 14px;
    font-weight: 300;
  }

  .poc_section {
    border: 1px solid #dfe2ec;
    border-radius: 3px;
    padding-left: 17px;
    margin: 12px 0px 8px 0px;
    cursor: pointer;

    &.contactForm {
      background: #ffffff;
    }

    &.clientDetailsModal {
      background: #f2f5f9;
    }

    .poc_header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: baseline;
      padding-top: 11px;

      .poc_header_text {
        font-weight: 500;
        font-size: 16px;
      }

      .poc_form_icons {
        display: flex;
        gap: 14px;
        font-size: 18px;
        margin-right: 14px;
        cursor: pointer;
      }
    }

    .poc_body {
      display: flex;
      justify-content: space-between;

      .poc_contact_details {
        display: inline;
        font-size: 14px;
      }

      .status__name {
        padding-right: 1rem;
        font-weight: 500;

        &.active {
          color: #00b300;
        }

        &.inactive {
          color: #ff0000;
        }
      }
    }

    .poc_location {
      padding-bottom: 0.5rem;
    }

    &.clicked {
      border-left: 6px solid #075bc9;
    }
  }
`

export default ContactListing
