import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MultiDropDownSelect } from '../../DropdownSelect'

import {
  fetchSkills,
  fetchProfessionalHeadlines,
  fetchPersonalSkills,
  setProfessionalHeadlinesFilter,
  setSkillsFilter,
  setSoftSkillsFilter,
} from '../../../../../store/actions/talentActions'

const Skills = () => {
  const dispatch = useDispatch()

  const {
    skills,
    skillsFilters,
    selectedSoftSkills,
    personalSkills,
    professionalHeadlines,
    professionalHeadlinesFilters,
  } = useSelector((state) => state.talents)

  useEffect(() => {
    dispatch(fetchProfessionalHeadlines())
    dispatch(fetchPersonalSkills())
  }, [])

  const handleProfessionalHeadlinesFilter = (value) => {
    dispatch(setProfessionalHeadlinesFilter(value))
    const headlineIds = value.map((e) => e.id)
    dispatch(fetchSkills(headlineIds))
  }

  const handleTechnicalSkillsFilter = (value) => {
    dispatch(setSkillsFilter(value))
  }
  const handleSoftSkillsFilter = (value) => {
    dispatch(setSoftSkillsFilter(value))
  }

  const newTechnicalSkills = skills.map((skill) => ({
    label: skill.skills,
    value: skill.id,
  }))

  return (
    <>
      <MultiDropDownSelect
        setSelectedOption={handleProfessionalHeadlinesFilter}
        selectedOption={professionalHeadlinesFilters}
        options={professionalHeadlines.map((professionalHeadline) => {
          return {
            value: professionalHeadline.id,
            label: professionalHeadline.professional_headline,
            id: professionalHeadline.id,
          }
        })}
        title="Professional Headlines"
        placeholder="Select Professional Headlines"
      />

      <MultiDropDownSelect
        setSelectedOption={handleTechnicalSkillsFilter}
        selectedOption={skillsFilters}
        options={newTechnicalSkills}
        title="Technical Skills"
        placeholder="Select Technical Skills"
      />

      <MultiDropDownSelect
        setSelectedOption={handleSoftSkillsFilter}
        selectedOption={selectedSoftSkills}
        options={personalSkills.map((personalSkill) => {
          return {
            value: personalSkill,
            label: personalSkill,
            id: personalSkill,
          }
        })}
        title="Personal Skills"
        placeholder="Select Soft Skills"
      />
    </>
  )
}

export default Skills
