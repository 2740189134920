import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import propTypes from 'prop-types'

import logo from '../assets/icons/logo.svg'
import avatar from '../assets/icons/avatar.png'

import { FiMessageSquare } from 'react-icons/fi'
import { IoMdNotificationsOutline } from 'react-icons/io'
import HeaderProfileDropdown from './HeaderProfileDropdown'
import NotificationDropdown from './NotificationDropdown'

import SocketIOClient from '../lib/SocketIOClient'

const Header = ({ token }) => {
  const [profileToggle, setProfileToggle] = useState(false)
  const [notificationToggle, setNotificationToggle] = useState(false)
  const [notViewedNotifications, setNotViewedNotifications] = useState(0)
  const [notifications, setNotifications] = useState([])

  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type

  const socket = useMemo(() => new SocketIOClient(), [])

  const handleProfileToggle = () => {
    setNotificationToggle(false)
    setProfileToggle(!profileToggle)
  }

  const handleNotificationToggle = () => {
    setNotificationToggle(!notificationToggle)
    setProfileToggle(false)
  }

  const handleUrlClick = (id) => {
    socket.emit('click_notification_url', {
      notificationId: id,
      userId: localStorage.getItem('id'),
      source: role,
    })
  }

  useEffect(() => {
    const userId = localStorage.getItem('id')
    if (userId) {
      socket.emit('join_room', {
        prefix: role,
        userId: userId,
      })
    }

    // socket listeners
    socket.on('joined_room', (data) => {
      socket.emit('get_notifications', {
        userId: data?.userId,
        source: role,
      })
    })

    socket.on(`receive_${role}_notifications`, (data) => {
      setNotifications([])
      setNotViewedNotifications(data?.notViewedNotifications)
    })
  }, [])

  return (
    <StyledHeader>
      <Link to="/" className="logo">
        <img src={logo} alt="hopla logo" />
      </Link>
      {!token ? (
        <div className="menu">
          <Link to="/">Home</Link>
          <Link to="/login">Login</Link>
        </div>
      ) : (
        <div className="menu">
          <div className="menuItem">
            <FiMessageSquare className="icon" />
          </div>
          <div onClick={handleNotificationToggle} className="menuItem">
            <IoMdNotificationsOutline className="icon" />
            {notViewedNotifications > 0 && (
              <span className="notificationCount">
                {notViewedNotifications}
              </span>
            )}
            {notificationToggle && (
              <NotificationDropdown
                notifications={notifications}
                onUrlClick={handleUrlClick}
              />
            )}
          </div>
          <div onClick={handleProfileToggle} className="menuItem">
            <img src={avatar} />
            {profileToggle && <HeaderProfileDropdown />}
          </div>
        </div>
      )}
    </StyledHeader>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  }
}

export default connect(mapStateToProps, {})(Header)

// eslint-disable-next-line react/no-typos
Header.propTypes = {
  isLogin: propTypes.bool,
}

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: #fff;
  width: 100%;
  max-width: 100vw;
  padding: 1.125rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfe9;

  a {
    text-decoration: none;
  }

  .logo {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    color: #1a1a1a;

    img {
      height: 2rem;
      width: auto;
      margin-right: 0.75rem;
    }
  }

  .menu {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      font-size: 1rem;
      font-weight: 300;
      color: #31374f;
      margin-left: 3.5rem;
    }

    .menuItem {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 2rem;
      position: relative;

      &:first-child {
        margin-left: 0;
      }

      .icon {
        font-size: 1.65rem;
        color: #31374f;
        cursor: pointer;
      }

      .notificationCount {
        position: absolute;
        top: 0rem;
        right: 0.1rem;
        min-width: 1rem;
        padding: 1.5px;
        height: 1rem;
        border-radius: 50%;
        background: #31374f;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.6rem;
      }

      img {
        object-fit: cover;
        cursor: pointer;
      }
    }
  }
`
