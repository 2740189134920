// this is the landing page for job posting.
import React from 'react'

import { useNavigate } from 'react-router'

import Flex from '../../../components/layouts/Flex'
import { H2 } from '../../../style/Typography.style'
import { IconButton } from '../../../components/Button'

// image for job posting icon
import job from '../../../assets/icons/job.svg'

// icons for buttons from react icons
import { FiEdit } from 'react-icons/fi'
import { IoChevronForwardOutline } from 'react-icons/io5'

const JobPostingHome = () => {
  const naviate = useNavigate()

  return (
    <>
      <Flex
        gap="2.5rem"
        height="100%"
        justify="center"
        direction="column"
        items="center"
      >
        <img src={job} alt="job posting icon" />
        <div>
          <H2 mb=".5rem" textAlign="center">
            Publish Job Posting
          </H2>
        </div>

        <Flex mobileDirection="column" maxw="688px" gap="24px" width="100%">
          <IconButton handleClick={() => naviate('/job-posting/create')}>
            <FiEdit className="leftIcon" />
            Create a new job post
            <IoChevronForwardOutline className="rightIcon" />
          </IconButton>
        </Flex>
      </Flex>
    </>
  )
}

export default JobPostingHome
