import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'

import { setModal, setPlaceholder, dataChanged, getReplacedText } from '../../../../store/actions/emailTemplateActions'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import Flex from '../../../../components/layouts/Flex'
import DashboardInput from '../../../../components/dashboard/DashboardInput'
import RichTextbox from '../../../../components/dashboard/controls/RichTextBox'

import useMutation from '../../../../hooks/useMutation'

const CreateEmailTemplate = () => {
  const { addToast } = useToasts()
  const { placeholder, replacedText } = useSelector((state) => state.emailTemplates)

  const dispatch = useDispatch()
  const { loading, error, success, handleRequest } = useMutation()

  const [templateName, setTemplateName] = useState('')
  const [templateDescription, setTemplateDescription] = useState('')
  const [subject, setSubject] = useState('')
  const [body, setBody] = useState('')

  useEffect(() => {
    if (success) {
      addToast(`Successfully created new email template for ${templateName}`, {
        appearance: 'success',
        autoDismiss: true,
      })
      dispatch(setModal(''))
      dispatch(dataChanged())
      setTemplateName('')
      setSubject('')
      setBody('')
    }
  }, [success])

  useEffect(() => {
    if (error) {
      addToast(error?.response?.data?.message || 'Something went wrong', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }, [error])

  useEffect(() => {
    if (replacedText) {
      setBody(body + placeholder)
    }

    if (placeholder) {
      setBody(body.replace(replacedText, placeholder));
    }

  }, [placeholder]);


  function handleCreateEmailTemplate() {
    let errorMessage = ''
    if (templateName.trim() === '')
      errorMessage = 'Template Name is required.'
    if (templateDescription.trim() === '')
      errorMessage = 'Template Description is required.'
    if (subject.trim() === '')
      errorMessage = 'Email Subject is required.'
    if (body.trim() === '')
      errorMessage = 'Email Body is required.'

    const request = {
      name: templateName,
      description: templateDescription,
      subject: subject,
      body: body,
    }

    if (errorMessage) {
      return addToast(errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    handleRequest('/email-template/create', request)
  }


  return (
    <MainModal
      title="Add Email Template"
      onClick={handleCreateEmailTemplate}
      buttonText="Add"
      loading={loading}
      size='large'
    >
      <Flex gap="0.4rem">
        <DashboardInput
          name="templateName"
          label="Template Name"
          placeholder=""
          type="text"
          height="2.5rem"
          value={templateName}
          handleChange={(e) => setTemplateName(e.target.value)}
        />
      </Flex>
      <Flex gap="0.4rem">
        <DashboardInput
          name="templateName"
          label="Template Description"
          placeholder=""
          type="text"
          height="2.5rem"
          value={templateDescription}
          handleChange={(e) => setTemplateDescription(e.target.value)}
        />
      </Flex>
      <Flex gap="0.4rem">
        <DashboardInput
          name="subject"
          label="Email Subject"
          placeholder=""
          type="text"
          height="2.5rem"
          value={subject}
          handleChange={(e) => setSubject(e.target.value)}
        />
      </Flex>
      <Flex gap="0.4rem">
        <RichTextbox
          label="Email Body"
          content={body}
          setContent={(val) => setBody(val)}
          additionalConfig={
            {
              cleanHTML: {
                fillEmptyParagraph: false
              },
              enter: 'br'
            }
          }
          customButtons={['hr', {
            name: 'Insert Placeholder',
            icon: 'custom-icon',
            exec: (editor) => {
              const selectedText = editor.selection.sel.toString()
              dispatch(getReplacedText(selectedText))
              dispatch(setModal('createPlaceholder'))
              dispatch(setPlaceholder(''))
            },
          }]}
        />
      </Flex>
    </MainModal>
  )
}

export default CreateEmailTemplate
