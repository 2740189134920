import { components } from 'react-select'

const Menu = (props) => {
    const optionSelectedLength = props.getValue().length || 0
    return (
      <components.Menu {...props}>
        {optionSelectedLength < 5 ? (
          props.children
        ) : (
          <div style={{ margin: 15 }}>Maximum input completed</div>
        )}
      </components.Menu>
    )
  }

  export default Menu