const userTypes = {
  SET_LOADING: 'SET_LOADING',
  SET_MODAL: 'SET_MODAL',
  FETCH_LIST_LOADING: 'FETCH_LIST_LOADING',
  FETCH_LIST_SUCCESS: 'FETCH_LIST_SUCCESS',
  FETCH_LIST_ERROR: 'FETCH_LIST_ERROR',
  SAVE_DATA_LOADING: 'SAVE_DATA_LOADING',
  ADD_SIDEBAR_MODULE_DATA_SUCCESS: 'ADD_SIDEBAR_MODULE_DATA_SUCCESS',
  UPDATE_DATA_SUCCESS: 'UPDATE_DATA_SUCCESS',
  UPDATE_USER_STATUS_SUCCESS: 'UPDATE_USER_STATUS_SUCCESS',
  SAVE_DATA_ERROR: 'SAVE_DATA_ERROR',
  FETCH_RECORD_LOADING: 'FETCH_RECORD_LOADING',
  FETCH_RECORD_SUCCESS: 'FETCH_RECORD_SUCCESS',
  FETCH_RECORD_ERROR: 'FETCH_RECORD_ERROR',
  SET_ERROR: 'SET_ERROR',
  SET_PAGINATION_OPTIONS: 'SET_PAGINATION_OPTIONS',
  SET_QUERY_PARAMS: 'SET_QUERY_PARAMS',
  FETCH_USER_TYPE: 'FETCH_USER_TYPE',
  DELETE_SIDEBAR_DATA_SUCCESS: 'DELETE_SIDEBAR_DATA_SUCCESS',
  RESET_NOTIFICATION: 'RESET_NOTIFICATION',
}

export default userTypes
