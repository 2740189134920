import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../components/Button'
import { PasswordInput } from '../components/Input'
import AuthLayout from '../layouts/AuthLayout'
import { H2 } from '../style/Typography.style'
import { LoginContainer } from './Login'

import { useNavigate, useParams } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import axios from 'axios'
import { baseUrl } from '../constant/constant'

const ResetPassword = () => {
  const { id } = useParams()
  const { addToast } = useToasts()
  const navigate = useNavigate()

  const [password, setPassword] = useState('')
  const [cPassword, setCPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  // split token with '-' and remove the last part of the array and join the array with '-'
  const tokenArray = id.split('-')
  const newToken = tokenArray.slice(1, tokenArray.length).join('-')
  const userId = tokenArray[0]

  const handleSubmit = (e) => {
    e.preventDefault()

    setIsLoading(true)

    if (password !== cPassword) {
      setIsLoading(false)
      addToast('Password and new password do not match', {
        appearance: 'error',
      })
    }

    if (password === '' && cPassword === '') {
      setIsLoading(false)
      addToast('Please enter a password and your new password', {
        appearance: 'error',
      })
    }

    if (password === cPassword && password.length > 0) {
      axios
        .post(`${baseUrl}/change-password`, {
          userId,
          newPassword: password,
          code: newToken,
        })
        .then((res) => {
          setIsLoading(false)
          navigate('/reset-success')
        })
        .catch((err) => {
          setIsLoading(false)
          setError(
            err.response.data ||
              err.response.data.message ||
              'Something went wrong!'
          )
          addToast(
            err.response.data ||
              err.response.data.message ||
              'Something went wrong!',
            { appearance: 'error' }
          )
        })
    }
  }

  return (
    <AuthLayout>
      <LoginContainer onSubmit={handleSubmit}>
        <H2>Reset your password</H2>

        <PasswordInput
          error={error !== ''}
          value={password}
          onChange={(value) => setPassword(value)}
          title="New Password"
        />

        <PasswordInput
          error={error !== ''}
          value={cPassword}
          onChange={(value) => setCPassword(value)}
          title="Confirm New Password"
        />

        <Button>{isLoading ? 'Loading...' : 'Reset'}</Button>

        <p className="mt-3">
          Already have an account?{' '}
          <Link className="iconLink" to="/login">
            Log in Here
          </Link>
        </p>
      </LoginContainer>
    </AuthLayout>
  )
}

export default ResetPassword
