import React, { useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import moment from 'moment'

import TableAction from './TableAction'
import TableTag from '../../TableTag'
import Table from '../../../../components/Table'
import Flex from '../../../../components/layouts/Flex'
import { P } from '../../../../style/Typography.style'

import {
  setModal,
  setPagination,
  setQueryParams,
} from '../../../../store/actions/userActions'

import { capitalize } from '../../../../utils/capitalize'
import { convertObjectToUrlParams } from '../../../../utils/stateHelpers'

const UserList = ({ fetchUserList }) => {
  const { users, pagination, loading, queryParams } = useSelector(
    (state) => state.users
  )

  const dispatch = useDispatch()

  const url = `/users?${convertObjectToUrlParams(queryParams)}`

  const fetchData = useCallback((link) => {
    fetchUserList(link)
  }, [])

  const handlePagination = (option) => {
    dispatch(setPagination(option))
    if ('page' in option) {
      dispatch(setQueryParams({ pageNumber: option.page }))
    }
    if ('pageSize' in option) {
      dispatch(setQueryParams({ pageSize: option.pageSize }))
      dispatch(setQueryParams({ pageNumber: 1 }))
    }
  }

  useEffect(() => {
    fetchData(url)
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Action',
        accessor: 'actions',
        Cell: ({ value }) => <TableAction value={value} />,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'User Type',
        accessor: 'role',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Contact Number',
        accessor: 'phone_number',
      },
      {
        Header: 'Country',
        accessor: 'country',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => {
          if (row?.original?.status === 'Active') {
            return <TableTag status="approved" content="Active" />
          } else {
            return <TableTag status="rejected" content="Inactive" />
          }
        },
      },
      {
        Header: 'Date Created',
        accessor: 'dateCreated',
      },
      {
        Header: 'Last Updated',
        accessor: 'lastUpdated',
      },
    ],
    []
  )

  const getUserName = (userType, user) => {
    switch (userType) {
      case 'admin':
        return user?.adminName
      case 'recruiter':
        return user?.recruiterName
      case 'interviewer':
        return user?.interviewerName
      case 'poc':
        return user?.pocName
      case 'cdm':
        return user?.cdmName
      default:
        return user?.applicantName
    }
  }

  const getRole = (user) => {
    if (user?.userType === 'recruiter' && user?.recruiter_assistant) {
      return 'Recruiter Assistant'
    }
    return user?.userType
  }

  const tableData = users?.map(
    (item) =>
      ({
        actions: item,
        name: getUserName(item?.userType, item),
        role: capitalize(getRole(item)),
        email: item.email || '',
        phone_number: item.phone_number || '',
        country: item.country || '',
        status: item.status === 1 ? 'Active' : 'Inactive',
        dateCreated: moment(item.created_at).format('DD/MM/YYYY') || '',
        lastUpdated: moment(item.created_at).format('DD/MM/YYYY') || ' || ""',
      } || '')
  )

  return (
    <StyledUserList>
      {users?.length > 0 ? (
        <Table
          data={tableData}
          columns={columns}
          loading={loading}
          totalPage={pagination?.pageCount}
          currentPage={pagination?.page}
          pageSize={pagination?.pageSize}
          rowCount={pagination?.rowCount}
          fetchData={fetchData}
          setNewPageSize={(e) => handlePagination({ pageSize: e })}
          setCurrentPage={(e) => handlePagination({ page: e })}
          url={url}
          noData="User List is empty"
          height="calc(105vh - 16.3rem)"
          onClick={() => {
            dispatch(setModal(''))
          }}
        />
      ) : (
        <Flex
          height="calc(100vh - 15rem)"
          bg="#fff"
          justify="center"
          items="center"
        >
          <P>User List is empty</P>
        </Flex>
      )}
    </StyledUserList>
  )
}

export default UserList

const StyledUserList = styled.div`
  width: 100%;
  height: 100%;
`
