import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import { SET_SHARE_MODAL_STEP } from '../../store/types/modal'

import { BsCheck2 } from 'react-icons/bs'

const Step = ({ step }) => {
  const dispatch = useDispatch()
  const { shareStep } = useSelector((state) => state.modal)
  return (
    <div
      onClick={() => dispatch({ type: SET_SHARE_MODAL_STEP, payload: step })}
      className={
        shareStep > step
          ? 'num active completed'
          : shareStep === step
          ? 'num active'
          : 'num'
      }
    >
      {shareStep > step ? <BsCheck2 /> : step}
    </div>
  )
}

const ModalSteps = () => {
  return (
    <StyledModalSteps>
      <Step step={1} />
      <Step step={2} />
      <Step step={3} />
    </StyledModalSteps>
  )
}

export default ModalSteps

const StyledModalSteps = styled.div`
  margin: 0 auto;
  width: 154px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: repeat(3, 1fr);
  overflow-x: hidden;

  .num {
    position: relative;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #d8deea;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
    font-weight: 300;
    cursor: pointer;

    &::after {
      position: absolute;
      content: '';
      top: 50%;
      transform: translateY(-50%);
      right: 100%;
      width: 3.3rem;
      height: 0.1rem;
      background: #d8deea;
    }

    &.active {
      background: #1a1a1a;
      font-weight: 700;
      color: #fff;

      &.completed {
        background: #fff;
        color: #1a1a1a;
        border: 1px solid #1a1a1a;
      }

      &::after {
        position: absolute;
        content: '';
        top: 50%;
        transform: translateY(-50%);
        right: 100%;
        width: 3.3rem;
        height: 0.1rem;
        background: #1a1a1a;
      }
    }
  }
`
