import React from 'react'

import styled from 'styled-components'

const IconWrapper = ({ children }) => {
  return <StyledIconWrapper>{children}</StyledIconWrapper>
}

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;

  .icon {
    font-size: 1rem;
    color: #31374f;
  }
`

export default IconWrapper
