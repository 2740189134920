import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'

import axiosInstance from '../../../../services/config/AxiosIntance'

import DashboardButton from '../../../../components/dashboard/DashboardButton'
import DashboardInput from '../../../../components/dashboard/DashboardInput'
import Select from '../../../../components/dashboard/controls/Select'

import Flex from '../../../../components/layouts/Flex'

import { setFilters,clearFilters } from '../../../../store/actions/recruitersActions'

import ReactDatePicker from 'react-datepicker'
import styled from 'styled-components'
import { P } from '../../../../style/Typography.style'
import moment from 'moment'

const HeaderFilter = () => {
  const dispatch = useDispatch()

  const [searchTerm, setSearchTerm] = useState('')
  const [roles, setRoles] = useState([])
  const [role, setRole] = useState('')
  const [startDate, setstartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [minDate, setMinDate] = useState(new Date())
  const [maxDate, setMaxDate] = useState(new Date())

  const [sortOrder, setSortOrder] = useState('DESC')

  const currentUser = localStorage.getItem('user')
  const useRole = JSON.parse(currentUser)?.user_type
  const userId = JSON.parse(currentUser)?.id
  const recruiterAssistant = !!JSON.parse(currentUser)?.recruiter_assistant

  const handleFilter = (e) => {
    e.preventDefault()
    const _role = role === 'All Roles' ? '' : role

    const formattedStartDate = formatDate(startDate)
    const formattedEndDate = formatDate(endDate, true)

    dispatch(setFilters({
       searchRole: encodeURIComponent(_role),
       search: encodeURIComponent(searchTerm),
       startDate: encodeURIComponent(formattedStartDate),
       endDate: encodeURIComponent(formattedEndDate)
      }));
  }

  const formatDate = (date, isEndOfDay = false) => {
    const formattedDate = moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    if (isEndOfDay) {
      return moment(formattedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    }
    return formattedDate;
  }

  const handleClear = (e) => {
    setSearchTerm('')
    setRole('All Roles')

    setInitialDateFilters()
    setSortOrder('DESC')
    dispatch(clearFilters())
  }

  useEffect(() => {
    dispatch(setFilters({ sort: encodeURIComponent(sortOrder) }))
  }, [sortOrder])

  useEffect(() => {
   setInitialDateFilters()

    let url = ''
    if (recruiterAssistant || useRole === 'admin') {
      url = `/job-roles`
    } else if (!recruiterAssistant && useRole === 'recruiter') {
      url = (`/job-roles?recruiterId=${userId}`)
    } else if (useRole === 'interviewer') {
      if (!url) {
        url = `/job-roles?interviewerId=${JSON.parse(currentUser)?.interviewerId}`
      }
    }
    axiosInstance.get('/job-roles').then((res) => {
      const data = res?.data?.data
      data.unshift({ value: 'All Roles', label: 'All Roles' })
      setRoles(data)
    })
  }, [])
  
  const setInitialDateFilters = () => {
    const startDate = new Date()
    startDate.setMonth(startDate.getMonth() - 1)
    setstartDate(startDate)
    axiosInstance.get('/dashboard/dates').then(resp => {
      const dates = resp.data.result[0]
      setMinDate(new Date(dates.oldest_date))
      setMaxDate(new Date(dates.newest_date))
    })
  }

  return (
    <StyledHeadFilter>
      <Flex
        width="100%"
        margin="1.5rem 0 0 0"
        items="center"
        justify="space-between"
        direction="column"
        gap="1rem"
      >
        <Flex
          width="100%"
          as="form"
          justify="flex-start"
          gap="1rem"
          onSubmit={handleFilter}
        >
          <DashboardInput
            maxWidth="15%"
            name="search"
            margin="0"
            marginBottom="0"
            height="2.5rem"
            value={searchTerm}
            handleChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search..."
          />
          <Select
            options={roles}
            onchange={(e) => setRole(e.target.value)}
            height="2.5rem"
            maxW="15%"
            defaultValue={role}
            value={role}
          />
          <label htmlFor="startDate" >Start Date</label>
          <ReactDatePicker
            id="startDate"
            selected={startDate}
            onChange={(e) => setstartDate(e)}
            dateFormat="MMMM dd, yyyy"
            placeholderText="Enter Start Date"
            className='react-date-picker'
            minDate={minDate}
            maxDate={endDate}
          />
          <label htmlFor="endDate" >End Date</label>
          <ReactDatePicker
            id="endDate"
            selected={endDate}
            onChange={(e) => setEndDate(e)}
            dateFormat="MMMM dd, yyyy"
            placeholderText="Enter End Date"
            className='react-date-picker'
            minDate={startDate}
            maxDate={maxDate}
          />
          <div className="sort">
            <P>Sort By:</P>
            <SelectSort onChange={(e) => setSortOrder(e.target.value)} value={sortOrder}>
              <option value="DESC">Newest First</option>
              <option value="ASC">Oldest First</option>
            </SelectSort>
          </div>
          <DashboardButton
            text='Search'
            type="button"
            onClick={handleFilter}
          />
          <DashboardButton
            text='Clear'
            type="button"
            onClick={handleClear}
          />
        </Flex>

      </Flex>
    </StyledHeadFilter>
  )
}

const SelectSort = styled.select`
  max-width: 150px;
  border: none;
  outline: none;
  background: none;
  font-size: 0.75rem;
  font-weight: 300;
`

const StyledHeadFilter = styled.div`
.sort{
  display: flex;
  justify-content: space-between;
  position: sticky;
  margin: 8px 0px;
  z-index: 100;
}

.margin-top {
  margin-top: 0.2rem;
}

.react-datepicker-popper{
  z-index: 1000
}

label{
  font-size: 0.875rem;
  text-align: center;
  font-weight: 300;
  color: #31374f;
  margin-top: 0.5rem;
}

.react-date-picker{
  font-size: 1rem;
  font-weight: 300;
  color: #31374f;
  margin-top: 0.2rem;
}
`

export default HeaderFilter