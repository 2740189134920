import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import useMutation from '../../../../hooks/useMutation'
import { P } from '../../../../style/Typography.style'

import {
  showApplicants,
  setModal,
  addLists,
} from '../../../../store/actions/listsActions'

import { updateTalents } from '../../../../store/actions/talentActions'

const MoveApplicant = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { applicant, moveList, applicants, selectedList, lists } = useSelector(
    (state) => state.lists
  )

  const { talents, talent } = useSelector((state) => state.talents)

  const { data, handleRequest, loading, success, error } = useMutation()

  function handleMoveList() {
    handleRequest(
      `applicant-list/${applicant.applicantId}/update`,
      { list_id: moveList.id.toString() },
      'PUT'
    )
  }

  useEffect(() => {
    if (data) {
      const filteredApplicants = applicants.filter(
        (user) => user.applicant_id !== applicant.applicantId
      )

      const filteredTalents = talents.filter(
        (talent) => talent.id !== applicant.applicantId
      )

      const selectedTalent = talent
      selectedTalent.listName = moveList.list_name

      const filteredLists = lists.filter(
        (newList) =>
          newList.id !== moveList.id && newList.id !== selectedList.id
      )

      const changedList = selectedList
      const updatedList = moveList

      changedList.numbersOfApplicantInList -= 1
      updatedList.numbersOfApplicantInList += 1

      dispatch(addLists([updatedList, changedList, ...filteredLists]))

      dispatch(updateTalents([selectedTalent, ...filteredTalents]))
      dispatch(showApplicants(filteredApplicants))

      dispatch(setModal(''))

      addToast(
        `${
          applicant?.applicantName
        } is moved to ${`${moveList?.list_name.substring(0, 20)}
        ${moveList?.list_name.length > 20 ? '...' : ''}`} and removed from ${
          selectedList?.list_name
        }`,
        {
          appearance: 'success',
          autoDismiss: true,
        }
      )
    }
  }, [success])

  useEffect(() => {
    if (error) {
      addToast(
        error?.response?.data?.message ||
          error?.response?.message ||
          'Something went wrong',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    }
  }, [error])

  return (
    <MainModal
      info
      title={`This applicant is already in a list`}
      buttonText="Move Applicant"
      loading={loading}
      onClick={handleMoveList}
    >
      <P size="1.1rem">
        {applicant?.applicantName} is already added to{' '}
        {`${selectedList?.list_name.substring(0, 20)}
        ${selectedList?.list_name.length > 20 ? '...' : ''}`}
        . Move applicant to
        {`${moveList?.list_name.substring(0, 20)}
        ${moveList?.list_name.length > 20 ? '...' : ''}`}
        ?
      </P>
    </MainModal>
  )
}

export default MoveApplicant
