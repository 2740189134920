import React from 'react'

import styled from 'styled-components'

const DashboardItemTitle = ({ title }) => {
  return <StyledTitle>{title}</StyledTitle>
}

export default DashboardItemTitle

const StyledTitle = styled.h5`
  font-weight: 300;
  font-size: 1.5rem;
`
