import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import MainModal from '../../../../components/dashboard/modal/MainModal'

import useMutation from '../../../../hooks/useMutation'
import useFetch from '../../../../hooks/useFetch'

import {
  setModal,
  showApplicants,
} from '../../../../store/actions/listsActions'
import { updateTalents } from '../../../../store/actions/talentActions'
import { P } from '../../../../style/Typography.style'


const AddApplicantToList = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { applicant, moveList, applicants } = useSelector(
    (state) => state.lists
  )

  const { talents } = useSelector((state) => state.talents)
  const { user } = useSelector((state) => state.auth)

  const { data, loading, success, error, handleRequest } = useMutation()

  const { data: channel } = useFetch('/channels')

  function handleAddList() {
    const selectedApplicant = talents.find(
      (user) => user?.id === applicant?.applicantId
    )

    const defaultChannel = channel.data.data.filter(x => x.channel === 'Careers Dashboard')[0].id
    const data = {
      applicant_id: applicant?.applicantId?.toString(),
      applicant_name: applicant?.applicantName || '',
      email: selectedApplicant?.email || '',
      job_id: moveList?.id ? moveList?.id.toString() : '',
      job_title: moveList?.title,
      added_by: user?.recruiterName || 'Recruiter',
      tags: 'Pending',
      channel_id: defaultChannel
    }

    if (selectedApplicant?.phone_number) {
      data.phone_number = selectedApplicant?.phone_number
    }

    handleRequest(
      `screening/${applicant.applicantId}/add-applicant`,
      data,
      'POST'
    )
  }

  useEffect(() => {
    if (data) {
      const filteredApplicants = talents.filter(
        (user) => user.id !== data.resp[0].id
      )

      const selectedApplicant = talents.find(
        (user) => user.id === data.resp[0].id
      )

      selectedApplicant.role = moveList?.title

      dispatch(showApplicants([...applicants, data.resp[0]]))
      dispatch(updateTalents([selectedApplicant, ...filteredApplicants]))
      dispatch(setModal(''))
      addToast(`Added ${applicant?.applicantName} to ${moveList?.title}`, {
        appearance: 'success',
        autoDismiss: true,
      })
    }
  }, [success])

  useEffect(() => {
    if (error) {
      addToast(
        error?.response?.data?.recruiterError ||
        error?.response?.message ||
        'Something went wrong',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    }
  }, [error])

  return (
    <MainModal
      title="Add Applicant to List"
      buttonText="Add"
      loading={loading}
      onClick={handleAddList}
    >
      <P>
        {applicant.applicantName} will be added to {moveList.title}?
      </P>
    </MainModal>
  )
}

export default AddApplicantToList
