import React from 'react'

import styled from 'styled-components'

const Select = ({
  options = [],
  defaultValue,
  onchange,
  firstOption,
  height,
  maxW,
}) => {
  return (
    <StyledSelect
      value={defaultValue}
      onChange={onchange}
      height={height}
      maxW={maxW}
    >
      {firstOption && (
        <option value={firstOption.value}>{firstOption.label}</option>
      )}
      {options.map((option, index) => {
        return (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        )
      })}
    </StyledSelect>
  )
}

export default Select

const StyledSelect = styled.select`
  width: 100%;
  max-width: ${({ maxW }) => maxW || 'auto'};
  height: ${({ height }) => height || '2.5rem'};
  outline: none;
  background: #ffffff;
  border: 0.5px solid #aeb3c9;
  font-size: 0.75rem;
  font-weight: 300;
`
