import React, { useEffect, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch } from 'react-redux'

import TalentCardPopList from '../../../../components/dashboard/TalentPool/TalentCardPopList'
import TalentCardPopout from '../../../../components/dashboard/TalentPool/TalentCardPopout'

import { setModal } from '../../../../store/actions/userActions'
import { capitalize } from '../../../../utils/capitalize'

const ActionsMenu = ({ setShowMenu, value }) => {
  const dispatch = useDispatch()
  const [currentStatus, setCurrentStatus] = useState('deactivate')

  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type

  useEffect(() => {
    if (value.status === 1) {
      setCurrentStatus('deactivate')
    } else if (value.status === 0) {
      setCurrentStatus('activate')
    }
  }, [])

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowMenu(false)
      }}
    >
      <TalentCardPopout maxW="199px" width="199px" top="0" left="76%">
        <TalentCardPopList
          onClick={(e) => {
            e.stopPropagation()
            dispatch(setModal('updateUser'))
          }}
          text="Edit User Profile"
        />
        <TalentCardPopList
          onClick={(e) => {
            e.stopPropagation()
            dispatch(setModal('updateStatus'))
          }}
          text={`${capitalize(currentStatus)} User`}
        />
        {role === 'admin' && (
          <TalentCardPopList
            onClick={(e) => {
              e.stopPropagation()
              dispatch(setModal('pwReset'))
            }}
            text={'Password Reset'}
          />
        )}
      </TalentCardPopout>
    </OutsideClickHandler>
  )
}

export default ActionsMenu
