import requisitionType from '../types/requisition'

const initialState = {
  modal: '',
  interviews: [],
  applicants: [],
  filters: {
    startDate: '',
    endDate: '',
    search: '',
    searchRole: '',
    sort: 'DESC',
  },
  selectedInterview: {},
  recruiters: [],
  selectedStage: 'applicant_screening',
  modalFooter: false,
  applicantListUrl: '',
  refreshList: '',
  updatedNote: null,
}

const recruiterReducer = (state = initialState, action) => {
  switch (action.type) {
    case requisitionType.SETMODAL:
      return {
        ...state,
        modal: action.payload,
      }
    case requisitionType.SETINTERVIEWS:
      return {
        ...state,
        interviews: action.payload,
      }
    case requisitionType.SETAPPLICANTS:
      return {
        ...state,
        applicants: action.payload,
      }
    case requisitionType.SETFILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }
    case requisitionType.CLEARFILTERS:
      return {
        ...state,
        filters: {
          ...initialState.filters,
        },
      }
    case requisitionType.SETUPDATEDSTAGE:
      return {
        ...state,
        updatedStage: action.payload,
      }
    case requisitionType.SETSELECTEDINTERVIEW:
      return {
        ...state,
        selectedInterview: action.payload,
      }
    case requisitionType.SETRECRUITERS:
      return {
        ...state,
        recruiters: action.payload,
      }
    case requisitionType.SETSTAGE:
      return {
        ...state,
        selectedStage: action.payload,
      }
    case requisitionType.SETMODALFOOTER:
      return {
        ...state,
        modalFooter: action.payload,
      }
    case requisitionType.SETAPPLICANTLISTURL:
      return {
        ...state,
        applicantListUrl: action.payload,
      }
    case requisitionType.REFRESHLIST:
      return {
        ...state,
        refreshList: action.payload,
      }
    case requisitionType.SETUPDATEDNOTE:
      return {
        ...state,
        updatedNote: action.payload,
      }
    default:
      return state
  }
}

export default recruiterReducer
