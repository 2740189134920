import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import { P } from '../../../../style/Typography.style'

import MainModal from '../../../../components/dashboard/modal/MainModal'

import useMutation from '../../../../hooks/useMutation'

import {
  setModal,
  showApplicants,
} from '../../../../store/actions/listsActions'
import { updateTalents } from '../../../../store/actions/talentActions'

const TagApplicant = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { applicant, moveList, applicants } = useSelector(
    (state) => state.lists
  )

  const { talents } = useSelector((state) => state.talents)

  const { data, loading, success, error, handleRequest } = useMutation()

  const handleTagApplicant = () => {
    const reqBody = {
      applicant_id: applicant.applicantId.toString(),
      job_id: moveList.id.toString(),
    }
    handleRequest(`pooling`, reqBody, 'POST')
  }

  useEffect(() => {
    if (data) {
      const filteredApplicants = talents.filter(
        (e) => e.id !== data?.resp?.applicant_id
      )

      const selectedApplicant = talents.find(
        (e) => e.id === data?.resp?.applicant_id
      )

      selectedApplicant.role = moveList?.title

      dispatch(showApplicants([...applicants, data.resp]))
      dispatch(updateTalents([selectedApplicant, ...filteredApplicants]))
      dispatch(setModal(''))
      addToast(
        `Successfully tagged ${applicant?.applicantName} to ${moveList?.title}`,
        {
          appearance: 'success',
          autoDismiss: true,
        }
      )
    }
  }, [success])

  useEffect(() => {
    if (error) {
      addToast(error?.response?.data || 'Something went wrong', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }, [error])

  return (
    <MainModal
      title="Tag Applicant"
      buttonText="Tag to Vacancy"
      loading={loading}
      onClick={handleTagApplicant}
    >
      <P>
        {applicant.applicantName} will be tagged to {moveList.title}?
      </P>
    </MainModal>
  )
}

export default TagApplicant
