import React, { useMemo, useRef } from 'react'

import JoditEditor from 'jodit-react'

import styled from 'styled-components'

const RichTextbox = ({
  content,
  setContent,
  isReadOnly,
  label,
  removeButtons = [],
  customButtons = {},
  additionalConfig,
  placeholder = 'Start typing text...',
}) => {
  const editor = useRef(null)
  const config = useMemo(() => {
    // Could not find the link of the codes for Jodit buttons so please dont delete the comments below and this one for reference purposes.
    const defaultButtons = [
      'paragraph',
      'bold',
      'underline',
      'italic',
      '|',
      'ul',
      'ol',
      '|',
      'link',
      '|',
      'undo',
      'redo',
      'preview',
    ]
    if (customButtons.length) {
      customButtons.forEach((value) => defaultButtons.push(value))
    }

    return {
      buttons: defaultButtons,
      toolbarAdaptive: false,
      showPlaceholder: true,
      addNewLine: false,
      placeholder: placeholder,
      readonly: isReadOnly,
      removeButtons: removeButtons,
      ...additionalConfig,
    }
  }, [isReadOnly])

  return (
    <StyledRichTextbox>
      {label && <label>{label}</label>}
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        // config={{
        //   style: {
        //     font: '18px Arial',
        //     color: 'red',
        //   },
        // }}
        tabIndex={1} // tabIndex of textarea
        // https://github.com/jodit/jodit-react/issues/217
        onBlur={(newContent) => setContent(newContent)}
      />
    </StyledRichTextbox>
  )
}

export default RichTextbox

const StyledRichTextbox = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  label {
    font-size: 0.75rem;
    font-weight: 300;
    color: #1a1a1a;
    margin-bottom: 4px;
  }

  .jodit {
    width: 100%;

    .jodit-placeholder {
      color: #aeb3c9 !important;
      font-size: 0.75rem !important;
      font-weight: 300 !important;
    }

    h1 {
      font-size: 1.5rem !important;
      font-weight: 500;
    }

    h2 {
      font-size: 1.4rem;
      font-weight: 400;
    }

    h3 {
      font-size: 1.25rem;
      font-weight: 500;
    }

    h4 {
      font-size: 1rem;
      font-weight: 700;
    }

    p {
      font-size: 0.875rem !important;
      font-weight: 300;
      line-height: 1.5;
    }

    li {
      margin-left: 1rem;
      font-size: 0.875rem;
      font-weight: 300;
    }

    .jodit-workplace {
      &:not(.jodit_inline) {
        border: 1px solid #aeb3c9;
        border-radius: 0 !important;

        .jodit-wysiwyg {
          width: 100%;
          height: 100%;
          overflow-y: auto;

          /* Styles for Job Summary - Content from Jodit 
            Data is populated from MS Word/Google Docs which creates inline styles and 
            pasted on Jodit Editor.
          */
          span {
            font-size: 16px !important;
            font-family: 'Lato', sans-serif !important;
            font-weight: 300 !important;
          }

          span[style*='font-weight:700;'] {
            font-weight: 700 !important;
          }
          span[style*='font-weight: 700;'] {
            font-weight: 700 !important;
          }

          /* For content pasted from MS Word  */
          .MsoNormal {
            strong {
              span {
                font-weight: 700 !important;
              }
            }
          }
        }
      }
    }
  }
`
