export function capitalize(sentence) {
    if (sentence) {
        const words = sentence?.split(" ");

        return words.map((word) => {
            if (word[0]) {
                return word[0].toUpperCase() + word.substring(1);
            }

            return word;
        }).join(" ");
    }
}