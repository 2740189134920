import React from 'react'

import styled from 'styled-components'

export const Button = ({
  handleClick,
  children,
  type,
  height,
  width,
  fontSize,
  bgColor = '#1a1a1a',
}) => {
  return (
    <StyledButton
      type={type}
      onClick={handleClick}
      height={height}
      width={width}
      fontSize={fontSize}
      bgColor={bgColor}
    >
      {children}
    </StyledButton>
  )
}

export const SocialButton = ({
  handleClick,
  title,
  children,
  color,
  bgColor,
}) => {
  return (
    <StyledButton
      color={color}
      bgColor={bgColor}
      className="social"
      onClick={handleClick}
    >
      {children}
      {title}
    </StyledButton>
  )
}

export const IconButton = ({
  handleClick,
  children,
  color,
  hoverColor,
  border,
  justify,
  gap,
}) => {
  return (
    <StyledIconButton
      border={border}
      justify={justify}
      gap={gap}
      color={color}
      hoverColor={hoverColor}
      onClick={handleClick}
    >
      {children}
    </StyledIconButton>
  )
}

const StyledButton = styled.button`
  width: ${(p) => (p.width ? p.width : '100%')};
  height: ${(p) => (p.height ? p.height : '3.375rem')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(p) => (p.fontSize ? p.fontSize : '1.125rem')};
  font-weight: 500;
  color: #fff;
  border-radius: 100px;
  /* background-color: #1a1a1a; */
  background-color: ${(p) => (p.bgColor ? p.bgColor : '#1a1a1a')};
  /* background-color: #1a1a1a; */
  cursor: pointer;
  outline: none;
  border: none;
  letter-spacing: 0.05em;

  &.social {
    justify-content: flex-start;
    font-size: 0.75rem;
    color: ${(p) => (p.color ? p.color : '#31374F')};
    padding: 0 1.5rem;
    background: none;
    border: 1px solid;
    border-color: ${(p) => (p.color ? p.color : '#AEB3C9')};
    margin-bottom: 1.5rem;
  }
`

const StyledIconButton = styled.button`
  position: relative;
  width: 100%;
  height: 4.75rem;
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || 'space-between'};
  padding: 1.3rem 1.5rem;
  border-radius: 100px;
  border: ${({ border }) => border || '1px solid #858aa0'};
  font-size: 0.85rem;
  font-weight: 300;
  letter-spacing: 0.03em;
  color: ${({ color }) => color || '#31374f'};
  background: none;
  gap: ${({ gap }) => gap || 'auto'};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: ${({ hoverColor }) => hoverColor || '#31374f'};
  }

  .leftIcon {
    font-size: 1.3rem;
    color: ${({ color }) => color || '#1a1a1a'};
  }

  .rightIcon {
    font-size: 1.3rem;
    color: #858aa0;
  }
`
