import React, { useEffect, useState } from 'react'

import AuthLayout from '../layouts/AuthLayout'
import styled from 'styled-components'

import mail from '../assets/icons/mail.svg'
import axios from 'axios'
import { ColorRing } from 'react-loader-spinner'
import { useNavigate } from 'react-router'
import { baseUrl } from '../constant/constant'

const VerificationEmail = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setEmail(localStorage.getItem('email'))
  }, [])

  const handleRetry = () => {
    setLoading(true)
    axios
      .post(`${baseUrl}/user/retry-verification`, {
        email: email,
        user_type: 'recruiter',
      })
      .then((res) => {
        setLoading(false)
        navigate('/verification')
      })
  }

  return (
    <AuthLayout width="500px">
      <StyledVerify>
        <img src={mail} alt="mail" className="icon" />
        <h1>Please verify your account</h1>
        <p>
          To continue using HOPLA Careers, please verify your email by clicking
          on the link we sent to your registered email: <b>{email}</b>
        </p>
        <p>
          <span onClick={handleRetry}>
            {loading ? (
              <ColorRing
                visible={true}
                ariaLabel="blocks-loading"
                color="#404040"
                height="16px"
                width="16px"
              />
            ) : (
              'Click here'
            )}
          </span>{' '}
          to resend a verification email
        </p>
      </StyledVerify>
    </AuthLayout>
  )
}

export default VerificationEmail

export const StyledVerify = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .errorImge {
    font-size: 5rem;
    color: #f44336;
  }

  .icon {
    width: 8rem;
    height: auto;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 300;
    letter-spacing: 5%;
    text-align: center;

    &.error {
      color: #f44336;
    }
  }

  p {
    font-size: 0.9rem;
    text-align: center;
    letter-spacing: 3%;
    line-height: 2rem;
    color: #404040;
    font-weight: 300;
    margin-top: 2rem;
    margin-bottom: 1rem;

    span {
      color: #fd2055;
      cursor: pointer;
    }
  }
`
