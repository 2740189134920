import React, { useRef } from 'react'

import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { AiFillCloseCircle } from 'react-icons/ai'

import { P } from '../../style/Typography.style'

import OtherInfo from './OtherInfo'
import Preview from './Preview'

import { SET_DOWNLOAD_MODAL } from '../../store/actions/types'

const CvWrapper = () => {
  const dispatch = useDispatch()
  const downloadRef = useRef(null)

  const handleDownloadModal = () => {
    dispatch({ type: SET_DOWNLOAD_MODAL, payload: false })
  }

  const handlePrint = useReactToPrint({
    content: () => downloadRef.current,
  })

  return (
    <StyledWrapper>
      <P size="1.5rem" color="#0E1324">
        Preview CV
      </P>

      <div className="contentWrapper">
        <Preview downloadRef={downloadRef} />
        <OtherInfo handlePrint={handlePrint} />

        <AiFillCloseCircle
          onClick={handleDownloadModal}
          className="closeIcon"
        />
      </div>
    </StyledWrapper>
  )
}

export default CvWrapper

const StyledWrapper = styled.section`
  width: 100%;

  .contentWrapper {
    display: flex;
    align-items: flex-start;
    margin-top: 1.5rem;
    gap: 2.5rem;

    .closeIcon {
      position: absolute;
      top: 2rem;
      right: 2rem;
      z-index: 12;
      width: 2rem;
      height: 2rem;
      color: red;
      cursor: pointer;
    }
  }
`
