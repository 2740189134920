/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'

import { IoCloseSharp } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { useToasts } from 'react-toast-notifications'
import { toast, ToastContainer } from 'react-toastify'
import styled from 'styled-components'
import axios from 'axios'

import AddSectionButton from '../../../../../components/dashboard/controls/AddSectionButton'
import Certification from '../../../../../components/dashboard/jobPosting/Certification'
import EducationalLevel from '../../../../../components/dashboard/jobPosting/EducationalLevel'
import ExperienceInput from '../../../../../components/dashboard/jobPosting/ExperienceInput'
import HardWareRequirement from '../../../../../components/dashboard/jobPosting/HardWareRequirement'
import Shift from '../../../../../components/dashboard/jobPosting/Shift'
import Software from '../../../../../components/dashboard/jobPosting/Software'
import Flex from '../../../../../components/layouts/Flex'
import AvailableToStart from '../../../job-posting/components/AvailableToStart'
import {
  Close,
  Grid,
  MultiSelectWrapper,
} from '../../../job-posting/components/Qualification'
import ModalFooter from './ModalFooter'

import {
  SET_CERTIFICATION,
  SET_EDUCATION,
  SET_EXPERIENCE,
  SET_HARDWARE_REQUIREMENT,
  SET_IS_CERTIFICATION,
  SET_IS_EDUCATION,
  SET_IS_EXPERIENCE,
  SET_IS_HARDWARE,
  SET_IS_LANGUAGE,
  SET_IS_PERSONAL_SKILLS,
  SET_IS_SHIFT,
  SET_IS_SKILL,
  SET_IS_SOFTWARE,
  SET_LANGUAGE,
  SET_PERSONAL_SKILLS,
  SET_SHIFT_AVAILABILITY,
  SET_SOFTWARE,
  SET_TECHNICAL_SKILLS,
} from '../../../../../store/types/jobPosting'
import { GET_SKILLS } from '../../../../../store/types/skills'
import {
  setAvailableToStart,
  setIsAvalibleToStart,
} from '../../../../../store/actions/jobPostingActions'

import axiosInstance from '../../../../../services/config/AxiosIntance'

import { languages } from '../../../../../constant/languages'
import { baseUrl } from '../../../../../constant/constant'

const QualificationUpdate = ({
  id,
  experience: initialExperience,
  education: initialEducation,
  certification: initialCertification,
  language: initialLanguage,
  personal_skills: initialPersonalSkills,
  technical_skills: initialTechnicalSkills,
  software: initialSoftware,
  hardware: initialHardware,
  availability_to_start: initialAvailabilityToStart,
  shift_availability: initialShiftAvailability,
  setOpenModal,
  status,
}) => {
  const dispatch = useDispatch()

  const [newLanguages, setNewLanguages] = useState([])

  const {
    isEducation,
    isLanguage,
    isShift,
    isExperience,
    isCertification,
    isSoftware,
    isHardware,
    isSkill,
    education,
    personal_skills,
    language,
    experience,
    technical_skills,
    isAvalableToStart,
    availability_to_start,
    isPersonalSkills,
    shift_availability,
    certification,
    software,
    hardware_requirement: job_posting_hardware_requirement,
  } = useSelector((state) => state.jobPosting)

  const { hardware_requirement } = useSelector(
    (state) => state.jobListing.selectedJob
  )

  const personalSkills = useSelector((state) => state.skills.personalSkills)
  const softSkills = useSelector((state) => state.skills.technicalSkills)

  const [newPersonalSkills, setNewPersonalSkills] = React.useState([])
  const [newSoftSkills, setNewSoftSkills] = React.useState([])

  const [initialState, setIntialState] = React.useState({
    experience: [],
    technical_skills: '',
    education: '',
    hardware: [],
    language: [],
    certification: '',
    personalSkills: [],
    shift: [],
    software: '',
    isEducation,
    isExperience,
    isHardware,
    isCertification,
    isLanguage,
    isShift,
    isSkill,
    isSoftware,
    isAvalableToStart,
    availability_to_start,
    isPersonalSkills,
  })

  const [newUpdate, setNewUpdate] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const { addToast } = useToasts()

  // handle first experience years input
  const handleExperienceYearOne = (e) => {
    const cloneExperience = [...experience]
    cloneExperience[0] = {
      years: e.target.value,
      specification: cloneExperience[0].specification || '',
    }

    dispatch({ type: SET_EXPERIENCE, payload: cloneExperience })
  }

  // handle first experience specification input
  const handleExperienceSpecificationOne = (e) => {
    const cloneExperience = [...experience]
    cloneExperience[0] = {
      years: cloneExperience[0].years,
      specification: e.target.value,
    }

    dispatch({ type: SET_EXPERIENCE, payload: cloneExperience })
  }

  // handle first experience years input
  const handleExperienceYearTwo = (e) => {
    const cloneExperience = [...experience]
    cloneExperience[1] = {
      years: e.target.value,
      specification: cloneExperience[1].specification || '',
    }

    dispatch({ type: SET_EXPERIENCE, payload: cloneExperience })
  }

  // handle first experience specification input
  const handleExperienceSpecificationTwo = (e) => {
    const cloneExperience = [...experience]
    cloneExperience[1] = {
      years: cloneExperience[1].years || '',
      specification: e.target.value,
    }

    dispatch({ type: SET_EXPERIENCE, payload: cloneExperience })
  }

  const loadSkills = () => {
    setNewPersonalSkills([])
    setNewSoftSkills([])
    dispatch({
      type: GET_SKILLS,
      payload: {
        personalSkills: [],
        technicalSkills: [],
      },
    })

    axios
      .get(`${baseUrl}/technical-skills?all=true`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('recruiterToken')}`,
        },
      })
      .then((res) => {
        const data = res.data.resp
        if (data) {
          const skills = data.map((x) => x.skills)
          dispatch({
            type: GET_SKILLS,
            payload: {
              personalSkills: [],
              technicalSkills: skills,
            },
          })
        }
      })
      .catch((err) => {
        addToast(
          err.response.data.message ||
            err.response.message ||
            'something went wrong while fetching clients data',
          {
            appearance: 'error',
            autoDismiss: true,
          }
        )
      })
  }

  useEffect(() => {
    if (newUpdate) return
    loadSkills()

    setIntialState((prevState) => ({
      ...prevState,
      experience: initialExperience,
      education: initialEducation,
      technical_skills: initialTechnicalSkills,
      hardware_requirement: initialHardware,
      language: initialLanguage,
      certification: initialCertification,
      shift_availability: initialShiftAvailability,
      software: initialSoftware,
      personalSkills: initialPersonalSkills,
      isEducation: !!initialEducation,
      isExperience: !!initialExperience,
      isHardware: !!initialHardware,
      isCertification: !!initialCertification,
      isLanguage: !!initialLanguage,
      isShift: !!initialShiftAvailability,
      isSkill: !!initialTechnicalSkills,
      isSoftware: !!initialSoftware,
      availability_to_start: initialAvailabilityToStart,
      isAvalableToStart: !!initialAvailabilityToStart,
      isPersonalSkills: !!initialPersonalSkills,
    }))

    if (personalSkills.length !== 0) {
      personalSkills.map((skill) =>
        setNewPersonalSkills((prevState) => [
          ...prevState,
          { value: skill, label: skill },
        ])
      )
    }

    if (softSkills.length !== 0) {
      softSkills.map((skill) =>
        setNewSoftSkills((prevState) => [
          ...prevState,
          { value: skill, label: skill },
        ])
      )
    }

    if (languages.length !== 0) {
      languages.map((lang) =>
        setNewLanguages((prevState) => [
          ...prevState,
          { value: lang.name, label: lang.name },
        ])
      )
    }

    dispatch({
      type: SET_EXPERIENCE,
      payload: initialExperience ? JSON.parse(initialExperience) : [],
    })
    dispatch({
      type: SET_TECHNICAL_SKILLS,
      payload: initialTechnicalSkills ? JSON.parse(initialTechnicalSkills) : [],
    })
    dispatch({ type: SET_EDUCATION, payload: initialEducation })
    dispatch({
      type: SET_HARDWARE_REQUIREMENT,
      payload: initialHardware ? JSON.parse(initialHardware) : [],
    })
    dispatch({
      type: SET_LANGUAGE,
      payload: initialLanguage ? JSON.parse(initialLanguage) : [],
    })
    dispatch({ type: SET_CERTIFICATION, payload: initialCertification })
    dispatch({
      type: SET_PERSONAL_SKILLS,
      payload: initialPersonalSkills ? JSON.parse(initialPersonalSkills) : [],
    })
    dispatch({
      type: SET_SHIFT_AVAILABILITY,
      payload: initialShiftAvailability,
    })
    dispatch({
      type: SET_SOFTWARE,
      payload: initialSoftware ? JSON.parse(initialSoftware) : [],
    })

    dispatch({
      type: SET_IS_SOFTWARE,
      payload: !!initialSoftware,
    })

    dispatch({
      type: SET_IS_HARDWARE,
      payload: !!initialHardware,
    })

    dispatch({
      type: SET_IS_CERTIFICATION,
      payload: !!initialCertification,
    })

    dispatch({
      type: SET_IS_LANGUAGE,
      payload: !!initialLanguage,
    })

    dispatch({
      type: SET_IS_SHIFT,
      payload: !!initialShiftAvailability,
    })

    dispatch({
      type: SET_IS_SKILL,
      payload: !!initialTechnicalSkills,
    })

    dispatch({
      type: SET_IS_EDUCATION,
      payload: !!initialEducation,
    })

    dispatch({
      type: SET_IS_PERSONAL_SKILLS,
      payload: !!initialPersonalSkills,
    })

    dispatch(setAvailableToStart(initialAvailabilityToStart))

    setNewUpdate(true)
  }, [personalSkills])

  const handleQualificationUpdate = async () => {
    if (experience[0].specification === '')
      return toast('Please enter experience specification', { type: 'error' })
    if (experience[0].years === '')
      return toast('Please enter experience years', { type: 'error' })

    if (technical_skills.length === 0)
      return toast('Please select technical skills', { type: 'error' })

    setLoading(true)

    const data = {
      experience: JSON.stringify(experience),
      technical_skills: JSON.stringify(technical_skills),
      hardware_requirement: JSON.stringify(job_posting_hardware_requirement),
    }

    if (education) data.education = education
    if (language.length !== 0) data.language = JSON.stringify(language)
    if (certification) data.certification = certification
    if (shift_availability) data.shift_availability = shift_availability
    if (software.length !== 0) data.software = JSON.stringify(software)
    if (personalSkills.length !== 0)
      data.personal_skills = JSON.stringify(personalSkills)
    if (availability_to_start)
      data.availability_to_start = availability_to_start

    try {
      const res = await axiosInstance.put(`/${id}/job`, data)
      if (res.status === 200) {
        toast('Qualification updated successfully', { type: 'success' })
        setLoading(false)
      }
    } catch (err) {
      toast(
        err.response.data.message ||
          err.response.message ||
          'something went wrong while updating qualification',
        { type: 'error' }
      )
      setLoading(false)
    }
  }

  const handleTechnicalSkills = (value) => {
    dispatch({ type: SET_TECHNICAL_SKILLS, payload: value })
  }

  const handleCloseAvailabilityToStart = () => {
    dispatch(setAvailableToStart(''))
    dispatch(setIsAvalibleToStart(initialState.isAvalableToStart))
    setIntialState((prevState) => ({
      ...prevState,
      isAvalableToStart: false,
    }))
  }

  const handleCloseEducationalLevel = () => {
    dispatch({ type: SET_EDUCATION, payload: '' })
    dispatch({ type: SET_IS_EDUCATION })
    setIntialState((prevState) => ({
      ...prevState,
      isEducation: false,
    }))
  }

  const handleCloseExperience = () => {
    dispatch({
      type: SET_EXPERIENCE,
      payload: [
        {
          years: '',
          specification: '',
        },
        {
          years: '',
          specification: '',
        },
      ],
    })
    dispatch({ type: SET_IS_EXPERIENCE })
    setIntialState((prevState) => ({
      ...prevState,
      isExperience: false,
    }))
  }

  const handleCloseHardwareRequirements = () => {
    dispatch({ type: SET_HARDWARE_REQUIREMENT, payload: [] })
    dispatch({ type: SET_IS_HARDWARE })
    setIntialState((prevState) => ({
      ...prevState,
      isHardware: false,
    }))
  }

  const handleLanguage = (value) => {
    dispatch({ type: SET_LANGUAGE, payload: value })
  }

  const handleCloseLanguage = () => {
    dispatch({ type: SET_LANGUAGE, payload: [] })
    dispatch({ type: SET_IS_LANGUAGE })
    setIntialState((prevState) => ({
      ...prevState,
      isLanguage: false,
    }))
  }

  const handleCloseCertification = () => {
    dispatch({ type: SET_CERTIFICATION, payload: '' })
    dispatch({ type: SET_IS_CERTIFICATION })
    setIntialState((prevState) => ({
      ...prevState,
      isCertification: false,
    }))
  }

  const handlePersonalSkills = (value) => {
    dispatch({ type: SET_PERSONAL_SKILLS, payload: value })
  }

  const handleClosePersonalSkills = () => {
    dispatch({ type: SET_PERSONAL_SKILLS, payload: [] })
    dispatch({ type: SET_IS_PERSONAL_SKILLS })
    setIntialState((prevState) => ({
      ...prevState,
      isPersonalSkills: false,
    }))
  }

  const handleCloseShift = () => {
    dispatch({ type: SET_SHIFT_AVAILABILITY, payload: '' })
    dispatch({ type: SET_IS_SHIFT })
    setIntialState((prevState) => ({
      ...prevState,
      isShift: false,
    }))
  }

  const handleCloseSoftware = () => {
    dispatch({ type: SET_SOFTWARE, payload: '' })
    dispatch({ type: SET_IS_SOFTWARE })
    setIntialState((prevState) => ({
      ...prevState,
      isSoftware: false,
    }))
  }

  const handleAddAvailableToStart = () => {
    dispatch(setIsAvalibleToStart(!initialState.isAvalableToStart))
    setIntialState((prevState) => ({
      ...prevState,
      isAvalableToStart: true,
    }))
  }

  const handleAddEducation = () => {
    dispatch({ type: SET_IS_EDUCATION })
    setIntialState((prevState) => ({
      ...prevState,
      isEducation: true,
    }))
  }

  const handleAddLanguage = () => {
    setIntialState((prevState) => ({
      ...prevState,
      isLanguage: true,
    }))
    dispatch({ type: SET_IS_LANGUAGE })
  }

  const handleAddShift = () => {
    setIntialState((prevState) => ({
      ...prevState,
      isShift: true,
    }))
    dispatch({ type: SET_IS_SHIFT })
  }

  const handleAddCertification = () => {
    setIntialState((prevState) => ({
      ...prevState,
      isCertification: true,
    }))
    dispatch({ type: SET_IS_CERTIFICATION })
  }

  const handleAddSoftware = () => {
    setIntialState((prevState) => ({
      ...prevState,
      isSoftware: true,
    }))
    dispatch({ type: SET_IS_SOFTWARE })
  }

  const handleAddHardwareRequirements = () => {
    dispatch({ type: SET_IS_HARDWARE })
    setIntialState((prevState) => ({
      ...prevState,
      isHardware: true,
    }))
  }

  const handleAddPersonalSkills = () => {
    dispatch({ type: SET_IS_PERSONAL_SKILLS })
    setIntialState((prevState) => ({
      ...prevState,
      isPersonalSkills: true,
    }))
  }

  const onClose = () => {
    dispatch({ type: SET_EXPERIENCE, payload: initialState.experience })
    dispatch({
      type: SET_TECHNICAL_SKILLS,
      payload: initialState.technical_skills,
    })
    dispatch({ type: SET_EDUCATION, payload: initialState.education })
    dispatch({ type: SET_HARDWARE_REQUIREMENT, payload: initialState.hardware })
    dispatch({ type: SET_LANGUAGE, payload: initialState.language })
    dispatch({ type: SET_CERTIFICATION, payload: initialState.certification })
    dispatch({
      type: SET_PERSONAL_SKILLS,
      payload: initialState.personalSkills,
    })
    dispatch({ type: SET_SHIFT_AVAILABILITY, payload: initialState.isShift })
    dispatch({ type: SET_SOFTWARE, payload: software })
    dispatch(setAvailableToStart(initialState.availability_to_start))
    setOpenModal(false)
  }
  return (
    <div>
      <StyledQualification>
        <ExperienceInput
          disabled={status === 'closed'}
          onChangeExperience={handleExperienceYearOne}
          onChangeSpecification={handleExperienceSpecificationOne}
          years={experience[0].years}
          specification={experience[0].specification}
        />

        <MultiSelectWrapper>
          <label>Required Technical Skills</label>
          <Select
            isDisabled={status === 'closed'}
            placeholder="Press enter to add more..."
            className="slider"
            options={newSoftSkills}
            isMulti
            value={technical_skills}
            onChange={handleTechnicalSkills}
          />
        </MultiSelectWrapper>

        {/* Availability to Start */}
        {initialState.isAvalableToStart && (
          <Flex margin="1.5rem 0 0 0" width="90%" position="relative">
            <MultiSelectWrapper>
              <label>Availability to Start</label>

              <AvailableToStart disabled={status === 'closed'} />
            </MultiSelectWrapper>
            {status !== 'closed' && (
              <Close bottom="-16%" onClick={handleCloseAvailabilityToStart}>
                <IoCloseSharp className="icon" />
              </Close>
            )}
          </Flex>
        )}

        {/* add Educational Level */}
        {initialState.isEducation && (
          <Flex margin="1.5rem 0 0 0" width="90%" position="relative">
            <EducationalLevel disabled={status === 'closed'} />
            {status !== 'closed' && (
              <Close onClick={handleCloseEducationalLevel}>
                <IoCloseSharp className="icon" />
              </Close>
            )}
          </Flex>
        )}

        {/* Experience added */}
        {initialState.isExperience && (
          <Flex margin=".5rem 0 0 0" width="90%" position="relative">
            <ExperienceInput
              disabled={status === 'closed'}
              onChangeExperience={handleExperienceYearTwo}
              onChangeSpecification={handleExperienceSpecificationTwo}
              years={experience[1].years}
              specification={experience[1].specification}
            />
            {status !== 'closed' && (
              <Close onClick={handleCloseExperience}>
                <IoCloseSharp className="icon" />
              </Close>
            )}
          </Flex>
        )}

        {/* Hardware Requirements added */}
        {initialState.isHardware && (
          <Flex margin=".5rem 0 0 0" width="90%" position="relative">
            <HardWareRequirement
              disabled={status === 'closed'}
              data={hardware_requirement}
            />
            {status !== 'closed' && (
              <Close onClick={handleCloseHardwareRequirements}>
                <IoCloseSharp className="icon" />
              </Close>
            )}
          </Flex>
        )}

        {/* Languages added */}
        {initialState.isLanguage && (
          <Flex width="90%" position="relative">
            <MultiSelectWrapper>
              <label>Language</label>
              <Select
                isDisabled={status === 'closed'}
                placeholder="Press enter to add more..."
                className="slider"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                options={newLanguages}
                value={language}
                onChange={handleLanguage}
                isMulti
              />
            </MultiSelectWrapper>
            {status !== 'closed' && (
              <Close onClick={handleCloseLanguage}>
                <IoCloseSharp className="icon" />
              </Close>
            )}
          </Flex>
        )}

        {/* Certification added */}
        {initialState.isCertification && (
          <Flex margin="1.5rem 0 0 0" width="90%" position="relative">
            <Certification readOnly={status === 'closed'} />
            {status !== 'closed' && (
              <Close onClick={handleCloseCertification}>
                <IoCloseSharp className="icon" />
              </Close>
            )}
          </Flex>
        )}

        {/* Personal Skills added */}
        {initialState.isPersonalSkills && (
          <Flex width="90%" position="relative">
            <MultiSelectWrapper>
              <label>Personal Skills</label>
              <Select
                isDisabled={status === 'closed'}
                placeholder="Press enter to add more..."
                className="slider"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                options={newPersonalSkills}
                value={personal_skills}
                onChange={handlePersonalSkills}
                isMulti
              />
            </MultiSelectWrapper>
            {status !== 'closed' && (
              <Close bottom="2%" onClick={handleClosePersonalSkills}>
                <IoCloseSharp className="icon" />
              </Close>
            )}
          </Flex>
        )}

        {/* Sift added */}
        {initialState.isShift && (
          <Flex margin="1.5rem 0 0 0" width="90%" position="relative">
            <Shift disabled={status === 'closed'} />
            {status !== 'closed' && (
              <Close onClick={handleCloseShift}>
                <IoCloseSharp className="icon" />
              </Close>
            )}
          </Flex>
        )}

        {/* Software added */}
        {initialState.isSoftware && (
          <Flex margin=".5rem 0 0 0" width="90%" position="relative">
            <Software disabled={status === 'closed'} />
            {status !== 'closed' && (
              <Close bottom="-16%" onClick={handleCloseSoftware}>
                <IoCloseSharp className="icon" />
              </Close>
            )}
          </Flex>
        )}
      </StyledQualification>

      {status !== 'closed' && (
        <StyledGrid>
          <Grid>
            <AddSectionButton
              onClick={handleAddAvailableToStart}
              text="Availability to Start"
              active={initialState.isAvalableToStart}
            />
            <AddSectionButton
              onClick={handleAddEducation}
              text="Education"
              active={initialState.isEducation}
            />
            <AddSectionButton
              onClick={handleAddLanguage}
              text="Language"
              active={initialState.isLanguage}
            />
            <AddSectionButton
              onClick={handleAddShift}
              text="Shift Preference"
              active={initialState.isShift}
            />

            <AddSectionButton
              onClick={handleAddCertification}
              text="License/Certification"
              active={initialState.isCertification}
            />
            <AddSectionButton
              onClick={handleAddSoftware}
              text="Software"
              active={initialState.isSoftware}
            />
            <AddSectionButton
              onClick={handleAddHardwareRequirements}
              text="Hardware Requirements"
              active={initialState.isHardware}
            />
            <AddSectionButton
              onClick={handleAddPersonalSkills}
              text="Personal Skills"
              active={initialState.isPersonalSkills}
            />
          </Grid>
        </StyledGrid>
      )}

      <ModalFooter
        readOnly={status === 'closed'}
        handleUpdate={handleQualificationUpdate}
        onClose={onClose}
        loading={loading}
      />

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default QualificationUpdate

const StyledQualification = styled.div`
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 0.5rem;
`

const StyledGrid = styled.div`
  width: 100%;
  padding-top: 0.75rem;
  border-top: 1px solid #e0e0e0;
  margin-top: 2rem;
`
