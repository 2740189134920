import React, { useEffect, useState, memo } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { useToasts } from 'react-toast-notifications'

import ReactDatePicker from 'react-datepicker'

import Flex from '../../../../components/layouts/Flex'
import { Button } from '../../../../components/Button'

import CreatableSelect from 'react-select/creatable'

import styled from 'styled-components'

import axiosInstance from '../../../../services/config/AxiosIntance'

import CSVDownloader from '../../../../lib/CSVDownloader'

import {
  setFilters,
  clearFilters,
  setDownload,
  setFooter,
} from '../../../../store/actions/dashboardActions'

const DashboardFilter = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const filters = useSelector((state) => state.dashboard.filters)

  const [jobs, setJobs] = useState([])
  const [selectedJobs, setSelectedJobs] = useState([])
  const [clients, setClients] = useState([])
  const [selectedClients, setSelectedClients] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [minDate, setMinDate] = useState(new Date())
  const [maxDate, setMaxDate] = useState(new Date())

  const dates = filters.dates

  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type
  const companyDetails =
    role === 'poc' && JSON.parse(currentUser)?.companyDetails

  useEffect(() => {
    let url = `/company`
    if (role === 'poc') {
      url += `?companyDetails=${JSON.stringify(companyDetails)}`
    }
    axiosInstance.get(url).then((response) => {
      const result = response.data.data.map((company) => ({
        value: company.id,
        label: company.name,
      }))
      setClients(result)
    })
  }, [])

  useEffect(() => {
    axiosInstance.get('/dashboard/dates').then((response) => {
      const dates = response.data.result[0]
      setMinDate(new Date(dates.oldest_date))
      setMaxDate(new Date(dates.newest_date))
    })
  }, [])

  useEffect(() => {
    let url = `/job?company_ids=${JSON.stringify(filters.company_id)}`
    if (role === 'poc') {
      url += `&companyDetails=${JSON.stringify(companyDetails)}`
    }
    axiosInstance.get(url).then((response) => {
      const result = response.data.data.map((job) => ({
        value: job.id,
        label: job.title,
      }))
      setJobs(result)
    })
  }, [filters.company_id])

  const handleStartDate = (date) => {
    dispatch(setFilters({ dates: [date, dates[1]] }))
    dispatch(setFooter({ dates: [date, dates[1]] }))
  }

  const handleEndDate = (date) => {
    dispatch(setFilters({ dates: [dates[0], date] }))
    dispatch(setFooter({ dates: [dates[0], date] }))
  }

  const handleJobDropdown = (e) => {
    setSelectedJobs(e)

    const values = e.map((x) => x.value)
    dispatch(setFilters({ job_id: values }))

    const labels = e.map((x) => x.label).join(', ')
    dispatch(setFooter({ job: labels }))
  }

  const handleClientDropdown = (e) => {
    setSelectedClients(e)

    const companyIds = e.map((x) => x.value)
    dispatch(setFilters({ company_id: companyIds }))

    const labels = e.map((x) => x.label).join(', ')
    dispatch(setFooter({ company: labels }))
  }

  const handleDownloadCSV = () => {
    const queryParams = new URLSearchParams(filters).toString()
    axiosInstance
      .get(`/dashboard/dashboardRawData?${queryParams}`)
      .then((res) => {
        let fileDate = ''
        const startDate = dates[0].toLocaleDateString().replace(/\//g, '')
        const endDate = dates[1].toLocaleDateString().replace(/\//g, '')
        if (startDate !== endDate) fileDate = `${startDate}-${endDate}`
        else fileDate = startDate
        const filename = `Dashboard_${fileDate}.csv`
        CSVDownloader.downloadUnparsedCSV(res.data.result, filename)
      })
      .catch((err) => {
        addToast(
          err?.response?.message ||
          err?.response?.data?.message ||
          'Internal Server Error',
          {
            appearance: 'error',
            autoDismiss: true,
          }
        )
      })
  }

  const handleDownloadPDF = () => {
    dispatch(setDownload('pdf'))
  }

  const handleResetFilters = () => {
    dispatch(clearFilters())
    setSelectedJobs([])
    setSelectedClients([])
  }

  useEffect(() => {
    setStartDate(new Date(dates[0]))
    setEndDate(new Date(dates[1]))
  }, [dates])

  return (
    <>
      <Flex direction="column">
        <Flex gap="0.5rem">
          <label htmlFor="startDate">Start Date</label>
          <ReactDatePicker
            id="startDate"
            selected={filters.dates[0]}
            onChange={(e) => handleStartDate(e)}
            dateFormat="MMMM dd, yyyy"
            placeholderText="Enter Start Date"
            minDate={minDate}
            maxDate={endDate}
          />
          <label htmlFor="endDate">End Date</label>
          <ReactDatePicker
            id="endDate"
            selected={filters.dates[1]}
            onChange={(e) => handleEndDate(e)}
            dateFormat="MMMM dd, yyyy"
            placeholderText="Enter End Date"
            minDate={startDate}
            maxDate={maxDate}
          />
        </Flex>
        <Flex
          width="100%"
          gap="2.5rem"
          items="center"
          margin="0.5rem 0.5rem 0 0"
        >
          <label htmlFor="select__clients">Client</label>
          <StyledWrapper>
            <CreatableSelect
              id="select__clients"
              isMulti
              onChange={handleClientDropdown}
              options={clients}
              value={selectedClients}
              placeholder="Select Clients"
            />
          </StyledWrapper>
        </Flex>
        <Flex
          width="100%"
          gap="1.5rem"
          items="center"
          margin="0.5rem 1.5rem 0.5rem 0"
        >
          <label htmlFor="select__jobs">Vacancy</label>
          <StyledWrapper>
            <CreatableSelect
              id="select__jobs"
              isMulti
              onChange={handleJobDropdown}
              options={jobs}
              value={selectedJobs}
              placeholder="Select Vacancies"
            />
          </StyledWrapper>
        </Flex>
        <Flex width="100%">
          <Button
            height="auto"
            fontSize="1rem"
            handleClick={handleResetFilters}
          >
            Clear Filters
          </Button>
        </Flex>

        <Flex width="100%" margin="0.5rem 1.5rem 0.5rem 0" gap="0.5rem">
          {role !== 'poc' && (
            <Button
              height="auto"
              fontSize="1rem"
              handleClick={handleDownloadCSV}
            >
              Download CSV
            </Button>
          )}
          <Button height="auto" fontSize="1rem" handleClick={handleDownloadPDF}>
            Download PDF
          </Button>
        </Flex>
      </Flex>
    </>
  )
}

const StyledWrapper = styled.div`
  width: 100%;
`

export default memo(DashboardFilter)
