import React, { useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import { useSelector, useDispatch } from 'react-redux'

import DashboardButton from '../../../../components/dashboard/DashboardButton'
import FilterInput from '../../../../components/dashboard/FilterInput'
import Flex from '../../../../components/layouts/Flex'

import {
  setQueryParams
} from '../../../../store/actions/userActions'

const HeaderFilter = ({ createUser }) => {
  const { loading } = useSelector((state) => state.users)
  const dispatch = useDispatch()

  const [filter, setFilter] = useState('')

  function handleFilter(e) {
    e.preventDefault();
    dispatch(setQueryParams({ searchTerm: filter }))
    // fetchList(`/users?searchTerm=${filter}`)
  }

  return (
    <Flex
      width="100%"
      margin="1.5rem 0 0 0"
      items="center"
      justify="space-between"
    >
      <Flex
        width="100%"
        as="form"
        items="center"
        justify="flex-start"
        gap="1rem"
        onSubmit={handleFilter}
      >
        <FilterInput
          maxW="300px"
          whiteSpace="nowrap"
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
          placeholder="Search..."
          handleClear={() => setFilter('')}
        />
        <DashboardButton
          text={loading ? 'Loading...' : 'Search'}
          type="submit"
        />
      </Flex>

      <Flex width="180px" items="center" gap="0.5rem">
        <DashboardButton
          text="Add User"
          type="button"
          notIcon
          externalIcon={<FiPlus className="icon" />}
          maxWidth="180px"
          onClick={createUser}
        />
      </Flex>
    </Flex>
  )
}

export default HeaderFilter
