const clientTypes = {
  SET_LOADING: 'SET_LOADING',
  SET_MODAL: 'SET_MODAL',
  SET_FORM_DATA: 'SET_FORM_DATA',
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  SET_PAGINATION_OPTIONS: 'SET_PAGINATION_OPTIONS',
  SET_FILTERS: 'SET_FILTERS',
  SET_LIST: 'SET_LIST',
  SET_SELECTED_RECORD: 'SET_SELECTED_RECORD',
  SET_LIST_CHANGED: 'SET_LIST_CHANGED',
  SET_DEFAULT_FORM_DATA: 'SET_DEFAULT_FORM_DATA',
}

export default clientTypes
