import { v4 as uuidv4 } from 'uuid'

export const getUUID = () => uuidv4()

export const updateList = (list = [], key, fields) => {
  const clonedList = list
  const index = clonedList.findIndex((e) => e[key] === fields[key])
  const selectedRecord = list[index]
  const updatedRecord = { ...selectedRecord, ...fields }
  let updatedList
  if (index !== -1) {
    const recordsBefore = clonedList.slice(0, index)
    const recordsAfter = clonedList.slice(index + 1)
    updatedList = [...recordsBefore, updatedRecord, ...recordsAfter]
  }
  return updatedList
}

export const deleteFromList = (list, obj) =>
  list.filter((item) => {
    return Object.keys(obj).every((key) => item[key] === obj[key]) !== true
  })

export const getPageButtons = (pageCount, cb) => {
  const pageNumbers = []
  for (let i = 1; i <= pageCount; i++) {
    pageNumbers.push(i)
  }

  const buttonList = []
  let startIndex = 0
  let endIndex = 0
  const maxVisibleButtons = 2

  for (let i = 0; i < pageNumbers.length; i++) {
    if (
      i === 0 ||
      i === pageNumbers.length - 1 ||
      (i >= startIndex && i <= endIndex)
    ) {
      buttonList.push(
        <button key={pageNumbers[i]} onClick={cb()}>
          {pageNumbers[i]}
        </button>
      )
    } else if (i < maxVisibleButtons + 1) {
      buttonList.push(
        <button key={pageNumbers[i]} onClick={cb()}>
          {pageNumbers[i]}
        </button>
      )
      endIndex++
    } else if (i > pageNumbers.length - (maxVisibleButtons + 2)) {
      buttonList.push(
        <button key={pageNumbers[i]} onClick={cb()}>
          {pageNumbers[i]}
        </button>
      )
      startIndex++
    } else if (i === maxVisibleButtons + 1) {
      buttonList.push(
        <button key={pageNumbers[i]} onClick={cb()}>
          ...
        </button>
      )
      startIndex = i
      endIndex = i
    }
  }
}

export const convertObjectToUrlParams = (object) => {
  return Object.keys(object)
    .map(
      (key) => encodeURIComponent(key) + '=' + encodeURIComponent(object[key])
    )
    .join('&')
}

export const handleLoadingState = (defaultLoading, targetLoading) => {
  const result = {}
  for (const key in defaultLoading) {
    if (key in targetLoading) {
      result[key] = targetLoading[key]
    } else {
      result[key] = false
    }
  }
  return result
}

export const isBoolean = (result) => {
  // This is because MySQL does not store boolean data but rather TINYINT(1) which is a number in JavaScript
  if (result === 1) {
    return true
  } else if (result === 0) {
    return false
  }
}
