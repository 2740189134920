import userTypes from '../types/users'
import { updateList } from '../../utils/stateHelpers'

const initialState = {
  modal: '',
  users: [],
  selectedRecord: {},
  pagination: {},
  loading: false,
  notification: {},
  queryParams: {
    searchTerm: '',
    pageNumber: 1,
    pageSize: 10,
  },
}

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case userTypes.SET_LOADING:
      return {
        ...state,
        loading: true,
      }
    case userTypes.SET_MODAL:
      return {
        ...state,
        loading: false,
        modal: action.payload,
      }
    case userTypes.SET_ERROR:
      return {
        ...state,
        notification: {
          value: 'error',
          message: action.payload,
        },
      }
    case userTypes.SET_PAGINATION_OPTIONS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      }
    case userTypes.SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          ...action.payload,
        },
      }
    case userTypes.FETCH_LIST_LOADING:
      return {
        ...state,
        loading: true,
      }
    case userTypes.FETCH_LIST_SUCCESS:
      return {
        ...state,
        pagination: action.payload.pagination,
        users: action.payload.users,
        loading: false,
      }
    case userTypes.FETCH_LIST_ERROR:
      return {
        ...state,
        notification: {
          value: 'error',
          message:
            action.payload.response.data.message || 'Something went wrong.',
        },
        loading: false,
      }
    case userTypes.FETCH_RECORD_LOADING:
      return {
        ...state,
        loading: true,
      }
    case userTypes.FETCH_RECORD_SUCCESS:
      return {
        ...state,
        selectedRecord: action.payload,
        loading: false,
      }
    case userTypes.FETCH_RECORD_ERROR:
      return {
        ...state,
        notification: {
          value: 'error',
          message:
            action.payload.response.data.message || 'Something went wrong.',
        },
        loading: false,
      }
    case userTypes.SAVE_DATA_LOADING:
      return {
        ...state,
        loading: true,
      }
    case userTypes.ADD_DATA_SUCCESS:
      return {
        ...state,
        users: [action.payload.data, ...state.users],
        modal: '',
        notification: {
          value: 'success',
          message: action.payload.message,
        },
        selectedRecord: {},
        loading: false,
      }
    case userTypes.UPDATE_DATA_SUCCESS: {
      return {
        ...state,
        users: updateList(state.users, 'id', action.payload.data),
        notification: {
          value: 'success',
          message: action.payload.message,
        },
        selectedRecord: {},
        loading: false,
        modal: '',
      }
    }

    case userTypes.SAVE_DATA_ERROR:
      return {
        ...state,
        notification: {
          value: 'error',
          message:
            action.payload.response.data.message || 'Something went wrong.',
        },
        loading: false,
      }

    default:
      return state
  }
}

export default usersReducer
