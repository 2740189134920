import React from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import ErrorModalHead from '../../../../components/dashboard/modal/ErrorModalHead'

import { P } from '../../../../style/Typography.style'

import { closeJob } from '../../../../store/actions/jobListingActions'

const ConfirmCloseJob = () => {
  const dispatch = useDispatch()

  const { id } = useParams()

  const { loading } = useSelector((state) => state.jobListing)

  return (
    <MainModal
      isOpen={true}
      buttonText="Close Job"
      loading={loading}
      error
      onClick={() => dispatch(closeJob(id))}
      header={<ErrorModalHead title="Confirm Close Job" />}
    >
      <P>
        Are you sure you want to close this job? This will move all applicants
        with pending applications in this job to the talent pool.
      </P>
    </MainModal>
  )
}

export default ConfirmCloseJob
