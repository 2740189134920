import React, { useState, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import Flex from '../../../../components/layouts/Flex'
import { SingleDropdownSelect } from '../../../../components/dashboard/TalentPool/DropdownSelect'

import { setModal } from '../../../../store/actions/listsActions'
import { setInterviews } from '../../../../store/actions/recruitersActions'

import useMutation from '../../../../hooks/useMutation'

import axiosInstance from '../../../../services/config/AxiosIntance'

const ChangeInterviewer = () => {
  const [recruiterId, setRecruiterId] = useState('')
  const [calendarUrls, setCalendarUrls] = useState([])
  const [selectedUrl, setSelectedUrl] = useState('')
  const [interviewerName, setInterviewerName] = useState('')
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { selectedInterview, interviews, recruiters } = useSelector(
    (state) => state.requisitions
  )
  const { data, loading, handleRequest, success, error } = useMutation()

  function handleSelectRecruiter(e) {
    const selectedIndex = e.target.selectedIndex
    const selectedOption = e.target.options[selectedIndex]
    const label = selectedOption.textContent
    setInterviewerName(label)
    setRecruiterId(e.target.value)
    setSelectedUrl('')
  }

  function handleSelectCalendarUrl(e) {
    setSelectedUrl(e.target.value)
  }

  function handleSubmit() {
    if (!recruiterId) {
      return addToast('Recruiter field is required.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    if (selectedInterview?.stages === 'first_interview') {
      if (!selectedUrl) {
        return addToast('Calendly Url field is required.', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    }

    handleRequest(
      `/interviews/${selectedInterview.id}/update`,
      {
        recruiter_id: recruiterId.toString(),
        applicant_id: selectedInterview.applicant_id.toString(),
        calendar_url: selectedUrl,
      },
      'PUT'
    )
  }

  // check if request was successful.
  useEffect(() => {
    if (data) {
      const filteredInterview = interviews.filter(
        (interview) => interview.id !== selectedInterview.id
      )

      const updatedInterview = selectedInterview
      updatedInterview.recruiter_id = recruiterId
      updatedInterview.interviewer = interviewerName

      dispatch(setInterviews([updatedInterview, ...filteredInterview]))

      addToast('Interviewer updated successfully!', {
        appearance: 'success',
        autoDismiss: true,
      })

      dispatch(setModal(''))
    }
  }, [success])

  useEffect(() => {
    if (recruiterId) {
      axiosInstance.get(`/${recruiterId}/calendly_url`).then((res) => {
        setCalendarUrls(res.data.data.calendly_urls)
      }).catch((err) => console.log(err))
    }
  }, [recruiterId])

  // check if request was unsuccessful.
  useEffect(() => {
    if (error) {
      addToast(
        error.message ||
          error.data.message ||
          error.response.data.message ||
          'something went wrong.',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    }
  }, [error])

  return (
    <>
      <MainModal
        title={`Change Interviewer`}
        buttonText="Submit"
        loading={loading}
        onClick={handleSubmit}
      >
        <Flex direction="column" gap="0.4rem" margin="1rem 0 0 0">
          <SingleDropdownSelect
            onChange={(e) => {
              handleSelectRecruiter(e)
            }}
            title="Interviewer"
          >
            <option value="">Choose an option</option>
            {recruiters?.map((recruiter) => {
              if (recruiter?.name) {
                return (
                  <option
                    key={recruiter?.id}
                    value={recruiter?.id}
                  >
                    {recruiter?.name}
                  </option>
                )
              }
              return null
            })}
          </SingleDropdownSelect>
        </Flex>
        {selectedInterview?.stages === 'first_interview' && (
          <Flex direction="column" gap="0.4rem" margin="1rem 0 0 0">
            <SingleDropdownSelect
              onChange={(e) => {
                handleSelectCalendarUrl(e)
              }}
              title="Calendly Url"
            >
              <option value="">Choose an option</option>
              {calendarUrls?.map((url, index) => {
                return (
                  <option key={index} value={url}>
                    {url}
                  </option>
                )
              })}
            </SingleDropdownSelect>
          </Flex>
        )}
      </MainModal>
    </>
  )
}

export default ChangeInterviewer
