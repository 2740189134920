import React from 'react'

import styled from 'styled-components'

const SwitchToggle = ({
  active,
  setActive,
  size = 'large',
  maxw,
  firstText,
  secondText,
}) => {
  return (
    <StyledToggle
      size={size}
      maxw={maxw}
      onClick={() => {
        setActive()
      }}
    >
      <div
        className={active ? 'active-overlay active' : 'active-overlay inactive'}
      />
      <div className="switch-section">
        <span className={active && 'active'}>{firstText}</span>
      </div>
      <div className="switch-section">
        <span className={!active && 'active'}>{secondText}</span>
      </div>
    </StyledToggle>
  )
}

export default SwitchToggle

const StyledToggle = styled.div`
  width: 100%;
  max-width: ${({ maxw }) => maxw || '292px'};
  height: ${({ size }) => (size === 'small' ? '1.5rem' : '2.3125rem')};
  border-radius: 100px;
  background: #efeff7;
  overflow: hidden;
  display: flex;
  position: relative;
  cursor: pointer;

  .switch-section {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ size }) => (size === 'small' ? '0.625rem' : '0.875rem')};
    font-weight: 300;
    color: #676e92;

    span {
      position: relative;

      &.active {
        color: #0e1324;
      }
    }
  }

  .active-overlay {
    position: absolute;
    left: 0;
    width: 50%;
    height: 100%;
    border-radius: 100px;
    background: #fff;
    border: 0.5px solid #aeb3c9;
    transition: all 0.3s ease-in-out;

    &.active {
      left: 0;
    }

    &.inactive {
      left: 50%;
    }
  }
`
