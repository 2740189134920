import React from 'react'

import { useSelector, useDispatch } from 'react-redux'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import ErrorModalHead from '../../../../components/dashboard/modal/ErrorModalHead'
import InfoModalHead from '../../../../components/dashboard/modal/InfoModalHead'

import { P } from '../../../../style/Typography.style'

import {
  setModal,
  setNotification,
  setListChanged,
  setLoading,
} from '../../../../store/actions/clientActions'

import axiosInstance from '../../../../services/config/AxiosIntance'

const ConfirmChangeClientStatus = () => {
  const dispatch = useDispatch()

  const loadingChangeClientStatus = useSelector(
    (state) => state.clients.loading.changeClientStatus
  )

  const selectedClient = useSelector(
    (state) => state.clients.selectedRecord.client
  )

  const handleConfirmChangeStatus = () => {
    axiosInstance
      .put(`/company/${selectedClient.id}/statusUpdate`, {
        status: selectedClient.status_name === 'Active' ? 'Inactive' : 'Active',
      })
      .then((response) => {
        dispatch(setLoading({ name: 'changeClientStatus', value: true }))
        dispatch(setNotification('success', response?.data?.message))
        dispatch(setListChanged('client'))
      })
      .catch((err) => {
        dispatch(setNotification('error', err?.response?.data?.message))
      })
      .finally(() => {
        dispatch(setLoading({ name: 'changeClientStatus', value: false }))
        dispatch(setModal([]))
      })
  }

  const handleClose = () => dispatch(setModal([]))

  return (
    <MainModal
      buttonText={
        selectedClient.status_name === 'Active' ? 'Deactivate' : 'Activate'
      }
      loading={loadingChangeClientStatus}
      onClick={handleConfirmChangeStatus}
      onClose={handleClose}
      {...(selectedClient.status_name === 'Active'
        ? { error: true }
        : { info: true })}
      header={
        selectedClient.status_name === 'Active' ? (
          <ErrorModalHead title={`Deactivate ${selectedClient?.name}`} />
        ) : (
          <InfoModalHead title={`Activate ${selectedClient?.name}`} />
        )
      }
    >
      {/*  */}
      <P>
        Are you sure you want to{' '}
        {selectedClient?.status_name === 'Active' ? 'deactivate' : 'activate'}{' '}
        <strong>{selectedClient?.name}</strong> and their contacts?{' '}
        {selectedClient.status_name === 'Active'
          ? 'This action cannot be undone'
          : 'Reactivating this client will allow them to post jobs in the system'}
        .
      </P>
    </MainModal>
  )
}

export default ConfirmChangeClientStatus
