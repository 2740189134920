import React from 'react'

import { useSelector, useDispatch } from 'react-redux'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import ErrorModalHead from '../../../../components/dashboard/modal/ErrorModalHead'

import { P } from '../../../../style/Typography.style'

import {
  setModal,
  deleteRecord,
} from '../../../../store/actions/sidebarModuleActions'

const ConfirmDeleteModule = () => {
  const dispatch = useDispatch()

  const { loading, selectedRecord } = useSelector(
    (state) => state.sidebarModules
  )

  const handleConfirmDeleteModule = () => {
    dispatch(deleteRecord(selectedRecord.id))
  }

  const handleClose = () => dispatch(setModal(''))

  return (
    <MainModal
      buttonText="Delete Module"
      loading={loading}
      onClick={handleConfirmDeleteModule}
      onClose={handleClose}
      error={true}
      header={<ErrorModalHead title={`Delete module`} />}
    >
      {/*  */}
      <P>
        Are you sure you want to delete this module? This process is
        irreversible and the deleted module can no longer be accessed in the
        system.
      </P>
    </MainModal>
  )
}

export default ConfirmDeleteModule
