import React, { useEffect, useState } from 'react'

import styled from 'styled-components'
import useMutation from '../../../hooks/useMutation'

import { useToasts } from 'react-toast-notifications'
import { useDispatch } from 'react-redux'

import RichTextbox from '../controls/RichTextBox'

import { OPEN_ACTIVITIES_MODAL } from '../../../store/actions/types'

import { setUpdatedNote } from '../../../store/actions/recruitersActions'

const EditNote = ({ id, data }) => {
  const { addToast } = useToasts()

  const dispatch = useDispatch()

  const [content, setContent] = useState([])

  const { error, success } = useMutation()

  useEffect(() => {
    if (success) {
      dispatch({ type: OPEN_ACTIVITIES_MODAL, payload: 'notes' })
      addToast('Note saved successfully.', { appearance: 'success' })
    }
    if (error) {
      addToast(error?.response?.data, { appearance: 'error' })
    }
  }, [success, error])

  const parser = (note) => {
    try {
      if (note?.notes) {
        return JSON.parse(note?.notes)[0]
      } else {
        return ''
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleNoteChanged = (e) => {
    const newNoteData = {
      ...content,
      notes: [e],
    }

    dispatch(setUpdatedNote(newNoteData))
  }

  useEffect(() => {
    if (id) {
      const note = data?.resp?.filter((x) => x.id === id)[0]
      setContent(note)
    } else {
      setUpdatedNote(null)
    }
  }, [id])

  return (
    <StyledTextareaWrapper>
      <RichTextbox
        content={parser(content)}
        setContent={handleNoteChanged}
        isReadOnly={false}
      />
    </StyledTextareaWrapper>
  )
}

export default EditNote

export const BorderlessButton = styled.button`
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: #858aa0;
  background: none;
  border: none;
  outline: none;
  float: left;
  padding: 0.3rem;

  &:hover {
    color: #1a1a1a;
  }
`

const StyledTextareaWrapper = styled.form`
  width: 100%;
  position: relative;

  label {
    font-size: 0.75rem;
    font-weight: 300;
    color: #1a1a1a;
    margin-bottom: 0.25rem;
    display: block;
  }

  textarea {
    width: 100%;
    height: 4.375rem;
    padding: 0.375rem;
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1.5;
    border: 1px solid #dfdfe9;
    outline: none;
    transition: border 0.2s ease-in;

    &:hover {
      border: 1px solid #1a1a1a;
    }
  }

  .loader-wrapper {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }

  .details_rightarea {
    float: right;
  }
`
