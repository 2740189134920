import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import Heading from '../../../components/dashboard/Heading'
import HeaderFilter from './components/HeaderFilter'
import EmailTemplateListWrapper from './components/EmailTemplateListWrapper'
import CreateEmailTemplate from './components/CreateEmailTemplate'
import UpdateEmailTemplate from './components/UpdateEmailTemplate'
import SetPlaceholder from './components/SetPlaceholder'
import SendEmail from './components/SendEmail'

const EmailTemplates = () => {
  const { modal } = useSelector((state) => state.emailTemplates)

  return (
    <>
      {/* Email Template Lists heading */}
      <Heading title="Email Template Lists" subTitle={moment().format('MMMM Do, YYYY')} />
      {/* Email Template Lists filter, it contains the search filter */}
      <HeaderFilter />
      {/* Contains list of email templates */}
      <EmailTemplateListWrapper />
      
      {/* modal section */}
      {(modal === 'createEmailTemplate' || modal === 'createPlaceholder') && <CreateEmailTemplate />}
      {(modal === 'updateEmailTemplate' || modal === 'updatePlaceholder') && <UpdateEmailTemplate />}
      {(modal === 'createPlaceholder' || modal === 'updatePlaceholder') && <SetPlaceholder />}
      {(modal === 'sendEmail') && <SendEmail />}
    </>
  )
}

export default EmailTemplates
