import listTypes from '../types/lists'

export const setModal = (modalType) => {
  return { type: listTypes.SET_MODAL, payload: modalType }
}

export const addLists = (lists) => {
  return { type: listTypes.ADD_LISTS, payload: lists }
}

export const updateLists = (list) => {
  return { type: listTypes.UPDATE_LISTS, payload: list }
}

export const addList = (list) => {
  return { type: listTypes.ADD_LIST, payload: list }
}

// show applicants in a list
export const showApplicants = (applicants) => {
  return { type: listTypes.SHOW_APPLICANTS, payload: applicants }
}

// select an applicant
export const selectApplicant = (applicant) => {
  return { type: listTypes.SELECT_APPLICANT, payload: applicant }
}

// select a recruiter
export const selectRecruiter = (recruiter) => {
  return { type: listTypes.SELECT_RECRUITER, payload: recruiter }
}

// move an applicant to a list
export const moveList = (list) => {
  return { type: listTypes.MOVE_LIST, payload: list }
}

// select an applicant list
export const selectApplicantList = (applicantList) => {
  return { type: listTypes.SELECTED_APPLICANT_LIST, payload: applicantList }
}

// set pagination
export const setPagination = (options) => {
  return { type: listTypes.SET_PAGINATION, payload: options }
}
