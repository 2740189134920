import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'

import styled from 'styled-components'
import Pagination from 'react-responsive-pagination'

import { P } from '../../../style/Typography.style'

import TalentCard from './TalentCard'
import TalentControls from '../../../pages/dashboard/talent-pool/components/TalentControls'

import {
  sortTalents,
  filterTalents,
  setBulkAction,
} from '../../../store/actions/talentActions'

import { setModal } from '../../../store/actions/listsActions'

const TalentLists = ({
  showDetails,
  setShowDetails,
  isLoading,
  filterTalents,
}) => {
  const [active, setActive] = useState('')

  const talents = useSelector((state) => state.talents.talents)

  const pagination = useSelector((state) => state.talents.pagination)

  const handlePageChange = (pageNumber) => {
    filterTalents(pageNumber)
  }

  return (
    <StyledTalentLists>
      {isLoading ? (
        <P size=".75rem">Loading...</P>
      ) : (
        <>
          <TalentControls />
          <>
            {talents && talents.length > 0 ? (
              <>
                {talents.map((talent, index) => (
                  <TalentCard
                    setShowDetails={setShowDetails}
                    showDetails={showDetails}
                    talent={talent}
                    setActive={setActive}
                    active={active}
                    key={talent?.email || index}
                    index={index}
                  />
                ))}
                <Pagination
                  total={pagination?.pageCount || 1}
                  current={pagination?.page || 1}
                  onPageChange={(page) => handlePageChange(page, pagination)}
                />
              </>
            ) : (
              <P size=".75rem">No applicants found</P>
            )}
          </>
        </>
      )}
    </StyledTalentLists>
  )
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.talents.isLoading,
  }
}

export default connect(mapStateToProps, {
  sortTalents,
  filterTalents,
  setBulkAction,
  setModal,
})(TalentLists)

const StyledTalentLists = styled.div`
  width: 100%;
  max-width: 30.25rem;
  height: 100%;
  margin-top: 1rem;
  padding-bottom: 6rem;
  box-sizing: border-box;
`
