import React from 'react'
import styled from 'styled-components'

import { FiCheck } from 'react-icons/fi'
import { ClipLoader } from 'react-spinners'

const ListSelector = ({ text = '', active, onClick, loading }) => {
  return (
    <StyledList onClick={onClick} className={active ? 'active' : ''}>
      <FiCheck className={active ? 'check-icon active' : 'check-icon'} />
      {text}
      {loading && (
        <div className="load_wrapper">
          <ClipLoader color="#1a1a1a" size="0.75rem" />
        </div>
      )}
    </StyledList>
  )
}

export default ListSelector

const StyledList = styled.div`
  width: 100%;
  height: 2rem;
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 300;
  color: #0e1324;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  position: relative;

  .check-icon {
    color: #5cca95;
    font-size: 1rem;
    visibility: hidden;

    &.active {
      visibility: visible;
    }
  }

  .load_wrapper {
    position: absolute;
    top: 50%;
    right: 0.75rem;
    transform: translateY(-50%);
  }

  &:hover,
  &.active {
    background: #eeeef6;
    .check-icon {
      visibility: visible;
    }
  }
`
