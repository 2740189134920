import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import styled from 'styled-components'
import moment from 'moment'

import NotificationItem from './notifications/NotificationItem'
import EmptyNotification from './notifications/EmptyNotification'

import VirtualizedList from '../components/VirtualizedList'

import SocketIOClient from '../lib/SocketIOClient'

const NotificationDropdown = ({ top, onUrlClick }) => {
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    const socket = new SocketIOClient()
    const fetchDataAndSetNotifications = () => {
      socket.emit('get_notification_list', {
        userId: localStorage.getItem('id'),
        source: 'recruiter',
      })
    }

    const handleReceivedNotification = (data) => {
      setNotifications(data.notifications)
    }

    socket.on(
      'received_recruiter_notification_list',
      handleReceivedNotification
    )
    fetchDataAndSetNotifications()

    const interval = setInterval(() => {
      fetchDataAndSetNotifications()
    }, 5000)

    return () => {
      clearInterval(interval)
      socket.off(
        'received_recruiter_notification_list',
        handleReceivedNotification
      )
    }
  }, [])

  return ReactDOM.createPortal(
    <StyledNotificationDropdown top={top} itemLength={notifications.length}>
      {notifications.length ? (
        <VirtualizedList data={notifications} className="notifications-list">
          {({ item }) => (
            <NotificationItem
              key={item.id}
              message={item.message}
              date={moment(item.created_at).format('LL')}
              status={parseInt(item.status_id) === 3 ? 'notViewed' : 'viewed'}
              url={item.url}
              onUrlClick={() => onUrlClick(item.id)}
            />
          )}
        </VirtualizedList>
      ) : (
        <EmptyNotification />
      )}
    </StyledNotificationDropdown>,
    document.getElementById('header_popout')
  )
}

export default NotificationDropdown

const StyledNotificationDropdown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 7%;
  right: 6%;
  width: 302px;
  height: calc(600px - ${(props) => (props.itemLength === 0 ? 22 : 7)}rem);
  background: #fff;
  border: 1px solid #eeeef6;
  box-sizing: border-box;
  box-shadow: 3px 3px 7px rgba(178, 178, 203, 0.2);
  border-radius: 1px;
  padding: 1rem;
  z-index: 103;

  .notifications-list {
    margin-bottom: 0.5rem;
    position: relative;
    right: 7.5rem;
  }
`
