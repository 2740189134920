const phases = [
  {
    value: 'applicant_screening',
    label: 'Screening',
  },
  {
    value: 'first_interview',
    label: '1st Interview',
  },
  {
    value: 'second_interview',
    label: '2nd Interview',
  },
  {
    value: 'Skill_assesment',
    label: 'Skill Assessment',
  },
  {
    value: 'client_profiling',
    label: 'Client Profiling',
  },
  {
    value: 'client_interview',
    label: 'Client Interview',
  },
  {
    value: 'offer',
    label: 'Offer',
  },
  {
    value: 'closed',
    label: 'Closed',
  },
]

export default phases
