import React, { useMemo, useCallback, useEffect, useState, memo } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'

import styled from 'styled-components'
import moment from 'moment'

import { P } from '../../../../style/Typography.style'
import Table from '../../../../components/Table'
import Flex from '../../../../components/layouts/Flex'
import TableAction from './TableAction'
import TableTag from '../../TableTag'

import axiosInstance from '../../../../services/config/AxiosIntance'

import {
  setLoading,
  setList,
  setNotification,
  setPagination,
  setFormData,
  setModal,
  setSelectedRecord,
} from '../../../../store/actions/clientActions'

import { convertObjectToUrlParams } from '../../../../utils/stateHelpers'

const ClientList = () => {
  const dispatch = useDispatch()
  const lists = useSelector((state) => state.clients.lists, shallowEqual)
  const pagination = useSelector(
    (state) => state.clients.pagination,
    shallowEqual
  )
  const loading = useSelector((state) => state.clients.loading, shallowEqual)
  const listChanged = useSelector(
    (state) => state.clients.listChanged,
    shallowEqual
  )
  const filters = useSelector((state) => state.clients.filters, shallowEqual)
  const modal = useSelector((state) => state.clients.modal, shallowEqual)

  const [url, setUrl] = useState('')

  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type

  const fetchData = useCallback((url) => {
    axiosInstance
      .get(url)
      .then((response) => {
        dispatch(setLoading({ name: 'clientList', value: true }))
        dispatch(setList({ listName: 'client', data: response.data.result }))
        dispatch(setPagination(response.data.pagination))
      })
      .catch((err) => {
        dispatch(setNotification('error', err?.response?.data?.message))
      })
      .finally(() => dispatch(setLoading({ name: 'clientList', value: false })))
  }, [])

  const handlePagination = (option) => {
    setPagination((prev) => ({ ...prev, ...option }))

    setUrl(
      `/companies/table?${convertObjectToUrlParams({
        ...filters,
        ...option,
      })}`
    )
  }

  const handleEditClient = (fields) => {
    axiosInstance
      .get('/company?id=' + fields.id)
      .then((response) => {
        const result = response.data.data[0]

        // TODO: Remove below code
        delete result.client_status_id
        delete result.user_id
        delete result.created_at
        delete result.updated_at

        dispatch(setFormData('client', result))
        dispatch(setModal([...modal, 'clientForm']))
      })
      .catch((err) => {
        dispatch(setNotification('error', err?.response?.data?.message))
      })

    dispatch(setLoading({ name: 'contactList', value: true }))

    let requestUrl = `/poc/users?company_id=${fields.id}`

    if (role === 'poc') {
      const companyDetails = JSON.parse(currentUser)?.companyDetails
      requestUrl += `&companyDetails=${JSON.stringify(companyDetails)}`
    }

    axiosInstance
      .get(requestUrl)
      .then((response) => {
        const result = response.data.data
        const fetchedContacts = result.map((contact) => {
          return {
            id: contact.id,
            user_id: contact.user_id,
            company_id: contact.company_id,
            first_name: contact.first_name,
            middle_name: contact.middle_name,
            last_name: contact.last_name,
            email: contact.email,
            phone_number: contact.phone_number,
            city: contact.city,
            country: contact.country,
            status: contact.status,
            monitor_all_jobs: contact.monitor_all_jobs,
          }
        })
        dispatch(setList({ listName: 'contact', data: fetchedContacts }))
        dispatch(setLoading({ name: 'contactList', value: false }))
        dispatch(setSelectedRecord('contact', fetchedContacts[0]))
      })
      .catch((err) => {
        dispatch(setNotification('error', err?.response?.data?.message))
      })
  }

  const handleUpdateClientStatus = (fields) => {
    dispatch(setModal([...modal, 'confirmChangeClientStatus']))
    dispatch(setSelectedRecord('client', fields))
  }

  const handleRowClick = (fields) => {
    axiosInstance
      .get('/company?id=' + fields.id)
      .then((response) => {
        const result = response.data.data[0]
        dispatch(setSelectedRecord('client', result))
        dispatch(setModal([...modal, 'clientDetailsModal']))
      })
      .catch((err) => {
        dispatch(setNotification('error', err?.response?.data?.message))
      })
  }

  useEffect(() => {
    let requestUrl = `/companies/table?${convertObjectToUrlParams({
      ...filters,
    })}`

    if (role === 'poc') {
      const companyDetails = JSON.parse(currentUser)?.companyDetails
      requestUrl += `&companyDetails=${JSON.stringify(companyDetails)}`
    }

    setUrl(requestUrl)
  }, [filters])

  useEffect(() => {
    fetchData(url)
  }, [url, listChanged.client])

  const columns = useMemo(
    () => [
      {
        Header: 'Action',
        accessor: 'actions',
        Cell: ({ cell }) => {
          const value = cell.row.original

          return (
            <TableAction
              value={value}
              onEditClient={() => handleEditClient(value)}
              onChangeClientStatus={() => handleUpdateClientStatus(value)}
            />
          )
        },
      },
      {
        Header: 'Client',
        accessor: 'name',
      },
      {
        Header: 'Sector',
        accessor: 'sector',
      },
      {
        Header: 'Email',
        accessor: 'client_email',
      },
      {
        Header: 'Client Website',
        accessor: 'client_website',
        Cell: ({ value }) => (
          <StyledURLRow href={`${value}`} rel="noreferrer" target="_blank">
            {value}
          </StyledURLRow>
        ),
      },
      {
        Header: 'Total Active Listings',
        accessor: 'total_active_listings',
      },
      {
        Header: 'Status',
        accessor: 'status_name',
        Cell: ({ row }) => {
          if (row?.original?.status_name === 'Active') {
            return <TableTag status="approved" content="Active" />
          } else {
            return <TableTag status="rejected" content="Inactive" />
          }
        },
      },
      {
        Header: 'Date Created',
        accessor: 'dateCreated',
      },
      {
        Header: 'Last Updated',
        accessor: 'lastUpdated',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      lists?.client?.map((item) => ({
        ...item,
        dateCreated: moment(item.created_at).format('DD/MM/YYYY'),
        lastUpdated: moment(item.updated_at).format('DD/MM/YYYY'),
      })),
    [lists?.client]
  )

  let component

  let updatedColumns = columns

  if (role === 'poc') {
    updatedColumns = columns.filter((e) => e.accessor !== 'actions')
  }

  if (loading.clientList) {
    component = (
      <Flex
        height="calc(100vh - 15rem)"
        bg="#fff"
        justify="center"
        items="center"
      >
        <P>Loading...</P>
      </Flex>
    )
  } else {
    if (tableData?.length > 0) {
      component = (
        <Table
          data={tableData}
          columns={updatedColumns}
          loading={loading.clientList}
          totalPage={pagination?.pageCount}
          currentPage={pagination?.page}
          pageSize={pagination?.pageSize}
          rowCount={pagination?.rowCount}
          fetchData={fetchData}
          setNewPageSize={(e) => handlePagination({ pageSize: e })}
          setCurrentPage={(e) => handlePagination({ page: e })}
          url={url}
          noData="Client List is empty"
          height="calc(100vh - 16.3rem)"
          onClick={(row) => {
            handleRowClick(row)
          }}
        />
      )
    } else {
      component = (
        <Flex
          height="calc(100vh - 15rem)"
          bg="#fff"
          justify="center"
          items="center"
        >
          <P>Client List is empty</P>
        </Flex>
      )
    }
  }

  return <StyledClientList>{component}</StyledClientList>
}

export default memo(ClientList)

const StyledClientList = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 16rem);
  background: #fff;
  border: 0.5px solid #aeb3c9;
  border-bottom: none;
  margin-top: 1rem;
  overflow-y: hidden;
`
const StyledURLRow = styled.a`
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
`
