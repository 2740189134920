import React from 'react'

import styled from 'styled-components'
import ListApplicants from './ListApplicants'
import Lists from './Lists'

const ListsWrapper = () => {
  return (
    <StyledGridWrapper>
      <Lists />
      <ListApplicants />
    </StyledGridWrapper>
  )
}

export default ListsWrapper

const StyledGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 270px 1fr;
  gap: 1rem;
  margin-top: 1.25rem;
  height: calc(100vh - 18rem);
`
