import React, { useEffect, useState } from 'react'
import countries from 'all-countries-and-cities-json'
import { MultiDropDownSelect, SingleDropdownSelect } from '../../DropdownSelect'
import { useDispatch, useSelector } from 'react-redux'

import {
  setSecondFilter,
  setCountryFilter,
} from '../../../../../store/actions/talentActions'

const LocationFilter = () => {
  const dispatch = useDispatch()

  const { country, secondFilter } = useSelector((state) => state.talents)

  const [, setCountries] = useState([])
  const [cities, setCities] = useState([])

  useEffect(() => {
    Object.keys(countries).forEach((country) => {
      setCountries((prevState) => [
        ...prevState,
        { value: country, label: country },
      ])
    })
  }, [])

  const handleCountryChange = (e) => {
    const country = e.target.value
    dispatch(setCountryFilter(country))

    // check is there is an array with the key of country

    for (const [key, value] of Object.entries(countries)) {
      if (key === country) {
        setCities(value)
      }
    }
  }

  const handleCityChange = (e) => {
    dispatch(setSecondFilter(e))
  }

  return (
    <>
      <SingleDropdownSelect
        value={country}
        onChange={handleCountryChange}
        title="Country"
      >
        <option>Choose Option</option>
        {Object.keys(countries).map((country, index) => (
          <option key={index} value={country}>
            {country}
          </option>
        ))}
      </SingleDropdownSelect>

      <MultiDropDownSelect
        selectedOption={secondFilter}
        title="City"
        setSelectedOption={handleCityChange}
        options={cities.map((city) => ({ label: city, value: city }))}
      ></MultiDropDownSelect>
    </>
  )
}

export default LocationFilter
