import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import Flex from '../../../../components/layouts/Flex'
import MainModal from '../../../../components/dashboard/modal/MainModal'

import RichTextbox from '../../../../components/dashboard/controls/RichTextBox'
import DashboardInput from '../../../../components/dashboard/DashboardInput'

import {
  insertRecord,
  updateRecord,
} from '../../../../store/actions/sidebarModuleActions'

const SaveModule = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const { loading, modal, selectedRecord } = useSelector(
    (state) => state.sidebarModules
  )

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [route, setRoute] = useState('')
  const [svgPath, setSvgPath] = useState('')

  useEffect(() => {
    if (modal === 'updateModule') {
      setName(selectedRecord.name)
      setDescription(selectedRecord.description)
      setRoute(selectedRecord.route)
      setSvgPath(selectedRecord.svg_path)
    }
  }, [])

  const handleSaveModule = () => {
    const request = {
      name,
      description: description.replace(/<\/?p[^>]*>/g, ''),
      route,
      svg_path: svgPath,
      user_type_id: id,
    }

    if (modal === 'updateModule') {
      dispatch(updateRecord(selectedRecord.id, request))
    } else {
      dispatch(insertRecord(request))
    }
  }

  return (
    <MainModal
      title={modal === 'updateModule' ? 'Update Module' : 'Create Module'}
      onClick={handleSaveModule}
      buttonText="Submit"
      loading={loading}
      size="large"
    >
      <Flex gap="0.4rem">
        <DashboardInput
          name="name"
          label="Name *"
          placeholder="Enter Name"
          type="text"
          height="2.5rem"
          value={name}
          handleChange={(e) => setName(e.target.value)}
        />
      </Flex>
      <Flex gap="0.4rem">
        <RichTextbox
          placeholder="Enter description..."
          label="Description"
          content={description}
          setContent={setDescription}
        />
      </Flex>
      <Flex gap="0.4rem">
        <DashboardInput
          name="route"
          label="Route *"
          placeholder="eg. /dashboard"
          type="text"
          height="2.5rem"
          value={route}
          handleChange={(e) => setRoute(e.target.value)}
        />
      </Flex>
      <Flex gap="0.4rem">
        <DashboardInput
          name="route"
          label="SVG Path *"
          placeholder="Enter only the child element of the SVG. Exclude the svg tags. This will display its corresponding icon in the sidebar."
          type="text"
          height="2.5rem"
          value={svgPath}
          handleChange={(e) => setSvgPath(e.target.value)}
        />
      </Flex>
    </MainModal>
  )
}

export default SaveModule
