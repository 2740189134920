import React, { useEffect } from 'react'

import styled from 'styled-components'
import { P } from '../../../style/Typography.style'

const TechnicalSkillCard = ({ experience, skill }) => {
  const [exprienceLevel, setExprienceLevel] = React.useState()

  const getStatus = (val) => {
    if (val === 1) {
      return 'Beginner'
    } else if (val === 2) {
      return 'Novice'
    } else if (val === 3) {
      return 'Skilled'
    } else if (val === 4) {
      return 'Experienced'
    } else if (val === 5) {
      return 'Expert'
    }
  }

  useEffect(() => {
    setExprienceLevel(() => getStatus(experience))
  }, [])

  return (
    <StyledTechnicalSkillCard>
      <div>
        <P size=".75rem" weight="500" marginBottom="2px">
          {skill}
        </P>
        <P size=".75rem">{exprienceLevel}</P>
      </div>

      <input type="range" value={experience} min="0" max="5" readOnly />
    </StyledTechnicalSkillCard>
  )
}

export default TechnicalSkillCard

const StyledTechnicalSkillCard = styled.div`
  width: 100%;
  display: flex;
  max-width: 300px;
  justify-content: space-between;
  margin-bottom: 1rem;

  input {
    color: #31374f;
    height: 5px;
    margin-top: 0.5rem;
    accent-color: #31374f;
  }
`
