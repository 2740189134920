import React from 'react'
import { ColorRing } from 'react-loader-spinner'

import styled from 'styled-components'

const Loader = () => {
  return (
    <LoaderWrapper>
      <ColorRing
        visible={true}
        height="24"
        width="24"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={['#000']}
      />
    </LoaderWrapper>
  )
}

export default Loader

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
`
