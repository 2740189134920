import React from 'react'
import styled from 'styled-components'

const CustomNumberInput = ({ value, onChange }) => {
  const increment = () => {
    onChange(Math.min(value + 1, 100))
  }

  const decrement = () => {
    onChange(Math.max(value - 1, 0))
  }

  const handleInputChange = (e) => {
    const newValue = parseInt(e.target.value)
    if (!isNaN(newValue)) {
      onChange(Math.min(Math.max(newValue, 0), 100))
    }
  }

  return (
    <NumberInputWrapper>
      <ChangeValueButton onClick={decrement}>-</ChangeValueButton>
      <NumberInput
        type="number"
        value={value}
        onChange={handleInputChange}
        min={0}
        max={100}
      />
      <ChangeValueButton onClick={increment}>+</ChangeValueButton>
    </NumberInputWrapper>
  )
}

export default CustomNumberInput

const NumberInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const NumberInput = styled.input`
  padding: 5px;
  font-size: 16px;
  text-align: center;
  font-weight: 300;
`

const ChangeValueButton = styled.button`
  background-color: rgb(26, 26, 26);
  border: 1px solid rgb(26, 26, 26);
  padding: 6px 8px;
  color: #f0f0f0;
  cursor: pointer;
`
