import React from 'react'

import styled from 'styled-components'

import { H6, P } from '../../../../style/Typography.style'
import Flex from '../../../layouts/Flex'

import hoplaThumb from '../../../../assets/image/hopla-thumb.jpg'

const StepThree = () => {
  return (
    <>
      <Flex padding="0 0 1rem 0" bB="1px solid #EBEBEB">
        <P>Job Post Preview</P>
      </Flex>

      <H6 mt="1.5rem" weight="700">
        Marketing Assistant
      </H6>

      <P weight="500">$1200 - $1500 a month</P>

      <P padding="1rem 0 0.75rem 0">
        Full-Time | Permanent Position | Australian Business Hours
      </P>

      <P padding="1rem 0" lineHeight="1.75">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.{' '}
      </P>

      {/* Others */}
      <Flex direction="column" gap="1.5rem" wrap="wrap" maxw="400px">
        <Flex direction="column" gap="0.5rem">
          <P size="0.875rem" weight="500">
            Experience
          </P>
          <P>Market Research: 2 Years</P>
          <P>Content Management: 3 Years</P>
        </Flex>
        <Flex direction="column" gap="0.5rem">
          <P size="0.875rem" weight="500">
            Education
          </P>
          <P>Bachelor’s Degree in Human Resources</P>
        </Flex>
        <Flex direction="column" gap="0.5rem">
          <P size="0.875rem" weight="500">
            Benefits
          </P>
          <P>Remote Work</P>
        </Flex>

        <Flex gap="1rem">
          <StyledLink>#HOPLA</StyledLink>
          <StyledLink>#Remote</StyledLink>
          <StyledLink>#ApplyNow</StyledLink>
        </Flex>
      </Flex>

      <img
        style={{ width: '100%', height: 'auto', marginTop: '1rem' }}
        src={hoplaThumb}
        alt="hopla"
      />
    </>
  )
}

export default StepThree

const StyledLink = styled.a`
  font-size: 0.75rem;
  font-weight: 300;
  color: #1877f2;
  text-decoration: none;
`
