import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { useToasts } from 'react-toast-notifications'

import Heading from '../../../components/dashboard/Heading'
import HeaderFilter from './components/HeaderFilter'
import UserTypeListWrapper from './components/UserTypeListWrapper'
import SaveUserType from './components/SaveUserType'

import { fetchList } from '../../../store/actions/userTypeActions'

import { convertObjectToUrlParams } from '../../../utils/stateHelpers'

const UserTypes = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { modal, notification, queryParams } = useSelector(
    (state) => state.userTypes
  )

  useEffect(() => {
    if (notification.value === 'success') {
      addToast(notification?.message, {
        appearance: 'success',
        autoDismiss: true,
      })
    }
    if (notification.value === 'error') {
      addToast(notification?.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }, [notification])

  useEffect(() => {
    const currentRole = JSON.parse(localStorage.getItem('user')).user_type
    const allowedAccess = ['admin']
    if (!allowedAccess.includes(currentRole)) {
      window.location.replace('/')
    }
  }, [])

  useEffect(() => {
    dispatch(
      fetchList(`/user-types/paged?${convertObjectToUrlParams(queryParams)}`)
    )
  }, [queryParams])

  const handleFetchList = (filters, url) => {
    dispatch(fetchList(filters, url))
  }

  return (
    <>
      {/* User Types heading */}
      <Heading title="User Types" subTitle={moment().format('MMMM Do, YYYY')} />

      {/* User Types filter, it contains the search filter */}
      <HeaderFilter fetchList={handleFetchList} />

      {/* Contains list of users */}
      <UserTypeListWrapper fetchUserTypeList={handleFetchList} />

      {/* Modal section */}
      {(modal === 'createUserType' || modal === 'updateUserType') && (
        <SaveUserType />
      )}
    </>
  )
}

export default UserTypes
