import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import DashboardInput from '../../../../components/dashboard/DashboardInput'
import useMutation from '../../../../hooks/useMutation'
import { useDispatch } from 'react-redux'
import { setModal, updateLists } from '../../../../store/actions/listsActions'

const CreateList = () => {
  const [listName, setListName] = useState('')
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { data, loading, error, success, handleRequest } = useMutation()

  useEffect(() => {
    if (success) {
      addToast(`Successfully created new list ${listName.trim()}`, {
        appearance: 'success',
        autoDismiss: true,
      })
      setListName('')
      dispatch(setModal(''))
      dispatch(updateLists(data.resp))
    }
  }, [success])

  useEffect(() => {
    if (error) {
      addToast(error?.response?.data?.message || 'Something went wrong', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }, [error])

  function handleListCreation() {
    if (listName.trim() === '')
      return addToast('List Name is required.', {
        appearance: 'error',
        autoDismiss: true,
      })
    handleRequest('/create-list', { list_name: listName.trim() })
  }

  return (
    <MainModal
      title="Create New List"
      onClick={handleListCreation}
      buttonText="Add"
      loading={loading}
    >
      <DashboardInput
        name="listName"
        label="List Name"
        placeholder=""
        type="text"
        height="2.5rem"
        value={listName}
        handleChange={(e) => setListName(e.target.value)}
      />
    </MainModal>
  )
}

export default CreateList
