import React, { useState } from 'react'
import { FiPlus } from 'react-icons/fi'

import { useDispatch, useSelector } from 'react-redux'

import DashboardButton from '../../../../components/dashboard/DashboardButton'
import FilterInput from '../../../../components/dashboard/FilterInput'
import Flex from '../../../../components/layouts/Flex'

import { setFilters, setModal } from '../../../../store/actions/clientActions'

const HeaderFilter = () => {
  const [filter, setFilter] = useState('')

  const modal = useSelector((state) => state.clients.modal)

  const dispatch = useDispatch()

  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type

  const handleFilter = async (e) => {
    e.preventDefault()
    dispatch(setFilters({ searchTerm: filter }))
  }

  const handleClientModal = () => {
    dispatch(setModal([...modal, 'clientForm']))
  }

  return (
    <Flex
      width="100%"
      margin="1.5rem 0 0 0"
      items="center"
      justify="space-between"
    >
      <Flex
        width="100%"
        as="form"
        items="center"
        justify="flex-start"
        gap="1rem"
        onSubmit={handleFilter}
      >
        <FilterInput
          maxW="300px"
          whiteSpace="nowrap"
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
          placeholder="Search..."
          handleClear={() => setFilter('')}
        />
        <DashboardButton text={'Search'} type="submit" />
      </Flex>

      <Flex width="180px" items="center" gap="0.5rem">
        {role === 'admin' && (
          <DashboardButton
            text="Add Client"
            type="button"
            notIcon
            externalIcon={<FiPlus className="icon" />}
            maxWidth="180px"
            onClick={handleClientModal}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default HeaderFilter
